import './ColMenu.css'
import { useNavigate, useParams } from 'react-router-dom'
import {openChangeCollectionNamePopup, fireCustomError, fireSuccessMessage, fireCustomRequestError, fireExportMessage, fireRequestError} from '../Common Functions/commonFunctions';
import Swal from 'sweetalert2';
import axios from 'axios';
import VariableContext from '../userContext/VariableContext';
import { useContext } from 'react';
import * as gaEvents from "../ga-utils"

function ColMenu({pageType}) {
const {getColList, setGetColList} = useContext(VariableContext)
const {currentAppRole} = useContext(VariableContext)
const {currentAppCols, setCurrentAppCols} = useContext(VariableContext)
const {appCol} = useContext(VariableContext)
// const {importArray, setImportArray} = useContext(VariableContext)


const {isUploadingDescription} = useContext(VariableContext)
const {isUploadingKeyword,} = useContext(VariableContext)
const {isUploadingWhatsnew,} = useContext(VariableContext)
const {isUploadingPhoto,} = useContext(VariableContext)
const {isUploadingPreview,} = useContext(VariableContext)
const {isUploading} = useContext(VariableContext)






  let {appId, collectionId} = useParams()
  let navigate = useNavigate()

  const deleteCollectionPopup = () =>{
    Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        position: 'center',
        width: '400',
        text: 'You are about to delete this collection. You can not take this action back!',
        backdrop: true,
        showConfirmButton: true,
        showCancelButton: true,     
        confirmButtonColor: '#cf000fe6',
        cancelButtonColor: '#3A6FF8e6',
        cancelButtonText: 'Cancel',      
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,     
        preConfirm: async () => {
            var data = JSON.stringify({
                "appId": appId,
                "collectionId" : collectionId,
              });
              
              var config = {
                method: 'delete',
                url: `${process.env.REACT_APP_API_URL}/app/collection`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                    'Content-Type': 'application/json'
                },
                data : data
              };
              
            try {
                const response = await axios(config);
                if(response.status === 200){
                    gaEvents.deleteCollectionSuccess(pageType+" "+"Page")
                    setGetColList(getColList + 1);
                    fireSuccessMessage(response);
                    appCol.current[appId] = ''
                    navigate(`/${appId}/${pageType}`);
                }
            } catch (error) {
              gaEvents.deleteCollectionFail()
              if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        })
  }

  // const exportCollection = () =>{
  //   if(pageType!=='screenshot'){
  //     fireExportMessage()
  //     var data = '';
  
  //     var config = {
  //       method: 'get',
  //       url: `${process.env.REACT_APP_API_URL}/app/collection/${appId}`,
  //       headers: { 
  //         'Authorization': `Bearer ${localStorage.getItem("token")}`
  //       },
  //       data : data
  //     };
      
  //     axios(config)
  //     .then(function (response) {
  //       setCurrentAppCols(response.data.data)
  
  //       var data = JSON.stringify({
  //         "appId": appId,
  //         "collectionId": collectionId,
  //         "title": response.data.data.find((i)=>i.collection_id===+collectionId).collection_title
  //       });
        
  //       var config = {
  //         method: 'post',
  //         url: `${process.env.REACT_APP_API_URL}/app/collection/${pageType==='whatsnew' ? 'whatsNew' : pageType}/export`,
  //         headers: { 
  //           'Authorization': `Bearer ${localStorage.getItem("token")}`, 
  //           'Content-Type': 'application/json'
  //         },
  //         data : data
  //       };
        
  //       axios(config)
  //       .then(function (response) {
          
  //           let a = document.createElement('a');
  //           a.href = `${process.env.REACT_APP_API_URL}/${response.data.data.path}`;
  //           a.download = `${response.data.data.path}`.split("/")[2]
  //           a.click();
  //           Swal.close()
  //       })
  //   })
  //     .catch(function (error) {
  //       if(error.message === 'Network Error'){
  //         fireCustomError('NETWORK ERROR. ')
  //       }else{
  //         fireRequestError(error)
  //       }
  //     });
  //   }else{
  //     document.getElementById('exportAppPopup'+collectionId).style.display = 'flex'
  //   }
    
  // }

  // const importCollection = () =>{
  //     document.getElementById('importAppPopup'+collectionId).style.display = 'flex'
  // }

  const lockUnlock = (bool) =>{
    var data = JSON.stringify({
      "appId": appId,
      "collectionId" : collectionId,
      "status": bool
    });
    
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/app/collection/lockStatus`,
      headers: { 
          'Authorization': `Bearer ${localStorage.getItem('token')}`, 
          'Content-Type': 'application/json'
      },
      data : data
    };
    axios(config)
    .then(function (response) {
      setCurrentAppCols((current)=>{
        let newCols = current.map((i)=>{
          if (i.collection_id===+collectionId){
            return {...i, locked:bool}
          }
          else{
            return i
          }
        })
        return newCols
      })
    })
    .catch(function (error) {
      if(error.message === 'Network Error'){
        fireCustomError('NETWORK ERROR. ')
      }else{
        fireRequestError(error)
      }
    });
  }

  return (
    <div id={"threeDotColMenu"} className={`three-dot-col-menu`}>
      <div
        style={
          isUploading.status ||
          isUploadingDescription.status ||
          isUploadingKeyword.status ||
          isUploadingPhoto.status ||
          isUploadingPreview.status ||
          isUploadingWhatsnew.status
            ? { cursor: "not-allowed" }
            : { cursor: "pointer" }
        }
        onClick={
          isUploading.status ||
          isUploadingDescription.status ||
          isUploadingKeyword.status ||
          isUploadingPhoto.status ||
          isUploadingPreview.status ||
          isUploadingWhatsnew.status
            ? () => {}
            : openChangeCollectionNamePopup
        }
        className={`three-dot-col-menu-rename colCustomize`}
      >
        Rename Collection
      </div>
      <div
        style={
          isUploading.status ||
          isUploadingDescription.status ||
          isUploadingKeyword.status ||
          isUploadingPhoto.status ||
          isUploadingPreview.status ||
          isUploadingWhatsnew.status
            ? { cursor: "not-allowed" }
            : { cursor: "pointer" }
        }
        onClick={
          isUploading.status ||
          isUploadingDescription.status ||
          isUploadingKeyword.status ||
          isUploadingPhoto.status ||
          isUploadingPreview.status ||
          isUploadingWhatsnew.status
            ? () => {}
            : currentAppCols
            ? currentAppCols.find((i) => i.collection_id === +collectionId)
              ? () =>
                  lockUnlock(
                    currentAppCols.find(
                      (i) => i.collection_id === +collectionId
                    ).locked
                      ? false
                      : true
                  )
              : () => {}
            : () => {}
        }
        className={`three-dot-col-menu-rename colCustomize`}
      >
        {currentAppCols
          ? currentAppCols.find((i) => i.collection_id === +collectionId)
            ? currentAppCols.find((i) => i.collection_id === +collectionId)
                .locked
              ? "Unlock Collection"
              : "Lock Collection"
            : ""
          : ""}
      </div>
      <div
        style={
          isUploading.status ||
          isUploadingDescription.status ||
          isUploadingKeyword.status ||
          isUploadingPhoto.status ||
          isUploadingPreview.status ||
          isUploadingWhatsnew.status
            ? { cursor: "not-allowed" }
            : { cursor: "pointer" }
        }
        onClick={
          isUploading.status ||
          isUploadingDescription.status ||
          isUploadingKeyword.status ||
          isUploadingPhoto.status ||
          isUploadingPreview.status ||
          isUploadingWhatsnew.status
            ? () => {}
            : deleteCollectionPopup
        }
        className={`three-dot-col-menu-delete colCustomize`}
      >
        Delete Collection
      </div>
    </div>
  );
}

export default ColMenu