import { Route, Routes, useParams } from 'react-router-dom'
import './OwnerPage.css'
// import CurrentOwnerPage from './CurrentOwnerPage'
import CollectionOwnerPage from './CollectionOwnerPage'
import CollectionOwnerBox from '../Components/CollectionOwnerBox/CollectionOwnerBox';



function OwnerPage() {

  let pageType = 'owner'
  let {appId, collectionId} = useParams()


  return (
    <div className='owner-content'>

        <div className="owner-inner-container">
          <Routes>
            <Route exact  path='/' element={
              <CollectionOwnerBox/>
            }>
            </Route>

            {/* <Route exact  path='/current' element={
              <CurrentOwnerPage/>
            }></Route> */}

            <Route exact  path={`/:collectionId`} element={
              <CollectionOwnerPage pageType={pageType}/>
            }></Route>
          </Routes> 
      </div>

    </div>
  )
}

export default OwnerPage