import { useContext } from 'react'
import VariableContext from '../../../userContext/VariableContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { fireCustomSuccessMessage, sortResponseCol, fireCustomError, fireRequestError } from '../../../Common Functions/commonFunctions';
import '../../CurrentItems/Screenshot/ScreenshotLocalItem.css'


function CollectionScreenshotNotLocalItem({data, func, count, class_name}) {

  const {collectionScreenshotLocalizations, setCollectionScreenshotLocalizations} = useContext(VariableContext)
  const {collectionNotLocalizations, setCollectionNotLocalizations} = useContext(VariableContext)
  // const {languageSignal, setLanguageSignal} = useContext(VariableContext)
  const {currentAppPrimaryLocale} = useContext(VariableContext)

  const {isUploadingDescription, setIsUploadingDescription} = useContext(VariableContext)
  const {isUploadingKeyword, setIsUploadingKeyword} = useContext(VariableContext)
  const {isUploadingWhatsnew, setIsUploadingWhatsnew} = useContext(VariableContext)
  const {isUploadingPhoto, setIsUploadingPhoto} = useContext(VariableContext)
  const {isUploadingPreview, setIsUploadingPreview} = useContext(VariableContext)
  const {isUploading, setIsUploading} = useContext(VariableContext)

  let {appId, collectionId} = useParams()

  const addLanguage = () =>{
    
    var dataa = JSON.stringify({
      "appId": appId,
      "collectionId": collectionId,
      "language": data.value
    });

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/app/collection/language`,
      headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json'},
      data : dataa
    };

    axios(config)
    .then(function (response) {    

          setCollectionScreenshotLocalizations(sortResponseCol([...collectionScreenshotLocalizations, {'screenshot_localization_id': response.data.data.addition.screenshot, 'language_code': data.value, 'locale_name' : data.label}], currentAppPrimaryLocale))  
          setCollectionNotLocalizations(collectionNotLocalizations.filter((i)=>i.value!==data.value))
          func(response.data.data.addition.screenshot, data.value, data.label)
          fireCustomSuccessMessage(`${data.label} added`)
    })
    .catch(function (error) {
      if(error.message === 'Network Error'){
        fireCustomError('NETWORK ERROR. ')
      }else{
        fireRequestError(error)
      }
    });
  }

  return (
    <span className={class_name}>
      <span style={{width: '100%', padding: '15px 15px'}}>{data.label}</span>
      {isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status ?
      ''
      :
      <span className='add-language' onClick={addLanguage}>
        <img src="/assets/Icons/addOn.svg" alt="add" />
      </span>
      }
    </span>
  )
}

export default CollectionScreenshotNotLocalItem