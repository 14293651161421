import {useState, useContext} from 'react'
import SpinnerSmall from '../Misc/SpinnerSmall'
import axios from 'axios'
import './ForgotPassword.css'
import { fireRequestError, fireSuccessMessage, fireCustomError } from '../Common Functions/commonFunctions'
import * as gaEvents from "../ga-utils"
import VariableContext from '../userContext/VariableContext'
import { useNavigate } from 'react-router-dom'

function DeleteAccount({func}) {
    const {setInitialUserData} = useContext(VariableContext)
    const {setQuota} = useContext(VariableContext)
    const {setSubWarning} = useContext(VariableContext)
    const {setAppUploads} = useContext(VariableContext)
  
    const {setCurrentAppId}= useContext(VariableContext)
    const {setCurrentAppIcon} = useContext(VariableContext)
    const {setCurrentAppName} = useContext(VariableContext)
    const {setCurrentAppLocalizations} = useContext(VariableContext)
    const {setPrimaryLocale} = useContext(VariableContext)
    const {setCurrentAppCols} = useContext(VariableContext)
    const {setCurrentAppVersion} = useContext(VariableContext)
  
    const {setColDescriptions} = useContext(VariableContext)
    const {setColKeywords} = useContext(VariableContext)
    const {setColWhatsNews} = useContext(VariableContext)
    const {setColScreenshots} = useContext(VariableContext)
    const {setColPrimaryScreenshots} = useContext(VariableContext)
  
    const {setCurrentAppRole} = useContext(VariableContext)
    const {setSelectedAppColName} = useContext(VariableContext)
    const {setSelectedAppColId} = useContext(VariableContext)
    
    const {setVersionEdit} = useContext(VariableContext)
  
    const {appCol} = useContext(VariableContext)
    const {setRegistryArray} = useContext(VariableContext)
    const {setImportArray} = useContext(VariableContext)
    const {setImportPercentage} = useContext(VariableContext)
  
  
    // const {setName} = useContext(VariableContext)
    // const {setLanguage} = useContext(VariableContext)
    const {setCollectionLocalizations} = useContext(VariableContext)
    const {setCollectionScreenshotLocalizations} = useContext(VariableContext)
    const {setCollectionNotLocalizations} = useContext(VariableContext)
  
    const {setIsUploadingDescription} = useContext(VariableContext)
    const {setIsUploadingKeyword} = useContext(VariableContext)
    const {setIsUploadingWhatsnew} = useContext(VariableContext)
    const {setIsUploadingPhoto} = useContext(VariableContext)
    const {setIsUploading} = useContext(VariableContext)
    const {setLastUpload} = useContext(VariableContext)
  
    const {setUploadMessageDescription} = useContext(VariableContext)
    const {setUploadMessageKeyword} = useContext(VariableContext)
    const {setUploadMessageWhatsnew} = useContext(VariableContext)
    const {setUploadMessagePhoto} = useContext(VariableContext)
    const {setUploadMessagePreview} = useContext(VariableContext)
    const {setUploadMessage} = useContext(VariableContext)
  
    const {setSummaryUpload} = useContext(VariableContext)
    const {setSummary} = useContext(VariableContext)
  
    const {setSocket} = useContext(VariableContext)

    let navigate = useNavigate()
    const[deleting, setDeleting] = useState(false)
    const[pass, setPass] = useState('')


    //popup pass input
    const handlePassChange = (e) =>{
        setPass(e.target.value)
    }

    const handleDeletion = () => {

        localStorage.clear()
    
        setInitialUserData(0)
        
        setQuota(null)
        setSubWarning(null)
        setAppUploads([])
    
        setCurrentAppId(0)
        setCurrentAppIcon(null)
        setCurrentAppName(null)
        setCurrentAppRole(null)
        setCurrentAppVersion(null)
        setCurrentAppLocalizations(null)
        setPrimaryLocale(null)
        setCurrentAppCols(null)
    
        setColDescriptions(null)
        setColKeywords(null)
        setColWhatsNews(null)
        setColScreenshots(null)
        setColPrimaryScreenshots(null)
    
        // setLanguage('')
        // setName('')
    
        setCollectionLocalizations(null)
        setCollectionScreenshotLocalizations(null)
        setCollectionNotLocalizations(null)
      
        setSelectedAppColName(null)
        setSelectedAppColId(0)
    
        setVersionEdit(null)
        setRegistryArray(new Map())
        appCol.current= {}
    
        setImportArray(new Map())
        setImportPercentage(new Map())
        setSummary(null)
    
        setIsUploadingDescription({status:false, appID: null, collectionID: null})
        setIsUploadingKeyword({status:false, appID: null, collectionID: null})
        setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
        setIsUploadingPhoto({status:false, appID: null, collectionID: null})
        setIsUploading({status:false, appID: null, collectionID: null})
    
        setUploadMessageDescription([{status:0}])
        setUploadMessageKeyword([{status:0}])
        setUploadMessageWhatsnew([{status:0}])
        setUploadMessagePhoto([{status:0}])
        setUploadMessagePreview([{status:0}])
        setUploadMessage([{status:0}])
    
        setSummaryUpload(null)
    
        setSocket(0)
        setLastUpload(null)

        // func(false)
    
        navigate('/login')
      }

    //popup request and close popup

    const sendMailE = (e) =>{ 
        e.preventDefault()
        setDeleting(true)
        var data = JSON.stringify({
            "password": pass,

        });
        
        var config = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}/auth`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then(function (response) {
            handleDeletion()
            fireSuccessMessage(response)
        })
        .catch(function (error) {

            func(false)
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
        });   
    }



    return (
        <div id='forgotPpassPopup' className='forgot-pass-popup-container'>
            <div className='forgotPpassPopupBack' onClick={deleting ? ()=>{} : ()=>{func(false)}}></div>
            <div className='forgot-pass-popup-content'>
                <span className="forgot-pass-popup-name">Delete Account</span>

                <form className='forgot-pass-form' onSubmit={sendMailE}>

                    <input required={true} id='forgotPpassForm' onChange={handlePassChange} className="forgot-pass-popup-form" type="password" value={pass} placeholder='Enter Your Password'/>

                    <input type='submit' hidden={true} disabled={deleting}/>

                    <div className="forgotPpassPopupButtons">
                        <button disabled={deleting} type='submit' className="forgot-pass-popup-button">
                            {!deleting ? 'Confirm' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>
                        <span disabled={deleting} onClick={!deleting ? ()=>func(false) : ()=>{}} className="forgot-pass-popup-button2 popupCancel">
                            Cancel 
                        </span>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default DeleteAccount