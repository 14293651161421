import './OwnerKeyword.css'

function OwnerKeyword({data, type}) {
  return (
    <div className="owner-keyword-item">

      <div className="owner-keyword-div">

          <div className="owner-section-name">
              <p className='owner-name'>{type} </p>
          </div>
          <p className='owner-keyword-count' style={{display:'none'}}>{!data ? 0 : data.length}/100</p>

      </div>

      <div className="owner-keyword-field">
          <textarea readOnly={true} className='owner-keyword-textarea' name="owner-keyword-textarea" cols="50" rows="1" value={!data ? '' : data} 
          ></textarea>
      </div>

    </div>
  )
}

export default OwnerKeyword