import { fireExportMessage, fireCustomRequestError, fireCustomError, fireRequestError } from "../Common Functions/commonFunctions"
import axios from "axios"
import VariableContext from "../userContext/VariableContext"
import { useParams } from "react-router-dom"
import SpinnerSmall from "../Misc/SpinnerSmall"
import { useState, useContext } from "react"
import Swal from "sweetalert2"
import * as gaEvents from "../ga-utils"
import Qmark from "../Misc/Qmark"

function Export({pageType, colId}) {
    const {setCurrentAppCols} = useContext(VariableContext)
    const [isWaiting, setIsWaiting] = useState(false)
    const [isWaitingSC, setIsWaitingSC] = useState(false)
    const [isWaitingPR, setIsWaitingPR] = useState(false)
    const [isWaitingB, setIsWaitingB] = useState(false)

    const [answer, setAnswer] = useState(null)
 
    let {appId} = useParams()

    const closePopup = () =>{
        let elem = document.getElementsByName("item");
        for(let i=0; i<elem.length; i++){
          elem[i].checked = false;
          if(i+1===elem.length){
            setIsWaiting(false)
            setIsWaitingSC(false)
            setIsWaitingPR(false)
            setIsWaitingB(false)
            setAnswer(null)
            document.getElementById('exportAppPopup'+colId).style.display = 'none'
          }
        }
    }

    const answerQuestion = (answer) =>{
        exportCollection(answer)
        if(answer===3){
          setIsWaitingSC(true)
        }
        else if(answer===1){
          setIsWaitingPR(true)
        }
        else{
          setIsWaitingB(true)
        }
    }



    const exportCollection = (answer) =>{
        setIsWaiting(true)
        fireExportMessage()
        var data = '';
    
        var config = {
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/app/collection/${appId}`,
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          setCurrentAppCols(response.data.data)
    
          var data = JSON.stringify({
            "appId": appId,
            "collectionId": colId,
            "title": response.data.data.find((i)=>i.collection_id===+colId).collection_title
          });
          
          var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/${pageType==='whatsnew' ? 'whatsNew' : 
                pageType==='screenshot'&& answer===3 ? pageType : pageType==='screenshot'&& answer===1 ? 'preview' : pageType==='screenshot'&& answer===2 ? 'media' : pageType}/export`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem("token")}`, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            gaEvents.exportSuccess(pageType)
            let a = document.createElement('a');
            a.href = `${process.env.REACT_APP_API_URL}/${response.data.data.path}`;
            a.download = `${response.data.data.path}`.split("/")[2]
            a.click();
            Swal.close()
            closePopup()
          })
          .catch((error)=>{
            gaEvents.exportFail(pageType)
            if(error.message === 'Network Error'){
              fireCustomError('NETWORK ERROR. ')
            }else{
              fireRequestError(error)
            }
            closePopup()
          })
          
      })
        .catch(function (error) {
          gaEvents.exportFail(pageType)
          if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            fireRequestError(error)
          }
          closePopup()
        });
    }
    
    return (
        <div id={'exportAppPopup'+colId} className='import-popup-container'>
            <div className='importPopupBack' onClick={isWaiting ? ()=>{} : ()=>{closePopup()}}></div>
            <div className='import-popup-content-pre-2'>
                <span className="import-popup-name">What do you want to export? <Qmark link="https://doc.storesyncer.com/export/how-export-screenshots-and-previews"/></span>
                <div onChange={(e)=>{setAnswer(+e.target.value)}} className="importPopupButtons2">

                        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start', borderRadius:'10px', backgroundColor:'#f5f5f5', width:'380px', height:'50px', fontWeight:'500', fontSize:'20px', padding:'20px 30px', marginBottom:'10px'}}>
                          <label>
                            <input value={3} disabled={isWaitingSC} type="radio" name='item'/>&nbsp;&nbsp;Screenshots
                            </label>
                        </div>

                        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start', borderRadius:'10px', backgroundColor:'#f5f5f5', width:'380px', height:'50px', fontWeight:'500', fontSize:'20px', padding:'20px 30px', marginBottom:'10px'}}>
                          <label>
                            <input value={1} disabled={isWaitingPR} type="radio" name='item'/>&nbsp;&nbsp;Previews
                          </label>
                        </div>

                        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start', borderRadius:'10px', backgroundColor:'#f5f5f5', width:'380px', height:'50px', fontWeight:'500', fontSize:'20px', padding:'20px 30px', marginBottom:'15px'}}>
                          <label>
                            <input value={2} disabled={isWaitingB} type="radio" name='item'/>&nbsp;&nbsp;Both
                            </label>
                        </div>

                        <span style={{fontSize: '0.8rem', fontStyle: 'italic', margin:'15px 60px 25px 60px', display:'flex', alignItems:'center'}}>*Every screenshot and/or preview for every device and language in this collection will be exported.</span>

                        <span style={isWaiting||!answer ? {cursor:'not-allowed', marginRight:'0', backgroundColor:'#3A6FF8'} : { marginRight:'0', backgroundColor:'#3A6FF8'}} onClick={!isWaiting&&answer ? ()=>answerQuestion(answer) : ()=>{}} className="import-popup-button2-pre popupCancel">
                            {isWaiting ? <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> : 'OK' }
                        </span>
                        
                </div>
            </div>
        </div>
    )
}

export default Export