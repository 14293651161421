import {useContext, useEffect, useState} from 'react'
import VariableContext from '../userContext/VariableContext'
import { NavLink, useParams } from 'react-router-dom'
import './AskScreenshotUpload.css'
import * as gaEvents from "../ga-utils"
import Qmark from '../Misc/Qmark'

function AskScreenshotUpload({func}) {

    let {appId, collectionId} = useParams()

    const {initialUserData} = useContext(VariableContext)
    const {currentAppQuota} = useContext(VariableContext)
    const {currentAppRole} = useContext(VariableContext)

    const {setIsUploadingPhoto} = useContext(VariableContext)
    const {setIsUploadingPreview} = useContext(VariableContext)
    const {setUploadSignal} = useContext(VariableContext)

    const {setUploadMessagePhoto} = useContext(VariableContext)
    const {setUploadMessagePreview} = useContext(VariableContext)

    const [answer, setAnswer] = useState(null)



    //popup close
    const closePopup = () => {
        func(false)
        
    }

    const uploadE = () =>{ 

        if(answer===3){
            setIsUploadingPhoto({status:'initiated', appID: +appId, collectionID: +collectionId})
            gaEvents.uploadStarted("screenshot")
        }
        else if(answer===1){
            setIsUploadingPreview({status:'initiated', appID: +appId, collectionID: +collectionId})
            gaEvents.uploadStarted("preview")
        }
        else{
            setIsUploadingPhoto({status:'initiated', appID: +appId, collectionID: +collectionId})
            setIsUploadingPreview({status:'initiated', appID: +appId, collectionID: +collectionId})
            setUploadMessagePhoto([{status:1}])
            setUploadMessagePreview([{status:1}])
            gaEvents.uploadStarted("previewScreenshot")
        }
        setUploadSignal(true)
        closePopup()  
    }

    return (
      <div id="AskcollectionPopup" className="Ask-popup-container">
        <div
          className="Ask-collectionPopupBack"
          onClick={() => {
            closePopup();
          }}
        ></div>
        <div className="Ask-popup-content">
          <span className="Ask-popup-name">
            What would you like to upload? <Qmark link='https://doc.storesyncer.com/upload-to-app-store-connect/upload-screenshots-and-previews'/>
          </span>

          <form className="Ask-collection-form" onSubmit={uploadE}>
            <div
              onChange={(e) => {
                setAnswer(+e.target.value);
              }}
              className="importPopupButtons2"
            >
            {/* screenshots */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  borderRadius: "10px",
                  backgroundColor: "#f5f5f5",
                  width: "380px",
                  height: "50px",
                  fontWeight: "500",
                  fontSize: "20px",
                  padding: "20px 30px",
                  marginBottom: "10px",
                }}
              >
                <label>
                  <input 
                    disabled={currentAppQuota.screenshot_upload_appstore === 0}
                    value={3} 
                    required={true} 
                    type="radio"
                    name="item" 
                  />
                  &nbsp;&nbsp;
                  <span
                    style={
                      currentAppQuota.screenshot_upload_appstore === 0
                        ? { color: "#aaa" }
                        : { color: "unset" }
                    }
                  >
                    Screenshots
                  </span>
                {/* quota reached */}
                  {(currentAppQuota.screenshot_data_storage === 1 &&
                      currentAppQuota.screenshot_upload_appstore === 0) ?
                        <>
                            &nbsp;
                            <img
                                style={{ width: "21px" }}
                                src="/assets/Icons/lock-fill.svg"
                                alt="locked"
                            />
                            &nbsp;
                            <NavLink
                                to={"/subscription"}
                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                            >
                                (upload limit reached)
                            </NavLink>
                        </>
                  :
                        ''
                  }
                </label>
              </div>

            {/* previews */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  borderRadius: "10px",
                  backgroundColor: "#f5f5f5",
                  width: "380px",
                  height: "50px",
                  fontWeight: "500",
                  fontSize: "20px",
                  padding: "20px 30px",
                  marginBottom: "10px",
                }}
              >
                <label>
                  <input
                    disabled={
                      (initialUserData.account_type === "free" &&
                        currentAppRole === "owner") ||
                      currentAppQuota.preview_upload_appstore === 0
                    }
                    value={1}
                    type="radio"
                    name="item"
                  />
                  &nbsp;&nbsp;
                  <span
                    style={
                      (initialUserData.account_type === "free" &&
                        currentAppRole === "owner") ||
                      currentAppQuota.preview_upload_appstore === 0
                        ? { color: "#aaa" }
                        : { color: "unset" }
                    }
                  >
                    Previews
                  </span>

                  {/* free user is owner */}
                  {(initialUserData.account_type === "free" &&
                      currentAppRole === "owner") ?
                        <>
                            &nbsp;
                            <img
                                style={{ width: "21px" }}
                                src="/assets/Icons/lock-fill.svg"
                                alt="locked"
                            />
                            &nbsp;
                            <NavLink
                                to={"/subscription"}
                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                            >
                                (upgrade your subscription)
                            </NavLink>
                        </>
                  :
                        ''
                  }

                  {/* quota reached */}
                  {(currentAppQuota.preview_data_storage === 1 &&
                      currentAppQuota.preview_upload_appstore === 0) ?
                        <>
                            &nbsp;
                            <img
                                style={{ width: "21px" }}
                                src="/assets/Icons/lock-fill.svg"
                                alt="locked"
                            />
                            &nbsp;
                            <NavLink
                                to={"/subscription"}
                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                            >
                                (upload limit reached)
                            </NavLink>
                        </>
                  :
                        ''
                  }
                </label>
              </div>

            {/* both */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  borderRadius: "10px",
                  backgroundColor: "#f5f5f5",
                  width: "380px",
                  height: "50px",
                  fontWeight: "500",
                  fontSize: "20px",
                  padding: "20px 30px",
                  marginBottom: "10px",
                }}
              >
                <label>
                  <input
                    disabled={
                      (initialUserData.account_type === "free" &&
                        currentAppRole === "owner") ||
                      currentAppQuota.screenshot_upload_appstore === 0 ||
                      currentAppQuota.preview_upload_appstore === 0
                    }
                    value={2}
                    type="radio"
                    name="item"
                  />
                  &nbsp;&nbsp;
                  <span
                    style={
                      (initialUserData.account_type === "free" &&
                        currentAppRole === "owner") ||
                      currentAppQuota.screenshot_upload_appstore === 0 ||
                      currentAppQuota.preview_upload_appstore === 0
                        ? { color: "#aaa" }
                        : { color: "unset" }
                    }
                  >
                    Both
                  </span>
                {/* free user is owner */}
                  {(initialUserData.account_type === "free" &&
                      currentAppRole === "owner") ?
                        <>
                            &nbsp;
                            <img
                                style={{ width: "21px" }}
                                src="/assets/Icons/lock-fill.svg"
                                alt="locked"
                            />
                            &nbsp;
                            <NavLink
                                to={"/subscription"}
                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                            >
                                (upgrade your subscription)
                            </NavLink>
                        </>
                  :
                        ''
                  }

                  {/* quota reached */}
                  {(currentAppQuota.preview_data_storage === 1 &&
                   currentAppQuota.screenshot_data_storage === 1) &&
                      (currentAppQuota.preview_upload_appstore === 0 || 
                       currentAppQuota.screenshot_upload_appstore === 0) ?
                        <>
                            &nbsp;
                            <img
                                style={{ width: "21px" }}
                                src="/assets/Icons/lock-fill.svg"
                                alt="locked"
                            />
                            &nbsp;
                            <NavLink
                                to={"/subscription"}
                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                            >
                                (upload limit reached)
                            </NavLink>
                        </>
                  :
                        ''
                  }
                </label>
              </div>

              <span
                style={{
                  fontSize: "0.8rem",
                  fontStyle: "italic",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  width: "380px",
                  marginBottom: "20px",
                  textAlign: "justify",
                }}
              >
                *If there are no previews in this collection, when you select
                "Previews" or "Both" for your upload, your previews will be
                deleted from App Store connect. Same applies for "Screenshots".
              </span>
            </div>

            <input type="submit" hidden={true} />
            <div className="Ask-collectionPopupButtons">
              <button
                disabled={!answer}
                type="submit"
                className="Ask-popup-button"
              >
                Upload
              </button>
              <span
                onClick={() => closePopup()}
                className="Ask-popup-button2 popupCancel"
              >
                Cancel
              </span>
            </div>
          </form>
        </div>
      </div>
    );
}

export default AskScreenshotUpload