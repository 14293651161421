import {useContext} from 'react'
import VariableContext from '../userContext/VariableContext';
import './SummaryPopup.css'

//import summary
function SummaryPopup() { 
    const {summary, setSummary} = useContext(VariableContext)
 
    //popup close reset states
    const closePopup = () => {
        setSummary(null)
    }

    return ( 
        <div id={'summaryPopup'} className='summary-popup-container'>
            <div className='summaryPopupBack' onClick={()=>closePopup()}></div>

                <div className='summary-popup-content'>

                    <span className="summary-popup-name">Summary</span>
                    
                    <div className='summary-div'>

                        <p className="summaryHeading">
                            Invalid Items&nbsp;
                            {summary.invalid.photo.length===0 && summary.invalid.preview.length===0 && summary.invalid.description.length===0 && summary.invalid.whatsNew.length===0 && summary.invalid.keyword.length===0 ? 
                                <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                            :
                                <img src="/assets/Icons/x.svg" alt="negative_mark" />
                            }
                        </p>

                            {summary.invalid.photo.length===0 && summary.invalid.preview.length===0 && summary.invalid.description.length===0 && summary.invalid.whatsNew.length===0 && summary.invalid.keyword.length===0 ? 
                                <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>
                                    <p className="summaryP">No invalid items.</p>
                                </div>
                            :
                                ''
                            }

                            {/* photo */}

                            {summary.invalid.photo.length===0 ? 
                                ''
                            :
                                <p className="summarySubHeading">Screenshots</p>
                            }


                            {summary.invalid.photo.length===0 ? '' 
                            
                            :
                                <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>

                                    {summary&&summary.invalid.photo.map((i,index)=>{
                                        return(
                                            <p key={index} className='summaryP'>
                                                ❌&nbsp;
                                                <span style={{fontWeight: 'bold'}}>Language:</span> {i.language} &nbsp;&nbsp; 
                                                <span style={{fontWeight: 'bold'}}>Size:</span> {i.size} &nbsp;&nbsp;
                                                <span style={{fontWeight: 'bold'}}>FileName:</span> {i.fileName} &nbsp;&nbsp; 
                                                <span style={{fontWeight: 'bold'}}>Error:</span> {i.error}
                                            </p>
                                        )
                                    })}

                                </div>
                            }



                            {/* Previews */}

                            {summary.invalid.preview.length===0 ? 
                                ''
                            :
                                <p className="summarySubHeading">Previews</p>
                            }


                            {summary.invalid.preview.length===0 ? '' 
                            
                            :
                                <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>

                                    {summary&&summary.invalid.preview.map((i,index)=>{
                                        return(
                                            <p key={index} className='summaryP'>
                                                ❌&nbsp;
                                                <span style={{fontWeight: 'bold'}}>Language:</span> {i.language} &nbsp;&nbsp; 
                                                <span style={{fontWeight: 'bold'}}>Size:</span> {i.size} &nbsp;&nbsp;
                                                <span style={{fontWeight: 'bold'}}>FileName:</span> {i.fileName} &nbsp;&nbsp; 
                                                <span style={{fontWeight: 'bold'}}>Error:</span> {i.error}
                                            </p>
                                        )
                                    })}

                                </div>
                            }



                            {/* Description */}

                            {summary.invalid.description.length===0 ? 
                                ''
                            :
                                <p className="summarySubHeading">Description</p>
                            }

                            {summary.invalid.description.length===0 ? 
                                    ''
                                :
                                    <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>
                                        

                                        {summary&&summary.invalid.description.map((i,index)=>{
                                            return(
                                                <p key={index} className='summaryP'>
                                                    ❌&nbsp;<span style={{fontWeight: 'bold'}}>Language:</span> {i.language} &nbsp;&nbsp; <span style={{fontWeight: 'bold'}}>Error:</span> {i.error}
                                                </p>
                                            )
                                        })}
                                    </div>
                            }

                            {/* keyword */}

                            {summary.invalid.keyword.length===0 ? 
                                ''
                            :
                                <p className="summarySubHeading">Keywords</p>
                            }

                            {summary.invalid.keyword.length===0 ? 
                                    ''
                                :
                                    <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>
                                        

                                        {summary&&summary.invalid.keyword.map((i,index)=>{
                                            return(
                                                <p key={index} className='summaryP'>
                                                    ❌&nbsp;<span style={{fontWeight: 'bold'}}>Language:</span> {i.language} &nbsp;&nbsp; <span style={{fontWeight: 'bold'}}>Error:</span> {i.error}
                                                </p>
                                            )
                                        })}
                                    </div>
                            }
                            

                            {/*  whatsNew */}

                            {summary.invalid.whatsNew.length===0 ? 
                                ''
                            :
                                <p className="summarySubHeading">What's New</p>
                            }

                            
                            {summary.invalid.whatsNew.length===0 ? 
                                    ''
                                :
                                    <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>
                                        

                                        {summary&&summary.invalid.whatsNew.map((i,index)=>{
                                            return(
                                                <p key={index} className='summaryP'>
                                                    ❌&nbsp;<span style={{fontWeight: 'bold'}}>Language:</span> {i.language} &nbsp;&nbsp; <span style={{fontWeight: 'bold'}}>Error:</span> {i.error}
                                                </p>
                                            )
                                        })}
                                    </div>
                            }


                        <p className="summaryHeading">
                            Errors&nbsp;
                            {summary.error.photo.length===0 && summary.error.preview.length===0 && summary.error.description.length===0 && summary.error.whatsNew.length===0 && summary.error.keyword.length===0 ? 
                                <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                            :
                                <img src="/assets/Icons/x.svg" alt="negative_mark" />
                            }
                        </p>


                            {summary.error.photo.length===0 && summary.error.preview.length===0 && summary.error.description.length===0 && summary.error.whatsNew.length===0 && summary.error.keyword.length===0 ? 
                                <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>
                                    <p className="summaryP">No errors.</p>
                                </div>
                            :
                                ''
                            }

                            {/* photo */}

                            {summary.error.photo.length===0 ? 
                                ''
                            :
                                <p className="summarySubHeading">Screenshots</p>
                            }


                            {summary.error.photo.length===0 ? '' 
                            
                            :
                                <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>

                                    {summary&&summary.error.photo.map((i,index)=>{
                                        return(
                                            <p key={index} className='summaryP'>
                                                ❌&nbsp;
                                                <span style={{fontWeight: 'bold'}}>Language:</span> {i.language} &nbsp;&nbsp; 
                                                <span style={{fontWeight: 'bold'}}>Size:</span> {i.size} &nbsp;&nbsp;
                                                <span style={{fontWeight: 'bold'}}>FileName:</span> {i.fileName} &nbsp;&nbsp; 
                                                <span style={{fontWeight: 'bold'}}>Error:</span> {i.error}
                                            </p>
                                        )
                                    })}

                                </div>
                            }

                            {/* Previews */}

                            {summary.error.preview.length===0 ? 
                                ''
                            :
                                <p className="summarySubHeading">Previews</p>
                            }


                            {summary.error.preview.length===0 ? '' 
                            
                            :
                                <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>

                                    {summary&&summary.error.preview.map((i,index)=>{
                                        return(
                                            <p key={index} className='summaryP'>
                                                ❌&nbsp;
                                                <span style={{fontWeight: 'bold'}}>Language:</span> {i.language} &nbsp;&nbsp; 
                                                <span style={{fontWeight: 'bold'}}>Size:</span> {i.size} &nbsp;&nbsp;
                                                <span style={{fontWeight: 'bold'}}>FileName:</span> {i.fileName} &nbsp;&nbsp; 
                                                <span style={{fontWeight: 'bold'}}>Error:</span> {i.error}
                                            </p>
                                        )
                                    })}

                                </div>
                            }


                            {/* Description */}

                            {summary.error.description.length===0 ? 
                                ''
                            :
                                <p className="summarySubHeading">Description</p>
                            }

                            {summary.error.description.length===0 ? 
                                    ''
                                :
                                    <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>
                                        

                                        {summary&&summary.error.description.map((i,index)=>{
                                            return(
                                                <p key={index} className='summaryP'>
                                                    ❌&nbsp;<span style={{fontWeight: 'bold'}}>Language:</span> {i.language} &nbsp;&nbsp; <span style={{fontWeight: 'bold'}}>Error:</span> {i.error}
                                                </p>
                                            )
                                        })}
                                    </div>
                            }

                            {/* keyword */}

                            {summary.error.keyword.length===0 ? 
                                ''
                            :
                                <p className="summarySubHeading">Keywords</p>
                            }

                            {summary.error.keyword.length===0 ? 
                                    ''
                                :
                                    <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>
                                        

                                        {summary&&summary.error.keyword.map((i,index)=>{
                                            return(
                                                <p key={index} className='summaryP'>
                                                    ❌&nbsp;<span style={{fontWeight: 'bold'}}>Language:</span> {i.language} &nbsp;&nbsp; <span style={{fontWeight: 'bold'}}>Error:</span> {i.error}
                                                </p>
                                            )
                                        })}
                                    </div>
                            }

                            {/*  whatsNew */}

                            {summary.error.whatsNew.length===0 ? 
                                ''
                            :
                                <p className="summarySubHeading">What's New</p>
                            }

                            
                            {summary.error.whatsNew.length===0 ? 
                                    ''
                                :
                                    <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>
                                        

                                        {summary&&summary.error.whatsNew.map((i,index)=>{
                                            return(
                                                <p key={index} className='summaryP'>
                                                    ❌&nbsp;<span style={{fontWeight: 'bold'}}>Language:</span> {i.language} &nbsp;&nbsp; <span style={{fontWeight: 'bold'}}>Error:</span> {i.error}
                                                </p>
                                            )
                                        })}
                                    </div>
                            }
                            


                    </div>

                    <div className="summaryPopupButtons">
                        <span onClick={()=>closePopup()} className="summary-popup-button2 popupCancel">
                            OK 
                        </span>
                    </div>
                </div>
        </div>
    )
}




export default SummaryPopup