import {useContext, useState} from 'react'
import SpinnerSmall from '../Misc/SpinnerSmall'
import VariableContext from '../userContext/VariableContext'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './EditTeamMemberPopup.css'
import { fireRequestError, fireSuccessMessage, fireCustomError } from '../Common Functions/commonFunctions'
import * as gaEvents from '../ga-utils'
import Qmark from '../Misc/Qmark'

function EditTeamMemberPopup({userId, index}) {
    let {appId} = useParams()
    const[editingMember, setEditingMember] = useState(false)
    const[role, setRole] = useState('')

    const{getRoles,setGetRoles} = useContext(VariableContext)
    const{setCurrentAppRole} = useContext(VariableContext)
    const{initialUserData} = useContext(VariableContext)

    //popup close
    const closePopup = () => {
        document.getElementById('editMemberPopup'+index).style.display = 'none'
        setRole('')
    }

    const handleRoleChange = (e) =>{
        setRole(e.target.value)
    }

    //popup request and close popup

    const editMemberE = (e) =>{ 
        e.preventDefault()
        setEditingMember(true)
        var data = JSON.stringify({
            "userId": userId,
            "appId": appId,
            "newRole": role

        });
        
        var config = {
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/role`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then(function (response) {
        gaEvents.editMemberSuccess()
        document.getElementById('editMemberPopup'+index).style.display = 'none'
        setEditingMember(false)
        setGetRoles(getRoles+1)
        if(initialUserData.user_id === userId){
            setCurrentAppRole(role)
        }
        setRole('')
        fireSuccessMessage(response)
        })
        .catch(function (error) {
        gaEvents.editMemberFail()
        document.getElementById('editMemberPopup'+index).style.display = 'none'
        setEditingMember(false)
        // setGetRoles(true)
        setRole('')
        
        if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            fireRequestError(error)
          }
        });   
    }



    return (
        <div id={'editMemberPopup'+index} className='edit-member-popup-container'>
            <div className='editMemberPopupBack' onClick={editingMember ? ()=>{} : ()=>{closePopup()}}></div>
            <div className='edit-member-popup-content'>
                <span className="edit-member-popup-name">Edit Member <Qmark link="https://doc.storesyncer.com/get-started/invite-team-members-assign-roles" /></span>

                <form className='edit-member-form' onSubmit={editMemberE}>







                    <select required={true} onChange={handleRoleChange} value={role} className="edit-member-popup-role">
                        <option defaultValue={true} disabled="disabled" value="">Select New Role</option>
                        
                        <option value="app-manager">App Manager</option>
                        <option value="designer">Designer</option>
                        <option value="content-manager">Content Manager</option>
                    </select>







                    <input type='submit' hidden={true} disabled={editingMember}/>
                    <div className="editMemberPopupButtons">
                        <button disabled={editingMember} type='submit' className="edit-member-popup-button">
                            {!editingMember ? 'Confirm' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>
                        <span disabled={editingMember} onClick={!editingMember ? ()=>closePopup() : ()=>{}} className="edit-member-popup-button2 popupCancel">
                            Cancel 
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditTeamMemberPopup