import { useEffect, useState} from 'react'
import './CurrentWhatsNewItem.css'
import {useParams} from 'react-router-dom'


function CurrentWhatsNewItem({data, count}) {

  const [text, setText] = useState('')
  const [textLenght, setTextLenght] = useState(0)
  const [name] = useState(data.attributes.locale_name)

  // const{isUploading} = useContext(VariableContext)
  // const{isUploadingWhatsnew} = useContext(VariableContext)



  useEffect(()=>{
    if(data.attributes.whatsNew!==null){
      setTextLenght(data.attributes.whatsNew.length)
      setText(data.attributes.whatsNew)
    }
  },[])

  useEffect(()=>{
    if(text.length>100){
      setTextLenght(100)
    }else{
      setTextLenght(text.length)
    }

  },[text])


  return (
    <div id={name} className="whatsnew-item">

    <div className="country-div">

        <div className="country-flag-name">
            <p className='country-name'>{name} {count===0 ? <span className='primary'>- Primary</span> : ''}</p>
        </div>

        <p className='character-count'>{textLenght}/4000</p>

    </div>

    <div className="whatsnew-field">
        <textarea className='whatsnew-textarea' name="whatsnew-textarea" cols="50" rows="4" value={text}  readOnly={true} 
        ></textarea>
    </div>

</div>
  )
}

export default CurrentWhatsNewItem