import '../../CurrentItems/Screenshot/ScreenshotLocalItem.css'
import { useContext } from 'react'
import VariableContext from '../../../userContext/VariableContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { fireCustomSuccessMessage, sortValue, fireCustomError, fireRequestError } from '../../../Common Functions/commonFunctions';


function CollectionScreenshotLocalItem({data, func, count, class_name}) {

  const {collectionScreenshotLocalizations} = useContext(VariableContext)
  const {languageSignal, setLanguageSignal} = useContext(VariableContext)

  const {isUploadingDescription, setIsUploadingDescription} = useContext(VariableContext)
  const {isUploadingKeyword, setIsUploadingKeyword} = useContext(VariableContext)
  const {isUploadingWhatsnew, setIsUploadingWhatsnew} = useContext(VariableContext)
  const {isUploadingPhoto, setIsUploadingPhoto} = useContext(VariableContext)
  const {isUploadingPreview, setIsUploadingPreview} = useContext(VariableContext)
  const {isUploading, setIsUploading} = useContext(VariableContext)

  let {appId, collectionId} = useParams()

  const deleteLanguage = () =>{
    var dataa = {
      "appId":appId,
      "collectionId":collectionId,
      "language":data.language_code
    }

    var config = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/app/collection/language`,
      headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`},
      data : dataa
    };

    axios(config)
    .then(function (response) {
      setLanguageSignal(languageSignal+1)
      func(collectionScreenshotLocalizations[0].screenshot_localization_id, collectionScreenshotLocalizations[0].language_code, collectionScreenshotLocalizations[0].locale_name)
      fireCustomSuccessMessage(`${data.locale_name} deleted`)
    })
    .catch(function (error) {
      if(error.message === 'Network Error'){
        fireCustomError('NETWORK ERROR. ')
      }else{
        fireRequestError(error)
      }
    });
  }

  return (
    

    <span className={class_name}>
    <span style={{width: '100%', padding: '15px 15px'}} onClick={()=>{func(data.screenshot_localization_id, data.language_code, data.locale_name)}}>{data.locale_name}&nbsp;&nbsp;{count===0 ? <span className='primary'>Primary</span> : ''}</span>
    {isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status ?
      ''
      :
      <span className='delete-language' onClick={deleteLanguage}>
        <img src="/assets/Icons/delete.svg" alt="del" />
      </span>
      }
  </span>
  )
}

export default CollectionScreenshotLocalItem