import { useEffect, useState} from 'react'
import './CurrentKeywordItem.css'


function CurrentKeywordItem({data, count}) {

  const [text, setText] = useState('')
  const [textLenght, setTextLenght] = useState(0)
  const [name] = useState(data.attributes.locale_name)

  // const{isUploading} = useContext(VariableContext)
  // const{isUploadingKeyword} = useContext(VariableContext)


  useEffect(()=>{
    if(data.attributes.keywords!==null){
      setTextLenght(data.attributes.keywords.length)
      setText(data.attributes.keywords)
    }
  },[])

  return (
    <div id={name} className="keyword-item">

    <div className="country-div">

        <div className="country-flag-name">
            <p className='country-name'>{name} {count===0 ? <span className='primary'>- Primary</span> : ''}</p>
        </div>

        <p className='character-count'>{textLenght}/100</p>

    </div>

    <div className="keyword-field">
        <textarea className='keyword-textarea' name="keyword-textarea" cols="50" rows="1" value={text} readOnly={true} 
        ></textarea>
    </div>

</div>
  )
}

export default CurrentKeywordItem