import { useContext } from 'react';
import './SidebarItem.css'
import VariableContext from '../userContext/VariableContext';
import {NavLink} from 'react-router-dom'

function SidebarItem({data, func}) {

  return (
    <NavLink to={`/${data.app_id}`} className={({ isActive }) =>
    isActive ? 'active-link bgs' : 'si '}>
      <div className="sidebar-item" 
      onClick={()=>{func(data.icon, data.app, data.app_id, data.primary_locale, data.role)}}
      >
        <img className='list-app-icon' src={!data.icon ? '/assets/Icons/AddAppImage.svg' : data.icon} alt="AppImage" />
        <p className='app-name'>{data.app}</p>
        {/* <div className='active-url-indicator' style={{backgroundColor:'#F3802D'}}></div> */}

      </div>
    </NavLink>
  )
}

export default SidebarItem