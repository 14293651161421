import './CurrentScreenshotItemv2.css'
import ReactTooltip from "react-tooltip";
import {useState, useLayoutEffect, useContext, useEffect} from 'react'
import axios from 'axios'
import Spinner from '../../../Misc/Spinner'
import { useParams } from 'react-router-dom'
import VariableContext from '../../../userContext/VariableContext'
import { fireRequestError, fireCustomErrorV2, fireCustomError } from '../../../Common Functions/commonFunctions'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import jurisdiction from '../../../Jusristiction/juristiction';
import Video from './Video'
import React from 'react';


function CurrentScreenshotItemv2({primaryLocalizationId, uniqueId, classId, localizationId, deviceSize, devicePreviewSize, screenshots, previews, primaryLocaleCode, primaryLanguageName, primaryLocaleScreenshots, primaryLocalePreviews}) {

    const [slideOpen, setSlideOpen] = useState(false)

    const playerReference = React.useRef(null);

    // const [isWaiting, setIsWaiting] = useState(false)
    // const [isWaiting2, setIsWaiting2] = useState(false)

    const [videoPlay, setVideoPlay] = useState(null)

    const [screenshotSet, setScreenshotSet] = useState(screenshots.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}) || false)
    const [primaryScreenshotSet, setPrimaryScreenshotSet] = useState(primaryLocaleScreenshots.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}) || false)
    const [forOrder, setForOrder] = useState([])

    const [screenshotSetImg, setScreenshotSetImg] = useState(screenshots)
    const [primaryScreenshotSetImg, setPrimaryScreenshotSetImg] = useState(primaryLocaleScreenshots)

    const [previewSet, setPreviewSet] = useState(previews.find((i)=>{return i.attributes.previewType === devicePreviewSize}) || false)
    const [primaryPreviewSet, setPrimaryPreviewSet] = useState(primaryLocalePreviews.find((i)=>{return i.attributes.previewType === devicePreviewSize}) || false)
    const [forPreviewOrder, setForPreviewOrder] = useState([])

    const [previewSetImg, setPreviewSetImg] = useState(previews)
    const [primaryPreviewSetImg, setPrimaryPreviewsSetImg] = useState(primaryLocalePreviews)

    const {internalRefreshSmall, setInternalRefreshSmall} = useContext(VariableContext)
    const {internalRefreshBig, setInternalRefreshBig} = useContext(VariableContext)
    const [internalRefresh, setInternalRefresh] = useState(false)

    const {internalPreviewRefreshSmall, setInternalPreviewRefreshSmall} = useContext(VariableContext)
    const {internalPreviewRefreshBig, setInternalPreviewRefreshBig} = useContext(VariableContext)
    const [internalPreviewRefresh, setInternalPreviewRefresh] = useState(false)

    const{isUploading} = useContext(VariableContext)
    const{isUploadingPhoto} = useContext(VariableContext)
    const{currentAppRole} = useContext(VariableContext)

    let {appId} = useParams()

    let slideIndex=1;



    function handleOnDragEnd(result) {
        if(jurisdiction.order.includes(currentAppRole)){
            if (!result.destination) return;

            const items = Array.from(forOrder);
            const [reorderedItem] = items.splice(result.source.index, 1);;
            items.splice(result.destination.index, 0, reorderedItem);
    
            if(isUploadingPhoto.status !== 'initiated' && isUploadingPhoto.status !== true && isUploading.status !== 'initiated' && isUploading.status!==true && jurisdiction.order.includes(currentAppRole)){
                setForOrder(items);
            }
    
            let order = []
            for (let i=0; i<items.length; i++){
                order.push(items[i].id)
                
                if(i===items.length-1){
                     var data = JSON.stringify({
                        "appId": appId,
                        "idList": order,
                        "setId": screenshotSet.id
                      });
                      var config = {
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}/app/current/screenshotOrder`,
                        headers: { 
                          'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                          'Content-Type': 'application/json'
                        },
                        data : data
                      };
                      
                      axios(config)
                      .then(function (response) {
                        if(deviceSize==='APP_IPHONE_55'){
                            setInternalRefreshSmall(true)
                        }else if (deviceSize==='APP_IPHONE_65'){
                            setInternalRefreshBig(true)
                        }
                        else{
                            setInternalRefresh(true)
                        }
                      })
                      .catch(function (error) {
                        if(error.message === 'Network Error'){
                            fireCustomError('NETWORK ERROR. ')
                          }else{
                            fireRequestError(error)
                          }
                      });
                }
            }
        }else{
            fireCustomErrorV2('Only owner of the app can do this operation')
        }


    }
    function handleOnDragEndPreview(result) {
        // if(jurisdiction.order.includes(currentAppRole)){
        //     if (!result.destination) return;

        //     const items = Array.from(forPreviewOrder);
        //     const [reorderedItem] = items.splice(result.source.index, 1);;
        //     items.splice(result.destination.index, 0, reorderedItem);
    
        //     if(isUploadingPhoto.status !== 'initiated' && isUploadingPhoto.status !== true && isUploading.status !== 'initiated' && isUploading.status!==true && jurisdiction.order.includes(currentAppRole)){
        //         setForPreviewOrder(items);
        //     }
    
        //     let order = []
        //     for (let i=0; i<items.length; i++){
        //         order.push(items[i].id)
        //         
        //         if(i===items.length-1){
        //              var data = JSON.stringify({
        //                 "appId": appId,
        //                 "idList": order,
        //                 "setId": previewSet.id
        //               });
        //               var config = {
        //                 method: 'post',
        //                 url: `${process.env.REACT_APP_API_URL}/app/current/previewOrder`,
        //                 headers: { 
        //                   'Authorization': `Bearer ${localStorage.getItem('token')}`, 
        //                   'Content-Type': 'application/json'
        //                 },
        //                 data : data
        //               };
                      
        //               axios(config)
        //               .then(function (response) {
        //                 
        //                 if(devicePreviewSize==='IPHONE_55'){
        //                     setInternalPreviewRefreshSmall(true)
        //                 }else if (devicePreviewSize==='IPHONE_65'){
        //                     setInternalPreviewRefreshBig(true)
        //                 }
        //                 else{
        //                     setInternalPreviewRefresh(true)
        //                 }
        //               })
        //               .catch(function (error) {
        //                 fireRequestError(error)
        //               });
        //         }
        //     }
        // }else{
        //     fireCustomErrorV2('Only owner of the app can do this operation')
        // }


    }

    useEffect(()=>{
        if(slideOpen===true){
            window.addEventListener('keydown', arrowKeys)
        }
        else{
            window.removeEventListener('keydown', arrowKeys)
        }
        return(()=>
            window.removeEventListener('keydown', arrowKeys)
        )
    },[slideOpen])


    function openLightbox(key) {
        document.getElementById(`lightbox${key}`).style.display = 'block';
        document.getElementById(`lightbox${key}`).focus()
        setSlideOpen(true)
    }  
    function closeSlide(e, key){
        if(e.target.id ==='next' || e.target.id ==='previous' || e.target.className === 'current-screenshots-slide'){

        }else{
            closeLightbox(key)
        }
    }
    function closeLightbox(key) {
        setSlideOpen(false)
        document.getElementById(`lightbox${key}`).style.display = 'none';
    }
    function arrowKeys (e){
        if (e.keyCode === 37) {
            e.preventDefault()
            // left arrow
            changeSlide(-1,uniqueId)
         }
         else if (e.keyCode === 39) {
            e.preventDefault()
            // right arrow
            changeSlide(1,uniqueId)
         }  
    }
    function changeSlide(n, key) {
        showSlide(slideIndex += n, key);
    } 
    function toSlide(n, key) {
        showSlide(slideIndex = n, key);
    }
    function showSlide(n, key) {

        const slides = document.getElementsByClassName(`slide${key}`);

        if (n > slides.length) {
            slideIndex = 1;	
          }

        if (n < 1) {
            slideIndex = slides.length;
        }
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = 'block';
    }


    useLayoutEffect(()=>{
        if(screenshotSet !== false){
            setForOrder(screenshotSet.screenshots)}
        if(previewSet !== false){
            setForPreviewOrder(previewSet.previews)}
    },[screenshotSet, previewSet])

    //resimleri boyutlarına göre yenile
    const getScreenshots = async () =>{
        
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/screenshot?appId=${appId}&locId=${localizationId}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
              };
              axios(config)
            .then((response)=>{
                setScreenshotSetImg(response.data.data)
                setScreenshotSet(response.data.data.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}) || false)
                if(localizationId === primaryLocalizationId){
                    setPrimaryScreenshotSetImg(response.data.data)
                    setPrimaryScreenshotSet(response.data.data.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}) || false)
                }
                setInternalRefreshBig(false)
                setInternalRefresh(false)
                setInternalRefreshSmall(false)

            })
            .catch((error)=>{
                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
                setInternalRefreshBig(false)
                setInternalRefresh(false)
                setInternalRefreshSmall(false)
            })
        
    }
    const getPriScreenshots = async () =>{
            if(localizationId !== primaryLocalizationId){
                var config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}/app/screenshot?appId=${appId}&locId=${primaryLocalizationId}`,
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                  };
                  axios(config)
                .then((response)=>{
                    setPrimaryScreenshotSetImg(response.data.data)
                    setPrimaryScreenshotSet(response.data.data.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}) || false)
                    setInternalRefreshBig(false)
                    setInternalRefresh(false)
                    setInternalRefreshSmall(false)
    
                })
                .catch((error)=>{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                    setInternalRefreshBig(false)
                    setInternalRefresh(false)
                    setInternalRefreshSmall(false)
                })
            }

        
    }
    useLayoutEffect(()=>{
        if(internalRefreshBig === true && deviceSize>'APP_IPHONE_55'){
            Promise.all(
                [getScreenshots(), getPriScreenshots()]
            )
        }
        if(internalRefreshSmall === true && deviceSize<='APP_IPHONE_55'){
            Promise.all(
                [getScreenshots(), getPriScreenshots()]
            )
        }
        if(internalRefresh === true){
            Promise.all(
                [getScreenshots(), getPriScreenshots()]
            )
        }
    },[internalRefresh, internalRefreshBig, internalRefreshSmall])




    //previewleri boyutlarına göre yenile
    const getPreviews = async () =>{
        
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/preview?appId=${appId}&locId=${localizationId}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
          };
          axios(config)
        .then((response)=>{
            setPreviewSetImg(response.data.data)
            setPreviewSet(response.data.data.find((i)=>{return i.attributes.previewType === devicePreviewSize}) || false)
            if(localizationId === primaryLocalizationId){
                setPrimaryPreviewsSetImg(response.data.data)
                setPrimaryPreviewSet(response.data.data.find((i)=>{return i.attributes.previewType === devicePreviewSize}) || false)
            }
            setInternalPreviewRefreshBig(false)
            setInternalPreviewRefresh(false)
            setInternalPreviewRefreshSmall(false)

        })
        .catch((error)=>{
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            setInternalPreviewRefreshBig(false)
            setInternalPreviewRefresh(false)
            setInternalPreviewRefreshSmall(false)
        })
    
    }
    const getPriPreviews = async () =>{
            if(localizationId !== primaryLocalizationId){
                var config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}/app/preview?appId=${appId}&locId=${primaryLocalizationId}`,
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                };
                axios(config)
                .then((response)=>{
                    setPrimaryPreviewsSetImg(response.data.data)
                    setPrimaryPreviewSet(response.data.data.find((i)=>{return i.attributes.previewType === devicePreviewSize}) || false)
                    setInternalPreviewRefreshBig(false)
                    setInternalPreviewRefresh(false)
                    setInternalPreviewRefreshSmall(false)

                })
                .catch((error)=>{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                    setInternalPreviewRefreshBig(false)
                    setInternalPreviewRefresh(false)
                    setInternalPreviewRefreshSmall(false)
                })
            }

        
    }
    useLayoutEffect(()=>{
        if(internalPreviewRefreshBig === true && devicePreviewSize>'IPHONE_55'){
            Promise.all(
                [getPreviews(), getPriPreviews()]
            )
        }
        if(internalPreviewRefreshSmall === true && devicePreviewSize<='IPHONE_55'){
            Promise.all(
                [getPreviews(), getPriPreviews()]
            )
        }
        if(internalPreviewRefresh === true){
            Promise.all(
                [getPreviews(), getPriPreviews()]
            )
        }
    },[internalPreviewRefresh, internalPreviewRefreshBig, internalPreviewRefreshSmall])


    useEffect(() => {
        if(deviceSize==='APP_IPHONE_65' || deviceSize==='APP_IPHONE_55'){
            openScreenshots()
        }
    }, [])


    const openScreenshots = () =>{
        document.getElementById('current-screenshot-set'+uniqueId).classList.toggle('current-unhid')
        document.getElementById('current-preview-set'+uniqueId).classList.toggle('current-unhid')
        document.getElementById('current-screenshot-icon'+uniqueId).classList.toggle('current-rotate')    
        // 
    }


    const playerReady = (player) => {
        playerReference.current = player;
        
        // handling video player
        player.on('waiting', () => {
           
        });
        player.on('dispose', () => {
            
        });
     };


  return (
    <div id={`${uniqueId+classId}`} className={`current-screenshot-item ${classId}`}>

        <div onClick={()=>openScreenshots()} className='current-screenshot-device-name'>
            {deviceSize === 'APP_IPHONE_35' ? 
            <span className='device-title'><p><strong>iPhone 3.5" Display </strong>(Optional)</p></span> :
            deviceSize === 'APP_IPHONE_40' ? 
            <span className='device-title'><p><strong>iPhone 4" Display </strong>(Optional)</p></span> :
            deviceSize === 'APP_IPHONE_47' ? 
            <span className='device-title'><p><strong>iPhone 4.7" Display </strong>(Optional)</p></span> :
            deviceSize === 'APP_IPHONE_55' ? 
            <span className='device-title'><p><strong>iPhone 5.5" Display</strong></p></span> :
            deviceSize === 'APP_IPHONE_58' ? 
            <span className='device-title'><p><strong>iPhone 5.8" Display </strong>(Optional)</p></span> :  
            deviceSize === 'APP_IPHONE_61' ?  
            <span className='device-title'><p><strong>iPhone 6.1" Display </strong>(Optional)</p></span> : 
            deviceSize === 'APP_IPHONE_65' ?  
            <span className='device-title'><p><strong>iPhone 6.5" Display</strong></p></span> : 
            deviceSize === 'APP_IPHONE_67' ?  
            <span className='device-title'><p><strong>iPhone 6.7" Display </strong>(Optional)</p></span> : 
            'Unknown Device'}

            <img id={'current-screenshot-icon'+uniqueId} className='current-screenshot-icon' src="/assets/Icons/down-arrow-black.svg" alt="down_arrow" />
        </div>


        <div className='current-previews-screenshots'>

            <div id={'current-preview-set'+uniqueId} className='current-preview-set'>

                <div className={`current-preview-set-info ${previewSet  ? '' : 'if-set-from-primay'}`}>
                    {/* dolu mu boş mu */}
                    {!previewSet ? 
                    //Boş
                        //cihaz 55 den büyük mü
                        devicePreviewSize > 'IPHONE_55' ?
                            //büyük
                            //kendisinde 65 cihaz var mı 
                            (previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}) || false) ?
                                //var
                                <span className='additional-info'>Using 6.5" Display</span>
                            :
                                //yok
                                //primary de varmı cihazdan
                                primaryPreviewSet ? 
                                    //var
                                    <span className='additional-info' > 
                                        {
                                            devicePreviewSize === 'IPHONE_58' ? 
                                            <span>Using {`${primaryLanguageName}`} 5.8" Display</span> :
                                            devicePreviewSize === 'IPHONE_61' ?  
                                            <span>Using {`${primaryLanguageName}`} 6.1" Display</span> : 
                                            devicePreviewSize === 'IPHONE_65' ?  
                                            <span>Using {`${primaryLanguageName}`} 6.5" Display</span> : 
                                            devicePreviewSize === 'IPHONE_67' ?  
                                            <span>Using {`${primaryLanguageName}`} 6.7" Display</span> : 
                                        ''}
                                    </span>
                                :
                                    //yok
                                    //primaryde 65 var mı
                                    (primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}) || false) ?
                                        //var
                                        <span className='additional-info'>Using {`${primaryLanguageName}`} 6.5" Display</span>
                                    :
                                        //yok
                                        <span className='additional-info'>&nbsp;</span>
                        :
                        //cihaz 55 den küçük veya eşit
                        //kendisinde 55 cihaz var mı 
                        (previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}) || false) ?
                            //var
                            <span className='additional-info'>Using 5.5" Display</span>
                        :
                            //yok                   
                            //primary de varmı cihazdan
                            primaryPreviewSet ? 
                            //var
                            <span className='additional-info' > 
                                {
                                    devicePreviewSize === 'IPHONE_35' ? 
                                    <span>Using {`${primaryLanguageName}`} 3.5" Display</span> :
                                    devicePreviewSize === 'IPHONE_40' ?  
                                    <span>Using {`${primaryLanguageName}`} 4" Display</span> : 
                                    devicePreviewSize === 'IPHONE_47' ?  
                                    <span>Using {`${primaryLanguageName}`} 4.7" Display</span> : 
                                    devicePreviewSize === 'IPHONE_55' ?  
                                    <span>Using {`${primaryLanguageName}`} 5.5" Display</span> : 
                                ''}
                            </span>
                        :
                            //yok
                            //primaryde 55 var mı
                            (primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}) || false) ?
                                //var
                                <span className='additional-info'>Using {`${primaryLanguageName}`} 5.5" Display</span>
                            :
                                //yok
                                <span className='additional-info'>&nbsp;</span>
                    :
                    //Dolu
                        ''            
                    }


                    <span style={{marginLeft: '15px', fontSize: '0.9rem'}}>
                        {
                            !previewSet ?
                            //Boş
                            //cihaz 55 den büyük mü
                            devicePreviewSize > 'IPHONE_55' ?
                                //cihaz 55 den büyük
                                //kendisinde 65 var mı
                                (previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}) || false) ?
                                    //kendisinde 65 var
                                    <span>{previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}).previews.length}/3</span>
                                :
                                    //kendisinde 65 yok
                                    //primary de cihazın kendi var mı
                                    primaryPreviewSet ?
                                        //primary de cihazın kendisi var
                                        <span>{primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === devicePreviewSize}).previews.length}/3</span>
                                    :
                                        //primary de cihazın kendisi yok
                                        //primary de 65 var mı
                                        (primaryPreviewSetImg.find((i)=>{return i.attributes.PreviewType === 'IPHONE_65'}) || false) ?
                                            //primary de 65 var 
                                            <span>{primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}).previews.length}/3</span>
                                        :
                                            //primary de 65 yok
                                            '0/3'  
                            :
                                //cihaz 55 e eşit ya da küçük
                                //primary de cihazın kendi var mı
                                primaryPreviewSet ?
                                    //primary de cihazın kendisi var
                                    <span>{primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === devicePreviewSize}).previews.length}/3</span>
                                :
                                    //primary de cihazın kendisi yok
                                    //primary de 55 var mı
                                    (primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}) || false) ?
                                        //primary de 55 var 
                                        <span>{primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}).previews.length}/3</span>
                                    :
                                        //primary de 55 yok
                                        '0/3'
                        :
                            //Dolu
                            <span>{previewSetImg.find((i)=>{return i.attributes.previewType === devicePreviewSize}).previews.length}/3</span>
                        }
                    </span>
                </div>

                <div id={`row-previews-${uniqueId}`} className={`row`}>
                    {true ?
                        //cihazın seçilen localde fotosu dolu mu   
                        !previewSet ?
                            //Boş
                            //cihaz 55 den büyük mü
                            devicePreviewSize > 'IPHONE_55' ?
                                //cihaz 55 den büyük
                                //kendisinde 65 var mı
                                (previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}) || false) ?
                                    //kendisinde 65 var
                                    previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}).previews.map((previews, index)=>{
                                            return(
                                                <span key={index + uniqueId + 'previews'}>
                                                    <div
                                                        data-background-color='#cf000f'
                                                        data-place="top" 
                                                        data-effect="float"
                                                        data-for={"custom-preview-class"+index + uniqueId} 
                                                        data-tip='Preview has been uploaded but is being processed by Apple.'  
                                                        className="col missing-current">
                                                            <img className='current-previews' 
                                                                src={
                                                                    previews.attributes.videoUrl ? previews.attributes.previewImage ?

                                                                        previews.attributes.previewImage.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg") :
                                                                        '/assets/Images/image_placeholder.jpg' 
                                                                    :
                                                                        '/assets/Images/image_placeholder.jpg'
                                                                }  
                                                            />
                                                            <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>

                                                    </div>
                                                    {!previews.attributes.videoUrl ? <ReactTooltip id={"custom-preview-class"+index + uniqueId}/> : ''}
                                                </span>
                                    )})
                                :
                                    //kendisinde 65 yok
                                    //primary de cihazın kendi var mı
                                    primaryPreviewSet ?
                                        //primary de cihazın kendisi var
                                        primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === devicePreviewSize}).previews.map((previews, index)=>{
                                            return(
                                                <span key={index + uniqueId + 'previews'}>
                                                    <div
                                                        data-background-color='#cf000f'
                                                        data-place="top" 
                                                        data-effect="float"
                                                        data-for={"custom-preview-class"+index + uniqueId} 
                                                        data-tip='Image has been uploaded but is being processed by Apple.'  
                                                        className="col missing-current">
                                                            <img className='current-previews' 
                                                                src={
                                                                    previews.attributes.videoUrl ? previews.attributes.previewImage ?

                                                                        previews.attributes.previewImage.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg") :
                                                                        '/assets/Images/image_placeholder.jpg' 
                                                                    :
                                                                        '/assets/Images/image_placeholder.jpg'
                                                                }  
                                                                />
                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                                
                                                    </div>
                                                    {!previews.attributes.videoUrl ? <ReactTooltip id={"custom-preview-class"+index + uniqueId}/> : ''}
                                                </span>
                                        )})
                                    :
                                        //primary de cihazın kendisi yok
                                        //primary de 65 var mı
                                        (primaryScreenshotSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}) || false) ?
                                            //primary de 65 var 
                                            primaryScreenshotSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}).previews.map((previews, index)=>{
                                                return(
                                                    <span key={index + uniqueId + 'previews'}>
                                                        <div
                                                            data-background-color='#cf000f'
                                                            data-place="top" 
                                                            data-effect="float"
                                                            data-for={"custom-preview-class"+index + uniqueId} 
                                                            data-tip='Image has been uploaded but is being processed by Apple.'  
                                                            className="col missing-current">
                                                                <img className='current-previews' 
                                                                      src={
                                                                        previews.attributes.videoUrl ? previews.attributes.previewImage ?
        
                                                                            previews.attributes.previewImage.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg") :
                                                                            '/assets/Images/image_placeholder.jpg' 
                                                                        :
                                                                            '/assets/Images/image_placeholder.jpg'
                                                                    }  
                                                                    />
                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                                
                                                        </div>
                                                        {!previews.attributes.videoUrl ? <ReactTooltip id={"custom-preview-class"+index + uniqueId}/> : ''}
                                                    </span>
                                                    
                                            )})
                                        :
                                            //primary de 65 yok
                                            <div className='drag-file-empty' id={"drag-file-empty-preview"+uniqueId} >No Previews available</div>  

                            :
                                //cihaz 55 eşit ya da küçük
                                //kendisinde 55 var mı
                                (previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}) || false) ?
                                    //kendisinde 55 var
                                    previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}).previews.map((previews, index)=>{
                                            return(
                                                <span key={index + uniqueId + 'previews'}>
                                                    <div
                                                        data-background-color='#cf000f'
                                                        data-place="top" 
                                                        data-effect="float"
                                                        data-for={"custom-preview-class"+index + uniqueId} 
                                                        data-tip='Preview has been uploaded but is being processed by Apple.'  
                                                        className="col missing-current">
                                                            <img className='current-previews' 
                                                                src={
                                                                    previews.attributes.videoUrl ? previews.attributes.previewImage ?
    
                                                                        previews.attributes.previewImage.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg") :
                                                                        '/assets/Images/image_placeholder.jpg' 
                                                                    :
                                                                        '/assets/Images/image_placeholder.jpg'
                                                                } 
                                                            />
                                                            <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>

                                                    </div>
                                                    {!previews.attributes.videoUrl ? <ReactTooltip id={"custom-preview-class"+index + uniqueId}/> : ''}
                                                </span>
                                    )})
                                :
                                    //kendisinde 55 yok
                                    //primary de cihazın kendi var mı
                                    primaryPreviewSet ?
                                        //primary de cihazın kendisi var
                                        primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === devicePreviewSize}).previews.map((previews, index)=>{
                                            return(
                                                <span key={index + uniqueId + 'previews'}>
                                                    <div
                                                        data-background-color='#cf000f'
                                                        data-place="top" 
                                                        data-effect="float"
                                                        data-for={"custom-preview-class"+index + uniqueId} 
                                                        data-tip='Image has been uploaded but is being processed by Apple.'  
                                                        className="col missing-current">
                                                            <img className='current-previews' 
                                                                src={
                                                                    previews.attributes.videoUrl ? previews.attributes.previewImage ?

                                                                        previews.attributes.previewImage.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg") :
                                                                        '/assets/Images/image_placeholder.jpg' 
                                                                    :
                                                                        '/assets/Images/image_placeholder.jpg'
                                                                }  
                                                            />
                                                            <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                                
                                                    </div>
                                                    {!previews.attributes.videoUrl ? <ReactTooltip id={"custom-preview-class"+index + uniqueId}/> : ''}
                                                </span>
                                        )})
                                    :
                                        //primary de cihazın kendisi yok
                                        //primary de 55 var mı
                                        (primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}) || false) ?
                                            //primary de 55 var 
                                            primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}).previews.map((previews, index)=>{
                                                return(
                                                    <span key={index + uniqueId + 'previews'}>
                                                        <div
                                                            data-background-color='#cf000f'
                                                            data-place="top" 
                                                            data-effect="float"
                                                            data-for={"custom-preview-class"+index + uniqueId} 
                                                            data-tip='Image has been uploaded but is being processed by Apple.'  
                                                            className="col missing-current">
                                                                <img className='current-previews' 
                                                                    src={
                                                                        previews.attributes.videoUrl ? previews.attributes.previewImage ?

                                                                            previews.attributes.previewImage.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg") :
                                                                            '/assets/Images/image_placeholder.jpg' 
                                                                        :
                                                                            '/assets/Images/image_placeholder.jpg'
                                                                    }   
                                                                />
                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                                    
                                                        </div>
                                                        {!previews.attributes.videoUrl ? <ReactTooltip id={"custom-preview-class"+index + uniqueId}/> : ''}
                                                    </span>
                                            )})
                                        :
                                            //primary de 55 yok
                                            <div className='drag-file-empty' id={"drag-file-empty"+uniqueId} >No Previews available</div>  

                        :
                            //Dolu
                            // <DragDropContext onDragEnd={handleOnDragEndPreview}>
                            //     <Droppable droppableId="col-list-preview" direction="horizontal">
                            //         {(provided) => (
                                        <ul className="col-list-preview"
                                        //  {...provided.droppableProps} ref={provided.innerRef}
                                         >
                                            {forPreviewOrder.map((previews, index)=>{
                                                return(
                                                    // <Draggable key={index + uniqueId + 'previews'} draggableId={uniqueId+index + 'previews'} index={index}>
                                                    <span key={index + uniqueId + 'previews'}>
                                                        {/* {(provided) => (    */}
                                                            {/* <li id={previews.id} className={`col ${uniqueId+index}previews`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} */}
                                                            <li id={previews.id} className={`col ${uniqueId+index}previews`}
                                                            data-background-color='#cf000f'
                                                            data-place="top" 
                                                            data-effect="float"
                                                            data-for={"custom-preview-class"+index + uniqueId} 
                                                            data-tip="Preview has been uploaded but is being processed by Apple."
                                                            >
                                                                <img className='current-previews' src={previews.attributes.videoUrl ? previews.attributes.previewImage ? previews.attributes.previewImage.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg") : '/assets/Images/image_placeholder.jpg' : '/assets/Images/image_placeholder.jpg'} 
                                                                />

                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>

                                                                {!previews.attributes.videoUrl ? <ReactTooltip html={true} id={"custom-preview-class"+index + uniqueId}/> : ''}
                                                            </li>
                                                        {/* )}         */}
                                                    </span>
                                                    // </Draggable>
                                            )})}
                                            {/* {provided.placeholder} */}
                                        </ul>
                            //         )}
                            //     </Droppable>
                            // </DragDropContext>                   
                    : 
                        <div className='spinner-full'><Spinner/></div>
                    }    
                </div>


            </div>


            <div id={'current-screenshot-set'+uniqueId} className='current-screenshot-set'>

                <div className={`current-screenshot-set-info ${screenshotSet  ? '' : 'if-set-from-primay'}`}>
                    {/* dolu mu boş mu */}
                    {!screenshotSet ? 
                    //Boş
                        //cihaz 55 den büyük mü
                        deviceSize > 'APP_IPHONE_55' ?
                            //büyük
                            //kendisinde 65 cihaz var mı 
                            (screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}) || false) ?
                                //var
                                <span className='additional-info'>Using 6.5" Display</span>
                            :
                                //yok
                                //primary de varmı cihazdan
                                primaryScreenshotSet ? 
                                    //var
                                    <span className='additional-info' > 
                                        {
                                            deviceSize === 'APP_IPHONE_58' ? 
                                            <span>Using {`${primaryLanguageName}`} 5.8" Display</span> :
                                            deviceSize === 'APP_IPHONE_61' ?  
                                            <span>Using {`${primaryLanguageName}`} 6.1" Display</span> : 
                                            deviceSize === 'APP_IPHONE_65' ?  
                                            <span>Using {`${primaryLanguageName}`} 6.5" Display</span> : 
                                            deviceSize === 'APP_IPHONE_67' ?  
                                            <span>Using {`${primaryLanguageName}`} 6.7" Display</span> : 
                                        ''}
                                    </span>
                                :
                                    //yok
                                    //primaryde 65 var mı
                                    (primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}) || false) ?
                                        //var
                                        <span className='additional-info'>Using {`${primaryLanguageName}`} 6.5" Display</span>
                                    :
                                        //yok
                                        <span className='additional-info'>&nbsp;</span>
                        :
                        //cihaz 55 den küçük veya eşit
                        //kendisinde 55 cihaz var mı 
                        (screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}) || false) ?
                            //var
                            <span className='additional-info'>Using 5.5" Display</span>
                        :
                            //yok                   
                            //primary de varmı cihazdan
                            primaryScreenshotSet ? 
                            //var
                            <span className='additional-info' > 
                                {
                                    deviceSize === 'APP_IPHONE_35' ? 
                                    <span>Using {`${primaryLanguageName}`} 3.5" Display</span> :
                                    deviceSize === 'APP_IPHONE_40' ?  
                                    <span>Using {`${primaryLanguageName}`} 4" Display</span> : 
                                    deviceSize === 'APP_IPHONE_47' ?  
                                    <span>Using {`${primaryLanguageName}`} 4.7" Display</span> : 
                                    deviceSize === 'APP_IPHONE_55' ?  
                                    <span>Using {`${primaryLanguageName}`} 5.5" Display</span> : 
                                ''}
                            </span>
                        :
                            //yok
                            //primaryde 55 var mı
                            (primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}) || false) ?
                                //var
                                <span className='additional-info'>Using {`${primaryLanguageName}`} 5.5" Display</span>
                            :
                                //yok
                                <span className='additional-info'>&nbsp;</span>
                    :
                    //Dolu
                        ''            
                    }


                    <span style={{marginLeft: '15px', fontSize: '0.9rem'}}>
                        {
                            !screenshotSet ?
                            //Boş
                            //cihaz 55 den büyük mü
                            deviceSize > 'APP_IPHONE_55' ?
                                //cihaz 55 den büyük
                                //kendisinde 65 var mı
                                (screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}) || false) ?
                                    //kendisinde 65 var
                                    <span>{screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}).screenshots.length}/10</span>
                                :
                                    //kendisinde 65 yok
                                    //primary de cihazın kendi var mı
                                    primaryScreenshotSet ?
                                        //primary de cihazın kendisi var
                                        <span>{primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}).screenshots.length}/10</span>
                                    :
                                        //primary de cihazın kendisi yok
                                        //primary de 65 var mı
                                        (primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}) || false) ?
                                            //primary de 65 var 
                                            <span>{primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}).screenshots.length}/10</span>
                                        :
                                            //primary de 65 yok
                                            '0/10'  
                            :
                                //cihaz 55 e eşit ya da küçük
                                //primary de cihazın kendi var mı
                                primaryScreenshotSet ?
                                    //primary de cihazın kendisi var
                                    <span>{primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}).screenshots.length}/10</span>
                                :
                                    //primary de cihazın kendisi yok
                                    //primary de 55 var mı
                                    (primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}) || false) ?
                                        //primary de 55 var 
                                        <span>{primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}).screenshots.length}/10</span>
                                    :
                                        //primary de 55 yok
                                        '0/10'
                        :
                            //Dolu
                            <span>{screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}).screenshots.length}/10</span>
                        }
                    </span>
                </div>

                <div id={`row-${uniqueId}`} className={`row`}>
                    {true ?
                        //cihazın seçilen localde fotosu dolu mu   
                        !screenshotSet ?
                            //Boş
                            //cihaz 55 den büyük mü
                            deviceSize > 'APP_IPHONE_55' ?
                                //cihaz 55 den büyük
                                //kendisinde 65 var mı
                                (screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}) || false) ?
                                    //kendisinde 65 var
                                    screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}).screenshots.map((screenshots, index)=>{
                                            return(
                                                <span key={index + uniqueId}>
                                                    <div
                                                        data-background-color='#cf000f'
                                                        data-place="top" 
                                                        data-effect="float"
                                                        data-for={"custom-class"+index + uniqueId} 
                                                        data-tip='Image has been uploaded but is being processed by Apple.'  
                                                        className="col missing-current">
                                                            <img className='current-screenshots' 
                                                                src={
                                                                    screenshots.attributes.imageAsset ?
                                                                        screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                                    :
                                                                        '/assets/Images/image_placeholder.jpg'
                                                                } 
                                                                onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                    </div>
                                                    {!screenshots.attributes.imageAsset ? <ReactTooltip id={"custom-class"+index + uniqueId}/> : ''}
                                                </span>
                                    )})
                                :
                                    //kendisinde 65 yok
                                    //primary de cihazın kendi var mı
                                    primaryScreenshotSet ?
                                        //primary de cihazın kendisi var
                                        primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}).screenshots.map((screenshots, index)=>{
                                            return(
                                                <span key={index + uniqueId}>
                                                    <div
                                                        data-background-color='#cf000f'
                                                        data-place="top" 
                                                        data-effect="float"
                                                        data-for={"custom-class"+index + uniqueId} 
                                                        data-tip='Image has been uploaded but is being processed by Apple.'  
                                                        className="col missing-current">
                                                            <img className='current-screenshots' 
                                                                src={
                                                                    screenshots.attributes.imageAsset ?
                                                                        screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                                    :
                                                                        '/assets/Images/image_placeholder.jpg'
                                                                } 
                                                                onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                    </div>
                                                    {!screenshots.attributes.imageAsset ? <ReactTooltip id={"custom-class"+index + uniqueId}/> : ''}
                                                </span>
                                        )})
                                    :
                                        //primary de cihazın kendisi yok
                                        //primary de 65 var mı
                                        (primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}) || false) ?
                                            //primary de 65 var 
                                            primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}).screenshots.map((screenshots, index)=>{
                                                return(
                                                    <span key={index + uniqueId}>
                                                        <div
                                                            data-background-color='#cf000f'
                                                            data-place="top" 
                                                            data-effect="float"
                                                            data-for={"custom-class"+index + uniqueId} 
                                                            data-tip='Image has been uploaded but is being processed by Apple.'  
                                                            className="col missing-current">
                                                                <img className='current-screenshots' 
                                                                    src={
                                                                        screenshots.attributes.imageAsset ?
                                                                            screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                                        :
                                                                            '/assets/Images/image_placeholder.jpg'
                                                                    } 
                                                                    onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                        </div>
                                                        {!screenshots.attributes.imageAsset ? <ReactTooltip id={"custom-class"+index + uniqueId}/> : ''}
                                                    </span>
                                            )})
                                        :
                                            //primary de 65 yok
                                            <div className='drag-file-empty' id={"drag-file-empty"+uniqueId} >No screenshots available</div>  
        
                            :
                                //cihaz 55 eşit ya da küçük
                                //kendisinde 55 var mı
                                (screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}) || false) ?
                                    //kendisinde 55 var
                                    screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}).screenshots.map((screenshots, index)=>{
                                            return(
                                                <span key={index + uniqueId}>
                                                    <div
                                                        data-background-color='#cf000f'
                                                        data-place="top" 
                                                        data-effect="float"
                                                        data-for={"custom-class"+index + uniqueId} 
                                                        data-tip='Image has been uploaded but is being processed by Apple.'  
                                                        className="col missing-current">
                                                            <img className='current-screenshots' 
                                                                src={
                                                                    screenshots.attributes.imageAsset ?
                                                                        screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                                    :
                                                                        '/assets/Images/image_placeholder.jpg'
                                                                } 
                                                                onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                    </div>
                                                    {!screenshots.attributes.imageAsset ? <ReactTooltip id={"custom-class"+index + uniqueId}/> : ''}
                                                </span>
                                    )})
                                :
                                    //kendisinde 55 yok
                                    //primary de cihazın kendi var mı
                                    primaryScreenshotSet ?
                                        //primary de cihazın kendisi var
                                        primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}).screenshots.map((screenshots, index)=>{
                                            return(
                                                <span key={index + uniqueId}>
                                                    <div
                                                        data-background-color='#cf000f'
                                                        data-place="top" 
                                                        data-effect="float"
                                                        data-for={"custom-class"+index + uniqueId} 
                                                        data-tip='Image has been uploaded but is being processed by Apple.'  
                                                        className="col missing-current">
                                                            <img className='current-screenshots' 
                                                                src={
                                                                    screenshots.attributes.imageAsset ?
                                                                        screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                                    :
                                                                        '/assets/Images/image_placeholder.jpg'
                                                                } 
                                                                onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                    </div>
                                                    {!screenshots.attributes.imageAsset ? <ReactTooltip id={"custom-class"+index + uniqueId}/> : ''}
                                                </span>
                                        )})
                                    :
                                        //primary de cihazın kendisi yok
                                        //primary de 55 var mı
                                        (primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}) || false) ?
                                            //primary de 55 var 
                                            primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}).screenshots.map((screenshots, index)=>{
                                                return(
                                                    <span key={index + uniqueId}>
                                                        <div
                                                            data-background-color='#cf000f'
                                                            data-place="top" 
                                                            data-effect="float"
                                                            data-for={"custom-class"+index + uniqueId} 
                                                            data-tip='Image has been uploaded but is being processed by Apple.'  
                                                            className="col missing-current">
                                                                <img className='current-screenshots' 
                                                                    src={
                                                                        screenshots.attributes.imageAsset ?
                                                                            screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                                        :
                                                                            '/assets/Images/image_placeholder.jpg'
                                                                    } 
                                                                    onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                        </div>
                                                        {!screenshots.attributes.imageAsset ? <ReactTooltip id={"custom-class"+index + uniqueId}/> : ''}
                                                    </span>
                                            )})
                                        :
                                            //primary de 55 yok
                                            <div className='drag-file-empty' id={"drag-file-empty"+uniqueId} >No screenshots available</div>  
        
                        :
                            //Dolu
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="col-list" direction="horizontal">
                                    {(provided) => (
                                        <ul className="col-list" {...provided.droppableProps} ref={provided.innerRef}>
                                            {forOrder.map((screenshots, index)=>{
                                                return(
                                                    <Draggable key={index + uniqueId} draggableId={uniqueId+index} index={index}>
                                                        {(provided) => (   
                                                            <li id={screenshots.screenshot_id} className={`col ${uniqueId+index}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                            data-background-color='#cf000f'
                                                            data-place="top" 
                                                            data-effect="float"
                                                            data-for={"custom-class"+index + uniqueId} 
                                                            data-tip="Image has been uploaded but is being processed by Apple.<br> Reload the page, if image is uploaded it will show, if not delete and reupload"
                                                            >
                                                                <img className='current-screenshots' src={screenshots.attributes.imageAsset ? screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg") : '/assets/Images/image_placeholder.jpg'} 
                                                                onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} 
                                                                alt='appImage'/>

                                                                {!screenshots.attributes.imageAsset ? <ReactTooltip html={true} id={"custom-class"+index + uniqueId}/> : ''}
                                                            </li>
                                                        )}        
                                                    </Draggable>
                                            )})}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>                   
                    : 
                        <div className='spinner-full'><Spinner/></div>
                    }    
                </div>


            </div>

        </div>


        <div id={`lightbox${uniqueId}`} onClick={(e)=>closeSlide(e,uniqueId)} className={`modal modal${uniqueId}`}>
            <span className="close pointer" onClick={()=>{closeLightbox(uniqueId)}}>&times;</span>
            <div className="modal-content">
                <p className='current-screenshot-slide-device-name'>
                    {deviceSize === 'APP_IPHONE_35' ? 
                    <span>iPhone <strong>3.5" Display</strong></span> :
                    deviceSize === 'APP_IPHONE_40' ? 
                    <span>iPhone <strong>4" Display</strong></span> :
                    deviceSize === 'APP_IPHONE_47' ? 
                    <span>iPhone <strong>4.7" Display</strong></span> :
                    deviceSize === 'APP_IPHONE_55' ? 
                    <span>iPhone <strong>5.5" Display</strong></span> : 
                    deviceSize === 'APP_IPHONE_61' ?  
                    <span>iPhone <strong>6.1" Display</strong></span> : 
                    deviceSize === 'APP_IPHONE_65' ?  
                    <span>iPhone <strong>6.5" Display</strong></span> : 
                    deviceSize === 'APP_IPHONE_67' ?  
                    <span>iPhone <strong>6.7" Display</strong></span> : 
                    'Unknown Device'}
                </p>
                {!screenshotSet ?
                        //Boş
                        //cihaz 55 den büyük mü
                        deviceSize > 'APP_IPHONE_55' ?
                            //cihaz 55 den büyük
                            //kendisinde 65 var mı
                            (screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}) || false) ?
                                //kendisinde 65 var
                                screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}).screenshots.map((screenshots, index)=>{
                                        return(
                                            <div key={index + uniqueId} className={`slide${uniqueId} slide-style`}>
                                            <img 
                                                className='current-screenshots-slide'
                                                src={
                                                    screenshots.attributes.imageAsset ?
                                                        screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                    :
                                                        '/assets/Images/image_placeholder.jpg'
                                                } />
                                        </div>
                                )})
                            :
                                //kendisinde 65 yok
                                //primary de cihazın kendi var mı
                                primaryScreenshotSet ?
                                    //primary de cihazın kendisi var
                                    primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}).screenshots.map((screenshots, index)=>{
                                        return(
                                            <div key={index + uniqueId} className={`slide${uniqueId} slide-style`}>
                                            <img 
                                                className='current-screenshots-slide'
                                                src={
                                                    screenshots.attributes.imageAsset ?
                                                        screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                    :
                                                        '/assets/Images/image_placeholder.jpg'
                                                } />
                                        </div>
                                    )})
                                :
                                    //primary de cihazın kendisi yok
                                    //primary de 65 var mı
                                    (primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}) || false) ?
                                        //primary de 65 var 
                                        primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_65'}).screenshots.map((screenshots, index)=>{
                                            return(
                                                <div key={index + uniqueId} className={`slide${uniqueId} slide-style`}>
                                                <img 
                                                    className='current-screenshots-slide'
                                                    src={
                                                        screenshots.attributes.imageAsset ?
                                                            screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                        :
                                                            '/assets/Images/image_placeholder.jpg'
                                                    } />
                                            </div>
                                        )})
                                    :
                                        //primary de 65 yok
                                        ''  
                        :
                            //cihaz 55 eşit ya da küçük
                            //kendisinde 55 var mı
                            (screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}) || false) ?
                                //kendisinde 55 var
                                screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}).screenshots.map((screenshots, index)=>{
                                        return(
                                            <div key={index + uniqueId} className={`slide${uniqueId} slide-style`}>
                                            <img 
                                                className='current-screenshots-slide'
                                                src={
                                                    screenshots.attributes.imageAsset ?
                                                        screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                    :
                                                        '/assets/Images/image_placeholder.jpg'
                                                } />
                                        </div>
                                )})
                            :
                                //kendisinde 55 yok
                                //primary de cihazın kendi var mı
                                primaryScreenshotSet ?
                                    //primary de cihazın kendisi var
                                    primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}).screenshots.map((screenshots, index)=>{
                                        return(
                                            <div key={index + uniqueId} className={`slide${uniqueId} slide-style`}>
                                            <img 
                                                className='current-screenshots-slide'
                                                src={
                                                    screenshots.attributes.imageAsset ?
                                                        screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                    :
                                                        '/assets/Images/image_placeholder.jpg'
                                                } />
                                        </div>
                                    )})
                                :
                                    //primary de cihazın kendisi yok
                                    //primary de 55 var mı
                                    (primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}) || false) ?
                                        //primary de 55 var 
                                        primaryScreenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === 'APP_IPHONE_55'}).screenshots.map((screenshots, index)=>{
                                            return(
                                                <div key={index + uniqueId} className={`slide${uniqueId} slide-style`}>
                                                <img 
                                                    className='current-screenshots-slide'
                                                    src={
                                                        screenshots.attributes.imageAsset ?
                                                            screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                                        :
                                                            '/assets/Images/image_placeholder.jpg'
                                                    } />
                                            </div>
                                        )})
                                    :
                                        //primary de 55 yok
                                        ''  
                    :
                        //Dolu
                        screenshotSetImg.find((i)=>{return i.attributes.screenshotDisplayType === deviceSize}).screenshots.map((screenshots, index)=>{
                            return(
                                <div key={index + uniqueId} className={`slide${uniqueId} slide-style`}>
                                    <img 
                                        className='current-screenshots-slide'
                                        src={
                                            screenshots.attributes.imageAsset ?
                                                screenshots.attributes.imageAsset.templateUrl.replace("{w}x{h}bb.{f}", "296x640bb.jpg")
                                            :
                                                '/assets/Images/image_placeholder.jpg'
                                        } />
                                </div>
                        )})  }                  
                <span id='previous' className="previous" onClick={()=>{changeSlide(-1,uniqueId)}}>&#10094;</span>
                <span id='next' className="next" onClick={()=>{changeSlide(1,uniqueId)}}>&#10095;</span>
            </div>
        </div>


        <div id={`video${uniqueId}`} className="video-div" style={videoPlay ? {display: 'flex'} : {display: 'none'}}>
            
            <span onClick={()=>setVideoPlay(null)} className='close pointer'>&times;</span>

            <div className="modal-content">
                <p className='current-screenshot-slide-device-name'>
                    {devicePreviewSize === 'IPHONE_35' ? 
                    <span>iPhone <strong>3.5" Display</strong></span> :
                    devicePreviewSize === 'IPHONE_40' ? 
                    <span>iPhone <strong>4" Display</strong></span> :
                    devicePreviewSize === 'IPHONE_47' ? 
                    <span>iPhone <strong>4.7" Display</strong></span> :
                    devicePreviewSize === 'IPHONE_55' ? 
                    <span>iPhone <strong>5.5" Display</strong></span> : 
                    devicePreviewSize === 'IPHONE_61' ?  
                    <span>iPhone <strong>6.1" Display</strong></span> : 
                    devicePreviewSize === 'IPHONE_65' ?  
                    <span>iPhone <strong>6.5" Display</strong></span> : 
                    devicePreviewSize === 'IPHONE_67' ?  
                    <span>iPhone <strong>6.7" Display</strong></span> : 
                    'Unknown Device'}
                </p>
                {!previewSet ?
                        //Boş
                        //cihaz 55 den büyük mü
                        devicePreviewSize > 'IPHONE_55' ?
                            //cihaz 55 den büyük
                            //kendisinde 65 var mı
                            (previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}) || false) ?
                                //kendisinde 65 var
                                previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}).previews.map((previews, index)=>{
                                        return(
                                            <span key={index + uniqueId}>
                                                {videoPlay==="video" + index + uniqueId ?
                                                            <div className='video-inner'>
                                                                <Video onReady={playerReady} options={
                                                                    {
                                                                        fill: false,
                                                                        fluid: false,
                                                                        autoplay: true,
                                                                        controls: true,
                                                                        controlBar: {
                                                                            pictureInPictureToggle: false
                                                                        },
                                                                        preload: "metadata",
                                                                        sources: [
                                                                            {
                                                                                src:
                                                                                previews.attributes.videoUrl,
                                                                                type: "application/x-mpegURL"
                                                                            }
                                                                        ]
                                                                    }
                                                                }/>
                                                            </div>
                                                : ''
                                                }
                                            </span>
                                )})
                            :
                                //kendisinde 65 yok
                                //primary de cihazın kendi var mı
                                primaryPreviewSet ?
                                    //primary de cihazın kendisi var
                                    primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === devicePreviewSize}).previews.map((previews, index)=>{
                                        return(
                                            <span key={index + uniqueId}>
                                                {videoPlay==="video" + index + uniqueId ?
                                                            <div className='video-inner'>
                                                                <Video onReady={playerReady} options={
                                                                    {
                                                                        fill: false,
                                                                        fluid: false,
                                                                        autoplay: true,
                                                                        controls: true,
                                                                        controlBar: {
                                                                            pictureInPictureToggle: false
                                                                        },
                                                                        preload: "metadata",
                                                                        sources: [
                                                                            {
                                                                                src:
                                                                                previews.attributes.videoUrl,
                                                                                type: "application/x-mpegURL"
                                                                            }
                                                                        ]
                                                                    }
                                                                }/>
                                                            </div>
                                                : ''
                                                }
                                            </span>
                                    )})
                                :
                                    //primary de cihazın kendisi yok
                                    //primary de 65 var mı
                                    (primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}) || false) ?
                                        //primary de 65 var 
                                        primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_65'}).previews.map((previews, index)=>{
                                            return(
                                                <span key={index + uniqueId}>
                                                    {videoPlay==="video" + index + uniqueId ?
                                                                <div className='video-inner'>
                                                                    <Video onReady={playerReady} options={
                                                                        {
                                                                            fill: false,
                                                                            fluid: false,
                                                                            autoplay: true,
                                                                            controls: true,
                                                                            controlBar: {
                                                                                pictureInPictureToggle: false
                                                                            },
                                                                            preload: "metadata",
                                                                            sources: [
                                                                                {
                                                                                    src:
                                                                                    previews.attributes.videoUrl,
                                                                                    type: "application/x-mpegURL"
                                                                                }
                                                                            ]
                                                                        }
                                                                    }/>
                                                                </div>
                                                    : ''
                                                    }
                                                </span>
                                        )})
                                    :
                                        //primary de 65 yok
                                        ''  
                        :
                            //cihaz 55 eşit ya da küçük
                            //kendisinde 55 var mı
                            (previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}) || false) ?
                                //kendisinde 55 var
                                previewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}).previews.map((previews, index)=>{
                                        return(
                                            <span key={index + uniqueId}>
                                                {videoPlay==="video" + index + uniqueId ?
                                                            <div className='video-inner'>
                                                                <Video onReady={playerReady} options={
                                                                    {
                                                                        fill: false,
                                                                        fluid: false,
                                                                        autoplay: true,
                                                                        controls: true,
                                                                        controlBar: {
                                                                            pictureInPictureToggle: false
                                                                        },
                                                                        preload: "metadata",
                                                                        sources: [
                                                                            {
                                                                                src:
                                                                                previews.attributes.videoUrl,
                                                                                type: "application/x-mpegURL"
                                                                            }
                                                                        ]
                                                                    }
                                                                }/>
                                                            </div>
                                                : ''
                                                }
                                            </span>
                                )})
                            :
                                //kendisinde 55 yok
                                //primary de cihazın kendi var mı
                                primaryPreviewSet ?
                                    //primary de cihazın kendisi var
                                    primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === devicePreviewSize}).previews.map((previews, index)=>{
                                        return(
                                            <span key={index + uniqueId}>
                                                {videoPlay==="video" + index + uniqueId ?
                                                            <div className='video-inner'>
                                                                <Video onReady={playerReady} options={
                                                                    {
                                                                        fill: false,
                                                                        fluid: false,
                                                                        autoplay: true,
                                                                        controls: true,
                                                                        controlBar: {
                                                                            pictureInPictureToggle: false
                                                                        },
                                                                        preload: "metadata",
                                                                        sources: [
                                                                            {
                                                                                src:
                                                                                previews.attributes.videoUrl,
                                                                                type: "application/x-mpegURL"
                                                                            }
                                                                        ]
                                                                    }
                                                                }/>
                                                            </div>

                                                : ''
                                                }
                                            </span>
                                    )})
                                :
                                    //primary de cihazın kendisi yok
                                    //primary de 55 var mı
                                    (primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}) || false) ?
                                        //primary de 55 var 
                                        primaryPreviewSetImg.find((i)=>{return i.attributes.previewType === 'IPHONE_55'}).previews.map((previews, index)=>{
                                            return(
                                                <span key={index + uniqueId}>
                                                {videoPlay==="video" + index + uniqueId ?
                                                            <div className='video-inner'>
                                                                <Video onReady={playerReady} options={
                                                                    {
                                                                        fill: false,
                                                                        fluid: false,
                                                                        autoplay: true,
                                                                        controls: true,
                                                                        controlBar: {
                                                                            pictureInPictureToggle: false
                                                                        },
                                                                        preload: "metadata",
                                                                        sources: [
                                                                            {
                                                                                src:
                                                                                previews.attributes.videoUrl,
                                                                                type: "application/x-mpegURL"
                                                                            }
                                                                        ]
                                                                    }
                                                                }/>
                                                            </div>
                                                : ''
                                                }
                                            </span>
                                        )})
                                    :
                                        //primary de 55 yok
                                        ''  
                    :
                        //Dolu
                        previewSetImg.find((i)=>{return i.attributes.previewType === devicePreviewSize}).previews.map((previews, index)=>{
                            return(
                                <span key={index + uniqueId}>
                                {videoPlay==="video" + index + uniqueId ?
                                            <div className='video-inner'>
                                                
                                                <Video onReady={playerReady} options={
                                                    {
                                                        fill: false,
                                                        fluid: false,
                                                        autoplay: true,
                                                        controls: true,
                                                        controlBar: {
                                                            pictureInPictureToggle: false
                                                        },
                                                        preload: "metadata",
                                                        sources: [
                                                            {
                                                                src:
                                                                previews.attributes.videoUrl,
                                                                type: "application/x-mpegURL"
                                                            }
                                                        ]
                                                    }
                                                }/>
                                            </div>
                                : ''
                                }
                            </span>
                        )})  }                  
            </div>
        </div>


    </div>
  )
}

export default CurrentScreenshotItemv2