import { useContext } from 'react'
import VariableContext from '../../../userContext/VariableContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { fireCustomSuccessMessage, sortValue, fireCustomError, fireRequestError } from '../../../Common Functions/commonFunctions';
import * as gaEvents from "../../../ga-utils"


function CollectionLocalItem({data, func, count, class_name}) {
  const {collectionLocalizations, setCollectionLocalizations} = useContext(VariableContext)
  const {collectionNotLocalizations, setCollectionNotLocalizations} = useContext(VariableContext)
  const {languageSignal, setLanguageSignal} = useContext(VariableContext)

  const {isUploadingDescription, setIsUploadingDescription} = useContext(VariableContext)
  const {isUploadingKeyword, setIsUploadingKeyword} = useContext(VariableContext)
  const {isUploadingWhatsnew, setIsUploadingWhatsnew} = useContext(VariableContext)
  const {isUploadingPhoto, setIsUploadingPhoto} = useContext(VariableContext)
  const {isUploadingPreview, setIsUploadingPreview} = useContext(VariableContext)
  const {isUploading, setIsUploading} = useContext(VariableContext)

  let {appId, collectionId} = useParams()

  const deleteLanguage = () =>{
    var dataa = {
      "appId":appId,
      "collectionId":collectionId,
      "language":data.value
    };
    var config = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/app/collection/language`,
      headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`},
      data : dataa
    };

    axios(config)
    .then(function (response) {
      gaEvents.deleteLanguage()

      setCollectionLocalizations(collectionLocalizations.filter((i)=>i.value!==data.value))
      setCollectionNotLocalizations(sortValue([...collectionNotLocalizations,{"value": data.value, "label":data.label}]))
      setLanguageSignal(languageSignal+1)
      func(collectionLocalizations[0].value, collectionLocalizations[0].label, 'delete')
      fireCustomSuccessMessage(`${data.label} deleted`)
    })
    .catch(function (error) {
      gaEvents.deleteLanguageFail()
      
      if(error.message === 'Network Error'){
        fireCustomError('NETWORK ERROR. ')
      }else{
        fireRequestError(error)
      }
    });
  }

  return (
    <span className={class_name}>
      <span style={{width: '100%', padding: '15px 15px'}} onClick={()=>{func(data.value, data.label, 'normal')}}>{data.label}&nbsp;&nbsp;{count===0 ? <span className='current-owner-primary'>Primary</span> : ''}</span>
      {isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status ?
      ''
      :
      <span className='delete-language' onClick={deleteLanguage}>
        <img src="/assets/Icons/delete.svg" alt="del" />
      </span>
      }
    </span>
  )
}

export default CollectionLocalItem