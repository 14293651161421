import {useContext, useState} from 'react'
import VariableContext from '../../userContext/VariableContext';
import {useParams} from 'react-router-dom'
import {NavLink} from 'react-router-dom'
import axios from 'axios';
import { useEffect } from 'react';
import Spinner from '../../Misc/Spinner';
import { fireRequestError, fireCustomError, fireCustomRequestError, fireSuccessMessage,
fireExportMessage, openCreateCollectionPopup } from '../../Common Functions/commonFunctions';
import SpinnerMid from '../../Misc/SpinnerMid';
import Swal from 'sweetalert2';
import CreateCollectionPopup from '../../Popup/CreateCollectionPopup'
import NewZip from '../../Popup/NewZip';
import ImportZip from '../../Popup/ImportZip';
import * as gaEvents from '../../ga-utils'

function CollectionOwnerBoxInnerWithDumy({data, index, func}) {
    const {isUploadingDescription} = useContext(VariableContext)
    const {isUploadingKeyword,} = useContext(VariableContext)
    const {isUploadingWhatsnew,} = useContext(VariableContext)
    const {isUploadingPhoto,} = useContext(VariableContext)
    const {isUploadingPreview,} = useContext(VariableContext)
    const {isUploading} = useContext(VariableContext)
    const {appCol} = useContext(VariableContext)
    
    const {currentAppPrimaryLocale} = useContext(VariableContext)
    const [screenshots, setScreenshots] = useState(null)
    const {lastUpload} =useContext(VariableContext)
    const {getColList, setGetColList} = useContext(VariableContext)
    const {setCurrentAppCols} = useContext(VariableContext)

    const {subscriptionLimits} = useContext(VariableContext)

    let {appId} = useParams()


    useEffect(()=>{
        getScreenshots(appId, data.collection_id, currentAppPrimaryLocale)
    },[currentAppPrimaryLocale])

    const getScreenshots = (appId, collectionId, currentAppPrimaryLocal) =>{
        if(currentAppPrimaryLocale){
          var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/array/${appId}/${collectionId}/${currentAppPrimaryLocal}`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          };
          
          axios(config)
          .then(function (response) {
            setScreenshots (response.data.data.sizes);
          })
          .catch(function (error) {
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
          });
        }
      }

      const openThreeDotMenu = () =>{
        const menu = document.getElementById('threeDotMenuX'+index)
        if(menu){
            menu.classList.toggle('three-dot-menu-openX');
        }
        window.addEventListener('click', closeThreeDotMenu)
    }
    const closeThreeDotMenu = (e) =>{
        if(e.target.className!=='three-dot-menu-iconX'+index && e.target.className!=='three-dot-menuX'+index && e.target.className!=='memberCustomizeX'+index && e.target.className!== `dot_menu${index}` && e.target.className!=='button'+index){

            window.removeEventListener('click', closeThreeDotMenu)
            const menu = document.getElementById('threeDotMenuX'+index)
            if(menu){
            document.getElementById('threeDotMenuX'+index).classList.remove('three-dot-menu-openX'); 
            }
        }
    }

    const deleteCollectionPopup = () =>{
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            position: 'center',
            width: '400',
            text: 'You are about to delete this collection. You can not take this action back!',
            backdrop: true,
            showConfirmButton: true,
            showCancelButton: true,     
            confirmButtonColor: '#cf000fe6',
            cancelButtonColor: '#3A6FF8e6',
            cancelButtonText: 'Cancel',      
            confirmButtonText: 'Delete',
            showLoaderOnConfirm: true,     
            preConfirm: async () => {
                var dataa = JSON.stringify({
                    "appId": appId,
                    "collectionId" : data.collection_id,
                });
                  
                var config = {
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_URL}/app/collection`,
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                        'Content-Type': 'application/json'
                    },
                    data : dataa
                };
                  
                try {
                    const response = await axios(config);
                    if(response.status === 200){
                        gaEvents.deleteCollectionSuccess("Collections Page")
                        setGetColList(getColList + 1);
                        fireSuccessMessage(response);
                        appCol.current[appId] = ''

                    }
                } catch (error) {
                    gaEvents.deleteCollectionFail()
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                }
              },
              allowOutsideClick: () => !Swal.isLoading()
            })
    }
    
    const exportCollection = () =>{
        fireExportMessage()
        var dataaaa = '';

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/${appId}`,
            headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            data : dataaaa
        };

        axios(config)
        .then(function (response) {
            setCurrentAppCols(response.data.data)

            var dataa = JSON.stringify({
            "appId": appId,
            "collectionId": data.collection_id,
            "title": response.data.data.find((i)=>i.collection_id===+data.collection_id).collection_title
            });
            
            var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/export`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                'Content-Type': 'application/json'
            },
            data : dataa
            };
            
            axios(config)
            .then(function (response) {
                gaEvents.exportSuccess("Collection")
                let a = document.createElement('a');
                a.href = `${process.env.REACT_APP_API_URL}/${response.data.data.path}`;
                a.download = `${response.data.data.path}`.split("/")[2]
                a.click();
                Swal.close()
            })
            .catch(function (error) {
                gaEvents.exportFail("Collection")
                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
            });
        })
        .catch(function (error) {
            gaEvents.exportFail("Collection")
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
        });
    }

    const importCollection = () =>{
    document.getElementById('importAppPopup'+data.collection_id).style.display = 'flex'
    }

    return (
        <>
        <div className='owner-collection' style={subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.includes(data.collection_id) ? {backgroundColor:'#ffdede'} : {} : {}}>
            <div className="owner-collection-name">
                <strong>{data.collection_title}</strong>
                <div className='owner-golive'>
                    {isUploading.status&&(isUploading.collectionID === data.collection_id)  ?
                            <div><SpinnerMid/></div> 
                        : 
                            !isUploading.status&&lastUpload  ?
                                lastUpload===data.collection_id ?
                                <div className='owner-golive-live'>Uploaded</div> : ''
                            : 
                                ''
                    }
                    <NavLink to={`/${appId}/owner/${data.collection_id}`}>
                        <button className='owner-golive-button'>Preview</button>
                    </NavLink>
                    <div onClick={openThreeDotMenu} className={`three-dot-menu-iconX ripple`}>
                        <img className={'button'+index} src="/assets/Icons/kebab_menu.svg" alt="dot_menu" />
                    </div>

                    <div id={`threeDotMenuX`+index} className={`three-dot-menuX three-dot-menu${index}`}>
                        
                        <div onClick={isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status ? ()=>{} : ()=>func(data.collection_id)} className={`member-editX memberCustomizeX memberCustomize${index}`}>Rename</div>

                        {isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status ?
                            <div style={{color:'grey', cursor:'not-allowed'}} className={`member-editY memberCustomizeX memberCustomize${index}`}>Import</div> 
                            :
                            <div onClick={importCollection} className={`member-editY memberCustomizeX memberCustomize${index}`}>Import</div>
                        }
                            
                        <div onClick={exportCollection} className={`member-editY memberCustomizeX memberCustomize${index}`}>Export</div>

                        {isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status ?
                            <div style={{color:'grey', cursor:'not-allowed'}} className={`member-deleteX memberCustomizeX memberCustomize${index}`}>Delete</div> 
                            :
                            <div onClick={deleteCollectionPopup} className={`member-deleteX memberCustomizeX memberCustomize${index}`}>Delete</div>  
                        }

                    </div>
                </div>
            </div>

            <div className="owner-collection-shots">
                {screenshots ? 
                    screenshots.length!==0 ?
                        screenshots.find((i)=>(i.size==='APP_IPHONE_65')||(i.size==='APP_IPHONE_55'))||false ?
                            screenshots.find((i)=>(i.size==='APP_IPHONE_65')||(i.size==='APP_IPHONE_55')).screenshots.map((i,index2)=>{
                                if(index2<4){
                                    return(
                                        <img key={index2} className='collection-shot' src={`${process.env.REACT_APP_API_URL}${i.path.slice(6)}${i.file_name.slice(0,i.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/>
                                    )
                                }
                                else{
                                    return (<span style={{display: 'none'}} key={index2}></span>)
                                }
                            }) 
                            :
                            <> 
                            <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', width:'100%'}}>
                                <img src="/assets/Icons/empty-pri.svg" alt="emptyBox" />
                                <p>You haven't uploaded any screenshots for 6.5'' or 5.5'' devices on this collection yet!</p>
                            </div>
                            {/* <img className='collection-shot' src="https://incipia.co/wp-content/uploads/2017/07/ios-11-screenshot-ASO_progressive.png" alt="" />
                            <img className='collection-shot' src="https://incipia.co/wp-content/uploads/2017/07/ios-11-screenshot-ASO_progressive.png" alt="" />
                            <img className='collection-shot' src="https://incipia.co/wp-content/uploads/2017/07/ios-11-screenshot-ASO_progressive.png" alt="" />
                            <img className='collection-shot' src="https://incipia.co/wp-content/uploads/2017/07/ios-11-screenshot-ASO_progressive.png" alt="" />  */}
                        </>

                : 
                <> 
                        <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', width:'100%'}}>
                            <img src="/assets/Icons/empty-box.svg" alt="emptyBox" />
                            <p>You haven't uploaded any screenshots on this collection yet!</p>
                        </div>
                    {/* <img className='collection-shot' src="https://incipia.co/wp-content/uploads/2017/07/ios-11-screenshot-ASO_progressive.png" alt="" />
                    <img className='collection-shot' src="https://incipia.co/wp-content/uploads/2017/07/ios-11-screenshot-ASO_progressive.png" alt="" />
                    <img className='collection-shot' src="https://incipia.co/wp-content/uploads/2017/07/ios-11-screenshot-ASO_progressive.png" alt="" />
                    <img className='collection-shot' src="https://incipia.co/wp-content/uploads/2017/07/ios-11-screenshot-ASO_progressive.png" alt="" />  */}
                </>
                : 
                    <div className="spinner-full">
                        <Spinner/>
                    </div>
                }
            </div>
            <ImportZip pageType='owner' colId={data.collection_id}/>

        </div>
        <div className='newCol'>
                      
                      <img style={isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status ? {cursor:'not-allowed', margin:'0'} : {cursor:'pointer', margin:'0'}} onClick={isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status ? ()=>{} : openCreateCollectionPopup} className='app-icon addApp' src={'/assets/Icons/Add-App.svg' } alt="plus" />
                        
                      
                      <CreateCollectionPopup pageType={'owner'}/>
                      <NewZip pageType={'owner'}/>
                      
                    </div>
                
        </>
    )
}

export default CollectionOwnerBoxInnerWithDumy