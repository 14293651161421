import {useContext, useState} from 'react'
import SpinnerSmall from '../Misc/SpinnerSmall'
import VariableContext from '../userContext/VariableContext'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import './CreateCollectionPopup.css'
import { fireRequestError, fireSuccessMessage, fireCustomError } from '../Common Functions/commonFunctions'
import * as gaEvents from '../ga-utils'
import Qmark from '../Misc/Qmark'

function CreateCollectionPopup({pageType}) {

    let {appId} = useParams()

    let navigate = useNavigate()

    const[creatingCol, setCreatingCol] =useState(false)
    const[newCollection, setNewCollection] = useState('')
    const[question, setQuestion] = useState(true)

    const[descritions, setDescriptions] = useState(false)
    const[screenshots , setScreenshots] = useState(false)
    const[previews, setPreviews] = useState(false)
    const[keywords, setKeywords] = useState(false)
    const[whatsnews,setWhatsnews] = useState(false)

    const{getColList, setGetColList} = useContext(VariableContext)
    const{appCol} = useContext(VariableContext)
    const{currentAppQuota} = useContext(VariableContext)
    const{initialUserData} = useContext(VariableContext)

    //popup create collection close
    const closePopup = () => {
        document.getElementById('collectionPopup').style.display = 'none'
        setNewCollection('')
        setQuestion(true)

    }
    //popup create collection name input
    const handleChange = (e) =>{
        setNewCollection(e.target.value)
    }

    const createCollectionE = (e) =>{ 
        e.preventDefault()
        setCreatingCol(true)
        var data = JSON.stringify({
        "appId": appId,
        "collectionTitle": newCollection,
        "options" : {
            "screenshot" : screenshots,
            "preview" : previews,
            "whatsNew" : whatsnews,
            "description" : descritions,
            "keyword" : keywords
        }
        });
        
        var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/app/collection`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then(function (response) {
            gaEvents.createCollection(pageType)
            document.getElementById('collectionPopup').style.display = 'none'
            setCreatingCol(false)
            setGetColList(getColList+1)
            setNewCollection('')
            fireSuccessMessage(response)
            appCol.current[appId] = response.data.data.collection_id
            if(pageType!=='owner'){
                navigate(`/${response.data.data.app_id}/${pageType}/${response.data.data.collection_id}`) 
            }
        })
        .catch(function (error) {
            gaEvents.createCollectionFail(pageType)
            document.getElementById('collectionPopup').style.display = 'none'
            setCreatingCol(false)
            setGetColList(true)
            setNewCollection('')
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
        });  
    }

    const answerYes = () =>{
        closePopup()
        document.getElementById('newzipAppPopup').style.display = 'flex'
    }
    const answerNo = () =>{
        setQuestion(false)
        setTimeout(()=>{
            document.getElementById('popupForm').focus()
        },100)
    }

    return (
        <div id='collectionPopup' className='popup-container'>
            <div className='collectionPopupBack' onClick={creatingCol ? ()=>{} : ()=>{closePopup()}}></div>
            {!question ?
                <div className='popup-content'>
                    <span className="popup-name">Create New Collection <Qmark link="https://doc.storesyncer.com/collections/how-to-create-a-collection" /></span>

                    <form className='create-collection-form' onSubmit={createCollectionE}>
                        <input id='popupForm' onChange={handleChange} className="popup-form" type="text" value={newCollection} placeholder='Collection Name' maxLength="15" required={true}/>
                        <div style={{display:'flex', justifyContent:'center', flexWrap:'wrap', flexDirection:'column', width:'100%', userSelect:'none', marginTop:'15px'}}>
                            <span style={{fontSize: '1rem'}}>Which elements do you want to get from 'Live'?</span>

                            <label style={{cursor:'pointer', fontSize:'0.9rem', width:'max-content', display:'flex', alignItems:'center', justifyContent:'center'}} htmlFor="createDescription"><input onChange={(e)=>setDescriptions(e.target.checked)} type="checkbox" id="createDescription" name="createDescription"/> Descriptions</label>
                            
                            <label style={{cursor:'pointer', fontSize:'0.9rem', width:'max-content', display:'flex', alignItems:'center', justifyContent:'center'}} htmlFor="createScreenshots"><input onChange={(e)=>setScreenshots(e.target.checked)} type="checkbox" id="createScreenshots" name="createScreenshots"/> Screenshots</label>
                            
                            {currentAppQuota.preview_data_storage === 0 ? 
                                <label style={{cursor:'pointer', fontSize:'0.9rem', width:'max-content', display:'flex', alignItems:'center', justifyContent:'center'}} htmlFor="createPreviews"><input disabled onChange={(e)=>setPreviews(e.target.checked)} type="checkbox" id="createPreviews" name="createPreviews"/> <span style={{color:'#aaa'}}>Previews</span>&nbsp;<img style={{ width: "15px" }} src="/assets/Icons/lock-fill.svg" alt="locked"/>&nbsp;<NavLink to={'/subscription'} style={{fontSize:'0.8rem', fontStyle:'italic'}}>(upgrade your subscription)</NavLink></label> 
                            :
                                <label style={{cursor:'pointer', fontSize:'0.9rem', width:'max-content', display:'flex', alignItems:'center', justifyContent:'center'}} htmlFor="createPreviews"><input onChange={(e)=>setPreviews(e.target.checked)} type="checkbox" id="createPreviews" name="createPreviews"/> Previews</label>
                            }
                            
                            <label style={{cursor:'pointer', fontSize:'0.9rem', width:'max-content', display:'flex', alignItems:'center', justifyContent:'center'}} htmlFor="createWhatsnew"><input onChange={(e)=>setWhatsnews(e.target.checked)} type="checkbox" id="createWhatsnew" name="createWhatsnew"/> What's News</label>
                            
                            <label style={{cursor:'pointer', fontSize:'0.9rem', width:'max-content', display:'flex', alignItems:'center', justifyContent:'center'}} htmlFor="createKeyowrds"><input onChange={(e)=>setKeywords(e.target.checked)} type="checkbox" id="createKeyowrds" name="createKeyowrds"/> Keywords</label>

                            <span style={{fontSize: '0.8rem', fontStyle: 'italic', marginTop:'10px', display:'flex', alignItems:'center'}}>*Note: Creating process may take a while depending on your choices.</span>

                        </div>
                        <input type='submit' hidden={true} disabled={creatingCol}/>
                        <div className="collectionPopupButtons">
                            <button disabled={creatingCol} type='submit' className="popup-button">
                                {!creatingCol ? 'Create' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                            </button>
                            <span disabled={creatingCol} onClick={!creatingCol ? ()=>closePopup() : ()=>{}} className="popup-button2 popupCancel">
                                Cancel 
                            </span>
                        </div>
                    </form>
                </div>
            :
                <div className='create-popup-content-pre'>
                    <span className="popup-name">Create New Collection <Qmark link="https://doc.storesyncer.com/collections/how-to-create-a-collection" /></span>
                    <div className="createPopupButtons">
                            <button disabled={false} onClick={answerYes} className="create-popup-button-pre createpre1">
                                {!false ? 
                                    <div style={{textAlign:'center'}}>
                                        <p>Create from file</p>
                                        <i>(example.zip)</i>
                                    </div>
                                : 
                                    <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                            </button>

                            <button disabled={false} onClick={answerNo} className="create-popup-button-pre createpre2">
                                {!false ? 
                                    <div style={{textAlign:'center'}}>
                                        <p>Create without file</p>
                                        <i>(dublicate of 'Live')</i>
                                    </div>
                                : 
                                    <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> }  
                            </button>

                            {/* <span disabled={false} onClick={!false ? ()=>closePopup() : ()=>{}} className="create-popup-button2-pre popupCancel">
                                Cancel 
                            </span> */}
                        </div>
                </div>
            }
        </div>
    )
}

export default CreateCollectionPopup