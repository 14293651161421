import { useEffect } from 'react'
import {useState} from 'react'
import './ScreenshotLocalItem.css'
import  langJSON from '../../../flagsLanguages/lang.json'


function ScreenshotLocalItem({data, func, count, class_name}) {

  const [language] = useState(data.attributes.locale.toLowerCase())
  const [languageOBJ, setLanguageOBJ] = useState('')
  const [name, setName] = useState('')

  useEffect(()=>{
    setLanguageOBJ(langJSON[language]); 
    if (typeof languageOBJ !== 'undefined'){
      setName(languageOBJ.nameEnglish)
    }

  },[languageOBJ])

  return (
    <span style={{width: '100%', padding: '15px'}} onClick={()=>{func(data.id, data.attributes.locale, data.attributes.locale_name)}} className={class_name}>{name}&nbsp;&nbsp;{count===0 ? <span className='primary'>Primary</span> : ''}</span>
  )
}

export default ScreenshotLocalItem