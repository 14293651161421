import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import VariableContext from '../userContext/VariableContext';
import {fireCustomError, fireRequestError} from '../Common Functions/commonFunctions'
import SpinnerSmall from "../Misc/SpinnerMid";
import Spinner from "../Misc/Spinner";
import GoBackButton from "../Components/GoBackButton";
import prices from "../Prices/prices";
import './Billing.css'
import * as gaEvents from "../ga-utils"

function Billing() {
    const {initialUserData, setInitialUserData} = useContext(VariableContext)
    const {quota, setQuota} = useContext(VariableContext)
    const [isWaiting, setIsWaiting] = useState(false)
    const [isWaiting2, setIsWaiting2] = useState(false)
    const [table, setTable] = useState(false)

    let navigate = useNavigate()

    //validate token and get user info
    useEffect(()=>{
    if(localStorage.getItem("token")){
        var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/validate?code_usage=true`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        };
        axios(config)
        .then(function (response) {
            setInitialUserData(response.data.data)
            localStorage.setItem("token", response.data.data.token)
        })
        .catch(function (error) {
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
            localStorage.removeItem("token")
            navigate('/login')
        });
    }else {
        navigate('/login')
    }
    },[])

    useEffect(()=>{
        var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/quota`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        };
        axios(config)
        .then(function (response) {
            setQuota(response.data.data)
           
        })
        .catch(function (error) {
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
        });
    },[])


    const sendPayData =(id, type)=>{
      var data={
        email:initialUserData.email,
        priceId:`${id}`
      }
      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/payment/createCheckoutSession`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        data : data
        };
        axios(config)
        .then(function (response) {
            gaEvents.checkoutStarted(type)
            localStorage.setItem("checkout", type)
            window.location.href= response.data.data.url
        })
        .catch(function (error) {
            
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
        });
    }

  return (
    <div className="billing-content">
      <div className="billing-title-div titleArea">
        <GoBackButton />
        <h1>Subscription</h1>
      </div>

      <div className="billing-inner-container">
        <div className="app-data" style={{ margin: "33px 0 0 0" }}>
          <div>
            <img
              style={{ height: "50px", width: "50px", marginRight: "15px" }}
              src="/assets/Icons/username.svg"
              alt="versionIcon"
            />
            <p
              style={{
                fontWeight: "700",
                fontSize: "16.5px",
                lineHeight: "23px",
              }}
            >
              <strong>
                {initialUserData ? (
                  initialUserData.first_name + " " + initialUserData.last_name
                ) : (
                  <SpinnerSmall />
                )}
              </strong>
            </p>
          </div>

          <div>
            <img
              style={{ height: "50px", width: "50px", marginRight: "15px" }}
              src="/assets/Icons/usermail.svg"
              alt="platformIcon"
            />
            <p
              style={{
                fontWeight: "700",
                fontSize: "16.5px",
                lineHeight: "23px",
              }}
            >
              <strong>
                {initialUserData ? initialUserData.email : <SpinnerSmall />}
              </strong>
            </p>
          </div>

          <div>
            <img
              style={{ height: "50px", width: "50px", marginRight: "15px" }}
              src="/assets/Icons/useraccount.svg"
              alt="primaryLocalIcon"
            />
            <p
              style={{
                fontWeight: "700",
                fontSize: "16.5px",
                lineHeight: "23px",
              }}
            >
              <strong>
                {initialUserData ? (
                  <>
                    {initialUserData.account_type}&nbsp;<span style={{ fontStyle:'italic'}}>{initialUserData.account_type!=="free"&&<>({initialUserData.accountSubsStatus.status!=='active' ?"Expired" : "Expires"} on {initialUserData.subscription_expires_at.slice(0,10)})</>}</span>&nbsp;
                    {initialUserData.account_type!=="premium_plus" ?
                      <NavLink
                        style={{
                          textDecoration: "none",
                          fontSize: "0.9rem",
                          fontStyle: "italic",
                          color: "#3A6FF8",
                        }}
                        to="/quota"
                      >
                        (Account Quotas)
                      </NavLink>
                    :
                      ''
                    }
                  </>
                ) : (
                  <SpinnerSmall />
                )}
              </strong>{" "}
            </p>
          </div>
        </div>

        {quota ? (
          quota.account_type === "free" ? (
            ""
          ) : quota.account_type === "premium" ? (
            <a
              className="manage-sub"
              href={`https://billing.stripe.com/p/login/cN28wD2gYfAn01OaEE?prefilled_email=${
                initialUserData ? initialUserData.email : ""
              }`}
              rel="noopener noreferrer"
            >
              <img src="/assets/Icons/add.svg" alt="upgrade" />
              <p>Upgrade to Premium Plus</p>
            </a>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {quota ? (
          quota.account_type === "free" ? (
            ""
          ) : quota.account_type === "premium" || quota.account_type === "trial" ? (
            <div className="upgrade-table">
              <h2>Utilize StoreSyncer Fully with Premium Plus</h2>
              <ul className="upgrade-list">
                <li>
                  <img src="/assets/Icons/app_num.svg" alt="app" /> <span>Add up to <strong>10 applications.</strong></span>
                  
                </li>
                <li>
                  <img src="/assets/Icons/team_number.svg" alt="team" /><span> Each app can
                  have up to <strong>10 team members.</strong></span>
                </li>
                <li>
                  <img src="/assets/Icons/collection_num.svg" alt="collection" /><span> Create up to
                  <strong> 8 collections</strong> for a single app.</span>
                </li>
                <li>
                  <img src="/assets/Icons/import_arrow.svg" alt="import_export" /><span> Limitless
                  <strong> import and export</strong> features.</span>
                </li>
                <li>
                  <img src="/assets/Icons/upload-fill.svg" alt="upload" /> <span><strong>40 montly
                  uploads</strong> for your texts, screenshots and previews to App Store Connect.</span>
                </li>
              </ul>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}


        {quota ? 
            quota.account_type === "premium_plus" ?
                <>
                    {/* Application Quota */}
                    <div className="billing-info-div">

                    <h2 className="billing-sub">Application Quota</h2>

                    {quota ? (
                      <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
                      {/* No of apps */}
                        <div className="quota-data-item">
                          <img src="/assets/Icons/app_num.svg" alt="app" />
                          <h3>Number of Applications: </h3>
                          <div className="quota-bar">
                            <div
                              style={
                                (quota.ownerships.length * 100) / quota.max_app_count < 50
                                  ? {
                                      width: `${
                                        (quota.ownerships.length * 100) /
                                        quota.max_app_count
                                      }%`,
                                      backgroundColor: "#14CC26",
                                    }
                                  : (quota.ownerships.length * 100) /
                                      quota.max_app_count >=
                                      50 &&
                                    (quota.ownerships.length * 100) /
                                      quota.max_app_count <
                                      75
                                  ? {
                                      width: `${
                                        (quota.ownerships.length * 100) /
                                        quota.max_app_count
                                      }%`,
                                      backgroundColor: "#F3802D",
                                    }
                                  : {
                                      width: `${
                                        (quota.ownerships.length * 100) /
                                        quota.max_app_count
                                      }%`,
                                      backgroundColor: "red",
                                    }
                              }
                              className="quota-bar-inner"
                            ></div>
                          </div>
                          <span>&nbsp;{quota.ownerships.length} of {quota.max_app_count}</span>
                        </div>
                      </div>
                    ) : (
                      <p><Spinner /></p>
                    )}
                    </div>

                    {/* Upload Quota */}
                    <div className="billing-info-div">

                    <h2 className="billing-sub">App Store Connect Upload Quota</h2>

                    {quota ? (
                      <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
                      {/* No of text */}
                      <div className="quota-data-item">
                          <img src="/assets/Icons/text_upload.svg" alt="text_upload" />

                          <h3>Text Uploads to App Store Connect: </h3>
                          <div className="quota-bar">
                            <div
                              style={
                                ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) / quota.max_textual_upload_appstore < 50
                                  ? {
                                      width: `${
                                        ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) /
                                        quota.max_textual_upload_appstore
                                      }%`,
                                      backgroundColor: "#14CC26",
                                    }
                                  : ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) /
                                      quota.max_textual_upload_appstore >=
                                      50 &&
                                    ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) /
                                      quota.max_textual_upload_appstore <
                                      75
                                  ? {
                                      width: `${
                                        ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) /
                                        quota.max_textual_upload_appstore
                                      }%`,
                                      backgroundColor: "#f3e22d",
                                    }
                                  : {
                                      width: `${
                                        ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) /
                                        quota.max_textual_upload_appstore
                                      }%`,
                                      backgroundColor: "red",
                                    }
                              }
                              className="quota-bar-inner"
                            ></div>
                          </div>
                          <span>&nbsp;{(quota.max_textual_upload_appstore-quota.textual_upload_appstore)} of {quota.max_textual_upload_appstore}</span>
                        </div>

                      {/* No of screenshots */}
                        <div className="quota-data-item">
                          <img src="/assets/Icons/screenshot_upload.svg" alt="screenshoot_upload" />

                          <h3>Screenshot Uploads to App Store Connect: </h3>
                          <div className="quota-bar">
                            <div
                              style={
                                ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) / quota.max_screenshot_upload_appstore < 50
                                  ? {
                                      width: `${
                                        ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) /
                                        quota.max_screenshot_upload_appstore
                                      }%`,
                                      backgroundColor: "#14CC26",
                                    }
                                  : ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) /
                                      quota.max_screenshot_upload_appstore >=
                                      50 &&
                                    ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) /
                                      quota.max_screenshot_upload_appstore <
                                      75
                                  ? {
                                      width: `${
                                        ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) /
                                        quota.max_screenshot_upload_appstore
                                      }%`,
                                      backgroundColor: "#f3e22d",
                                    }
                                  : {
                                      width: `${
                                        ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) /
                                        quota.max_screenshot_upload_appstore
                                      }%`,
                                      backgroundColor: "red",
                                    }
                              }
                              className="quota-bar-inner"
                            ></div>
                          </div>
                          <span>&nbsp;{(quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore)} of {quota.max_screenshot_upload_appstore}</span>
                        </div>

                      {/* No of previews */}
                        <div className="quota-data-item">
                          <img src="/assets/Icons/preview_upload.svg" alt="preview_upload" />

                          <h3>Preview Uploads to App Store Connect: </h3>
                          {quota.preview_data_storage===0 ?
                            <img src="/assets/Icons/x.svg" alt="negative_mark" />
                          :
                            <>
                              <div className="quota-bar">
                                  <div
                                  style={
                                      ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) / quota.max_preview_upload_appstore < 50
                                      ? {
                                          width: `${
                                              ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) /
                                              quota.max_preview_upload_appstore
                                          }%`,
                                          backgroundColor: "#14CC26",
                                          }
                                      : ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) /
                                          quota.max_preview_upload_appstore >=
                                          50 &&
                                          ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) /
                                          quota.max_preview_upload_appstore <
                                          75
                                      ? {
                                          width: `${
                                              ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) /
                                              quota.max_preview_upload_appstore
                                          }%`,
                                          backgroundColor: "#f3e22d",
                                          }
                                      : {
                                          width: `${
                                              ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) /
                                              quota.max_preview_upload_appstore
                                          }%`,
                                          backgroundColor: "red",
                                          }
                                  }
                                  className="quota-bar-inner"
                                  ></div>
                              </div>
                              <span>&nbsp;{(quota.max_preview_upload_appstore-quota.preview_upload_appstore)} of {quota.max_preview_upload_appstore}</span>  
                            </>  
                          }
                        </div>

                      </div>
                    ) : (
                      <p><Spinner /></p>
                    )}
                    </div>

                    {/* Team Quota */}
                    <div className="billing-info-div">

                    <h2 className="billing-sub">Team Member Quota</h2>

                    {quota ? (
                      <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
                          {/* Team Work */}
                          <div className="quota-data-item">
                              {quota.max_team_member_per_app===1 ? <>
                                  <img src="/assets/Icons/team_number.svg" alt="team_number" />
                                  <h3>
                                      Team Work:&nbsp;&nbsp;
                                      {quota.max_team_member_per_app===1 ? 
                                          <img src="/assets/Icons/x.svg" alt="nagative_mark" />
                                      :
                                          <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                                      }
                                  </h3></>
                              :   <>
                                  <img src="/assets/Icons/team_number.svg" alt="team_number" />   
                                  <h3>
                                      Maximum Team Member per Application:&nbsp;&nbsp;
                                      <span>{quota.max_team_member_per_app}</span>  
                                  </h3></>
                              }

                          </div>

                          {quota.max_team_member_per_app!==1&&quota.ownerships.length!==0 ? 
                              <ul className="quota-data-item-list">
                                  {quota.ownerships.map((app,i)=>{
                                      return(
                                          <li key={i} className="quota-data-item">
                                            {app.icon ? 
                                              <img className="quota-app-icon" src={app.icon} alt="_icon" />
                                            :
                                              <img className="quota-app-icon" src='/assets/Icons/AddAppImage.svg' alt="_icon" />
                                            }
                                              <span>{app.app}</span>  
                                              <div className="quota-bar">
                                                  <div
                                                      style={
                                                          (app.member_count * 100) / quota.max_team_member_per_app < 50
                                                          ? {
                                                              width: `${
                                                                  (app.member_count * 100) /
                                                                  quota.max_team_member_per_app
                                                              }%`,
                                                              backgroundColor: "#14CC26",
                                                              }
                                                          : (app.member_count * 100) /
                                                              quota.max_team_member_per_app >=
                                                              50 &&
                                                              (app.member_count * 100) /
                                                              quota.max_team_member_per_app <
                                                              75
                                                          ? {
                                                              width: `${
                                                                  (app.member_count * 100) /
                                                                  quota.max_team_member_per_app
                                                              }%`,
                                                              backgroundColor: "#f3e22d",
                                                              }
                                                          : {
                                                              width: `${
                                                                  (app.member_count * 100) /
                                                                  quota.max_team_member_per_app
                                                              }%`,
                                                              backgroundColor: "red",
                                                              }
                                                      }
                                                      className="quota-bar-inner"
                                                  ></div>
                                              </div>
                                              <span>&nbsp;{app.member_count} of {quota.max_team_member_per_app}</span>  
                                          </li>
                                      )
                                  })}
                      
                              </ul>
                          :
                              ''
                          }
                      </div>
                    ) : (
                      <p><Spinner /></p>
                    )}
                    </div>

                    {/* Collection Quota */}
                    <div className="billing-info-div">

                    <h2 className="billing-sub">Collection Quota</h2>

                    {quota ? (
                      <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
                      {/* no of collections */}
                          <div className="quota-data-item">
                              <img src="/assets/Icons/collection_num.svg" alt="collection_num" />
                              <h3>Max Collection per Application:</h3>
                              <span>{quota.max_collection_per_app}</span>
                          </div>

                          {quota.ownerships.length!==0 ?
                              <ul className="quota-data-item-list">
                                  {quota.ownerships.map((app,i)=>{
                                      return(
                                          <li key={i} className="quota-data-item">
                                              <img className="quota-app-icon" src={!app.icon ? '/assets/Icons/AddAppImage.svg' : app.icon} alt="_icon" />
                                              <span>{app.app}</span>  
                                              <div className="quota-bar">
                                                  <div
                                                      style={
                                                          (app.collection_count * 100) / quota.max_collection_per_app < 50
                                                          ? {
                                                              width: `${
                                                                  (app.collection_count * 100) /
                                                                  quota.max_collection_per_app
                                                              }%`,
                                                              backgroundColor: "#14CC26",
                                                              }
                                                          : (app.collection_count * 100) /
                                                              quota.max_collection_per_app >=
                                                              50 &&
                                                              (app.collection_count * 100) /
                                                              quota.max_collection_per_app <
                                                              75
                                                          ? {
                                                              width: `${
                                                                  (app.collection_count * 100) /
                                                                  quota.max_collection_per_app
                                                              }%`,
                                                              backgroundColor: "#f3e22d",
                                                              }
                                                          : {
                                                              width: `${
                                                                  (app.collection_count * 100) /
                                                                  quota.max_collection_per_app
                                                              }%`,
                                                              backgroundColor: "red",
                                                              }
                                                      }
                                                      className="quota-bar-inner"
                                                  ></div>
                                              </div>
                                              <span>&nbsp;{app.collection_count} of {quota.max_collection_per_app}</span>  
                                          </li>
                                      )
                                  })}
                      
                              </ul>
                          :
                              ''
                          }
                          
                      </div>
                    ) : (
                      <p><Spinner /></p>
                    )}
                    </div>

                    {/* Metadata */}
                    <div className="billing-info-div">

                    <h2 className="billing-sub">Metadata</h2>

                    {quota ? (
                      <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
                          {/* Textual bool */}
                          <div className="quota-data-item">
                            <img src="/assets/Icons/text_metadata.svg" alt="text_metadata" />
                              <h3>
                              Textual Data:&nbsp;&nbsp;
                              {quota.textual_data_storage ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" />
                              }
                              </h3>
                          </div>
                          {/* Screenshot bool */}
                          <div className="quota-data-item">
                              <img src="/assets/Icons/image_metadata.svg" alt="image_metadata" />
                              <h3>
                              Screenshots:&nbsp;&nbsp;
                              {quota.screenshot_data_storage ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" />
                              }
                              </h3>
                          </div>
                          {/* Preview bool */}
                          <div className="quota-data-item">
                              <img src="/assets/Icons/preview_metadata.svg" alt="preview_metadata" />
                              <h3>
                              Previews:&nbsp;&nbsp;
                              {quota.preview_data_storage ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" />
                              }
                              </h3>
                          </div>
                      </div>
                    ) : (
                      <p><Spinner /></p>
                    )}
                    </div>

                    {/* Import & Export */}
                    <div className="billing-info-div">

                    <h2 className="billing-sub">Import & Export</h2>

                    {quota ? (
                      <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
                          {/* Import */}
                          <div className="quota-data-item">
                              <img src="/assets/Icons/import_arrow.svg" alt="import_arrow" />
                              <h3>
                              Import Metadata:&nbsp;&nbsp;
                              <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                              </h3>
                          </div>

                          <ul className="quota-data-item-list">
                              <li className="quota-data-item">
                                  <span>&nbsp;Import Textual Data</span>
                                  {quota.textual_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                              </li>
                              <li className="quota-data-item">
                                  <span>&nbsp;Import Screenshots</span>
                                  {quota.screenshot_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                              </li>
                              <li className="quota-data-item">
                                  <span>&nbsp;Import Previews</span>
                                  {quota.preview_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                              </li>
                          </ul>

                          {/* Export */}
                          <div className="quota-data-item">
                              <img src="/assets/Icons/export_arrow.svg" alt="export_arrow" />
                              <h3>
                              Export Metadata:&nbsp;&nbsp;
                              {quota.export_storage===0 ? 
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" />
                              :
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                              }
                              </h3>
                          </div>

                          {quota.export_storage===0 ? 
                              ''
                          :
                              <ul className="quota-data-item-list">
                                  <li className="quota-data-item">
                                      <span>&nbsp;Export Textual Data</span>
                                      {quota.textual_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                                  </li>
                                  <li className="quota-data-item">
                                      <span>&nbsp;Export Screenshots</span>
                                      {quota.screenshot_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                                  </li>
                                  <li className="quota-data-item">
                                      <span>&nbsp;Export Previews</span>
                                      {quota.preview_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                                  </li>
                              </ul>
                          }

                      </div>
                    ) : (
                      <p><Spinner /></p>
                    )}
                    </div>
                </>
            :
                ""
        :
            ''
        }

        {quota ? 
            quota.account_type === "free" ?
                <div className="switch-price">
                  <span className="choicePrice" style={!table ? {color:'#3A6FF8', fontWeight:'600'} : {fontWeight:'600'}}>Monthly</span>
                  <label className="switch">
                    <input type="checkbox" onChange={(e)=>setTable(e.target.checked)}/>
                    <span className="slider round"></span>
                  </label>
                  <span className="choicePrice"  style={table ? {color:'#3A6FF8', fontWeight:'600'} : {fontWeight:'600'}}>Yearly</span>
                </div>

            :
                ''
        :
            ''
        }

        {quota ? (
          quota.account_type === "free" ? (
            <div id="priceTable" className="price-table">

              <div className="table13 table">
                <div className="table13-inner">
                      <h3></h3>
                      <span className="table-price" style={{margin:' 13px 0'}}>
                         <span className="table-price-span">Free</span>
                      </span>
                      <div className="table-btn-div">
                          <button className="free-btn">Free forever</button>
                          <span>{prices.free.subtitle}</span>
                      </div>
                      <div className="table-desc-div">
                          <hr />
                          <span>
                              {prices.free.prompt}
                          </span>
                          <hr />
                      </div>
                      <ul>
                          <li>
                              {prices.free.apps.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.free.apps.text}
                              </span>
                          </li>

                          <li>
                              {prices.free.collections.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.free.collections.text}
                              </span>
                          </li>

                          <li>
                              {prices.free.team.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.free.team.text}
                              </span>
                          </li>

                          <li>
                              {prices.free.metadata.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.free.metadata.text}
                              </span>
                          </li>

                          <li>
                              {prices.free.importExportPreview.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                {prices.free.importExportPreview.text}
                              </span>
                          </li>

                          <li>
                              {prices.free.uploads.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.free.uploads.text}
                              </span>
                          </li>
                      </ul>
                  </div>
   
              </div>

              <div className="table2 table">
                      <div className="table-title-div">
                          <h3>Premium</h3>
                          <span style={{backgroundColor:'gold', color:'#000', fontSize:'0.9rem', padding:'2px 9px',borderRadius:'15px', color:'#0f044c'}}>Most Popular</span>
                      </div>
                      <span className="table-price">
                        {!table ?
                          <>
                              <span className="table-price-span">{prices.premium.month}</span>/month
                          </>
                        :
                          <>
                              <span className="table-price-span">{prices.premiumYear.month}</span><span>/month*</span><br /><span style={{fontSize:'0.85rem', fontStyle:'italic'}}>*Pay 2 months less than monthly plan</span>
                          </> 
                        }
                         
                      </span>
                      <div className="table-btn-div">
                          {quota.trial_used===0 ?
                            <>
                              <button disabled={isWaiting || isWaiting2} onClick={
                                !table ? ()=>{setIsWaiting(true); sendPayData(prices.premium.priceId, "trial")}
                                        : ()=>{setIsWaiting(true); sendPayData(prices.premiumYear.priceId, "trial") } 
                              }>
                                {!isWaiting ?
                                    "Start Trial"
                                :
                                    <SpinnerSmall/>
                                }
                              </button>    
                              <span>{prices.premium.subtitleTrial}</span>
                            </>
                          :
                            <>
                              <button disabled={isWaiting || isWaiting2} onClick={
                                !table ? ()=>{setIsWaiting(true); sendPayData(prices.premium.priceId, "premium")}
                                        : ()=>{setIsWaiting(true); sendPayData(prices.premiumYear.priceId, "premium_year") } 
                              }>
                                  {!isWaiting ?
                                      "Get Started"
                                  :
                                      <SpinnerSmall/>
                                  }
                              </button>
                              <span>{prices.premium.subtitle}</span>
                            </>
                          }
                      </div>
                      <div className="table-desc-div">
                          <hr />
                          <span>
                            {prices.premium.prompt}
                          </span>
                          <hr />
                      </div>
                      <ul>
                          <li>
                              {prices.premium.apps.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                {prices.premium.apps.text}
                              </span>
                          </li>

                          <li>
                              {prices.premium.collections.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.premium.collections.text}
                              </span>
                          </li>

                          <li>
                              {prices.premium.team.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.premium.team.text}
                              </span>
                          </li>

                          <li>
                              {prices.premium.metadata.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.premium.metadata.text}
                              </span>
                          </li>

                          <li>
                              {prices.premium.importExportPreview.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.premium.importExportPreview.text}
                              </span>
                          </li>

                          <li>
                              {prices.premium.uploads.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.premium.uploads.text}
                              </span>
                          </li>
                      </ul>

              </div>

              <div className="table13 table">
                  <div className="table13-inner">
                      {/* <h3></h3>
                      <span className="table-price" style={{margin:' 10px 0'}}>
                         <span>Enterprise</span>
                      </span>
                      <div className="table-btn-div">
                          <button>Contact Us</button>
                          <span>Tailor your own experience</span>
                      </div>
                      <div className="table-desc-div">
                          <hr />
                          <span>
                            Get in touch with us and customize your subscription according to your needs
                          </span>
                          <hr />
                      </div>
                      <ul>
                      <li>
                          <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                          <span>
                              Custom number of applications
                          </span>
                      </li>

                      <li>
                          <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                          <span>
                              Custom number of collections
                          </span>
                      </li>

                      <li>
                          <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                          <span>
                              Custom number of team members
                          </span>
                      </li>

                      <li>
                          <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                          <span>
                              Descriptions, Keywords, What's New, Screenshots and Previews
                          </span>
                      </li>

                      <li>
                          <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                          <span>
                              Custom number of App Store Connect uploads
                          </span>
                      </li>

                      <li>
                          <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                          <span>
                              Unlimited import and export
                          </span>
                      </li>
                  </ul> */}
                  <div className="table-title-div">
                      <h3>Premium Plus</h3>
                      <span style={{backgroundColor:'gold', color:'#000', fontSize:'0.9rem', padding:'2px 9px',borderRadius:'15px', color:'#0f044c'}}>Best Value</span>
                  </div>
                  <span className="table-price">
                      {!table ?
                        <>
                            <span className="table-price-span">{prices.premiumPlus.month}</span>/month
                        </>
                      :
                        <>
                            <span className="table-price-span">{prices.premiumPlusYear.month}</span><span>/month*</span><br /><span style={{fontSize:'0.85rem', fontStyle:'italic'}}>*Pay 2 months less than monthly plan</span>
                        </> 
                      }
                  </span>
                  <div className="table-btn-div">
                          <button disabled={isWaiting || isWaiting2} onClick={
                                !table ? ()=>{setIsWaiting2(true); sendPayData(prices.premiumPlus.priceId, "premium_plus")}
                                        : ()=>{setIsWaiting2(true); sendPayData(prices.premiumPlusYear.priceId, "premium_plus_year") } 
                          }>
                          {!isWaiting2 ?
                              "Get Started"
                          :
                              <SpinnerSmall/>
                          }
                      </button>
                      <span>{prices.premiumPlus.subtitle}</span>
                  </div>
                  <div className="table-desc-div">
                      <hr />
                      <span>
                          {prices.premiumPlus.prompt}
                      </span>
                      <hr />
                  </div>
                  <ul>
                          <li>
                              {prices.premiumPlus.apps.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                {prices.premiumPlus.apps.text}
                              </span>
                          </li>

                          <li>
                              {prices.premiumPlus.collections.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.premiumPlus.collections.text}
                              </span>
                          </li>

                          <li>
                              {prices.premiumPlus.team.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.premiumPlus.team.text}
                              </span>
                          </li>

                          <li>
                              {prices.premiumPlus.metadata.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.premiumPlus.metadata.text}
                              </span>
                          </li>

                          <li>
                              {prices.premiumPlus.importExportPreview.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.premiumPlus.importExportPreview.text}
                              </span>
                          </li>

                          <li>
                              {prices.premiumPlus.uploads.flag ?
                                  <img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
                              :
                                  <img src="/assets/Icons/x.svg" alt="negative_mark" /> 
                              }
                              <span>
                                  {prices.premiumPlus.uploads.text}
                              </span>
                          </li>

                      </ul>
                  </div>
              </div>

            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {quota ? (
          quota.account_type === "free" ? (
            ""
          ) : (
            <a
              className="manage-sub"
              href={`https://billing.stripe.com/p/login/cN28wD2gYfAn01OaEE?prefilled_email=${
                initialUserData ? initialUserData.email : ""
              }`}
              rel="noopener noreferrer"
            >
              <p>Manage Subscription</p>
            </a>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Billing