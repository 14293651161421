import {useState} from 'react'
import './VideoPopup.css'


function VideoPopup({url, func}) {
    


    //popup close
    const closePopup = () => {
        func()
    }

    return (
        <div className='video-popup-container'>
            <div className='videoPopupBack' onClick={closePopup}></div>
            <div className='video-popup-content'>
                <iframe 
                    width="100%" 
                    height="100%" 
                    style={{border:'none'}}
                    src={url} 
                    title="YouTube video player" 
                    allow="accelerometer; autoplay; clipboard-write; web-share" 
                    allowFullScreen={true}>
                </iframe>
            </div>
        </div>
    )
}

export default VideoPopup