import { useNavigate } from 'react-router-dom'
import VariableContext from '../userContext/VariableContext';
import { useContext } from 'react';
import './LogoutButton.css'

function LogoutButton() {

  let navigate = useNavigate()

  const {setInitialUserData} = useContext(VariableContext)
  const {setQuota} = useContext(VariableContext)
  const {setSubWarning} = useContext(VariableContext)
  const {setAppUploads} = useContext(VariableContext)

  const {setCurrentAppId}= useContext(VariableContext)
  const {setCurrentAppIcon} = useContext(VariableContext)
  const {setCurrentAppName} = useContext(VariableContext)
  const {setCurrentAppLocalizations} = useContext(VariableContext)
  const {setPrimaryLocale} = useContext(VariableContext)
  const {setCurrentAppCols} = useContext(VariableContext)
  const {setCurrentAppVersion} = useContext(VariableContext)

  const {setColDescriptions} = useContext(VariableContext)
  const {setColKeywords} = useContext(VariableContext)
  const {setColWhatsNews} = useContext(VariableContext)
  const {setColScreenshots} = useContext(VariableContext)
  const {setColPrimaryScreenshots} = useContext(VariableContext)

  const {setCurrentAppRole} = useContext(VariableContext)
  const {setSelectedAppColName} = useContext(VariableContext)
  const {setSelectedAppColId} = useContext(VariableContext)
  
  const {setVersionEdit} = useContext(VariableContext)

  const {appCol} = useContext(VariableContext)
  const {setRegistryArray} = useContext(VariableContext)
  const {setImportArray} = useContext(VariableContext)
  const {setImportPercentage} = useContext(VariableContext)


  // const {setName} = useContext(VariableContext)
  // const {setLanguage} = useContext(VariableContext)
  const {setCollectionLocalizations} = useContext(VariableContext)
  const {setCollectionScreenshotLocalizations} = useContext(VariableContext)
  const {setCollectionNotLocalizations} = useContext(VariableContext)

  const {setIsUploadingDescription} = useContext(VariableContext)
  const {setIsUploadingKeyword} = useContext(VariableContext)
  const {setIsUploadingWhatsnew} = useContext(VariableContext)
  const {setIsUploadingPhoto} = useContext(VariableContext)
  const {setIsUploading} = useContext(VariableContext)
  const {setLastUpload} = useContext(VariableContext)

  const {setUploadMessageDescription} = useContext(VariableContext)
  const {setUploadMessageKeyword} = useContext(VariableContext)
  const {setUploadMessageWhatsnew} = useContext(VariableContext)
  const {setUploadMessagePhoto} = useContext(VariableContext)
  const {setUploadMessagePreview} = useContext(VariableContext)
  const {setUploadMessage} = useContext(VariableContext)

  const {setSummaryUpload} = useContext(VariableContext)
  const {setSummary} = useContext(VariableContext)

  const {setSocket} = useContext(VariableContext)



  const handleClick = () => {
    localStorage.clear()

    setInitialUserData(0)
    
    setQuota(null)
    setSubWarning(null)
    setAppUploads([])

    setCurrentAppId(0)
    setCurrentAppIcon(null)
    setCurrentAppName(null)
    setCurrentAppRole(null)
    setCurrentAppVersion(null)
    setCurrentAppLocalizations(null)
    setPrimaryLocale(null)
    setCurrentAppCols(null)

    setColDescriptions(null)
    setColKeywords(null)
    setColWhatsNews(null)
    setColScreenshots(null)
    setColPrimaryScreenshots(null)

    // setLanguage('')
    // setName('')

    setCollectionLocalizations(null)
    setCollectionScreenshotLocalizations(null)
    setCollectionNotLocalizations(null)
  
    setSelectedAppColName(null)
    setSelectedAppColId(0)

    setVersionEdit(null)
    setRegistryArray(new Map())
    appCol.current= {}

    setImportArray(new Map())
    setImportPercentage(new Map())
    setSummary(null)

    setIsUploadingDescription({status:false, appID: null, collectionID: null})
    setIsUploadingKeyword({status:false, appID: null, collectionID: null})
    setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
    setIsUploadingPhoto({status:false, appID: null, collectionID: null})
    setIsUploading({status:false, appID: null, collectionID: null})

    setUploadMessageDescription([{status:0}])
    setUploadMessageKeyword([{status:0}])
    setUploadMessageWhatsnew([{status:0}])
    setUploadMessagePhoto([{status:0}])
    setUploadMessagePreview([{status:0}])
    setUploadMessage([{status:0}])

    setSummaryUpload(null)

    setSocket(0)
    setLastUpload(null)

    navigate('/login')
  }

  return (
    <div className="sidebar-sub-item ssi" onClick={handleClick}>
        <img className='active-url' src="/assets/Icons/logoutActive.svg" alt="layers" />
        <img className='passive-url' src="/assets/Icons/logout-pre.svg" alt="layers" />
        <p className='log-out'>Log Out</p>
    </div>
  )
}

export default LogoutButton