import { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import "./App.css";
import {VariableProvider} from './userContext/VariableContext'
import AxiosProvider from './AxiosProvider';
import RegisterPage from './Pages/RegisterPage';
import LoginPage from './Pages/LoginPage'
import HomePage from './Pages/HomePage';
import EmailConfirmationPage from './Pages/EmailConfirmationPage';
import ChangeEmailConfirmationPage from './Pages/ChangeEmailConfirmationPage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import NotFound404 from './404/NotFound404';
import PaymentSuccessful from './Pages/PaymentSuccessful'
import PaymentCanceled from './Pages/PaymentCanceled'
import { useCookies, Cookies } from "react-cookie"
import * as gaEvents from "./ga-utils"
  
function App() {
  const [cookiesAll, setCookieAll] = useCookies(["_cookieConsentAll"]);
	const [cookiesGA, setCookieGA] = useCookies(["_cookieConsentGA"]);
	const [cookiesMS, setCookieMS] = useCookies(["_cookieConsentMS"]);
	const [cookiesAsk, setCookieAsk] = useCookies(["_cookieConsentAsk"]);

	const [msState, setMsState] = useState(true)
	const [gaState, setGaState] = useState(true)

	const [showOptions, setShowOptions] = useState(false)

	const [showOptionsNS, setShowOptionsNS] = useState(false)
	const [showOptionsGA, setShowOptionsGA] = useState(false)
	const [showOptionsMS, setShowOptionsMS] = useState(false)

  const cookies = new Cookies();


  const showCookieOptions = () =>{
		setShowOptions(!showOptions)
		if(showOptions===true){
			document.querySelector(".arrow-custom").style.transform = "rotate(0deg)"
		}
		if(showOptions===false){
			document.querySelector(".arrow-custom").style.transform = "rotate(-180deg)"
		}
	}

	const handleAcceptCookie = () =>{
		setCookieAll("_cookieConsentAll", "true", {
			path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
		  });
		setCookieAsk("_cookieConsentAsk", "false", {
			path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
		});
		//enable ga4 and clarity
		window.clarity('consent');
		gaEvents.initGA(`${process.env.REACT_APP_GA_ID}`)
	}

	const handleAcceptCookieCustom = () =>{
    const allCookies = cookies.getAll();

    Object.keys(allCookies).forEach(cookieName => {
      cookies.remove(cookieName, {domain:'.storesyncer.com' , path: '/' });
    });


		setCookieAsk("_cookieConsentAsk", "false", {
			path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
		});


		if(msState===true){
			setCookieMS("_cookieConsentMS", "true", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});
			window.clarity('consent');
		}
		else{
			setCookieMS("_cookieConsentMS", "false", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});
		}


		if(gaState===true){
			setCookieGA("_cookieConsentGA", "true", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});
			gaEvents.initGA(`${process.env.REACT_APP_GA_ID}`)
		}
		else{
			setCookieGA("_cookieConsentGA", "false", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});

		}
	}

	const handleDeclineCookie = () => {

    const allCookies = cookies.getAll();

    Object.keys(allCookies).forEach(cookieName => {
      cookies.remove(cookieName, {domain:'.storesyncer.com' , path: '/' });
    });

		setCookieAll("_cookieConsentAll", "false", {
			path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
		});
		setCookieAsk("_cookieConsentAsk", "false", {
			path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
		});
  };

	useEffect(()=>{
		//cookie check

		if(cookiesAsk._cookieConsentAsk !== "false"){
        const allCookies = cookies.getAll();

      Object.keys(allCookies).forEach(cookieName => {
          cookies.remove(cookieName, {domain:'.storesyncer.com' , path: '/' });
      });

			setCookieAsk("_cookieConsentAsk", "true", {
				  path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});
		}


		if (cookiesAll._cookieConsentAll === "true") {
			gaEvents.initGA(`${process.env.REACT_APP_GA_ID}`)
			window.clarity('consent');
		}else if(cookiesAll._cookieConsentAll === "false"){
      const allCookies = cookies.getAll();

      Object.keys(allCookies).forEach(cookieName => {
          if(cookieName!=='_cookieConsentAll' && cookieName!=='_cookieConsentAsk' && !(cookieName==="_cookieConsentGA"&&cookiesGA._cookieConsentGA === "true") && !(cookieName==="_cookieConsentMS"&&cookiesMS._cookieConsentMS === "true")){
            cookies.remove(cookieName, {domain:'.storesyncer.com' , path: '/' })
          }
      });
    }


		if (cookiesGA._cookieConsentGA === "true") {
			gaEvents.initGA(`${process.env.REACT_APP_GA_ID}`)
		}

		if (cookiesMS._cookieConsentMS === "true") {
			window.clarity('consent');
		}
	},[])
  return (
    <VariableProvider>   
      <Router>
        <div className="App">
          {/* cookie consent */}

          {cookiesAsk._cookieConsentAsk === "true" ?
              <div className='cookieConsent'>

                <div className='cookieConsent-inner-up'>
                  <div className='cookie-text'>
                    <h3>We Use Cookies</h3>
                    <p>
                      We may place cookies for analysis of our visitor data, to improve our website and measure advertising performance. Overall this data is used to provide a better website experience. For more information about the cookies we use, click the <strong>"Customize"</strong> button. <a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/privacypolicy">Privacy Policy</a> <a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/cookiepolicy">Cookie Policy</a> <a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/termsConditions">Terms and Conditions</a>
                    </p>
                  </div>
                  <div className='cookie-buttons'>
                    <button onClick={showCookieOptions} className='customize'>Customize <span className='arrow-custom'></span></button>
                    <button onClick={handleDeclineCookie} className='decline-all'>Decline All</button>
                    <button onClick={handleAcceptCookie} className='accept-all'>Accept All</button>
                  </div>
                </div>

                {showOptions===true ? <>
                  <div className='cookieConsent-inner-down'>

                    <div className='necessary cookie-type'>
                      <div className='cookie-type-content'>
                        <h3>Necessary</h3>
                        <p>These cookies are required for good functionality of our website and can't be switched off in our system.</p>
                        <p onClick={()=>setShowOptionsNS(!showOptionsNS)} style={{textDecoration:'underline', fontStyle:'italic'}}>
                          {showOptionsNS ? 'Show Less' : 'Show More'}
                        </p>
                      </div>
                      <div className='cookie-type-switch'>
                        <label className="switch">
                          <input checked={true} type="checkbox" disabled={true}/>
                          <span className="slider round disabled-input"></span>
                        </label>
                      </div>
                    </div>

                    {showOptionsNS ? 
                      <div className='cookie-details'>
                        <h4>_cookieConsentAsk</h4>
                        <p>This cookie is to determine whether to ask user for cookie consents.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>

                        <h4>_cookieConsentGA</h4>
                        <p>This cookie is to determine whether user accepts Google Analytics cookies.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>

                        <h4>_cookieConsentMS</h4>
                        <p>This cookie is to determine whether user accepts Microsoft Clarity and Bing cookies.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>

                        <h4>_cookieConsentAll</h4>
                        <p>This cookie is to determine whether user accepts all cookies.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>
                        
                        <h4>_stripe_mid</h4>
                        <p>This cookie is to to provide fraud prevention.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>
                        
                        <h4>__stripe_sid</h4>
                        <p>This cookie is to to provide fraud prevention.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>
                        <p></p>
                        
                        <h4>m</h4>
                        <p>This cookie is to to provide fraud prevention.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>
                        <p></p>

												<p></p>
												<a target='_blank' style={{textDecoration:'underline',color:'#fff', fontStyle:'italic'}} href="https://stripe.com/privacy" rel='noopener noreferrer'>Stripe Privacy Policy</a>

                      </div>
                    : ''
                    }


                    <hr />

                    <div className='analytics cookie-type'>
                      <div className='cookie-type-content'>
                        <h3>Analytics</h3>
                        <p>We use these cookies to provide statistical information about our website - they are used for performance measurement, improvement and analysis.</p>
                        <p onClick={()=>setShowOptionsGA(!showOptionsGA)} style={{textDecoration:'underline', fontStyle:'italic'}}>
                          {showOptionsGA ? 'Show Less' : 'Show More'}
                        </p>
                        {showOptionsGA ? 
                          <div className='cookie-heading'>
                            <img style={{width:'25px'}} src="/assets/Icons/google-analytics-icon.png" alt="GA4" />
                            <h4>Google Analytics</h4>
                          </div>
                        : ''
                        }
                      </div>
                      <div className='cookie-type-switch'>
                        <label className="switch">
                          <input checked={gaState} onChange={(e)=>setGaState(e.target.checked)} type="checkbox"/>
                          <span style={{cursor:'pointer'}} className="slider round"></span>
                        </label>
                      </div>
                    </div>

                    {showOptionsGA ? 
                      <div className='cookie-details'>
                        <h4>_ga</h4>
                        <p>Registers a unique ID for a website visitor it tracks how the visitor uses the website. The data is used for statistics.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 2 years - Type: Cookie</p>

                        <h4>_ga_********</h4>
                        <p>This cookie stores a unique ID for a website visitor it tracks how the visitor uses the website. The data is used for statistics.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 2 years - Type: Cookie</p>

                        <p></p>
                        <a style={{textDecoration:'underline',color:'#fff', fontStyle:'italic'}} target='_blank' href="https://policies.google.com/privacy" rel='noopener noreferrer'>Google Privacy Policy</a>
                      </div>
                    : ''
                    }

                    <hr />

                    <div className='functional cookie-type'>
                      <div className='cookie-type-content'>
                        <h3>Performance / Advertisement</h3>
                        <p>We use these cookies to enhance functionality and improve our site along with cookies set through our site by our advertising partners.</p>
                        <p onClick={()=>setShowOptionsMS(!showOptionsMS)} style={{textDecoration:'underline', fontStyle:'italic'}}>
                          {showOptionsMS ? 'Show Less' : 'Show More'}
                        </p>
                        {showOptionsMS ?
                          <div className='cookie-heading'>
                            <img style={{width:'25px'}} src="/assets/Icons/ms-clarity.png" alt="MS_Clarity" />
                            <h4>Microsoft Clarity</h4>
                          </div>
                        : ''
                        }
                      </div>
                      <div className='cookie-type-switch'>
                        <label className="switch">
                          <input checked={msState} onChange={(e)=>setMsState(e.target.checked)} type="checkbox"/>
                          <span style={{cursor:'pointer'}} className="slider round"></span>
                        </label>
                      </div>
                    </div>

                    {showOptionsMS ?
                      <div className='cookie-details'>
                        <h4>_clsk</h4>
                        <p>This cookie connects multiple page views by a user into a single Clarity session recording.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 day - Type: Cookie</p>
                        
                        <h4>_clck</h4>
                        <p>This cookie collects data about visitor navigation and behavior on the website - This is used to compile statistical reports and heatmaps for the website owner.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>
                        
                        <h4>MR</h4>
                        <p>This is a Microsoft MSN 1st party cookie which we use to measure the use of the website for internal analytics.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: .c.clarity.ms - Expiration time: 7 days - Type: Cookie</p>
                        
                        <h4>MUID</h4>
                        <p>This cookie is widely used by Microsoft as a unique user ID. It can be set by embedded Microsoft scripts. It is widely believed to synchronize between many different Microsoft domains, allowing users to be tracked.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>

                        <h4>ANONCHK</h4>
                        <p>Indicates whether MUID is transferred to ANID, a cookie used for advertising. Clarity doesn't use ANID and so this is always set to 0.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: www.clarity.ms - Expiration time: At least one session - Type: Cookie</p>
                        
                        <h4>CLID</h4>
                        <p>This cookie collects data about visitor navigation and behavior on the website - This is used to compile statistical reports and heatmaps for the website owner.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: www.clarity.ms - Expiration time: 1 year - Type: Cookie</p>

                        <h4>SM</h4>
                        <p>This is a Microsoft MSN 1st party cookie that ensures the proper functioning of the website.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: .c.clarity.ms - Expiration time: At least one session - Type: Cookie</p>

                        <h4>SRM_B</h4>
                        <p>This is a Microsoft MSN 1st party cookie that ensures the proper functioning of this website.</p>
                        <p style={{fontStyle:'italic'}}> Domain name: .c.bing.com - Expiration time: 1 year - Type: Cookie</p>
                        <p></p>
                        <a target='_blank' style={{textDecoration:'underline',color:'#fff', fontStyle:'italic'}} href="https://privacy.microsoft.com/en-us/privacystatement" rel='noopener noreferrer'>Microsoft Privacy Policy</a>
                      </div>
                    : ''
                    }


                  </div>
                  <div style={{maxWidth:'1200px', width:"100%", textAlign:"right"}}>
                    <button onClick={handleAcceptCookieCustom} className='saveButton accept-all'>Save Preferences</button>
                  </div></>

                : 
                  ''
                }
              

              </div>
          :
              ''
          }
          <AxiosProvider/>
          <Routes>
            <Route exact path='/confirmation/:token' element={
              <EmailConfirmationPage/>
            }>
            </Route>
            <Route exact path='/forgetPassword/:token' element={
              <ForgotPasswordPage/>
            }>
            </Route>
            <Route exact path='/changeEmail/:token' element={
              <ChangeEmailConfirmationPage/>
            }>
            </Route>
            <Route exact path='/paymentsuccessful' element={
              <PaymentSuccessful/>
            }>
            </Route>
            <Route exact path='/paymentcanceled' element={
              <PaymentCanceled/>
            }>
            </Route>
            <Route exact path='/register' element={
              <RegisterPage/>
            }>
            </Route>
            <Route exact path='/login' element={
              <LoginPage/>
            }>
            </Route>
            <Route exact path='/404' element={
              <NotFound404/>
            }>
            </Route>
            <Route exact path='/*' element={
              <HomePage/>
            }>
            </Route>
          </Routes>
        </div>
      </Router>
    </VariableProvider>
  );
}
  
export default App;