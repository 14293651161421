import './MailButton.css'

function MailButton({inbox, placeholder, icon, color}) {
  return (
    <a className='mail-button-inner' target='_blank' rel="noopener noreferrer" href={inbox} >    
        <div className='mail-button'>
            <img className={(placeholder=='Gmail') ? 'mail-gmail' : ''} style={{width: '48px', height: 'auto'}} src={icon} />
            <span className='mail-button-text' style={{color:color}}>{placeholder}</span>
        </div>
    </a>
  )
}

export default MailButton