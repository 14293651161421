import {useContext} from 'react'
import SpinnerMid from '../Misc/SpinnerMid'
import VariableContext from '../userContext/VariableContext'
import './UploadProgressPopup.css'
import SummaryUploadPopup from './SummaryUploadPopup'


function UploudProgressPopup() {

    const {isUploadingDescription, setIsUploadingDescription} = useContext(VariableContext)
    const {isUploadingKeyword, setIsUploadingKeyword} = useContext(VariableContext)
    const {isUploadingWhatsnew, setIsUploadingWhatsnew} = useContext(VariableContext)
    const {isUploadingPhoto, setIsUploadingPhoto} = useContext(VariableContext)
    const {isUploadingPreview, setIsUploadingPreview} = useContext(VariableContext)
    const {isUploading, setIsUploading} = useContext(VariableContext)


    const {uploadMessageDescription, setUploadMessageDescription} = useContext(VariableContext)
    const {uploadMessageKeyword, setUploadMessageKeyword} = useContext(VariableContext)
    const {uploadMessageWhatsnew, setUploadMessageWhatsnew} = useContext(VariableContext)
    const {uploadMessagePhoto, setUploadMessagePhoto} = useContext(VariableContext)
    const {uploadMessagePreview, setUploadMessagePreview} = useContext(VariableContext)
    const {uploadMessage, setUploadMessage} = useContext(VariableContext)

    const {summaryUpload, setSummaryUpload} = useContext(VariableContext)

    const {currentAppCols, setCurrentAppCols} = useContext(VariableContext)


    const uploadFinishedDescription = () =>{
        setIsUploadingDescription({status:false, appID: null, collectionID: null})
        setUploadMessageDescription([{status:0}])
    }
    const uploadFinishedKeyword = () =>{
        setIsUploadingKeyword({status:false, appID: null, collectionID: null})
        setUploadMessageKeyword([{status:0}])
    }
    const uploadFinishedWhatsnew = () =>{
        setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
        setUploadMessageWhatsnew([{status:0}])
    }
    const uploadFinishedPhoto = () =>{
        setIsUploadingPhoto({status:false, appID: null, collectionID: null})
        setUploadMessagePhoto([{status:0}])
    }
    const uploadFinishedPreview = () =>{
        setIsUploadingPreview({status:false, appID: null, collectionID: null})
        setUploadMessagePreview([{status:0}])
    }
    const uploadFinished = () =>{
        setIsUploading({status:false, appID: null, collectionID: null})
        setUploadMessage([{status:0}])
    }
    
    const togglePopup = () =>{
        document.getElementById('toggle-upload-popup-icon').classList.toggle('rotate')
        document.getElementById('upload-popup-content').classList.toggle('intoView-uploadPopup')
    }

    const openSummary = (message) =>{
        setSummaryUpload(message)
    }


    return (
        
        <div id='upload-popup-content' className='upload-popup-content'>
            <div onClick={togglePopup} id='toggle-upload-popup'><img id='toggle-upload-popup-icon' src="/assets/Icons/down-arrow-black.svg" alt="toggle" /></div>
            <div className="textUpload-inner-content">

            {/* No message yet */}
            {/* {!uploadMessage && !uploadMessageDescription && !uploadMessageKeyword && !uploadMessageWhatsnew && !uploadMessagePhoto ? <div style={{alignSelf: 'center'}}><SpinnerMid/></div> : ''} */}

            {uploadMessage[0].status===0 && uploadMessageDescription[0].status===0 && uploadMessageKeyword[0].status===0 && uploadMessageWhatsnew[0].status===0 && (uploadMessagePhoto[0].status===0||uploadMessagePhoto[0].status===1) && (uploadMessagePreview[0].status===0||uploadMessagePreview[0].status===1) ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px'}}><SpinnerMid/></div> : ''}
                
                
            {/* Whole Collection */}
                {uploadMessage[0].status===0 ? '' : <div className='upload-single'>
                <span className='upload-title'><strong>{isUploading.collectionID&&currentAppCols.find((i)=>i.collection_id===isUploading.collectionID) ? currentAppCols.find((i)=>i.collection_id===isUploading.collectionID).collection_title : ''} </strong></span>
                
                    <span><strong>Status:</strong>&nbsp; 
                        {uploadMessage[0].status==='done' ? 
                            'Finished' 
                        :
                            uploadMessage[0].status
                        }  
                    </span>  
                    
                 
                        {uploadMessage[0].status==='done' ? 
                            <span>

                                <strong>Summary:</strong>

                                &nbsp;
                                
                                {uploadMessage[0].data.textualData.errorList.length===0 && uploadMessage[0].data.photo.errorList.length===0 && uploadMessage[0].data.preview.errorList.length===0 ? 'All metadata uploaded succesfully.'+' ' : uploadMessage[0].message} 

                                <span onClick={()=>openSummary(uploadMessage[0])} style={{color:'#3A6FF8', cursor:'pointer'}}>Click here</span> for details.

                            </span>
                        :
                            ''
                        }  
             






                    <span className='upload-subtitle'><strong>Textual Data</strong></span>      

                    <span>
                        <span><strong>Error:</strong>&nbsp;{uploadMessage[0].data.textualData.error}</span>           
                    </span>

                    {uploadMessage[0].status!=='done' ?
                    <>

                    <span><strong>Progress:</strong>&nbsp; 
                        {Math.trunc((uploadMessage[0].data.textualData.uploaded+uploadMessage[0].data.textualData.error)*(100/uploadMessage[0].data.textualData.size))+' %'} 
                    </span>

                    <span className='loading-bar'>                        
                        <div className={uploadMessage[0].data.textualData.done===true ? 'loading-bar-finished' : 'loading-bar-ongoing'} 
                            style={{width: `${(uploadMessage[0].data.textualData.uploaded+uploadMessage[0].data.textualData.error)*(100/uploadMessage[0].data.textualData.size)}%`}}>
                        </div>
                    </span>
                    </>
                    : ''
                    }





                    <span className='upload-subtitle'><strong>Screenshots</strong></span>
                  
                    <span>                           
                        <span><strong>Error:</strong>&nbsp;{uploadMessage[0].data.photo.error}</span>                         
                    </span>

                    {uploadMessage[0].status!=='done' ?
                    <>
                        <span><strong>Progress:</strong>&nbsp; 
                            {uploadMessage[0].data.photo.size===0 ? '' :
                                Math.trunc((uploadMessage[0].data.photo.uploaded+uploadMessage[0].data.photo.error)*(100/uploadMessage[0].data.photo.size))+' %'
                            }                    
                                          
                        </span>
                        {uploadMessage[0].data.photo.size===0 ? '' :
                            <span className='loading-bar'>                        
                                <div className={uploadMessage[0].data.photo.done===true ? 'loading-bar-finished' : 'loading-bar-ongoing'} 
                                    style={{width: `${(uploadMessage[0].data.photo.uploaded+uploadMessage[0].data.photo.error)*(100/uploadMessage[0].data.photo.size)}%`}}>
                                </div>
                            </span>
                        }
                    </>  
                    : ''
                    }

 




                    <span className='upload-subtitle'><strong>Previews</strong></span>
                  
                    <span>                           
                        <span><strong>Error:</strong>&nbsp;{uploadMessage[0].data.preview.error}</span>                         
                    </span>

                    {uploadMessage[0].status!=='done' ?
                    <>

                    <span><strong>Progress:</strong>&nbsp; 
                        {uploadMessage[0].data.preview.size === 0 ? '' :
                            Math.trunc((uploadMessage[0].data.preview.uploaded+uploadMessage[0].data.preview.error)*(100/uploadMessage[0].data.preview.size))+' %'
                        }                                   
                    </span>
                    
                    {uploadMessage[0].data.preview.size === 0 ? '' :
                        <span className='loading-bar'>                        
                            <div className={uploadMessage[0].data.preview.done===true ? 'loading-bar-finished' : 'loading-bar-ongoing'} 
                                style={{width: `${(uploadMessage[0].data.preview.uploaded+uploadMessage[0].data.preview.error)*(100/uploadMessage[0].data.preview.size)}%`}}>
                            </div>
                        </span>
                    }



                    </>  
                    : ''
                    }

                    {uploadMessage[0].status==='done' ?
                        <button onClick={uploadFinished}>OK</button>
                    :
                        ''
                    }
                </div>}
            {/* Whole Collection */}
            {/* Description */}
                {uploadMessageDescription[0].status===0 ? '' : <div className='upload-single'>

                <span className='upload-title'><strong>{isUploadingDescription.collectionID&&currentAppCols.find((i)=>i.collection_id===isUploadingDescription.collectionID) ? currentAppCols.find((i)=>i.collection_id===isUploadingDescription.collectionID) ? currentAppCols.find((i)=>i.collection_id===isUploadingDescription.collectionID).collection_title : '': '' } - Description</strong></span>


                
                    <span><strong>Status:</strong>&nbsp; 
                        {uploadMessageDescription[0].status==='done' ? 
                            'Finished' 
                        :
                            uploadMessageDescription[0].status
                        }  
                    </span>  
                    
            
                    {uploadMessageDescription[0].status==='done' ? 
                        <span>

                            <strong>Summary:</strong>

                            &nbsp;

                            {uploadMessageDescription[0].data.textualData.errorList.length===0 && uploadMessageDescription[0].data.photo.errorList.length===0 && uploadMessageDescription[0].data.preview.errorList.length===0 ? 'All metadata uploaded succesfully.'+' ' : uploadMessageDescription[0].message}  
                            
                            <span onClick={()=>openSummary(uploadMessageDescription[0])} style={{color:'#3A6FF8', cursor:'pointer'}}>Click here</span> for details.
                            
                        </span>
                    :
                        ''
                    }  
                

                    <span>
                        <span><strong>Error:</strong>&nbsp;{uploadMessageDescription[0].data.textualData.error}</span>           
                    </span>


                    {uploadMessageDescription[0].status==='done' ?
                        <button onClick={uploadFinishedDescription}>OK</button>
                    :
                    <>
                        <span><strong>Progress:</strong>&nbsp; 
                            {Math.trunc((uploadMessageDescription[0].data.textualData.uploaded+uploadMessageDescription[0].data.textualData.error)*(100/uploadMessageDescription[0].data.textualData.size))+' %'} 
                        </span>

                        <span className='loading-bar'>                        
                            <div className={uploadMessageDescription[0].data.textualData.done===true ? 'loading-bar-finished' : 'loading-bar-ongoing'} 
                                style={{width: `${(uploadMessageDescription[0].data.textualData.uploaded+uploadMessageDescription[0].data.textualData.error)*(100/uploadMessageDescription[0].data.textualData.size)}%`}}>
                            </div>
                        </span>
                    </>
                    }
                    
                </div>}
            
            {/* Description */}
            {/* Keyword */}
                {uploadMessageKeyword[0].status===0 ? '' : <div className='upload-single'>
                    <span className='upload-title'><strong>{isUploadingKeyword.collectionID&&currentAppCols.find((i)=>i.collection_id===isUploadingKeyword.collectionID) ? currentAppCols.find((i)=>i.collection_id===isUploadingKeyword.collectionID).collection_title : ''} - Keyword</strong></span>
            

                
                    <span><strong>Status:</strong>&nbsp; 
                        {uploadMessageKeyword[0].status==='done' ? 
                            'Finished' 
                        :
                            uploadMessageKeyword[0].status
                        }  
                    </span>  
                    
      
                        {uploadMessageKeyword[0].status==='done' ? 
                            <span>
                                <strong>Summary:</strong>
                                
                                &nbsp;

                                {uploadMessageKeyword[0].data.textualData.errorList.length===0 && uploadMessageKeyword[0].data.photo.errorList.length===0 && uploadMessageKeyword[0].data.preview.errorList.length===0 ? 'All metadata uploaded succesfully.'+' ' : uploadMessageKeyword[0].message} 
                                
                                <span onClick={()=>openSummary(uploadMessageKeyword[0])} style={{color:'#3A6FF8', cursor:'pointer'}}>Click here</span> for details.
                                
                            </span>
                        :
                            ''
                        }  
        

                    <span>
                        <span><strong>Error:</strong>&nbsp;{uploadMessageKeyword[0].data.textualData.error}</span>           
                    </span>



                    {uploadMessageKeyword[0].status==='done' ?
                        <button onClick={uploadFinishedKeyword}>OK</button>
                    :
                    <>
                        <span><strong>Progress:</strong>&nbsp; 
                            {Math.trunc((uploadMessageKeyword[0].data.textualData.uploaded+uploadMessageKeyword[0].data.textualData.error)*(100/uploadMessageKeyword[0].data.textualData.size))+' %'} 
                        </span>

                        <span className='loading-bar'>                        
                            <div className={uploadMessageKeyword[0].data.textualData.done===true ? 'loading-bar-finished' : 'loading-bar-ongoing'} 
                                style={{width: `${(uploadMessageKeyword[0].data.textualData.uploaded+uploadMessageKeyword[0].data.textualData.error)*(100/uploadMessageKeyword[0].data.textualData.size)}%`}}>
                            </div>
                        </span>
                    </>
                    }
                   
                </div>}
            {/* Keyword */}
            {/* Whatsnew */}
        
            
                {uploadMessageWhatsnew[0].status===0 ? '' : <div className='upload-single'>
                    <span className='upload-title'><strong>{isUploadingWhatsnew.collectionID&&currentAppCols.find((i)=>i.collection_id===isUploadingWhatsnew.collectionID) ?  currentAppCols.find((i)=>i.collection_id===isUploadingWhatsnew.collectionID).collection_title : ''} - What's New</strong></span>

                
                    <span><strong>Status:</strong>&nbsp; 
                        {uploadMessageWhatsnew[0].status==='done' ? 
                            'Finished' 
                        :
                            uploadMessageWhatsnew[0].status
                        }  
                    </span>  
                    
         
                        {uploadMessageWhatsnew[0].status==='done' ? 
                               <span>
                                    
                                    <strong>Summary:</strong>
                                    
                                    &nbsp;
                                    
                                    {uploadMessageWhatsnew[0].data.textualData.errorList.length===0 && uploadMessageWhatsnew[0].data.photo.errorList.length===0 && uploadMessageWhatsnew[0].data.preview.errorList.length===0 ? 'All metadata uploaded succesfully.'+' ' : uploadMessageWhatsnew[0].message} 
                                    
                                    <span onClick={()=>openSummary(uploadMessageWhatsnew[0])} style={{color:'#3A6FF8', cursor:'pointer'}}>Click here</span> for details.
                                    
                                </span>
                        :
                            ''
                        }  
           

                    <span>
                        <span><strong>Error:</strong>&nbsp;{uploadMessageWhatsnew[0].data.textualData.error}</span>           
                    </span>



                    {uploadMessageWhatsnew[0].status==='done' ?
                        <button onClick={uploadFinishedWhatsnew}>OK</button>
                    :
                    <div>
                        <span><strong>Progress:</strong>&nbsp; 
                            {Math.trunc((uploadMessageWhatsnew[0].data.textualData.uploaded+uploadMessageWhatsnew[0].data.textualData.error)*(100/uploadMessageWhatsnew[0].data.textualData.size))+' %'} 
                        </span>

                        <span className='loading-bar'>                        <div className={uploadMessageWhatsnew[0].data.textualData.done===true ? 'loading-bar-finished' : 'loading-bar-ongoing'} 
                                style={{width: `${(uploadMessageWhatsnew[0].data.textualData.uploaded+uploadMessageWhatsnew[0].data.textualData.error)*(100/uploadMessageWhatsnew[0].data.textualData.size)}%`}}>
                            </div>
                        </span>
                    </div>
                    }
               
                </div>}
            
            {/* Whatsnew */}
            {/* Photo */}
                {uploadMessagePhoto[0].status===0 || uploadMessagePhoto[0].status===1 ? '' : <div className='upload-single'>
                    <span className='upload-title'><strong>{isUploadingPhoto.collectionID&&currentAppCols.find((i)=>i.collection_id===isUploadingPhoto.collectionID) ?  currentAppCols.find((i)=>i.collection_id===isUploadingPhoto.collectionID).collection_title : ''} - Screenshots</strong></span>
                

                
                    <span><strong>Status:</strong>&nbsp; 
                        {uploadMessagePhoto[0].status==='done' ? 
                            'Finished' 
                        :
                            uploadMessagePhoto[0].status
                        }  
                    </span>  
                    
                  
                        {uploadMessagePhoto[0].status==='done' ? 
                            <span>

                                <strong>Summary:</strong>
                                
                                &nbsp;

                                {uploadMessagePhoto[0].data.textualData.errorList.length===0 && uploadMessagePhoto[0].data.photo.errorList.length===0 && uploadMessagePhoto[0].data.preview.errorList.length===0 ? 'All metadata uploaded succesfully.'+' ' : uploadMessagePhoto[0].message} 
                                
                                <span onClick={()=>openSummary(uploadMessagePhoto[0])} style={{color:'#3A6FF8', cursor:'pointer'}}>Click here</span> for details.
                                
                            </span>
                        :
                            ''
                        }  
            

                    <span>                           
                        <span><strong>Error:</strong>&nbsp;{uploadMessagePhoto[0].data.photo.error}</span>                         
                    </span>



                    {uploadMessagePhoto[0].status==='done' ?
                        <button onClick={uploadFinishedPhoto}>OK</button>
                    :
                    <>
                        <span><strong>Progress:</strong>&nbsp; 
                            {uploadMessagePhoto[0].data.photo.size === 0 ? '' :
                                Math.trunc((uploadMessagePhoto[0].data.photo.uploaded+uploadMessagePhoto[0].data.photo.error)*(100/uploadMessagePhoto[0].data.photo.size))+' %'
                            }                                   
                        </span>

                        {uploadMessagePhoto[0].data.photo.size === 0 ? '' :
                            <span className='loading-bar'>                        
                                <div className={uploadMessagePhoto[0].data.photo.done===true ? 'loading-bar-finished' : 'loading-bar-ongoing'} 
                                    style={{width: `${(uploadMessagePhoto[0].data.photo.uploaded+uploadMessagePhoto[0].data.photo.error)*(100/uploadMessagePhoto[0].data.photo.size)}%`}}>
                                </div>
                            </span>
                        }

                    </>
                    }
                </div>}
            {/* Photo */}
            {/* Preview */}
            {uploadMessagePreview[0].status===0 || uploadMessagePreview[0].status===1 ? '' : <div className='upload-single'>
                    <span className='upload-title'><strong>{isUploadingPreview.collectionID&&currentAppCols.find((i)=>i.collection_id===isUploadingPreview.collectionID) ? currentAppCols.find((i)=>i.collection_id===isUploadingPreview.collectionID).collection_title : ''} - Previews</strong></span>
            

                
                    <span><strong>Status:</strong>&nbsp; 
                        {uploadMessagePreview[0].status==='done' ? 
                            'Finished' 
                        :
                            uploadMessagePreview[0].status
                        }  
                    </span>  
                    
                  
                        {uploadMessagePreview[0].status==='done' ? 
                            <span>
                                <strong>Summary:</strong>
                                
                                &nbsp;
               
                                {uploadMessagePreview[0].data.textualData.errorList.length===0 && uploadMessagePreview[0].data.photo.errorList.length===0 && uploadMessagePreview[0].data.preview.errorList.length===0 ? 'All metadata uploaded succesfully.'+' ' : uploadMessagePreview[0].message} 
                                
                                <span onClick={()=>openSummary(uploadMessagePreview[0])} style={{color:'#3A6FF8', cursor:'pointer'}}>Click here</span> for details.
                                
                            </span>
                        :
                            ''
                        }  
            

                    <span>                           
                        <span><strong>Error:</strong>&nbsp;{uploadMessagePreview[0].data.preview.error}</span>                         
                    </span>



                    {uploadMessagePreview[0].status==='done' ?
                        <button onClick={uploadFinishedPreview}>OK</button>
                    :
                    <>
                        <span><strong>Progress:</strong>&nbsp;  
                            {uploadMessagePreview[0].data.preview.size===0 ? '' :
                                Math.trunc((uploadMessagePreview[0].data.preview.uploaded+uploadMessagePreview[0].data.preview.error)*(100/uploadMessagePreview[0].data.preview.size))+' %'
                            }                                  
                        </span>

                        {uploadMessagePreview[0].data.preview.size===0 ? '' :
                            <span className='loading-bar'>                        
                                <div className={uploadMessagePreview[0].data.preview.done===true ? 'loading-bar-finished' : 'loading-bar-ongoing'} 
                                    style={{width: `${(uploadMessagePreview[0].data.preview.uploaded+uploadMessagePreview[0].data.preview.error)*(100/uploadMessagePreview[0].data.preview.size)}%`}}>
                                </div>
                            </span>
                        }
                    </>
                    }
                </div>}
            {/* Preview */}
  

            </div>

            {
                summaryUpload ? <SummaryUploadPopup/> : ''
            }  
        </div>
        
    )
}

export default UploudProgressPopup