
import './PaymentSuccessful.css'
import {Player} from '@lottiefiles/react-lottie-player';
import { useNavigate } from 'react-router-dom';
import * as gaEvents from "../ga-utils"
import { useEffect } from 'react';

function PaymentCanceled() {
  let navigate = useNavigate()

  useEffect(()=>{
    if(localStorage.getItem("checkout")){
      gaEvents.checkoutCanceled(localStorage.getItem("checkout"))
      localStorage.removeItem("checkout")
    }
  },[])

  return (
    <div className='payment-container'>
        <div className='payment-content'>
            <Player
                autoplay
                keepLastFrame
                src="https://assets2.lottiefiles.com/packages/lf20_yQzuX4EUqC.json"
                style={{ height: '300px', width: '300px' }}
            >
            </Player>
            <p></p>
            <span className="payment-name">Payment Canceled</span>
            <p></p>
            <span className="payment-text">You have canceled your payment process.</span>
            <p></p>
            <button className='payment-button' onClick={()=>navigate('/')}>Continue</button>
        </div>
    </div>
  )
}

export default PaymentCanceled