import {useParams} from 'react-router-dom'
import CollectionWhatsNewItem from '../Items/CollectionItems/WhatsNew/CollectionWhatsNewItem'
import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import VariableContext from '../userContext/VariableContext';
import Spinner from '../Misc/Spinner'
import { fireCustomRequestError, sortResponse, sortResponseCol, fireCustomError, sortValuePri, sortValue, fireSuccessMessage, fireExportMessage, openChangeCollectionNamePopup, fireRequestError, fireCustomErrorV2} from '../Common Functions/commonFunctions';
import ChangeCollectionNamePopup from '../Popup/ChangeCollectionNamePopup'
import SpinnerMid from '../Misc/SpinnerMid'
import SpinnerSmall from '../Misc/SpinnerSmall'
import CollectionLocalItemNotHas from '../Items/CollectionItems/Local/CollectionLocalItemNotHas';
import CollectionLocalItem from '../Items/CollectionItems/Local/CollectionLocalItem';
import ColMenu from '../Components/ColMenu'
import jurisdiction from '../Jusristiction/juristiction';
import ImportZip from '../Popup/ImportZip';
import Swal from 'sweetalert2';
import { statusListMetadata } from '../StatusList/status';
import * as gaEvents from "../ga-utils"

function CollectionWhatsNewPage({pageType}) {
  const[isWaiting, setIsWaiting] = useState(false)
  const[isWaiting2, setIsWaiting2] = useState(false)
  const[isWaiting3, setIsWaiting3] = useState(false)

  // const [language, setLanguage] = useState('')
  const [languageName, setLanguageName] = useState(null)
  const {language, setLanguage} = useContext(VariableContext)
  const {collectionLocalizations, setCollectionLocalizations} = useContext(VariableContext)
  const {collectionNotLocalizations, setCollectionNotLocalizations} = useContext(VariableContext)
  
  const {colWhatsNews, setColWhatsNews} = useContext(VariableContext)
  const {currentAppPrimaryLocale} = useContext(VariableContext)
  const {currentAppRole} = useContext(VariableContext)
  const {subWarning} = useContext(VariableContext)
  const {setCurrentAppLocalizations}= useContext(VariableContext)
 
  const {isUploadingWhatsnew, setIsUploadingWhatsnew} = useContext(VariableContext)
  const {isUploading} = useContext(VariableContext)
  const {setUploadSignal} = useContext(VariableContext)

  const {getColList, setGetColList} = useContext(VariableContext)
  const {setCurrentAppCols} = useContext(VariableContext)
  const {appCol} = useContext(VariableContext)

  const {importPercentage, setImportPercentage} = useContext(VariableContext)
  const {importArray, setImportArray} = useContext(VariableContext)

  const {subscriptionLimits} = useContext(VariableContext)
  
  let {appId} = useParams()
  let {collectionId} = useParams()


  //get collection localizations
  useEffect(()=>{

    if(currentAppPrimaryLocale){
    setIsWaiting2(true)
    var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/app/collection/import/options?appId=${appId}&collectionId=${collectionId}`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    };
    
    axios(config)
    .then(function (response) {
      setCollectionLocalizations(sortValuePri(response.data.data.textualData.has, currentAppPrimaryLocale))
      setCollectionNotLocalizations(sortValue(response.data.data.textualData.notHas))
      let temp1= sortValuePri(response.data.data.textualData.has, currentAppPrimaryLocale)


      if(!language.get(`${appId}`)){
      // if(language===""){
          // setLanguage(temp1[0].value.toLowerCase())
          setLanguage(new Map (language.set(`${appId}`, temp1[0].value.toLowerCase())))
          setLanguageName(temp1[0].label)
      }
      else{
        // if(temp1.find((i)=>i.value.toLowerCase()===language.toLowerCase())){
        if(temp1.find((i)=>i.value.toLowerCase()===language.get(`${appId}`).toLowerCase())){
          // setLanguageName(temp1.find((i)=>i.value.toLowerCase()===language.toLowerCase()).label)
          setLanguageName(temp1.find((i)=>i.value.toLowerCase()===language.get(`${appId}`)).label)
            setTimeout(()=>{
              scrollToLanguage(temp1.find((i)=>i.value.toLowerCase()===language.get(`${appId}`)).label)
              // scrollToLanguage(temp1.find((i)=>i.value.toLowerCase()===language.toLowerCase()).label)
            },100)
          }
          else{
            setLanguage(new Map (language.set(`${appId}`, temp1[0].value.toLowerCase())))
            // setLanguage(temp1[0].value.toLowerCase())
            setLanguageName(temp1[0].label)
          }
      }
      setIsWaiting2(false)
    })
    .catch(function (error) {
      if(error.message === 'Network Error'){
        fireCustomError('NETWORK ERROR. ')
      }else{
        fireRequestError(error)
      }
    });
  }
  },[appId, collectionId, currentAppPrimaryLocale, getColList])
  
  const handle = (locale, langName, from) =>{
    setLanguage(locale.toLowerCase())
    // setLanguage(new Map (language.set(`${appId}-${collectionId}`, locale.toLowerCase())))
    setLanguageName(langName)
    if(from==='add'){
      setTimeout(()=>{
        scrollToLanguage(langName)
      },2000)
    }
    else{
      scrollToLanguage(langName)
    }

  }
  
  
    const handleClick = () =>{   
      const localList = document.getElementById("ownerOpacityDiv")
      const localListIcon = document.getElementById('ownerSelectLocalIcon')
      localListIcon.classList.toggle("owner-local-list-rotate-icon")
      localList.classList.toggle('owner-local-list-visible')
      window.addEventListener('click', closeLocalList)
  
  
  }
  
  const closeLocalList = (event) =>{
        
      if ((event.target.className !== "owner-local-item") && (event.target.className !== "owner-select-local") && (event.target.className !== "owner-select-local-icon") && (event.target.className !== "owner-select-local-language") && (event.target.className !== "owner-select-local-icon owner-local-list-rotate-icon") && (event.target.className !== "owner-local-list owner-local-list-visible") && (event.target.className !== "owner-local-list"))  
      {
          window.removeEventListener('click', closeLocalList)
          const localList = document.getElementById("ownerOpacityDiv")
          const localListIcon = document.getElementById('ownerSelectLocalIcon')
          if(localListIcon){localListIcon.classList.remove("owner-local-list-rotate-icon")}
          if(localList){localList.classList.remove('owner-local-list-visible')}                
      }           
  }

  //get Collection Whatsnews
  useEffect(()=>{
    
    setIsWaiting(true)//check this later
    var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/app/collection/whatsNew/${appId}/${collectionId}`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      };     
      axios(config)
      .then(function (response) {
        let temp = (sortResponseCol(response.data.data, currentAppPrimaryLocale)[0] || false)
        if(temp === false){
          fireCustomError('Can not find collection. ') 
        }
        else{
          setColWhatsNews(sortResponseCol(response.data.data, currentAppPrimaryLocale))
          setIsWaiting(false)
        }
      })
      .catch(function (error) {
        if(error.message === 'Network Error'){
          fireCustomError('NETWORK ERROR. ')
        }else{
          fireRequestError(error)
        }
      });
  },[appId,collectionId,currentAppPrimaryLocale, getColList])

  const scrollToLanguage = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      top: '45'
    });

    
  }

  const uploadWhatsnew = () =>{
    Swal.fire({
      icon: 'question',
      title: 'Upload Initiated',
      position: 'center',
      width: '400',
      text: "You are about to upload this collections what's new fields to App Store Connect. Are you sure?",
      backdrop: true,
      showConfirmButton: true,
      showCancelButton: true,     
      confirmButtonColor: '#3A6FF8e6',
      cancelButtonColor: '#cf000fe6',
      cancelButtonText: 'Cancel',      
      confirmButtonText: 'Upload',
      showLoaderOnConfirm: true,     
      preConfirm: async () => {
        setIsWaiting3(true)  
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/${appId}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        }; 
          try {
              const response = await axios(config);
              if(response.status === 200){
                setCurrentAppLocalizations(sortResponse(response.data.data))
                if(statusListMetadata.includes(response.data.data.attributes.appStoreState)){
                    setIsUploadingWhatsnew({status:'initiated', appID: +appId, collectionID: +collectionId})
                    setUploadSignal(true)  
                    gaEvents.uploadStarted(pageType)
                }
                else{
                    gaEvents.uploadStartFail(pageType)
                    fireCustomErrorV2(`You can not upload any metadata to an app which is ${response.data.data.attributes.appStoreState}`)
                }
                setIsWaiting3(false)   
              }
          } catch (error) {
            gaEvents.uploadStartFail(pageType)
            setIsWaiting3(false)
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
              fireRequestError(error)
            } 
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
    })
}


const exportCollection = () =>{
  if(pageType!=='screenshot'){
    fireExportMessage()
    var data = '';

    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/app/collection/${appId}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      setCurrentAppCols(response.data.data)

      var data = JSON.stringify({
        "appId": appId,
        "collectionId": collectionId,
        "title": response.data.data.find((i)=>i.collection_id===+collectionId).collection_title
      });
      
      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/app/collection/${pageType==='whatsnew' ? 'whatsNew' : pageType}/export`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("token")}`, 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
          gaEvents.exportSuccess(pageType)
          let a = document.createElement('a');
          a.href = `${process.env.REACT_APP_API_URL}/${response.data.data.path}`;
          a.download = `${response.data.data.path}`.split("/")[2]
          a.click();
          Swal.close()
      }).catch(function (error) {
        gaEvents.exportFail(pageType)
        if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            fireRequestError(error)
          }
      });
  })
    .catch(function (error) {
      gaEvents.exportFail(pageType)
      if(error.message === 'Network Error'){
        fireCustomError('NETWORK ERROR. ')
      }else{
        fireRequestError(error)
      }
    });
  }else{
    document.getElementById('exportAppPopup'+collectionId).style.display = 'flex'
  }
  
}

const importCollection = () =>{
    document.getElementById('importAppPopup'+collectionId).style.display = 'flex'
}

  return(
    <>
      {!isWaiting ? <>
        <div className='language-menu-div'>
          <div className='language-menu-div-inner'
            style={importArray.get(`${appId}-${collectionId}-${pageType}`) ||
            (isUploading.status&&isUploading.collectionID === +collectionId) || 
            (isUploadingWhatsnew.status&&isUploadingWhatsnew.collectionID === +collectionId) ||
            (subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.includes(+collectionId) : false) ||
            (subWarning ? subWarning.status==="expiring" || subWarning.status==="banned" : false ) ? 
            {justifyContent:'space-between', width:'calc(100% - 45px)'} : {}}>


            {/* {importArray.get(`${appId}-${collectionId}-${pageType}`) ? 
                <span className='edit-warning' style={{display: 'flex', alignItems: 'center'}}><SpinnerSmall/><img style={{marginRight:'10px'}} src="/assets/Icons/excla.svg" alt="!!!" />Importing...&nbsp;{importPercentage.get(`${appId}-${collectionId}-${pageType}`)}</span>
            : 
                ''
            } */}

            {subWarning ? 
                subWarning.status==="expiring" ?
                    jurisdiction.role.includes(currentAppRole) ?
                        <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                    : 
                        <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner's subscription is expiring.</span>
                : subWarning.status==="banned" ?
                    jurisdiction.role.includes(currentAppRole) ?
                        <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                    : 
                        <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner's subscription has expired. This application is read only.</span>
                :     
                  ''
            :
                ''
            }

            {isUploading.status&&true ? isUploading.collectionID === +collectionId ? 
              <span className='edit-warning'><img style={{marginRight:'10px'}} src="/assets/Icons/excla.svg" alt="!!!" /><span className='resposive-edit-warning-inner'>&nbsp;Upload</span><span className='edit-warning-inner'>You can not edit this collection while is being uploaded</span></span>
            :
              ''
            : 
              ''
            }
            {isUploadingWhatsnew.status&&true ? isUploadingWhatsnew.collectionID === +collectionId ? 
              <span className='edit-warning'><img style={{marginRight:'10px'}} src="/assets/Icons/excla.svg" alt="!!!" /><span className='resposive-edit-warning-inner'>&nbsp;Upload</span><span className='edit-warning-inner'>You can not edit what's new fields while they are being uploaded</span></span>
            :
              ''
            : 
              ''
            }

            {subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.includes(+collectionId) ? 
              jurisdiction.role.includes(currentAppRole) ? 
                  <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />You have more collections than what your account type permits. Some of the features concerning this collection are disabled.</span>
              :
                  <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />There are more collections than what owner's account type permits. Some of the features concerning this collection are disabled.</span> 
              : 
                ""
              : 
                ""
            }



            <div 
            style={importArray.get(`${appId}-${collectionId}-${pageType}`) ||
                  (isUploading.status&&isUploading.collectionID === +collectionId) || 
                  (isUploadingWhatsnew.status&&isUploadingWhatsnew.collectionID === +collectionId) ||
                  (subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.includes(+collectionId) : false) ||
                  (subWarning ? subWarning.status==="expiring" || subWarning.status==="banned" : false ) ? 
                  {display:'flex', alignItems:'center', justifyContent:'center', gap:'10px', marginLeft:"auto"} : {display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}
            >


              {colWhatsNews ? 
                <>
                    {jurisdiction.collection.includes(currentAppRole) ?
                        <>
                            <button disabled={isUploading.status || isUploadingWhatsnew.status} onClick={uploadWhatsnew} className=' col-menu-upload'>{isWaiting3 || isUploading.status || isUploadingWhatsnew.status ? <SpinnerSmall/> : "Upload What's New"}</button>
                            <div className='divider-menu'></div>
                        </>
                    : 
                        ''
                    }
                    <button disabled={isUploading.collectionID===+collectionId || isUploadingWhatsnew.collectionID === +collectionId} onClick={importCollection} className='col-menu-button'>Import</button>
                    <button onClick={exportCollection} className='col-menu-button-ex'>Export</button>
                </>: ''}

          </div>


          </div>
          <div  className='language-menu-language'>
                <div id='ownerSelectLocal' onClick={()=>{handleClick()}} className='owner-select-local'>
                  <span className='owner-select-local-language'>{!isWaiting2 ? languageName : <SpinnerSmall/>}</span>
                  <img id='ownerSelectLocalIcon' className='owner-select-local-icon' src="/assets/Icons/down-arrow-black.svg" alt="AppImage" />
                </div>
                <div id='ownerOpacityDiv' className="owner-opacity-div">   
                  <div id='ownerArrowUp' className='owner-arrow-up'></div>
                  <div id='ownerArrowUp2' className='owner-arrow-up2'></div>
                  <div id='ownerLocalList' className="owner-local-list">
                        {!isWaiting2 ? 
                          <>
                            <span style={{padding:'15px 0 0 10px', color:'#bbb'}}>LOCALIZED</span>

                            {!collectionLocalizations ? '' :collectionLocalizations.map((data, index)=>{
                                return (
                                
                                    <CollectionLocalItem func={!isWaiting2 ? handle : ''} key={index+'x'} data={data} count={index} class_name={!isWaiting2 ? 'current-owner-local-item' : 'current-owner-local-item-disabled'}
                                    ></CollectionLocalItem>
                            
                                )
                            })}
                            <span style={{padding:'0px 10px', color:'#bbb'}}>NOT LOCALIZED</span>
                            {!collectionNotLocalizations ? '' :collectionNotLocalizations.map((data, index)=>{
                                return (
                                
                                    <CollectionLocalItemNotHas func={!isWaiting2 ? handle : ''} key={index+'y'} data={data} count={index} class_name={!isWaiting2 ? 'current-owner-local-item-not' : 'current-owner-local-item-not-disabled'}
                                    ></CollectionLocalItemNotHas>
                              
                                )
                            })}
                          </>
                        :
                        <div className="spinner">
                            <SpinnerMid/>
                        </div>
                        }
                  </div>
                </div>
          </div>
        </div>
        <div className="whatsnew-inner-content">



          {!colWhatsNews ? '' :colWhatsNews.map((data, index)=>{
            return (
              <CollectionWhatsNewItem key={index} data={data} count={index}></CollectionWhatsNewItem>
            )
          })}
        </div> </>
      : 
         <div className="spinner">
           <Spinner/>
         </div>
      }
      <ChangeCollectionNamePopup colId={collectionId}/>
      <ColMenu pageType={pageType}/>
      <ImportZip pageType={pageType} colId={collectionId}/>
    </>
  )
}

export default CollectionWhatsNewPage