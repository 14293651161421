import { useEffect, useContext } from 'react'
import {useState, useRef} from 'react'
import './CollectionWhatsNewItem.css'
// import  langJSON from '../../../flagsLanguages/lang.json'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import { fireCustomRequestError, fireSuccessMessage, fireCustomSuccessMessage, fireCustomError, fireRequestError } from '../../../Common Functions/commonFunctions'
import VariableContext from '../../../userContext/VariableContext'
import * as gaEvents from "../../../ga-utils"

function CollectionWhatsNewItem({data, count}) {

  const [text, setText] = useState('')
  const [oldText, setOldText] = useState('')
  const [textLenght, setTextLenght] = useState(0)
  const [toogleEdit, setToogleEdit] = useState(false)
  const timer = useRef(null)

  const [name] = useState(data.locale_name)
  const {isUploading} = useContext(VariableContext)

  const {toogleAll, setToogleAll} = useContext(VariableContext)


  let {appId ,collectionId} = useParams()

  useEffect(()=>{
    if(data.news!==null){
      setTextLenght(data.news.length)
      setOldText(data.news)
      setText(data.news)
    }
    // setLanguage((data.language_code).toLowerCase())
  },[])


  // useEffect(()=>{
  //   setLanguageOBJ(langJSON[language]); 
  //   if (typeof languageOBJ !== 'undefined'){
  //     setName(languageOBJ.nameEnglish)
  //   }

  // },[languageOBJ])

  useEffect(()=>{
    if(text.length>4000){
      setTextLenght(4000)
    }else{
      setTextLenght(text.length)
    }
    clearTimeout(timer.current)
    timer.current=setTimeout(updateItem,3000) 

  },[text])

  const handleChange = (e) =>{
    setText(e.target.value.slice(0, 4000))
  }

  const updateItem = () =>{
    clearTimeout(timer.current)
    if(oldText!==text)
    {    
      var change = JSON.stringify({
      "appId": appId,
      "collectionId": collectionId,
      "whatsNewId": data.whats_new_id,
      "news": text
      });

      var config = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/app/collection/whatsNew`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("token")}`, 
          'Content-Type': 'application/json',
        },
        data : change
      };

      axios(config)
      .then(function (response) {
        gaEvents.editKeywordSuccess()
        fireSuccessMessage(response)
        setOldText(text)
      })
      .catch(function (error) {
        gaEvents.editKeywordFail()
        setText(oldText)
        if(error.message === 'Network Error'){
          fireCustomError('NETWORK ERROR. ')
        }else{
          fireRequestError(error)
        }
      });
    }

  }

  const updateOldText = () =>{
    setOldText(text)
  }

  const toogleEditOnOff = () =>{
    setToogleEdit(!toogleEdit); 
    if(toogleEdit){
      //do nothing
    }else{
      setTimeout(()=>{
        document.getElementById(name).getElementsByTagName('textarea')[0].focus()
      },150)
    }
  }

  useEffect(()=>{
    setToogleEdit(toogleAll.get(`whatsnew.${appId}.${collectionId}`))
  },[toogleAll])

  return (
    <div id={name} className="whatsnew-item">
      {count===0 ? 
          <button className='toogleAll-button' onClick={()=>{
            if(!toogleAll.get(`whatsnew.${appId}.${collectionId}`)){
              setToogleAll(new Map(toogleAll.set(`whatsnew.${appId}.${collectionId}`, true)))
            }else{
              setToogleAll(new Map(toogleAll.set(`whatsnew.${appId}.${collectionId}`, false)))
            }
          }}>
            {!toogleAll.get(`whatsnew.${appId}.${collectionId}`) ?
              "Edit Toogle All"
            :
              "Done Toogle All"
            }
          </button> 
      :
        ""
      }
    <div className="country-div">

        <div className="country-flag-name">
            <p className='country-name'>{name} {count===0 ? <span className='primary'>- Primary</span> : ''} </p>
            <p style={{color:'#ccc'}}>|</p>
            <p onClick={isUploading.collectionID === +collectionId || isUploading.collectionID === +collectionId  ? ()=>{} : toogleEditOnOff} style={!toogleEdit ? {color:'#3A6FF8', cursor:'pointer', lineHeight : '0'} : {color:'#3A6FF8', cursor:'pointer', lineHeight : '0'}}>{!toogleEdit ? 'Edit' : 'Done'}</p>
            {/* <label class="switch">
              <input onChange={()=>{setToogleEdit(!toogleEdit); document.getElementById(name).getElementsByTagName('textarea')[0].focus()}} id={name+'whatsnewInput'} type="checkbox"/>
              <span class="slider round"></span>
            </label> */}
        </div>

        <p className='character-count'>{textLenght}/4000</p>

    </div>

    <div className="whatsnew-field">
        <textarea className='description-textarea' name="description-textarea" cols="50" rows="4" value={text} 
        onChange={handleChange} onBlur={updateItem} onFocus={updateOldText} disabled={isUploading.collectionID === +collectionId || isUploading.collectionID === +collectionId || !toogleEdit} readOnly={isUploading.collectionID === +collectionId || isUploading.collectionID === +collectionId || !toogleEdit ? true : false} style={toogleEdit ? {border: '1px solid #3A6FF8'} : {border: 'none'}}
        ></textarea>
    </div>

</div>
  )
}

export default CollectionWhatsNewItem