import './OwnerScreenshots.css'
import CollectionVideo from '../../CollectionItems/Screenshot/CollectionVideo';
import { useState, useRef } from 'react';

function CollectionOwnerPreviews({data, keys, fromItself, fromPrimary}) {
    const playerReference = useRef(null);

    const [videoPlay, setVideoPlay] = useState(null)

    const playerReady = (player) => {
        playerReference.current = player;
        
        // handling video player
        player.on('waiting', () => {
          
        });
        player.on('dispose', () => {
           
        });
    }

    return (
        <div className="current-owner-screenshot-item"> 

        <div className='current-owner-screenshot-set previewOwner'>
            {fromPrimary ? 
                <span style={{fontWeight:'100', fontSize: '0.8rem', marginBottom:'15px'}}>
                    {data.size === 'IPHONE_35' ? 
                    <span>Using English (U.S.) 3.5" Display</span> :
                    data.size === 'IPHONE_40' ? 
                    <span>Using English (U.S.) 4" Display</span> :
                    data.size === 'IPHONE_47' ? 
                    <span>Using English (U.S.) 4.7" Display</span> :
                    data.size === 'IPHONE_55' ? 
                    <span>Using English (U.S.) 5.5" Display</span> : 
                    data.size === 'IPHONE_61' ?  
                    <span>Using English (U.S.) 6.1" Display</span> : 
                    data.size === 'IPHONE_65' ?  
                    <span>Using English (U.S.) 6.5" Display</span> : 
                    data.size === 'IPHONE_67' ?  
                    <span>Using English (U.S.) 6.7" Display</span> : 
                    data.size === 'IPAD_PRO_129' ?  
                    <span>Using English (U.S.) 12.9" Display</span> : 
                    data.size === 'IPAD_97' ?  
                    <span>Using English (U.S.) 9.7" Display</span> : 
                    data.size === 'IPAD_105' ?  
                    <span>Using English (U.S.) 10.5" Display</span> : 
                    data.size === 'IPAD_PRO_3GEN_11' ?  
                    <span>Using English (U.S.) 11" Display</span> : 
                    ''}
                </span>
            :''}
            {fromItself ? 
                <span style={{fontWeight:'100', fontSize: '0.8rem', marginBottom:'15px'}}>
                    {data.size === 'IPHONE_35' ? 
                    <span>Using 3.5" Display</span> :
                    data.size === 'IPHONE_40' ? 
                    <span>Using 4" Display</span> :
                    data.size === 'IPHONE_47' ? 
                    <span>Using 4.7" Display</span> :
                    data.size === 'IPHONE_55' ? 
                    <span>Using 5.5" Display</span> : 
                    data.size === 'IPHONE_61' ?  
                    <span>Using 6.1" Display</span> : 
                    data.size === 'IPHONE_65' ?  
                    <span>Using 6.5" Display</span> : 
                    data.size === 'IPHONE_67' ?  
                    <span>Using 6.7" Display</span> :
                    data.size === 'IPAD_PRO_3GEN_129' ?  
                    <span>Using 12.9" Display</span> : 
                    data.size === 'IPAD_PRO_129' ?  
                    <span>Using 12.9" Display</span> : 
                    data.size === 'IPAD_97' ?  
                    <span>Using 9.7" Display</span> : 
                    data.size === 'IPAD_105' ?  
                    <span>Using 10.5" Display</span> : 
                    data.size === 'IPAD_PRO_3GEN_11' ?  
                    <span>Using 11" Display</span> :  
                    ''}
                </span>
            :''}
            <div className={`current-owner-screenshot-field ${fromItself||fromPrimary ? "current-owner-opacity" : ''}`}>
                {data.previews.map((previews, index)=>{
                    return(
                        <div key={index + 'w'} className="owner-col">
                            <img className='current-owner-screenshots' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`} alt='appImage'/>

                            <button style={{left:'38%'}} onClick={()=>{setVideoPlay('video'+index)}} className='play-button'>►</button>
                        </div>
                        
                    )
                })}
            </div>
        </div>

        <div className="collection-video-div" style={videoPlay ? {display: 'flex'} : {display: 'none'}}>
                
                <span onClick={()=>{setVideoPlay(null)}} className='collection-close pointer'>&times;</span>
                
                <div className="collection-modal-content">
                    <p className='collection-screenshot-slide-device-name'>
                        {data.size === 'IPHONE_35' ? 
                        <span>Using 3.5" Display</span> :
                        data.size === 'IPHONE_40' ? 
                        <span>Using 4" Display</span> :
                        data.size === 'IPHONE_47' ? 
                        <span>Using 4.7" Display</span> :
                        data.size === 'IPHONE_55' ? 
                        <span>Using 5.5" Display</span> : 
                        data.size === 'IPHONE_61' ?  
                        <span>Using 6.1" Display</span> : 
                        data.size === 'IPHONE_65' ?  
                        <span>Using 6.5" Display</span> : 
                        data.size === 'IPHONE_67' ?  
                        <span>Using 6.7" Display</span> :
                        data.size === 'IPAD_PRO_3GEN_129' ?  
                        <span>Using 6.7" Display</span> : 
                        data.size === 'IPAD_PRO_129' ?  
                        <span>Using 12.9" Display</span> : 
                        data.size === 'IPAD_97' ?  
                        <span>Using 9.7" Display</span> : 
                        data.size === 'IPAD_105' ?  
                        <span>Using 10.5" Display</span> : 
                        data.size === 'IPAD_PRO_3GEN_11' ?  
                        <span>Using 11" Display</span> :  
                        ''}
                    </p>
                    {
                        data.previews.map((previews, index)=>{
                            return(
                                <span key={index}>
                                    {videoPlay==="video" + index ?
                                        <div className='collection-video-inner'>

                                            <CollectionVideo onReady={playerReady} options={
                                                {
                                                    fill: true,
                                                    fluid: false,
                                                    autoplay: true,
                                                    controls: true,
                                                    controlBar: {
                                                        pictureInPictureToggle: false
                                                    },
                                                    preload: "metadata",
                                                    sources: [
                                                        {
                                                            src:
                                                            `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                            // type: "video/mp4"
                                                        }
                                                    ]
                                                }
                                            }/>
                                        </div>
                                    : 
                                        ''
                                    }
                                </span>
                            )
                        }) 
                    }                  
                </div>
            </div>
        </div>
    )
}

export default CollectionOwnerPreviews