import { useEffect, useState, useRef, useContext } from 'react'
import './CurrentDescriptionItem.css'

function CurrentDescriptionItem({data, count}) {

  const [text, setText] = useState('')
  const [textLenght, setTextLenght] = useState(0)
  const [name] = useState(data.attributes.locale_name)

  // const{isUploading} = useContext(VariableContext)
  // const{isUploadingDescription} = useContext(VariableContext)

  useEffect(()=>{
    if(data.attributes.description!==null){
      setTextLenght(data.attributes.description.length)
      setText(data.attributes.description)
    }
  },[])


  useEffect(()=>{
    if(text.length>4000){
      setTextLenght(4000)
    }else{
      setTextLenght(text.length)
    }
  },[text])


  return (
    <div id={name} className="description-item">

    <div className="country-div">

        <div className="country-flag-name">
            <p className='country-name'>{name} {count===0 ? <span className='primary'>- Primary</span> : ''}</p>
        </div>

        <p className='character-count'>{textLenght}/4000</p>

    </div>
    
    <div className="description-field">
    
        <textarea className='description-textarea' name="description-textarea" cols="50" rows="10" value={text} readOnly={true} 
        ></textarea>
    
    </div>
    
</div>
  )
}

export default CurrentDescriptionItem