import {useState} from 'react'
import SpinnerSmall from '../Misc/SpinnerSmall'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './ForgotPassword.css'
import { fireRequestError, fireSuccessMessage, fireCustomError } from '../Common Functions/commonFunctions'
import * as gaEvents from "../ga-utils"

function ForgotPassword({func}) {

    let {appId} = useParams()

    const[emailing, setEmailing] = useState(false)
    const[email, setEmail] = useState('')

    //popup close
    const closePopup = () => {
        func(false)
    }
    //popup email input
    const handleEmailChange = (e) =>{
        setEmail(e.target.value)
    }

    //popup request and close popup
    const sendMailE = (e) =>{ 
        e.preventDefault()
        setEmailing(true)
        var data = JSON.stringify({
            "email": email,

        });
        
        var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/auth/forgetPassword`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then(function (response) {
            gaEvents.forgatPassMailSent()
            closePopup()
            fireSuccessMessage(response)
        })
        .catch(function (error) {
            gaEvents.forgatPassMailFail()
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
        });   
    }



    return (
        <div id='forgotPpassPopup' className='forgot-pass-popup-container'>
            <div className='forgotPpassPopupBack' onClick={emailing ? ()=>{} : ()=>{closePopup()}}></div>
            <div className='forgot-pass-popup-content'>
                <span className="forgot-pass-popup-name">Forgot Password</span>

                <form className='forgot-pass-form' onSubmit={sendMailE}>
                    <input required={true} id='forgotPpassForm' onChange={handleEmailChange} className="forgot-pass-popup-form" type="email" value={email} placeholder='Registered email address'/>



                    <input type='submit' hidden={true} disabled={emailing}/>
                    <div className="forgotPpassPopupButtons">
                        <button disabled={emailing} type='submit' className="forgot-pass-popup-button">
                            {!emailing ? 'Confirm' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>
                        <span disabled={emailing} onClick={!emailing ? ()=>closePopup() : ()=>{}} className="forgot-pass-popup-button2 popupCancel">
                            Cancel 
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword