import { useEffect, useContext } from 'react'
import {useState, useRef} from 'react'
import './CollectionKeywordItem.css'
// import  langJSON from '../../../flagsLanguages/lang.json'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import { fireCustomRequestError, fireSuccessMessage, fireCustomError, fireRequestError } from '../../../Common Functions/commonFunctions'
import VariableContext from '../../../userContext/VariableContext'
import * as gaEvents from "../../../ga-utils"
import Quill from 'react-quill'; 
import 'react-quill/dist/quill.bubble.css';

function CollectionKeywordItem({data, count}) {

  const [text, setText] = useState('')
  const [oldText, setOldText] = useState('')
  const [toogleEdit, setToogleEdit] = useState(false)
  const [textLenght, setTextLenght] = useState(0)
  const timer = useRef(null)

  const [name] = useState(data.locale_name)


  const {isUploadingKeyword} = useContext(VariableContext)
  const {isUploading} = useContext(VariableContext)

  const {toogleAll, setToogleAll} = useContext(VariableContext)


  let {appId, collectionId} = useParams()

  let quillRef = null;
  let quill = null;
  const modules = {
    toolbar: false
  }  

  useEffect(() => {
      quill = quillRef.getEditor();
      
      quill.on('text-change', function(delta, oldDelta, source) {
          setText(quill.getText())
          if (quill.getText().length > 100) {
              const currentFormat = quill.getFormat(100, quill.getText().length);
              if (!currentFormat.bold) {
                  quill.formatText(100, quill.getText().length, { 'color': "red" })
              }
          }
      });
  }, []);

   useEffect(()=>{
    if(data.keyword_list!==null){
      setTextLenght(data.keyword_list.length)
      setOldText(data.keyword_list)
      setText(data.keyword_list)
   
    }
    // setLanguage((data.language_code).toLowerCase())
  },[])

  // useEffect(()=>{
  //   setLanguageOBJ(langJSON[language]); 
  //   if (typeof languageOBJ !== 'undefined'){
  //     setName(languageOBJ.nameEnglish)
  //   }

  // },[languageOBJ])

  useEffect(()=>{
    // if(text.length>100){
    //   setTextLenght(100)
    // }else{
      setTextLenght(text.length-1)
    // }
    clearTimeout(timer.current)
    timer.current=setTimeout(updateItem,3000) 

  },[text])


  const handleChange = (e) =>{
    setText(e.target.value)
  }

  const updateItem = () =>{
    clearTimeout(timer.current)
    if(oldText!==text)
    {    
      var change = JSON.stringify({
      "appId": appId,
      "collectionId": collectionId,
      "keywordId": data.keyword_id,
      "keyword": text
      });

      var config = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/app/collection/keyword`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("token")}`, 
          'Content-Type': 'application/json',
        },
        data : change
      };

      axios(config)
      .then(function (response) {
        gaEvents.editKeywordSuccess()
        fireSuccessMessage(response)
        setOldText(text)
      })
      .catch(function (error) {
        gaEvents.editKeywordSuccess()
        setText(oldText)
        if(error.message === 'Network Error'){
          fireCustomError('NETWORK ERROR. ')
        }else{
          fireRequestError(error)
        }
      });
    }

  }

  const updateOldText = () =>{
    setOldText(text)
  }

  const toogleEditOnOff = () =>{
    setToogleEdit(!toogleEdit); 
  }
  const toogleEditOnOffAll = () =>{
    let buttons = document.querySelectorAll(".country-flag-name p:nth-of-type(3)") 
    for(let i = 0; i<buttons.length; i++){
      buttons[i].click()
    }
  }


  useEffect(()=>{
    if(toogleEdit===true && !toogleAll.get(`keyword.${appId}.${collectionId}`)){
      setTimeout(()=>{
        // document.getElementById(name).getElementsByTagName('textarea')[0].focus()
        quillRef.getEditor().focus()
      },150)
    }else{

    }
  },[toogleEdit])

  useEffect(()=>{
    setToogleEdit(toogleAll.get(`keyword.${appId}.${collectionId}`))
  },[toogleAll])

  return (
    <div id={name} className="keyword-item">
      {count===0 ? 
          <button className='toogleAll-button' onClick={()=>{
            if(!toogleAll.get(`keyword.${appId}.${collectionId}`)){
              setToogleAll(new Map(toogleAll.set(`keyword.${appId}.${collectionId}`, true)))
            }else{
              setToogleAll(new Map(toogleAll.set(`keyword.${appId}.${collectionId}`, false)))
            }
          }}>
            {!toogleAll.get(`keyword.${appId}.${collectionId}`) ?
              "Edit Toogle All"
            :
              "Done Toogle All"
            }
          </button> 
      :
        ""
      }
      <div className="country-div">
          <div className="country-flag-name">
            
              <p className='country-name'>{name} {count===0 ? <span className='primary'>- Primary</span> : ''} </p>
              <p style={{color:'#ccc'}}>|</p>
              <p onClick={isUploading.collectionID === +collectionId || isUploadingKeyword.collectionID === +collectionId ? ()=>{} : toogleEditOnOff} style={!toogleEdit ? {color:'#3A6FF8', cursor:'pointer', lineHeight : '0'} : {color:'#3A6FF8', cursor:'pointer', lineHeight : '0'}}>{!toogleEdit ? 'Edit' : 'Done'}</p>

              {/* <label class="switch">
                <input onChange={()=>{setToogleEdit(!toogleEdit); document.getElementById(name).getElementsByTagName('textarea')[0].focus()}} id={name+'whatsnewInput'} type="checkbox"/>
                <span class="slider round"></span>
              </label> */}
          </div>

          <p style={textLenght>100 ? {color:"red"} : {}} className='character-count'>{textLenght}/100</p>

      </div>

      <Quill value={text}
                  onBlur={updateItem} onFocus={updateOldText}
                  className='keyword-textarea keyword-field2'
                  id={data.keyword_id+"editDiv"}
                  ref={(el) => { quillRef = el }}
                  theme="bubble"
                  modules={modules}
                  readOnly={isUploading.collectionID === +collectionId || isUploadingKeyword.collectionID === +collectionId || !toogleEdit ? true : false}
                  style={toogleEdit ? {border: '1px solid #3A6FF8'} : {border: 'none'}}
      />
    </div>
  )
}

export default CollectionKeywordItem