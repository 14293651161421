import { useContext, useState, useEffect } from 'react';
import "./Sidebar.css";
import SidebarItem from '../Components/SidebarItem';
import VariableContext from '../userContext/VariableContext';
import { NavLink } from 'react-router-dom'
import LogoutButton from '../Components/LogoutButton';
import AddAppPopup from '../Popup/AddAppPopup';
import jurisdiction from '../Jusristiction/juristiction';


function Sidebar() {
  const {initialUserData} = useContext(VariableContext)

  const {currentAppIcon, setCurrentAppIcon} = useContext(VariableContext)
  const {currentAppName, setCurrentAppName} = useContext(VariableContext)
  const {currentAppId, setCurrentAppId} = useContext(VariableContext)
  const {setPrimaryLocale} = useContext(VariableContext)
  const {currentAppRole, setCurrentAppRole} = useContext(VariableContext)
  const {appCol} = useContext(VariableContext)

  // const {setLanguage} = useContext(VariableContext)



  const handleClick = () =>{   
      const appList = document.getElementById("sideMenuAppList")
      const appListIcon = document.getElementById('selectedAppButton')
      appList.classList.toggle("visible")
      appList.style.transition=`max-height ${(0.15)*(initialUserData.roles.lengt+1)}s ease-in-out`
      appListIcon.classList.toggle("rotate-icon")
      appListIcon.style.transition=`transform ${(0.15)*(initialUserData.roles.length+1)}s ease-in-out`
  }
  

  const sidebarSelect = (icon, appName, appId, primaryLocale, role) =>{
    // setLanguage('')
    if(!icon){
      setCurrentAppIcon('/assets/Icons/AddAppImage.svg')
    }else{
      setCurrentAppIcon(icon)
    }
    setCurrentAppName(appName)
    setCurrentAppId(appId)
    setPrimaryLocale(primaryLocale)
    setCurrentAppRole(role)
  }


  //popup open
  const openPopup = () => {
    document.getElementById('addAppPopup').style.display = 'flex'
    document.getElementById('sideMenu').classList.toggle("invisible-scroll");
  }


    //RESPONSIVE SIDEBAR
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const {sidebarToogle, setSidebarToogle} = useContext(VariableContext)
  
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth)
    }
  
    useEffect(()=>{
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    },[])
  
    useEffect(()=>{
        let main = document.getElementById('app_content')
        if(windowWidth<769){
            if(sidebarToogle===false){
                // 
            }else{
                // 
                toogleSidebar()
            }
        }else{
            if(sidebarToogle===false){
                // 
                // toogleSidebar()
            }else{
                // 
                // main.style.marginLeft = '360px'
                document.querySelector('.logoBig').style.display = 'block'
                document.querySelector('.logoSmall').style.display = 'none'
            }
        }
    },[windowWidth])
  
    const toogleSidebar = (e) =>{
        let sidebar = document.getElementById('sideMenu')
        let main = document.getElementById('app_content')
        let toogle = document.getElementById('toogle')
        let toogleIcon = document.getElementById('toogleIcon')
        let home = document.getElementById('home_page')
        let appNames = document.querySelectorAll('.side-menu h3')
        let appNames2 = document.querySelectorAll('.side-menu .selected-app-name')
        let appNames3 = document.querySelectorAll('.side-menu .app-name')
        let appNames4 = document.querySelectorAll('.side-menu .add-app-name')
        let icons = document.querySelectorAll('.sidebar-sub-item img')
        let logo = document.querySelector('.logoBig')
        let logo2 = document.querySelector('.logoSmall')
        let logoMain = document.querySelector('.logo')
        let indicator = document.querySelectorAll('.active-url-indicator')
        let ps = document.querySelectorAll('.sidebar-sub-item p')
        let selectIcon = document.getElementById('selectedAppButton')
        if(sidebarToogle===true){
            setSidebarToogle(false)
            sidebar.style.width = '90px'
            sidebar.style.minWidth = '90px'
            toogle.style.left = '90px'
            main.style.marginLeft = '90px'
            toogleIcon.style.transform = 'rotateY(180deg)';
            logo.style.display = 'none'
            logo2.style.display = 'block'
            logoMain.style.padding = '36px 18px'
            selectIcon.style.display = 'none'
            for(let i =0; i<appNames.length; i++){
              appNames[i].style.display='none'
            }
            for(let i =0; i<appNames2.length; i++){
              appNames2[i].style.display='none'
            }
            for(let i =0; i<appNames3.length; i++){
              appNames3[i].style.display='none'
            }
            for(let i =0; i<appNames4.length; i++){
              appNames4[i].style.display='none'
            }
            for(let i =0; i<icons.length; i++){
              icons[i].style.marginLeft='30px'
            }
            for(let i =0; i<indicator.length; i++){
              indicator[i].style.display='none'
            }
            for(let i =0; i<ps.length; i++){
              ps[i].style.visibility='hidden'
            }
        }else{
            setSidebarToogle(true)
            sidebar.style.width = '360px'
            sidebar.style.minWidth = '360px'
            toogle.style.left = '360px'
            toogleIcon.style.transform = 'rotateY(0deg)';
            logo.style.display = 'block'
            logo2.style.display = 'none'
            logoMain.style.padding = '36px 22px'
            selectIcon.style.display = 'block'
            if(home.clientWidth>768){
                main.style.marginLeft = '360px'
            } 
            for(let i =0; i<appNames.length; i++){
              appNames[i].style.display='block'
            }
            for(let i =0; i<appNames2.length; i++){
              appNames2[i].style.display='block'
            }
            for(let i =0; i<appNames3.length; i++){
              appNames3[i].style.display='block'
            }
            for(let i =0; i<appNames4.length; i++){
              appNames4[i].style.display='block'
            }
            for(let i =0; i<icons.length; i++){
              icons[i].style.marginLeft='40px'
            }
            for(let i =0; i<indicator.length; i++){
              indicator[i].style.display='block'
            }
            for(let i =0; i<ps.length; i++){
              ps[i].style.visibility='visible'
            }
        }
    }

    useEffect(()=>{
      if(sidebarToogle===false){
        let sidebar = document.getElementById('sideMenu')
        let main = document.getElementById('app_content')
        let toogle = document.getElementById('toogle')
        let toogleIcon = document.getElementById('toogleIcon')
        let appNames = document.querySelectorAll('.side-menu h3')
        let appNames2 = document.querySelectorAll('.side-menu .selected-app-name')
        let appNames3 = document.querySelectorAll('.side-menu .app-name')
        let appNames4 = document.querySelectorAll('.side-menu .add-app-name')
        let icons = document.querySelectorAll('.sidebar-sub-item img')
        let logo = document.querySelector('.logoBig')
        let logo2 = document.querySelector('.logoSmall')
        let logoMain = document.querySelector('.logo')
        let indicator = document.querySelectorAll('.active-url-indicator')
        let ps = document.querySelectorAll('.sidebar-sub-item p')
        let selectIcon = document.getElementById('selectedAppButton')

        sidebar.style.width = '90px'
        sidebar.style.minWidth = '90px'
        toogle.style.left = '90px'
        main.style.marginLeft = '90px'
        toogleIcon.style.transform = 'rotateY(180deg)';
        logo.style.display = 'none'
        logo2.style.display = 'block'
        logoMain.style.padding = '36px 18px'
        selectIcon.style.display = 'none'
        for(let i =0; i<appNames.length; i++){
          appNames[i].style.display='none'
        }
        for(let i =0; i<appNames2.length; i++){
          appNames2[i].style.display='none'
        }
        for(let i =0; i<appNames3.length; i++){
          appNames3[i].style.display='none'
        }
        for(let i =0; i<appNames4.length; i++){
          appNames4[i].style.display='none'
        }
        for(let i =0; i<icons.length; i++){
          icons[i].style.marginLeft='30px'
        }
        for(let i =0; i<indicator.length; i++){
          indicator[i].style.display='none'
        }
        for(let i =0; i<ps.length; i++){
          ps[i].style.visibility='hidden'
        }

      }
    },[currentAppId])



  return (
    <div id='sideMenu' className='side-menu'>

        <button id='toogle' onClick={toogleSidebar} className='toogle-sidebar'>
          {sidebarToogle ? <img id="toogleIcon" src="/assets/Icons/toogle.svg" alt="ToogleImage" /> : <img id="toogleIcon" src="/assets/Icons/toogle.svg" alt="ToogleImage" /> }
        </button>   

        <NavLink className='logo' end to={'/'}>
          {initialUserData ?
            initialUserData.account_type ==='free' ?
              <>
                  <img className='logoBig' src="/assets/Icons/LogoFree.svg" alt="Logo" />
                  <img className='logoSmall' src="/assets/Icons/LogoMini.svg" alt="Logo" />
              </>
            :
            initialUserData.account_type ==='trial' ?
              <>
                  <img className='logoBig' src="/assets/Icons/LogoTrial.svg" alt="Logo" />
                  <img className='logoSmall' src="/assets/Icons/LogoMini.svg" alt="Logo" />
              </>
            :
            initialUserData.account_type ==='premium' ?
              <>
                  <img className='logoBig' src="/assets/Icons/LogoPremium.svg" alt="Logo" />
                  <img className='logoSmall' src="/assets/Icons/LogoMini.svg" alt="Logo" />
              </>
            :
            initialUserData.account_type ==='premium_plus' ?
              <>
                  <img className='logoBig' src="/assets/Icons/LogoPremiumPlus.svg" alt="Logo" />
                  <img className='logoSmall' src="/assets/Icons/LogoMini.svg" alt="Logo" />
              </>
            :
              <>
                  <img className='logoBig' src="/assets/Icons/Logo.svg" alt="Logo" />
                  <img className='logoSmall' src="/assets/Icons/LogoMini.svg" alt="Logo" />
              </>
          :

              <>
                  <img className='logoBig' src="/assets/Icons/Logo.svg" alt="Logo" />
                  <img className='logoSmall' src="/assets/Icons/LogoMini.svg" alt="Logo" />
              </>
          }
        </NavLink>

        <div className='select-app' onClick={()=>{handleClick()}}>
          <img className='selected-app-icon' src={currentAppIcon} alt="AddAppImage" />
          <p id="selectedAppName" className='selected-app-name' style={currentAppName==='Select Application' ?  {fontSize:'18px'} : {fontSize:'16px'} }>{currentAppName}</p>
          <img id="selectedAppButton" className='select-app-button' src="/assets/Icons/Down.svg" alt="AppImage" />
        </div>


        <div id="sideMenuAppList" className='side-menu-applist'>      
          {!initialUserData ? 
          
            ''
          :
          initialUserData.roles.length===0 ?
                '' :

              <div style={{maxHeight: '280px', overflow:'auto'}}>
                {
                initialUserData.roles.map((data, index)=>{
                  if(index<initialUserData.roles.length-1){
                    return (
                      <SidebarItem key={index} data={data} func={sidebarSelect}></SidebarItem>
                    )
                  }
                  if(index===initialUserData.roles.length-1){
                    return (
                      <SidebarItem key={index} data={data} func={sidebarSelect}></SidebarItem>
                    )
                  }

                })}

                
              </div>
          }

            <div onClick={()=>openPopup()} className="add-app">
                <img className='add-app-icon' src='/assets/Icons/Add-App.svg' alt="AddAppImage" />
                <p className='add-app-name'>Add Application</p>   
            </div> 

        </div>


{jurisdiction.add_app.includes(currentAppRole) ? 
        <div className="sidebar-sub store-assets">
            <h3>APPLICATION</h3>



            {currentAppId===0 ? 
              <div className="sidebar-sub-item disabled-sidebar-sub-item">
                <img style={{width: '24px'}} src="/assets/Icons/home.svg" alt="layers" />
                <p>App Info</p>
              </div> : 
              
              <NavLink  to={`/${currentAppId}`} end className={({ isActive }) =>
              isActive ? 'active-link' : 'ssi'}>
                <div className="sidebar-sub-item">
                  <img className='passive-url' style={{ width: '24px'}} src="/assets/Icons/appInfo.svg" alt="layers" />
                  <img className='active-url' style={{ width: '24px'}} src="/assets/Icons/appInfoActive.svg" alt="layers" />
                  <p>Application Info</p>
                  <div className='active-url-indicator'></div>
                </div>
              </NavLink>
            } 

          </div> 
: ''}


{jurisdiction.upload.includes(currentAppRole)  ?  

          <div className="sidebar-sub store-assets">
            <h3>VIEW & UPLOAD</h3>



            {currentAppId===0 ? 
              <div className="sidebar-sub-item disabled-sidebar-sub-item">
                <img style={{ width: '24px'}} src="/assets/Icons/collection.svg" alt="layers" />
                <p>Collections</p>
              </div> : 
              
              <NavLink to={`/${currentAppId}/owner`} className={({ isActive }) =>
              isActive ? 'active-link' : 'ssi'}>
                <div className="sidebar-sub-item">
                  <img className='passive-url' style={{ width: '24px'}} src="/assets/Icons/collection.svg" alt="layers" />
                  <img className='active-url' style={{ width: '24px'}} src="/assets/Icons/collectionActive.svg" alt="layers" />
                  <p>Collections</p>
                  <div className='active-url-indicator'></div>
                </div>
              </NavLink>
            } 

          </div> 
: ''}



{/* --------------------- */}

        <div className="sidebar-sub store-assets">
            {currentAppRole ? 
              <h3>STORE ASSETS</h3>
            : ''}

{jurisdiction.description.includes(currentAppRole) ? 
          <>
            {currentAppId===0 ? 
              <div className="sidebar-sub-item disabled-sidebar-sub-item">
                <img src="/assets/Icons/description.svg" alt="layers" />
                <p>Description</p>
              </div> : 
              
              <NavLink to={`/${currentAppId}/description/${appCol.current.hasOwnProperty(currentAppId) ? appCol.current[currentAppId] : ''}`} className={({ isActive }) =>
              isActive ? 'active-link' : 'ssi'}>
                <div className="sidebar-sub-item">
                  <img className='passive-url' src="/assets/Icons/description.svg" alt="layers" />
                  <img className='active-url' src="/assets/Icons/descriptionActive.svg" alt="layers" />
                  <p>Description</p>
                  <div className='active-url-indicator'></div>
                </div>
              </NavLink>
            }
          </>
: ''}

{/* --------------------- */}

{jurisdiction.screenshots.includes(currentAppRole) ? 
          <>
            {currentAppId===0 ? 
              <div className="sidebar-sub-item disabled-sidebar-sub-item">
                <img src="/assets/Icons/screenshots.svg" alt="layers" />
                <p>Screenshots</p>
              </div> : 
              
              <NavLink to={`/${currentAppId}/screenshot/${appCol.current.hasOwnProperty(currentAppId) ? appCol.current[currentAppId] : ''}`} className={({ isActive }) =>
              isActive ? 'active-link' : 'ssi'}>
                <div className="sidebar-sub-item">
                  <img className='passive-url' src="/assets/Icons/screenshots.svg" alt="layers" />
                  <img className='active-url' src="/assets/Icons/screenshotsActive.svg" alt="layers" />
                  <p>Screenshots</p>
                  <div className='active-url-indicator'></div>
                </div>
              </NavLink>
            }
          </>
: ''}

{/* --------------------- */}

{jurisdiction.whatsnew.includes(currentAppRole) ? 
        <>
          {currentAppId===0 ? 
            <div className="sidebar-sub-item disabled-sidebar-sub-item">
              <img src="/assets/Icons/whatsnew.svg" alt="layers" />
              <p>What’s New</p>
            </div> : 
            
            <NavLink to={`/${currentAppId}/whatsnew/${appCol.current.hasOwnProperty(currentAppId) ? appCol.current[currentAppId] : ''}`} className={({ isActive }) =>
            isActive ? 'active-link' : 'ssi'}>
              <div className="sidebar-sub-item">
                <img className='passive-url' src="/assets/Icons/whatsnew.svg" alt="layers" />
                <img className='active-url' src="/assets/Icons/whatsnewActive.svg" alt="layers" />
                <p>What’s New</p>
                <div className='active-url-indicator'></div>
              </div>
            </NavLink>
          }
        </>
: '' }

{/* --------------------- */}
{jurisdiction.keyword.includes(currentAppRole) ? 
        <>
          {currentAppId===0 ? 
            <div className="sidebar-sub-item disabled-sidebar-sub-item">
              <img src="/assets/Icons/keyword.svg" alt="layers" />
              <p>Keywords</p>
            </div> : 
            
            <NavLink to={`/${currentAppId}/keyword/${appCol.current.hasOwnProperty(currentAppId) ? appCol.current[currentAppId] : ''}`} className={({ isActive }) =>
            isActive ? 'active-link' : 'ssi'}>
              <div className="sidebar-sub-item">
                <img className='passive-url' src="/assets/Icons/keyword.svg" alt="layers" />
                <img className='active-url' src="/assets/Icons/keywordActive.svg" alt="layers" />
                <p>Keywords</p>
                <div className='active-url-indicator'></div>
              </div>
            </NavLink>
          }
        </>
: '' }
        </div>
{/* --------------------- */}

{/* --------------------- */}

        <div className="sidebar-sub store-assets">
          <h3>ACCOUNT</h3>

          <NavLink to={`/profile`} className={({ isActive }) =>
            isActive ? 'active-link' : 'ssi'}>
            <div className="sidebar-sub-item">
              <img className='passive-url' src="/assets/Icons/user.svg" alt="layers" />
              <img className='active-url' src="/assets/Icons/userActive.svg" alt="layers" />
              <p>Profile</p>
              <div className='active-url-indicator'></div>
            </div>
          </NavLink>

{/* --------------------- */}

          <NavLink to={`/subscription`} className={({ isActive }) =>
            isActive ? 'active-link' : 'ssi'}>
            <div className="sidebar-sub-item">
              <img className='passive-url' src="/assets/Icons/credit-card.svg" alt="layers" />
              <img className='active-url' src="/assets/Icons/credit-cardActive.svg" alt="layers" />
              <p>Subscription
                {/* <span style={{fontSize:'0.8rem', letterSpacing:'-0.5px', color:'#7E410F', fontWeight:'600', background:'black', padding:'1px 5px', marginLeft:'7px', background:'linear-gradient(0.25turn, rgb(246, 157, 60), rgb(250 185 113), rgb(246, 157, 60))', borderRadius:'3px', fontWeight:'700'}}>
                  PREMIUM
                </span> */}
              </p>
              <div className='active-url-indicator'></div>
            </div>
          </NavLink>

{/* --------------------- */}

          <NavLink to={`/help`} className={({ isActive }) =>
            isActive ? 'active-link' : 'ssi'}>
            <div className="sidebar-sub-item">
              <img className='passive-url' src="/assets/Icons/help-circle.svg" alt="layers" />
              <img className='active-url' src="/assets/Icons/help-circleActive.svg" alt="layers" />
              <p>Help Center
              </p>
              <div className='active-url-indicator'></div>
            </div>
          </NavLink>
          
{/* --------------------- */}      
          <LogoutButton/> 
          
        </div>
{/* --------------------- */}

        <AddAppPopup/>

    </div>

  )
}

export default Sidebar