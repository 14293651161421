import {useState, useContext} from 'react'
import VariableContext from '../userContext/VariableContext';
import SpinnerSmall from '../Misc/SpinnerSmall'
import axios from 'axios'
import './NewZip.css'
import {fireCustomErrorV2, sortValue, fireFileMessage, fireSuccessMessage, fireCustomError, fireCustomSuccessMessage, fireRequestError } from '../Common Functions/commonFunctions'
import Select from "react-select";
import langJSON from '../flagsLanguages/lang.json'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import * as gaEvents from '../ga-utils'
import Qmark from '../Misc/Qmark';

function NewZip({pageType}) { 
    const {importArray, setImportArray} = useContext(VariableContext)
    const {importPercentage, setImportPercentage} = useContext(VariableContext)
    const {getColList, setGetColList} = useContext(VariableContext)
    const {currentAppCols} = useContext(VariableContext)
    const {appCol} = useContext(VariableContext)
    const {setSummary} = useContext(VariableContext)
    const {currentAppQuota} = useContext(VariableContext)

    const [showDescription, setShowDescription] = useState(false)
    const [showWhatsnew, setShowWhatsnew] = useState(false)
    const [showKeyword, setShowKeyword] = useState(false)
    const [showPreviews, setShowPreviews] = useState(false)
    const [showScreenshots, setShowScreenshots] = useState(false)

    const [templateTurn, setTemplateTurn] = useState(false)
    const [uploadTurn, setUploadTurn] = useState(false)
    const [question, setQuestion] = useState(true)
    const [dragActive, setDragActive] = useState(false)
    const [zip, setZip] = useState(null)

    const [isDownloading, setIsDownlaoding] = useState(false)

    const [isWaiting, setIsWaiting] = useState(true)

    const [localized, setLocalized] = useState([])
    const [notLocalized, setNotLocalized] = useState([])

    const [userDontHaveDescription, setUserDontHaveDescription] = useState([])
    const [userHaveDescription, setUserHaveDescription] = useState([])
    const [userSelectForDescription, setUserSelectForDescription] = useState([])

    const [userDontHaveKeyword, setUserDontHaveKeyword] = useState([])
    const [userHaveKeyword, setUserHaveKeyword] = useState([])
    const [userSelectForKeyword, setUserSelectForKeyword] = useState([])

    const [userDontHaveWhatsnew, setUserDontHaveWhatsnew] = useState([])
    const [userHaveWhatsnew, setUserHaveWhatsnew] = useState([])
    const [userSelectForWhatsnew, setUserSelectForWhatsnew] = useState([])

    const [userDontHaveScreenshots, setUserDontHaveScreenshots] = useState([])
    const [dontHaveScreenshots, setDontHaveScreenshots] = useState([])
    const [userHaveScreenshots, setUserHaveScreenshots] = useState([])
    const [userSelectForScreenshots, setUserSelectForScreenshots] = useState([])
    const [openLangs, setOpenLangs] = useState([])

    const [userDontHavePreviews, setUserDontHavePreviews] = useState([])
    const [dontHavePreviews, setDontHavePreviews] = useState([])
    const [userHavePreviews, setUserHavePreviews] = useState([])
    const [userSelectForPreviews, setUserSelectForPreviews] = useState([])
    const [openLangsPreview, setOpenLangsPreview] = useState([])

    const [message, setMessage] = useState('')

    const deviceList = [
        {value:'APP_IPHONE_65',label:"Iphone 6.5''"},
        {value:'APP_IPHONE_61',label:"Iphone 6.1''"},
        {value:'APP_IPHONE_58',label:"Iphone 5.8''"},
        {value:'APP_IPHONE_55',label:"Iphone 5.5''"},
        {value:'APP_IPHONE_47',label:"Iphone 4.7''"},
        {value:'APP_IPHONE_40',label:"Iphone 4''"},
        {value:'APP_IPHONE_35',label:"Iphone 3.5''"},
        {value:'APP_IPAD_PRO_3GEN_129',label:"Ipad 6th gen 12.9''"},
        {value:'APP_IPAD_PRO_3GEN_11',label:"Ipad 6th gen 11''"},
        {value:'APP_IPAD_PRO_129',label:"Ipad 12.9''"},
        {value:'APP_IPAD_105',label:"Ipad 10.5''"},
        {value:'APP_IPAD_97',label:"Ipad 9.7''"},
    ]

    const deviceListPreviews = [
        {value:'IPHONE_65',label:"Iphone 6.5''"},
        {value:'IPHONE_61',label:"Iphone 6.1''"},
        {value:'IPHONE_58',label:"Iphone 5.8''"},
        {value:'IPHONE_55',label:"Iphone 5.5''"},
        {value:'IPHONE_47',label:"Iphone 4.7''"},
        {value:'IPHONE_40',label:"Iphone 4''"},
        {value:'IPHONE_35',label:"Iphone 3.5''"},
        {value:'IPAD_PRO_3GEN_129',label:"Ipad 6th gen 12.9''"},
        {value:'IPAD_PRO_3GEN_11',label:"Ipad 6th gen 11''"},
        {value:'IPAD_PRO_129',label:"Ipad 12.9''"},
        {value:'IPAD_105',label:"Ipad 10.5''"},
        {value:'IPAD_97',label:"Ipad 9.7''"},
    ]

    let {appId} = useParams()
    let navigate = useNavigate()


    //popup close reset states
    const closePopup = () => {
        document.getElementById('newzipAppPopup').style.display = 'none'
        if(document.getElementById('uplodaTemplateDivInput')){
            document.getElementById('uplodaTemplateDivInput').value = ''
        }
        setQuestion(true)
        setTemplateTurn(false)
        setUploadTurn(false)
        setZip(null)
        setDragActive(false)

        setOpenLangsPreview([])
        setOpenLangs([])

        setLocalized([])
        setNotLocalized([])

        setUserDontHaveDescription([])
        setUserHaveDescription([])
        setUserSelectForDescription([])

        setUserDontHaveKeyword([])
        setUserHaveKeyword([])
        setUserSelectForKeyword([])

        setUserDontHaveWhatsnew([])
        setUserHaveWhatsnew([])
        setUserSelectForWhatsnew([])

        setUserDontHaveScreenshots([])
        setDontHaveScreenshots([])
        setUserHaveScreenshots([])
        setUserSelectForScreenshots([])

        if(currentAppQuota.preview_data_storage===1){
            setUserDontHavePreviews([])
            setDontHavePreviews([])
            setUserHavePreviews([])
            setUserSelectForPreviews([])
        }

        setIsWaiting(true)
        setIsDownlaoding(false)

        setMessage('')
    }
    // do you have a template file
    const answerQuestionYes = () =>{
        setQuestion(false)
        setUploadTurn(true)
        gaEvents.userHasTemplateFileCol()
    }
    const answerQuestionNo = () =>{
        setQuestion(false)
        setTemplateTurn(true)
        setIsWaiting(true)
        gaEvents.userHasNotTemplateFileCol()

        
        
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/import/options?appId=${appId}&collectionId=${currentAppCols[0].collection_id}'`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                'Content-Type': 'application/json'
            },
          };
          
        axios(config)
        .then(function (response) {
            gaEvents.getTeplateOptionsColSuccess()

            setLocalized(sortValue(response.data.data.textualData.has))
            setNotLocalized(sortValue(response.data.data.textualData.notHas))

            setUserDontHaveDescription(sortValue(response.data.data.textualData.notHas))
            setUserSelectForDescription(sortValue(response.data.data.textualData.has))

            setUserDontHaveKeyword(sortValue(response.data.data.textualData.notHas))
            setUserSelectForKeyword(sortValue(response.data.data.textualData.has))

            setUserDontHaveWhatsnew(sortValue(response.data.data.textualData.notHas))
            setUserSelectForWhatsnew(sortValue(response.data.data.textualData.has))

            setUserDontHaveScreenshots(sortValue(response.data.data.photoData.notHas))
            setDontHaveScreenshots(sortValue(response.data.data.photoData.notHas))
            setUserSelectForScreenshots(sortValue(response.data.data.photoData.has))

            if(currentAppQuota.preview_data_storage===1){
                setUserDontHavePreviews(sortValue(response.data.data.previewData.notHas))
                setDontHavePreviews(sortValue(response.data.data.previewData.notHas))
                setUserSelectForPreviews(sortValue(response.data.data.previewData.has))
            }

            setIsWaiting(false)

        })
        .catch(function (error) {
            gaEvents.getTeplateOptionsColFail()

            
            closePopup()
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
        });
    }

    //add-remove language and devices from folder
    const addDescription = (data) =>{
        if(notLocalized.find((i)=>i.value===data.value)){
            setUserSelectForDescription((current)=>sortValue([...current, data]))
            setUserDontHaveDescription(sortValue(userDontHaveDescription.filter((i)=>i.value!==data.value)))
        }
        else if(localized.find((i)=>i.value===data.value)){
            setUserSelectForDescription((current)=>sortValue([...current, data]))
            setUserHaveDescription(sortValue(userHaveDescription.filter((i)=>i.value!==data.value)))
        }
    }
    const deleteDescription = (index,e) =>{
        e.preventDefault()
        if(notLocalized.find((i)=>i.value===userSelectForDescription[index].value)){
            setUserDontHaveDescription(current =>sortValue([...current, {value: userSelectForDescription[index].value, label:langJSON[userSelectForDescription[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForDescription(sortValue(userSelectForDescription.filter((i)=>i!==userSelectForDescription[index])))
        }
        else if(localized.find((i)=>i.value===userSelectForDescription[index].value)){
            setUserHaveDescription(current =>sortValue([...current, {value: userSelectForDescription[index].value, label:langJSON[userSelectForDescription[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForDescription(sortValue(userSelectForDescription.filter((i)=>i!==userSelectForDescription[index])))
        }
    }

    const addKeyword = (data) =>{
        if(notLocalized.find((i)=>i.value===data.value)){
            setUserSelectForKeyword((current)=>sortValue([...current, data]))
            setUserDontHaveKeyword(sortValue(userDontHaveKeyword.filter((i)=>i.value!==data.value)))
        }
        else if(localized.find((i)=>i.value===data.value)){
            setUserSelectForKeyword((current)=>sortValue([...current, data]))
            setUserHaveKeyword(sortValue(userHaveKeyword.filter((i)=>i.value!==data.value)))
        }
    }
    const deleteKeyword = (index,e) =>{
        e.preventDefault()
        if(notLocalized.find((i)=>i.value===userSelectForKeyword[index].value)){
            setUserDontHaveKeyword(current =>sortValue([...current, {value: userSelectForKeyword[index].value, label:langJSON[userSelectForKeyword[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForKeyword(sortValue(userSelectForKeyword.filter((i)=>i!==userSelectForKeyword[index])))
        }
        else if(localized.find((i)=>i.value===userSelectForKeyword[index].value)){
            setUserHaveKeyword(current =>sortValue([...current, {value: userSelectForKeyword[index].value, label:langJSON[userSelectForKeyword[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForKeyword(sortValue(userSelectForKeyword.filter((i)=>i!==userSelectForKeyword[index])))
        }
    }

    const addWhatsnew = (data) =>{
        if(notLocalized.find((i)=>i.value===data.value)){
            setUserSelectForWhatsnew((current)=>sortValue([...current, data]))
            setUserDontHaveWhatsnew(sortValue(userDontHaveWhatsnew.filter((i)=>i.value!==data.value)))
        }
        else if(localized.find((i)=>i.value===data.value)){
            setUserSelectForWhatsnew((current)=>sortValue([...current, data]))
            setUserHaveWhatsnew(sortValue(userHaveWhatsnew.filter((i)=>i.value!==data.value)))
        }
    }
    const deleteWhatsnew = (index,e) =>{
        e.preventDefault()
        if(notLocalized.find((i)=>i.value===userSelectForWhatsnew[index].value)){
            setUserDontHaveWhatsnew(current =>sortValue([...current, {value: userSelectForWhatsnew[index].value, label:langJSON[userSelectForWhatsnew[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForWhatsnew(sortValue(userSelectForWhatsnew.filter((i)=>i!==userSelectForWhatsnew[index])))
        }
        else if(localized.find((i)=>i.value===userSelectForWhatsnew[index].value)){
            setUserHaveWhatsnew(current =>sortValue([...current, {value: userSelectForWhatsnew[index].value, label:langJSON[userSelectForWhatsnew[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForWhatsnew(sortValue(userSelectForWhatsnew.filter((i)=>i!==userSelectForWhatsnew[index])))
        }
    }

    const addScreenshots = (data) =>{
        if(notLocalized.find((i)=>i.value===data.value)){
            setUserSelectForScreenshots((current)=>sortValue([...current, data]))
            setUserDontHaveScreenshots(sortValue(userDontHaveScreenshots.filter((i)=>i.value!==data.value)))
            setOpenLangs(current=>[...current, data.value])
        }
        else if(localized.find((i)=>i.value===data.value)){
            setUserSelectForScreenshots((current)=>sortValue([...current, data]))
            if(dontHaveScreenshots.find((i)=>i.value===data.value)){
                setUserDontHaveScreenshots(sortValue(userDontHaveScreenshots.filter((i)=>i.value!==data.value)))
            }
            else{
                setUserHaveScreenshots(sortValue(userHaveScreenshots.filter((i)=>i.value!==data.value)))
            }
            setOpenLangs(current=>[...current, data.value])
        }
    }
    const deleteScreenshots = (index,e) =>{
        e.preventDefault()

        if(notLocalized.find((i)=>i.value===userSelectForScreenshots[index].value)){
            setUserDontHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
            setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
            setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
        }
        else if(localized.find((i)=>i.value===userSelectForScreenshots[index].value)){
            if(dontHaveScreenshots.find((i)=>i.value===userSelectForScreenshots[index].value)){
                setUserDontHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
                setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
                setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
            }
            else{
                setUserHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
                setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
                setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
            }
        }
    }

    const deleteScreenshots2 = (index) =>{
        if(notLocalized.find((i)=>i.value===userSelectForScreenshots[index].value)){
            setUserDontHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
            setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
            setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
        }
        else if(localized.find((i)=>i.value===userSelectForScreenshots[index].value)){
            if(dontHaveScreenshots.find((i)=>i.value===userSelectForScreenshots[index].value)){
                setUserDontHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
                setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
                setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
            }
            else{
                setUserHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
                setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
                setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
            }
        }

    }
    const onDeviceSelect = (index, index2, e, valueLang) =>{
        if(e.target.checked){
            let temp = [...userSelectForScreenshots]
            temp[index].devices = [...temp[index].devices, {value: e.target.value, label: e.target.name}]
            setUserSelectForScreenshots([...temp])
        }
        else{
            let temp = [...userSelectForScreenshots]
            temp[index].devices = temp[index].devices.filter((i)=>i.value!==e.target.value) 
            setUserSelectForScreenshots([...temp])
        }
        let checked = 0
        let loopChoices = document.getElementsByClassName(valueLang+'devices')
        for(let a = 0; a<loopChoices.length; a++){
            if(loopChoices[a].checked===true){
                checked= checked+1
            }
            if(a===loopChoices.length-1){
                if(checked===0){
                    deleteScreenshots2(index)
                }
            }
        }

    }

    const addPreviews = (data) =>{
        if(notLocalized.find((i)=>i.value===data.value)){
            setUserSelectForPreviews((current)=>sortValue([...current, data]))
            setUserDontHavePreviews(sortValue(userDontHavePreviews.filter((i)=>i.value!==data.value)))
            setOpenLangs(current=>[...current, data.value])
        }
        else if(localized.find((i)=>i.value===data.value)){
            setUserSelectForPreviews((current)=>sortValue([...current, data]))
            if(dontHavePreviews.find((i)=>i.value===data.value)){
                setUserDontHavePreviews(sortValue(userDontHavePreviews.filter((i)=>i.value!==data.value)))
            }
            else{
                setUserHavePreviews(sortValue(userHavePreviews.filter((i)=>i.value!==data.value)))
            }
            setOpenLangs(current=>[...current, data.value])
        }
    }
    const deletePreviews = (index,e) =>{
        e.preventDefault()

        if(notLocalized.find((i)=>i.value===userSelectForPreviews[index].value)){
            setUserDontHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
            setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
            setOpenLangsPreview(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
        }
        else if(localized.find((i)=>i.value===userSelectForPreviews[index].value)){
            if(dontHavePreviews.find((i)=>i.value===userSelectForPreviews[index].value)){
                setUserDontHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
                setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
                setOpenLangs(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
            }
            else{
                setUserHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
                setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
                setOpenLangs(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
            }
        }
    }

    const deletePreviews2 = (index) =>{

        if(notLocalized.find((i)=>i.value===userSelectForPreviews[index].value)){
            setUserDontHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
            setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
            setOpenLangsPreview(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
        }
        else if(localized.find((i)=>i.value===userSelectForPreviews[index].value)){
            if(dontHavePreviews.find((i)=>i.value===userSelectForPreviews[index].value)){
                setUserDontHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
                setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
                setOpenLangs(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
            }
            else{
                setUserHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
                setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
                setOpenLangs(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
            }
        }

    }
    const onDeviceSelectPreview = (index, index2, e, valueLang) =>{
        if(e.target.checked){
            let temp = [...userSelectForPreviews]
            temp[index].devices = [...temp[index].devices, {value: e.target.value, label: e.target.name}]
            setUserSelectForPreviews([...temp])
        }
        else{
            let temp = [...userSelectForPreviews]
            temp[index].devices = temp[index].devices.filter((i)=>i.value!==e.target.value) 
            setUserSelectForPreviews([...temp])
        }
        let checked = 0
        let loopChoices = document.getElementsByClassName(valueLang+'devices')
        for(let a = 0; a<loopChoices.length; a++){
            if(loopChoices[a].checked===true){
                checked= checked+1
            }
            if(a===loopChoices.length-1){
                if(checked===0){
                    deletePreviews2(index)
                }
            }
        }

    }



    //show-hide
    const hideDescriptionFolder = () =>{
        setShowDescription(!showDescription)
    }
    const hideKeywordFolder = () =>{
        setShowKeyword(!showKeyword)
    }
    const hideWhatsnewFolder = () =>{
        setShowWhatsnew(!showWhatsnew)
    }
    const hideScreenshotsFolder = () =>{
        setShowScreenshots(!showScreenshots)
    }
    const hidePreviewsFolder = () =>{
        setShowPreviews(!showPreviews)
    }
    const hideLanguageFolder = (value) =>{
        setOpenLangs(current=>{
            
            if(current.includes(value)){
                return current.filter((i)=>i!==value)
            }else{
                return [...current,value]
            }
        })
    }
    const hideLanguageFolderPreview = (value) =>{
        setOpenLangsPreview(current=>{
            
            if(current.includes(value)){
                return current.filter((i)=>i!==value)
            }else{
                return [...current,value]
            }
        })
    }

    //popup download request and upload popup
    const getFile = async () =>{
        fireFileMessage()
        setIsDownlaoding(true)
        let fileStructure = new Promise ((resolve) =>{

            resolve(JSON.stringify({
                "details": {
                    "description" : userSelectForDescription,
                    "keyword" : userSelectForKeyword,
                    "whatsNew" : userSelectForWhatsnew,
                    "photo" : userSelectForScreenshots,
                    "preview" : userSelectForPreviews
                }
            }))
        })


        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/import/template`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                'Content-Type': 'application/json'
            },
            data : await fileStructure
          };
          
          axios(config)
          .then(function (response) {
            gaEvents.downloadTemplateColSuccess()
            let a = document.createElement('a');
            a.href = `${process.env.REACT_APP_API_URL}/${response.data.data.path}`;
            a.download = `${response.data.data.path}`.split("/")[2]
            a.click();
            Swal.close()
            setIsDownlaoding(false)
            setUploadTurn(true)
            setTemplateTurn(false)
          })
          .catch(function (error) {
            gaEvents.downloadTemplateColFail()
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            closePopup()
          }); 
    }
    const getFileE = async (e) =>{ 
        e.preventDefault()
        fireFileMessage()
        setIsDownlaoding(true)
        let fileStructure = new Promise ((resolve) =>{
            resolve(JSON.stringify({
                "details": {
                    "description" : userSelectForDescription,
                    "keyword" : userSelectForKeyword,
                    "whatsNew" : userSelectForWhatsnew,
                    "photo" : userSelectForScreenshots,
                    "preview" : userSelectForPreviews
                }
            }))
        })


        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/import/template`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                'Content-Type': 'application/json'
            },
            data : await fileStructure
          };
          
          axios(config)
          .then(function (response) {
            gaEvents.downloadTemplateColSuccess()

            let a = document.createElement('a');
            a.href = `${process.env.REACT_APP_API_URL}/${response.data.data.path}`;
            a.download = `${response.data.data.path}`.split("/")[2]
            a.click();
            Swal.close()
            setIsDownlaoding(false)
            setUploadTurn(true)
            setTemplateTurn(false)
          })
          .catch(function (error) {
            gaEvents.downloadTemplateColFail()

            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            closePopup()
          }); 
    }

    //drag-drop upload
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActive(true);
              } else if (e.type === "dragleave") {
                setDragActive(false);
              }
        
    }
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                if(setZip(e.dataTransfer.files)){
                    e.dataTransfer.clearData()
                }
            }
        
    }
    const onZipUpload = (e) =>{ 
        setZip(e.target.files)
        
    }
    //import ready zip file
    const uploadZipE = (e) =>{
        e.preventDefault()
        var data = new FormData();
        data.append('mode', '1');
        for (const z of zip) {
            data.append('zip', z);
        }
        data.append('appId', appId);

        const onUploadProgress = (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            if (percent <= 100) {
                
                setImportPercentage(new Map(importPercentage.set(`${appId}-newCollection`, percent)))
            }
        };

        var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/app/collection/import`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
        },
        data : data,
        onUploadProgress
        };
        setImportArray(new Map(importArray.set(`${appId}-newCollection`, true)))
        axios(config)
        .then(function (response) {
            gaEvents.importProcessColSuccess()

            
            setImportPercentage(new Map(importPercentage.set(`${appId}-newCollection`, false)))
            setImportArray(new Map(importArray.set(`${appId}-newCollection`, false)))
            setGetColList(getColList+1)
            appCol.current[appId] = response.data.data.collection.collectionId
            setSummary(response.data.data)
            if(pageType!=='owner'){
                navigate(`/${appId}/${pageType}/${response.data.data.collection.collectionId}`)
            }
            fireCustomSuccessMessage(`Collection created from ${zip[0].name}`)
            closePopup()
        })
        .catch(function (error) {
            gaEvents.importProcessColFail()
            
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            setImportPercentage(new Map(importPercentage.set(`${appId}-newCollection`, false)))
            setImportArray(new Map(importArray.set(`${appId}-newCollection`, false)))
            setZip(null)
            if(document.getElementById('uplodaTemplateDivInput')){
                document.getElementById('uplodaTemplateDivInput').value = ''
            }
        });
    }

    useEffect(()=>{
        if(importPercentage.get(`${appId}-newCollection`)===100){
            setMessage('Creating collection, please wait...')
            setTimeout(()=>{
                setMessage('This might take a while, do not close this popup or refresh your page...')
            },5000)
            setTimeout(()=>{
                setMessage('Almost there...')
            },35000)
        }
        else{
            setMessage('')
        }

    },[importPercentage])

    return ( 
        <div id='newzipAppPopup' className='newzip-popup-container'>
            <div className='newzipPopupBack' onClick={isDownloading||importPercentage.get(`${appId}-newCollection`) ? ()=>{} : ()=>{closePopup()}}></div>
                {question ? 
                    <div className='newzip-popup-content-pre'>
                        <span className="newzip-popup-name">Do you have your template file? <Qmark link="https://doc.storesyncer.com/template-file/what-is-a-template-file" /></span>
                        <div className="newzipPopupButtons">
                                <button disabled={false} onClick={answerQuestionYes} className="newzip-popup-button-pre newzippre1">
                                    {!false ? 'Yes' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                                </button>

                                <button disabled={false} onClick={answerQuestionNo} className="newzip-popup-button-pre newzippre2">
                                    {!false ? 'No' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                                </button>

                                <span disabled={false} onClick={!false ? ()=>closePopup() : ()=>{}} className="newzip-popup-button2-pre popupCancel">
                                    Cancel 
                                </span>
                            </div>
                    </div>

                :''}
                {templateTurn ? 
                    <div className='newzip-popup-content'>
                        <span className="newzip-popup-name">Arrange Your Template File <Qmark link="https://doc.storesyncer.com/template-file/how-to-create-and-use-a-template-file" /></span>
                        <form className='create-newzip-form' onSubmit={isDownloading ? ()=>{} : getFileE} >
                            <div className='create-newzip-form-inner'>
                                
                                    {/* //description */}
                                    <div className='selectTeplateDiv'>

                                        <div className='selectTeplateTitle'>
                                            <img onClick={hideDescriptionFolder} style={{width:'20px', marginRight: '10px'}} src={showDescription ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                            <img style={{width:'30px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/> Description
                                        </div>

                                        {!isWaiting ?    
                                            showDescription ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Localized",
                                                                options: userHaveDescription
                                                            },
                                                            {
                                                                label: "Not Localized",
                                                                options: userDontHaveDescription
                                                            }]
                                                        }
                                                        placeholder="Add Languages"
                                                        value={''}
                                                        onChange={isDownloading ? ()=>{} : addDescription}
                                                        isSearchable={true}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        {userSelectForDescription.map((i,index)=>{
                                                            return(
                                                                <li className={`selectedLanguage ${i.value}`} key={index}><img style={{width:'30px'}} src='/assets/Icons/txt.svg'/>{i.label}({i.value}.txt)  <div className='selectedLanguageDelete' onClick={isDownloading ?()=>{} : (e)=>deleteDescription(index,e)}>-</div></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        :
                                            showDescription ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Loading",
                                                                options: []
                                                            }]
                                                        }
                                                        placeholder="Loading..."
                                                        value={''}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Loading...</li>
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        }

                                    </div>
                  
                                
                                    {/* //keyword */}
                                    <div className='selectTeplateDiv'>

                                        <div className='selectTeplateTitle'>
                                            <img onClick={hideKeywordFolder} style={{width:'20px', marginRight: '10px'}} src={showKeyword ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                            <img style={{width:'30px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/> Keyword
                                        </div>

                                        {!isWaiting ?    
                                            showKeyword ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Localized",
                                                                options: userHaveKeyword
                                                            },
                                                            {
                                                                label: "Not Localized",
                                                                options: userDontHaveKeyword
                                                            }]
                                                        }
                                                        placeholder="Add Languages"
                                                        value={''}
                                                        onChange={isDownloading ? ()=>{} : addKeyword}
                                                        isSearchable={true}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        {userSelectForKeyword.map((i,index)=>{
                                                            return(
                                                                <li className={`selectedLanguage ${i.value}`} key={index}><img style={{width:'30px'}} src='/assets/Icons/txt.svg'/>{i.label}({i.value}.txt)  <div className='selectedLanguageDelete' onClick={isDownloading ?()=>{} : (e)=>deleteKeyword(index,e)}>-</div></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        :
                                            showDescription ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Loading",
                                                                options: []
                                                            }]
                                                        }
                                                        placeholder="Loading..."
                                                        value={''}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Loading...</li>
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        }

                                    </div>
                            
                               
                                    {/* //whatsnew */}
                                    <div className='selectTeplateDiv'>

                                        <div className='selectTeplateTitle'>
                                            <img onClick={hideWhatsnewFolder} style={{width:'20px', marginRight: '10px'}} src={showWhatsnew ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                            <img style={{width:'30px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/> What's New
                                        </div>

                                        {!isWaiting ?    
                                            showWhatsnew ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Localized",
                                                                options: userHaveWhatsnew
                                                            },
                                                            {
                                                                label: "Not Localized",
                                                                options: userDontHaveWhatsnew
                                                            }]
                                                        }
                                                        placeholder="Add Languages"
                                                        value={''}
                                                        onChange={isDownloading ? ()=>{} : addWhatsnew}
                                                        isSearchable={true}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        {userSelectForWhatsnew.map((i,index)=>{
                                                            return(
                                                                <li className={`selectedLanguage ${i.value}`} key={index}><img style={{width:'30px'}} src='/assets/Icons/txt.svg'/>{i.label}({i.value}.txt)  <div className='selectedLanguageDelete' onClick={isDownloading ?()=>{} : (e)=>deleteWhatsnew(index,e)}>-</div></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        :
                                            showWhatsnew ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Loading",
                                                                options: []
                                                            }]
                                                        }
                                                        placeholder="Loading..."
                                                        value={''}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Loading...</li>
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        }

                                    </div>                                
                             
                                
                                    {/* //screenshot */}
                                    <div className='selectTeplateDiv'>

                                        <div className='selectTeplateTitle'>
                                            <img onClick={hideScreenshotsFolder} style={{width:'20px', marginRight: '10px'}} src={showScreenshots ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                            <img style={{width:'30px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>Screenshots
                                        </div>
                                        {!isWaiting ?
                                            showScreenshots ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Localized",
                                                                options: userHaveScreenshots
                                                            },
                                                            {
                                                                label: "Not Localized",
                                                                options: userDontHaveScreenshots
                                                            }]
                                                        }
                                                        placeholder="Add Languages"
                                                        value={''}
                                                        onChange={isDownloading ? ()=>{} : addScreenshots}
                                                        isSearchable={true}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        {userSelectForScreenshots.map((i,index)=>{
                                                            return(
                                                                <li className='selectedLanguage2' key={index}>
                                                                    <span className='selectedLangguage2inner'>
                                                                        <img id={i.value+'icon'} onClick={()=>hideLanguageFolder(i.value)} style={{width:'15px', marginRight: '10px'}} src={openLangs.includes(i.value) ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                                                        <img style={{width:'20px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>{i.label}({i.value}) 
                                                                        <div className='selectedLanguageDelete' onClick={isDownloading ? ()=>{} : (e)=>deleteScreenshots(index,e)}>-</div>
                                                                    </span>
                                                                    {openLangs.includes(i.value) ?  
                                                                        <span id={i.value}>
                                                                            {deviceList.map((j, index2)=>{
                                                                                return(
                                                                                    <label className='selectedDevice' key={index2}>
                                                                                        <input onChange={isDownloading ? ()=>{} : (e)=>onDeviceSelect(index, index2, e, i.value)} className={i.value+'devices'} type='checkbox'  checked={userSelectForScreenshots[index].devices.find((x)=>x.value===j.value) || false} value={j.value} name={j.label} disabled={isDownloading}/>
                                                                                        <img style={{width:'15px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>
                                                                                        <div>{j.label}</div>
                                                                                    </label>
                                                                                )
                                                                            })}
                                                                        </span>
                                                                    : ''}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            :   
                                                ''
                                        :
                                            showScreenshots ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Loading",
                                                                options: []
                                                            }]
                                                        }
                                                        placeholder="Loading..."
                                                        value={''}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                                <li>
                                                                    Loading...
                                                                </li>
                                                    </ul>
                                                </>
                                            :
                                                ''
                                        }

                                    </div>

                                    {/* //previews */}
                                    {currentAppQuota.preview_data_storage===1 ?
                                        <div className='selectTeplateDiv'>

                                            <div className='selectTeplateTitle'>
                                                <img onClick={hidePreviewsFolder} style={{width:'20px', marginRight: '10px'}} src={showPreviews ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                                <img style={{width:'30px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>Preivews
                                            </div>
                                            {!isWaiting ?
                                                showPreviews ? 
                                                    <>
                                                        <Select
                                                            blurInputOnSelect = {true}
                                                            className='languageDropdown'
                                                            options={
                                                                [{
                                                                    label: "Localized",
                                                                    options: userHavePreviews
                                                                },
                                                                {
                                                                    label: "Not Localized",
                                                                    options: userDontHavePreviews
                                                                }]
                                                            }
                                                            placeholder="Add Languages"
                                                            value={''}
                                                            onChange={isDownloading ? ()=>{} : addPreviews}
                                                            isSearchable={true}
                                                            />
                                                        <ul style={{listStyle:'none'}}>
                                                            {userSelectForPreviews.map((i,index)=>{
                                                                return(
                                                                    <li className='selectedLanguage2' key={index}>
                                                                        <span className='selectedLangguage2inner'>
                                                                            <img id={i.value+'icon'} onClick={()=>hideLanguageFolderPreview(i.value)} style={{width:'15px', marginRight: '10px'}} src={openLangsPreview.includes(i.value) ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                                                            <img style={{width:'20px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>{i.label}({i.value}) 
                                                                            <div className='selectedLanguageDelete' onClick={isDownloading ? ()=>{} : (e)=>deletePreviews(index,e)}>-</div>
                                                                        </span>
                                                                        {openLangsPreview.includes(i.value) ?  
                                                                            <span id={i.value}>
                                                                                {deviceListPreviews.map((j, index2)=>{
                                                                                    return(
                                                                                        <label className='selectedDevice' key={index2}>
                                                                                            <input onChange={isDownloading ? ()=>{} : (e)=>onDeviceSelectPreview(index, index2, e, i.value)} className={i.value+'devices'} type='checkbox'  checked={userSelectForPreviews[index].devices.find((x)=>x.value===j.value) || false} value={j.value} name={j.label} disabled={isDownloading}/>
                                                                                            <img style={{width:'15px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>
                                                                                            <div>{j.label}</div>
                                                                                        </label>
                                                                                    )
                                                                                })}
                                                                            </span>
                                                                        : ''}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </>
                                                :   
                                                    ''
                                            :
                                                showPreviews ? 
                                                    <>
                                                        <Select
                                                            blurInputOnSelect = {true}
                                                            className='languageDropdown'
                                                            options={
                                                                [{
                                                                    label: "Loading",
                                                                    options: []
                                                                }]
                                                            }
                                                            placeholder="Loading..."
                                                            value={''}
                                                            />
                                                        <ul style={{listStyle:'none'}}>
                                                                    <li>
                                                                        Loading...
                                                                    </li>
                                                        </ul>
                                                    </>
                                                :
                                                    ''
                                            }

                                        </div>
                                    :
                                        ''
                                    }

                            </div>

                            <input type='submit' hidden={true} disabled={isDownloading}/>
                            <div className="newzipPopupButtons">
                                <button disabled={isDownloading} onClick={getFile} className="newzip-popup-button">
                                    {!isDownloading ? 'Download' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                                </button>

                                <span disabled={isDownloading} onClick={!isDownloading ? ()=>closePopup() : ()=>{}} className="newzip-popup-button2 popupCancel">
                                    Cancel 
                                </span>
                            </div>
                        </form>
                    </div>
                : 
                    ''
                }

                {uploadTurn ? 
                    <div className='newzip-popup-content'>
                        <span className="newzip-popup-name">Upload Your Template File  <Qmark link="https://doc.storesyncer.com/template-file/how-to-create-and-use-a-template-file" /></span>
                        <form className='create-newzip-form' onSubmit={!importArray.get(`${appId}-newCollection`) ? uploadZipE : (e)=>{e.preventDefault()}}>
                            <div className='create-newzip-form-inner'>

                                <div className='uploadTemplateDiv' onDragEnter={handleDrag}>
                                    <span style={{color: '#8b7260'}}>
                                        <label style={{cursor: 'pointer'}} htmlFor={'uplodaTemplateDivInput'}>
                                            <img src="/assets/Icons/uploading.svg" alt="folder" />
                                            <br />
                                            {!zip ? 'Choose or drag your .zip file' : importPercentage.get(`${appId}-newCollection`) ? '' : <span><span style={ {color:'#0f044c', fontWeight:'bold'}}>{zip[0].name}</span>, change or drag new file</span>}
                                            <br />
                                            {importPercentage.get(`${appId}-newCollection`) ? 
                                                importPercentage.get(`${appId}-newCollection`)<100 ?
                                                    <span style={{display: 'flex', alignItems: 'center'}}>Uploading...&nbsp;<span style={ {color:'#0f044c'}}>{importPercentage.get(`${appId}-newCollection`)}%</span></span> 
                                                :
                                                    <span style={{display: 'flex', alignItems: 'center'}}>{message}&nbsp;<SpinnerSmall/></span>
                                            : 
                                                ''
                                            }
                                        </label> 
                                    </span>

                                    <input type='file' accept='.zip' multiple={false} id={'uplodaTemplateDivInput'} hidden={true} onChange={onZipUpload} disabled={importArray.get(`${appId}-newCollection`)}/>


                                    {dragActive && <div className='dragDiv' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                                </div>

                            </div>

                            <div className="newzipPopupButtons">
                                <button disabled={importArray.get(`${appId}-newCollection`)} type='submit' className="newzip-popup-button">
                                    {!importArray.get(`${appId}-newCollection`) ? 'Create' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                                </button>

                                <span disabled={importArray.get(`${appId}-newCollection`)} onClick={!importArray.get(`${appId}-newCollection`) ? ()=>closePopup() : ()=>{}} className="import-popup-button2 popupCancel">
                                    Cancel 
                                </span>
                            </div>
                        </form>
                    </div>
                : 
                    ''
                }
        </div>
    )
}




export default NewZip