import {useState, useContext } from 'react'
// import { NavLink } from 'react-router-dom'
import axios from 'axios'
import VariableContext from '../userContext/VariableContext';
import Spinner from '../Misc/Spinner'
import SpinnerSmall from '../Misc/SpinnerSmall'
import {useParams} from 'react-router-dom'
import ScreenshotLocalItem from '../Items/CurrentItems/Screenshot/ScreenshotLocalItem'
import CurrentScreenshotItemv2 from '../Items/CurrentItems/Screenshot/CurrentScreenshotItemv2';
import CurrentScreenshotIpadItemv2 from '../Items/CurrentItems/Screenshot/CurrentScreenshotIpadItemv2';
import './CurrentScreenshotPagev2.css'
import { useEffect } from 'react';
import { fireCustomError, fireCustomRequestError, sortResponse, fireRequestError } from '../Common Functions/commonFunctions';
import SpinnerMid from '../Misc/SpinnerMid';
import jurisdiction from '../Jusristiction/juristiction';


function CurrentScreenshotPagev2() {
    const[isWaiting, setIsWaiting] = useState(false)
    const[isWaitingSets, setIsWaitingSets] = useState(false)
    const[isWaitingSets2, setIsWaitingSets2] = useState(false)
    const[isWaitingSets3, setIsWaitingSets3] = useState(false)
    const[isWaitingSets4, setIsWaitingSets4] = useState(false)

    const[localizationId, setLocalizationId] = useState(null)
    const[primaryLocalizationId, setPrimaryLocalizationId] = useState(null)
    const[primary_locale, setPrimary_Locale] = useState(null)
    const[primaryName, setPrimaryName] = useState(null)

    const[primaryScreenshots, setPrimaryScreenshots] = useState(null)
    const[screenshots, setScreenshots] = useState(null)

    const[primaryPreviews, setPrimaryPreviews] = useState(null)
    const[previews, setPreviews] = useState(null)

    const [language, setLanguage] = useState('')
    const {showDevices, setShowDevices} = useContext(VariableContext)
    const [name, setName] = useState('')

    const {currentAppRole} = useContext(VariableContext)
    const {subWarning} = useContext(VariableContext)

    const {currentAppLocalizations, setCurrentAppLocalizations} = useContext(VariableContext)
    const {isUploading} = useContext(VariableContext)
    const {isUploadingPhoto} = useContext(VariableContext)

    let {appId} = useParams()

    const baseIpadList = [
        // {deviceName: 'APP_IPAD_PRO_3GEN_129', size: 5},
        {deviceName: 'APP_IPAD_PRO_3GEN_11', size: 4},
        // {deviceName: 'APP_IPAD_PRO_129', size: 3},
        {deviceName: 'APP_IPAD_105', size: 2},
        {deviceName: 'APP_IPAD_97', size: 1}
    ]
    
    const baseDeviceList = [
        // 'APP_IPHONE_65',
        // 'APP_IPHONE_55',
        'APP_IPHONE_67',
        'APP_IPHONE_61',
        'APP_IPHONE_58',
        'APP_IPHONE_47',
        'APP_IPHONE_40',
        'APP_IPHONE_35'
    ]

    const requiredDevices = [
        'APP_IPHONE_65',
        'APP_IPHONE_55'
    ]
    const requiredDevicesIpad = [
        {deviceName: 'APP_IPAD_PRO_3GEN_129', size: 5},
        {deviceName: 'APP_IPAD_PRO_129', size: 3},
    ]



    //get current localizations on apple store
    useEffect(()=>{
        setIsWaiting(true)
        const controller = new AbortController();

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/${appId}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },sinal: controller.signal
        };
        
        axios(config)
        .then(function (response) {
            setCurrentAppLocalizations(sortResponse(response.data.data))
            let temp1= sortResponse(response.data.data)
            let temp2 = response.data.data.info.primary_locale.toLowerCase()

            setPrimaryName(temp1[0].attributes.locale_name)
            setPrimary_Locale(temp2)
            setPrimaryLocalizationId(temp1[0].id)

            if(language===''){
                setLanguage(temp2)
                setName(temp1[0].attributes.locale_name)
                setLocalizationId(temp1[0].id)
            }
            else{
                setLocalizationId(response.data.data.localizations.find((local)=> local.attributes.locale.toLowerCase()===language).id)
                setName(response.data.data.localizations.find((local)=> local.attributes.locale.toLowerCase()===language).attributes.locale_name)
            }
            setIsWaiting(false)            
        })
        .catch(function (error) {
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
        });

        return ()=>{
            controller.abort()
        }

    },[appId])



    const handleClick = () =>{   
        const localList = document.getElementById("opacityDiv")
        const localListIcon = document.getElementById('screenshotSelectLocalIcon')
        localListIcon.classList.toggle("screenshot-local-list-rotate-icon")
        localList.classList.toggle('screenshot-local-list-visible')
        window.addEventListener('click', closeLocalList)

  
    }
    const closeLocalList = (event) =>{
        
        if ((event.target.className !== "screenshot-local-item") && (event.target.className !== "screenshot-select-local") && (event.target.className !== "screenshot-select-local-icon") && (event.target.className !== "screenshot-select-local-language") && (event.target.className !== "screenshot-select-local-icon screenshot-local-list-rotate-icon") && (event.target.className !== "screenshot-local-list screenshot-local-list-visible") && (event.target.className !== "screenshot-local-list"))   
            {
                window.removeEventListener('click', closeLocalList)
                const localList = document.getElementById("opacityDiv")
                const localListIcon = document.getElementById('screenshotSelectLocalIcon')
                if(localListIcon){localListIcon.classList.remove("screenshot-local-list-rotate-icon")}
                if(localList){localList.classList.remove('screenshot-local-list-visible')}                
            }           
    }




    const handle = (localizationId, locale, name) =>{
        setLocalizationId(localizationId)
        setLanguage(locale.toLowerCase())
        setName(name)    
    }




    const screenshotsGet = async (controller) =>{
        if(localizationId){
            setIsWaitingSets(true)

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/screenshot?appId=${appId}&locId=${localizationId}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                signal: controller.signal
            };
            axios(config)
            .then(function (response) {              
                    setScreenshots(response.data.data.filter((i)=>i.screenshots.length!==0))
                    if(localizationId === primaryLocalizationId){
                        setPrimaryScreenshots(response.data.data.filter((i)=>i.screenshots.length!==0))          
                    }
                    setIsWaitingSets(false)   
            })
            .catch(function (error) {
                // setIsWaiting(true)
                if(error.code === 'ERR_CANCELED'){
                    //do nothing
                }
                else{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                }
            });
        }
    }
    const screenshotsPriGet = async (controller) =>{
        if(primaryLocalizationId && (localizationId !== primaryLocalizationId)){
            setIsWaitingSets2(true)

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/screenshot?appId=${appId}&locId=${primaryLocalizationId}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                signal: controller.signal

            };
            axios(config)
            .then(function (response) {              
                    setPrimaryScreenshots(response.data.data.filter((i)=>i.screenshots.length!==0))
                    setIsWaitingSets2(false)   
            })
            .catch(function (error) {
                // setIsWaiting(true)
                if(error.code === 'ERR_CANCELED'){
                    //do nothing
                }
                else{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                }
            });
        }
    }
    const previewsGet = async (controller) =>{
        if(localizationId){
            setIsWaitingSets3(true)

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/preview?appId=${appId}&locId=${localizationId}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                signal: controller.signal
            };
            axios(config)
            .then(function (response) {   
                    setPreviews(response.data.data.filter((i)=>i.previews.length!==0))
                    if(localizationId === primaryLocalizationId){
                        setPrimaryPreviews(response.data.data.filter((i)=>i.previews.length!==0))
                    

                    }
                    setIsWaitingSets3(false)   
            })
            .catch(function (error) {
                // setIsWaiting(true)
                if(error.code === 'ERR_CANCELED'){
                    //do nothing
                }
                else{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                }
            });
        }
    }
    const previewsPriGet = async (controller) =>{
        if(primaryLocalizationId && (localizationId !== primaryLocalizationId)){
            setIsWaitingSets4(true)
            
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/preview?appId=${appId}&locId=${primaryLocalizationId}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                signal: controller.signal

            };
            axios(config)
            .then(function (response) {         
                    setPrimaryPreviews(response.data.data.filter((i)=>i.previews.length!==0))
                    setIsWaitingSets4(false)   
            })
            .catch(function (error) {
                // setIsWaiting(true)
                if(error.code === 'ERR_CANCELED'){
                    //do nothing
                }
                else{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                }
            });
        }
    }
    useEffect(() => {
        const controller = new AbortController();
        Promise.all(
            [screenshotsGet(controller),screenshotsPriGet(controller),previewsGet(controller),previewsPriGet(controller)]
        )
        return ()=>{
            controller.abort()
        }
    }, [localizationId])





    //show hide optional media devices 
    const showHideDevices = () =>{
        setShowDevices(!showDevices)
    }

    return (

        <div className="screenshot-inner-content current-inner-container"> 

        {subWarning ? 
            subWarning.status==="expiring" ?
                jurisdiction.role.includes(currentAppRole) ?
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0', marginBottom:'30px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                : 
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0', marginBottom:'30px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner's subscription is expiring.</span>
            : subWarning.status==="banned" ?
                jurisdiction.role.includes(currentAppRole) ?
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0', marginBottom:'30px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                : 
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0', marginBottom:'30px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner's subscription has expired. This application is read only.</span>
            :     
                ''
        :
            ''
        }
        
            <div className="screenshot-select-div">
                <div id='screenshotSelectLocal' onClick={()=>{handleClick()}} className='screenshot-select-local'>
                    <span className='screenshot-select-local-language'>{!isWaiting ? name : <SpinnerSmall/>}</span>
                    <img id='screenshotSelectLocalIcon' className='screenshot-select-local-icon' src="/assets/Icons/down-arrow-black.svg" alt="AppImage" />
                </div>
                <div id='opacityDiv' className="opacity-div">   
                    <div id='arrowUp' className='arrow-up'></div>
                    <div id='arrowUp2' className='arrow-up2'></div>
                    <div id='screenshotLocalList' className="screenshot-local-list">
                        {!isWaiting ? <>
                            {!currentAppLocalizations ? '' :currentAppLocalizations.map((data, index)=>{
                                return (
                                    <ScreenshotLocalItem func={!isWaitingSets&&!isWaitingSets2 ? handle : ()=>{}} key={index+'x'} data={data} count={index} class_name={!isWaitingSets&&!isWaitingSets2 ? 'screenshot-local-item' : 'screenshot-local-item-disabled'}></ScreenshotLocalItem>
                                )
                            })}</>
                        :
                        <div className="spinner">
                            <SpinnerMid/>
                        </div>
                        }
                    </div>
                </div> 
            </div>

            {isUploading.status&&true ?  
            <span className='edit-warning' style={{marginTop:'15px'}}>You can not edit this collection while is being uploaded</span>
            :
                ''
            }

            {!isWaitingSets && !isWaitingSets2 && !isWaitingSets3 && !isWaitingSets4 ? 
                !(previews && primaryPreviews && screenshots && primaryScreenshots ) ? <div className='spinner'><Spinner/></div> :

                    requiredDevices.map((device, index)=>{
                        if(index === 0){
                            return(
                                <span className='encap-media-current' key={index}>
                                    
                                    <CurrentScreenshotItemv2 key={index} primaryLocalizationId={primaryLocalizationId} uniqueId={index+'curItem'} classId='curItem' localizationId={localizationId} deviceSize={device} devicePreviewSize={device.slice(4)} screenshots={screenshots} previews={previews} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} primaryLocalePreviews={primaryPreviews}
                                    />
                                </span>
                            )
                        }
                        else{
                            return(
                                <span className='encap-media-current' key={index}>
        
                                    <CurrentScreenshotItemv2 key={index} primaryLocalizationId={primaryLocalizationId} uniqueId={index+'curItem'} classId='curItem' localizationId={localizationId} deviceSize={device} devicePreviewSize={device.slice(4)} screenshots={screenshots} previews={previews} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} primaryLocalePreviews={primaryPreviews}
                                    /> 
                                </span>
                            )
                        }
        
                    })
            : 
                <div className='spinner'><Spinner/></div>
            }


            {!isWaitingSets && !isWaitingSets2 && !isWaitingSets3 && !isWaitingSets4 ? 
                !(previews && primaryPreviews && screenshots && primaryScreenshots )  ? '' :

                    requiredDevicesIpad.map((device, index)=>{
                        if(index===0){
                            return(
                                <span className='encap-media-current' key={index}>
                                    
                                    <CurrentScreenshotIpadItemv2 key={index}
                                    primaryLocalizationId={primaryLocalizationId} uniqueId={index+'curIpadItem'} classId='curIpadItem' localizationId={localizationId} deviceSize={device.deviceName} size={device.size} screenshots={screenshots} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} primaryLocalePreviews={primaryPreviews} devicePreviewSize={device.deviceName.slice(4)} previews={previews}
                                    />
                                </span>
                            )
                        }
                        else{
                            return(
                                <span className='encap-media-current'  key={index}>
                                    <CurrentScreenshotIpadItemv2 key={index}
                                    primaryLocalizationId={primaryLocalizationId} uniqueId={index+'curIpadItem'} classId='curIpadItem' localizationId={localizationId} deviceSize={device.deviceName} size={device.size} screenshots={screenshots} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} primaryLocalePreviews={primaryPreviews} devicePreviewSize={device.deviceName.slice(4)} previews={previews}
                                    />


                                </span>
                            )
                        }

                    })
            : 
                ''
            }


                {!isWaitingSets && !isWaitingSets2 && !isWaitingSets3 && !isWaitingSets4 ? 
                    !(previews && primaryPreviews && screenshots && primaryScreenshots )  ? '' :
                            <span className='current-show-media-devices'>
                                        <span id='showHideMediaCurrent' onClick={()=>showHideDevices()}>
                                            {!showDevices ? 'Show all media devices' : 'Hide media devices'}
                                        </span>
                            </span>
                    : 
                    ''
                }

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            {!isWaitingSets && !isWaitingSets2 && !isWaitingSets3 && !isWaitingSets4 ? 
                !(previews && primaryPreviews && screenshots && primaryScreenshots ) ? '' :

                    baseDeviceList.map((device, index)=>{
                        return(
                            <span className={!showDevices ? 'current-hidden-devices' : 'current-visible-devices encap-media-current'} key={index}>
                                <CurrentScreenshotItemv2 key={index} primaryLocalizationId={primaryLocalizationId} uniqueId={'base'+index+'curItem'} classId='curItemBase' localizationId={localizationId} deviceSize={device} devicePreviewSize={device.slice(4)} screenshots={screenshots} previews={previews} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} primaryLocalePreviews={primaryPreviews}
                                /> 
                            </span>
                        )
                    })
            : 
                ''
            }


            {!isWaitingSets && !isWaitingSets2 && !isWaitingSets3 && !isWaitingSets4 ? 
                !(previews && primaryPreviews && screenshots && primaryScreenshots )  ? '' :

                    baseIpadList.map((device, index)=>{
                        return(
                            <span className={!showDevices ? 'current-hidden-devices' : 'current-visible-devices encap-media-current'} key={index}>
                                <CurrentScreenshotIpadItemv2 key={index}
                                primaryLocalizationId={primaryLocalizationId} uniqueId={'base'+index+'curIpadItem'} classId='curIpadItemBase' localizationId={localizationId} deviceSize={device.deviceName} size={device.size} screenshots={screenshots} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} primaryLocalePreviews={primaryPreviews} devicePreviewSize={device.deviceName.slice(4)} previews={previews}
                                />
                            </span>
                        )
                    })
            : 
                ''
            }

        </div> 
    )
}   

export default CurrentScreenshotPagev2