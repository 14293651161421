import './CurrentnCollectionOwnerPage.css'
import { useEffect, useState, useContext } from 'react'
import {fireCustomRequestError, sortResponseCol, sortValuePri, sortValue, fireCustomError, fireExportMessage, fireRequestError, fireCustomErrorV2, sortResponse } from '../Common Functions/commonFunctions';
import axios from 'axios';
import VariableContext from '../userContext/VariableContext';
import { useParams } from 'react-router-dom';
import SpinnerSmall from '../Misc/SpinnerSmall';
import Spinner from '../Misc/Spinner';
import CollectionOwnerLocalItem from '../Items/Owner/Local/CollectionOwnerLocalItem'
import OwnerDescription from '../Items/Owner/Description/OwnerDescription';
import OwnerKeyword from '../Items/Owner/Keyword/OwnerKeyword';
import OwnerWhatsnew from '../Items/Owner/Whatsnew/OwnerWhatsnew';
import CollectionOwnerScreenshots from '../Items/Owner/Screenshots/CollectionOwnerScreenshots';
import CollectionOwnerPreviews from '../Items/Owner/Screenshots/CollectionOwnerPreviews';
import Swal from 'sweetalert2';
import SpinnerMid from '../Misc/SpinnerMid';
import ImportZip from '../Popup/ImportZip';
import GoBackButton from '../Components/GoBackButton';
import { statusListMetadata } from '../StatusList/status';
import * as gaEvents from "../ga-utils"

function CollectionOwnerPage({pageType}) {

  const[isWaiting, setIsWaiting] = useState(false)
  const[isWaiting3, setIsWaiting3] = useState(false)
  // const[isWaitingSets, setIsWaitingSets] = useState(false)

  const{currentAppPrimaryLocale} = useContext(VariableContext)

  const {isUploadingDescription} = useContext(VariableContext)
  const {isUploadingKeyword} = useContext(VariableContext)
  const {isUploadingWhatsnew} = useContext(VariableContext)
  const {isUploadingPhoto} = useContext(VariableContext)
  const {isUploadingPreview} = useContext(VariableContext)
  const {isUploading ,setIsUploading} = useContext(VariableContext)

  const {setUploadSignal} = useContext(VariableContext)
  const {currentAppCols, setCurrentAppCols} = useContext(VariableContext)
  const {setCurrentAppLocalizations} = useContext(VariableContext)

  const {subWarning} = useContext(VariableContext)

  const[locale, setLocale] = useState(null)
  const[languageName, setLanguageName] = useState(null)
  const[languages, setLanguages] =useState(null)

  const[display, setDisplay] =useState(null)
  const[primaryScreenshots, setPrimaryScreenshots] = useState(null)
  const[screenshots, setScreenshots] = useState(null)
  const[previews, setPreviews] = useState(null)
  const[primaryPreviews, setPrimaryPreviews] = useState(null)

  const {getColList} = useContext(VariableContext)

  const {importPercentage, setImportPercentage} = useContext(VariableContext)
  const {importArray, setImportArray} = useContext(VariableContext)

  const[device, setDevice] = useState(null)

  const {subscriptionLimits} = useContext(VariableContext)

  
  let {appId,collectionId} = useParams()

  const requiredDevices = [
    'APP_IPHONE_55',
    'APP_IPHONE_65',
    'APP_IPHONE_67',  
  ]
  const requiredIpadDevices = [
    'APP_IPAD_PRO_129',
    'APP_IPAD_PRO_3GEN_129',
  ]

  const requiredDevicesPreviews = [
    'IPHONE_55',
    'IPHONE_65',
    'IPHONE_67',  
  ]
  const requiredIpadDevicesPreviews = [
    'IPAD_PRO_129',
    'IPAD_PRO_3GEN_129',
  ]

    //get collections (themes...)
    useEffect(()=>{
      setIsWaiting(true)
      var data = '';
  
      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/app/collection/${appId}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
        data : data
      };
      axios(config)
      .then(function (response) {
      setIsWaiting(false)

        setCurrentAppCols(response.data.data)
        // 
      })
      .catch(function (error) {
      // setIsWaiting(false)
      if(error.message === 'Network Error'){
        fireCustomError('NETWORK ERROR. ')
      }else{
        fireRequestError(error)
      }

      });
    
    },[appId, getColList])

    useEffect(()=>{
      if(currentAppPrimaryLocale && !locale){
      setIsWaiting(true)
      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/app/collection/uploadPreview?appId=${appId}&collectionId=${collectionId}&locale=${currentAppPrimaryLocale}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }
    
      axios(config)
      .then(function (response) {
        let temp = sortResponseCol(response.data.data.languages, currentAppPrimaryLocale)
        setLanguages(temp)
        setLanguageName(temp[0].locale_name)
        setDisplay(response.data.data.attributes)
        setPrimaryScreenshots(response.data.data.screenshotSizes)
        setScreenshots(response.data.data.screenshotSizes)
        setPreviews(response.data.data.previewSizes)
        setPrimaryPreviews(response.data.data.previewSizes)
        setDevice('APP_IPHONE_67')
        setIsWaiting(false)
        
      })
      .catch(function (error) {
        if(error.message === 'Network Error'){
          fireCustomError('NETWORK ERROR. ')
        }else{
          fireRequestError(error)
        }
      });
    }
    },[appId,collectionId,currentAppPrimaryLocale, getColList])



  useEffect(()=>{
    if(locale){
      setIsWaiting(true)
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/app/collection/uploadPreview?appId=${appId}&collectionId=${collectionId}&locale=${locale}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    };
    
    axios(config)
    .then(function (response) {
      setDisplay(response.data.data.attributes)
      setPreviews(response.data.data.previewSizes)
      setScreenshots(response.data.data.screenshotSizes)
      setIsWaiting(false)
    })
    .catch(function (error) {
      if(error.message === 'Network Error'){
        fireCustomError('NETWORK ERROR. ')
      }else{
        fireRequestError(error)
      }
    });
  }
  },[appId,collectionId,locale, getColList])

const handle = (locale, langName) =>{
  setLocale(locale)
  setLanguageName(langName)
}

const handleClick = () =>{   
    const localList = document.getElementById("ownerOpacityDiv")
    const localListIcon = document.getElementById('ownerSelectLocalIcon')
    localListIcon.classList.toggle("owner-local-list-rotate-icon")
    localList.classList.toggle('owner-local-list-visible')
    window.addEventListener('click', closeLocalList)


}

const closeLocalList = (event) =>{
      
          if ((event.target.className !== "owner-local-item") && (event.target.className !== "owner-select-local") && (event.target.className !== "owner-select-local-icon") && (event.target.className !== "owner-select-local-language") && (event.target.className !== "owner-select-local-icon owner-local-list-rotate-icon") && (event.target.className !== "owner-local-list owner-local-list-visible") && (event.target.className !== "owner-local-list"))  
          {
              window.removeEventListener('click', closeLocalList)
              const localList = document.getElementById("ownerOpacityDiv")
              const localListIcon = document.getElementById('ownerSelectLocalIcon')
              if(localListIcon){localListIcon.classList.remove("owner-local-list-rotate-icon")}
              if(localList){localList.classList.remove('owner-local-list-visible')}                
          }           
}

const selectDevice = (devi,e) =>{
  setDevice(devi)

  let tablinks = document.getElementsByClassName("tablinks");
  for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  e.target.classList += " active";
}


const uploadCollection = () =>{
      Swal.fire({
        icon: 'question',
        title: 'Upload Initiated',
        position: 'center',
        width: '400',
        text: 'You are about to upload this collection to App Store Connect. Are you sure?',
        backdrop: true,
        showConfirmButton: true,
        showCancelButton: true,     
        confirmButtonColor: '#3A6FF8e6',
        cancelButtonColor: '#cf000fe6',
        cancelButtonText: 'Cancel',      
        confirmButtonText: 'Upload',
        showLoaderOnConfirm: true,     
        preConfirm: async () => {
          setIsWaiting3(true)  
          var config = {
              method: 'get',
              url: `${process.env.REACT_APP_API_URL}/app/${appId}`,
              headers: { 
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
              },
              
          };
            try {
                const response = await axios(config);
                if(response.status === 200){
                  setCurrentAppLocalizations(sortResponse(response.data.data))
                  if(statusListMetadata.includes(response.data.data.attributes.appStoreState)){
                      setIsUploading({status:'initiated', appID: +appId, collectionID: +collectionId})
                      setUploadSignal(true)  
                      gaEvents.uploadStarted(pageType)
                  }
                  else{
                      gaEvents.uploadStartFail(pageType)
                      fireCustomErrorV2(`You can not upload any metadata to an app which is ${response.data.data.attributes.appStoreState}`)
                  }
                  setIsWaiting3(false)   
                }
            } catch (error) {
              gaEvents.uploadStartFail(pageType)
              setIsWaiting3(false)
              if(error.message === 'Network Error'){
                  fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              } 
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        })
  }


const exportCollection = () =>{
  fireExportMessage()
  var data = '';

  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/app/collection/${appId}`,
    headers: { 
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    setCurrentAppCols(response.data.data)

    var data = JSON.stringify({
      "appId": appId,
      "collectionId": collectionId,
      "title": response.data.data.find((i)=>i.collection_id===+collectionId).collection_title
    });
    
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/app/collection/export`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("token")}`, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
        gaEvents.exportSuccess(pageType)
        let a = document.createElement('a');
        a.href = `${process.env.REACT_APP_API_URL}/${response.data.data.path}`;
        a.download = `${response.data.data.path}`.split("/")[2];
        a.click();
        Swal.close()
    })
    .catch(function (error) {
      gaEvents.exportFail(pageType)
      Swal.close()
      if(error.message === 'Network Error'){
        fireCustomError('NETWORK ERROR. ')
      }else{
        fireRequestError(error)
      }
    });
})
  .catch(function (error) {
    gaEvents.exportFail(pageType)
    Swal.close()
    if(error.message === 'Network Error'){
      fireCustomError('NETWORK ERROR. ')
    }else{
      fireRequestError(error)
    }
  });
}

const importCollection = () =>{
    document.getElementById('importAppPopup'+collectionId).style.display = 'flex'
}


 
  return (<>
    <div className="owner-title-div titleArea">
      <GoBackButton/>

      <h1>{currentAppCols ? currentAppCols.find((i)=>i.collection_id===+collectionId)||false ? currentAppCols.find((i)=>i.collection_id===+collectionId).collection_title : '' : ''}</h1>

    </div>
    <div className='owner-inner-content-col'>
      <div className='language-menu-div' style={{backgroundColor:'#f5f5f5',width:' calc(100% + 35px)', padding:'18px 0'}}>
        <div className='language-menu-div-inner'
                    style={importArray.get(`${appId}-${collectionId}-${pageType}`) ||
                    (isUploading.status&&isUploading.collectionID === +collectionId) || 
                    (isUploadingPreview.status&&isUploadingPreview.collectionID === +collectionId) || 
                    (isUploadingPhoto.status&&isUploadingPhoto.collectionID === +collectionId) || 
                    (isUploadingWhatsnew.status&&isUploadingWhatsnew.collectionID === +collectionId) || 
                    (isUploadingKeyword.status&&isUploadingKeyword.collectionID === +collectionId) || 
                    (isUploadingDescription.status&&isUploadingDescription.collectionID === +collectionId) ||
                    (subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.includes(+collectionId) : false) ||
                    (subWarning ? subWarning.status==="expiring"||subWarning.status==="banned" : false) ? 
                    {justifyContent:'space-between', width:'100%', marginLeft:'25px'} : { marginLeft:'25px',  width:'100%'}}>

          {!isWaiting&&primaryScreenshots&&screenshots ? <>

              {/* {importArray.get(`${appId}-${collectionId}-${pageType}`) ? 
                  <span className='edit-warning' style={{display: 'flex', alignItems: 'center'}}><SpinnerSmall/><img style={{marginRight:'10px'}} src="/assets/Icons/excla.svg" alt="!!!" />Importing...&nbsp;{importPercentage.get(`${appId}-${collectionId}-${pageType}`)}</span>
              : 
                  ''
              } */}

              {subWarning ? 
                  subWarning.status==="expiring" ?
                      <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                  : subWarning.status==="banned" ?
                      <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                  :     
                    ''
              : 
                  ''
              }

              {importArray.get(`${appId}-${collectionId}-${pageType}`) || 
                    (isUploading.status&&isUploading.collectionID === +collectionId) || 
                    (isUploadingPreview.status&&isUploadingPreview.collectionID === +collectionId) || 
                    (isUploadingPhoto.status&&isUploadingPhoto.collectionID === +collectionId) || 
                    (isUploadingWhatsnew.status&&isUploadingWhatsnew.collectionID === +collectionId) || 
                    (isUploadingKeyword.status&&isUploadingKeyword.collectionID === +collectionId) || 
                    (isUploadingDescription.status&&isUploadingDescription.collectionID === +collectionId) ? 
                <span className='edit-warning'><img style={{marginRight:'10px'}} src="/assets/Icons/excla.svg" alt="!!!" /><span className='resposive-edit-warning-inner'>&nbsp;Upload</span><span className='edit-warning-inner'>You can not upload this collection while another upload is in progress</span></span>
              :
                  ''
              }

              {subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.includes(+collectionId) ? 
                <span className='sub-warning2' style={{display: 'flex', alignItems: 'center', marginLeft:'0', width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />You have more collections than what your account type permits. Some of the features concerning this collection are disabled.</span>
              : 
                ""
              : 
                ""
              }


              <div
                  style={importArray.get(`${appId}-${collectionId}-${pageType}`) ||
                  (isUploading.status&&isUploading.collectionID === +collectionId) || 
                  (isUploadingPreview.status&&isUploadingPreview.collectionID === +collectionId) || 
                  (isUploadingPhoto.status&&isUploadingPhoto.collectionID === +collectionId) || 
                  (isUploadingWhatsnew.status&&isUploadingWhatsnew.collectionID === +collectionId) || 
                  (isUploadingKeyword.status&&isUploadingKeyword.collectionID === +collectionId) || 
                  (isUploadingDescription.status&&isUploadingDescription.collectionID === +collectionId) ||
                  (subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.includes(+collectionId) : false) ||
                  (subWarning ? subWarning.status==="expiring"||subWarning.status==="banned" : false) ? 
                  {display:'flex', alignItems:'center', justifyContent:'center', gap:'10px', marginLeft:"auto"} : {display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}
              >


                  <button disabled={isWaiting3 || isUploading.status||isUploadingDescription.status||isUploadingKeyword.status||isUploadingWhatsnew.status||isUploadingPhoto.status||isUploadingPreview.status} onClick={()=>{uploadCollection()}} className={`col-menu-upload`}>
                    {isWaiting3 || isUploading.status||isUploadingDescription.status||isUploadingKeyword.status||isUploadingWhatsnew.status||isUploadingPhoto.status ? <SpinnerSmall/> : 'Upload to Store'}
                  </button>
                  <div className='divider-menu'></div>

                  {isUploading.collectionID === +collectionId || isUploadingDescription.collectionID === +collectionId || isUploadingKeyword.collectionID === +collectionId || isUploadingWhatsnew.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ?
                    <><button disabled={true} onClick={importCollection} className='col-menu-button'>Import</button>
                    <button onClick={exportCollection} className='col-menu-button-ex'>Export</button></>
                  :
                    <><button onClick={importCollection} className='col-menu-button'>Import</button>
                    <button onClick={exportCollection} className='col-menu-button-ex'>Export</button></>
                  }
              </div>

          </> : ''}

        </div>
        <span className='language-menu-language'>
          <div id='ownerSelectLocal' onClick={()=>{handleClick()}} className='owner-select-local'>
              <span className='owner-select-local-language'>{!isWaiting ? languageName : <SpinnerSmall/>}</span>
              <img id='ownerSelectLocalIcon' className='owner-select-local-icon' src="/assets/Icons/down-arrow-black.svg" alt="AppImage" />
          </div>
          <div id='ownerOpacityDiv' className="owner-opacity-div">   
              <div id='ownerArrowUp' className='owner-arrow-up'></div>
              <div id='ownerArrowUp2' className='owner-arrow-up2'></div>
              <div id='ownerLocalList' className="owner-local-list">
                    {!isWaiting ? <>
                        {!languages ? '' :languages.map((data, index)=>{
                            return (
                                <CollectionOwnerLocalItem func={!isWaiting ? handle : ''} key={index+'x'} data={data} count={index} class_name={!isWaiting ? 'current-owner-local-item' : 'current-owner-local-item-disabled'}
                                ></CollectionOwnerLocalItem>
                            )
                        })}</>
                    :
                    <div className="spinner">
                        <SpinnerMid/>
                    </div>
                    }
              </div>
          </div>
        </span>
      </div>
      
 


      
      

      {!isWaiting&&primaryScreenshots&&screenshots ?
           <>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <h2 style={{margin:'0 0 25px 0'}}>Screenshots</h2>
              
            </div> 
            <div className="tab">             
                <button className="tablinks active" onClick={(e)=>selectDevice("APP_IPHONE_67",e)}>iPhone 6.7"</button>          
                <button className="tablinks" onClick={(e)=>selectDevice("APP_IPHONE_65",e)}>iPhone 6.5"</button>          
                <button className="tablinks" onClick={(e)=>selectDevice("APP_IPHONE_55",e)}>iPhone 5.5"</button>          
                <button className="tablinks" onClick={(e)=>selectDevice("APP_IPAD_PRO_3GEN_129",e)}>iPad Pro (6th Gen) 12.9"</button>          
                <button className="tablinks" onClick={(e)=>selectDevice("APP_IPAD_PRO_129",e)}>iPad Pro (2nd Gen) 12.9"</button>          
            </div></>
        :
            ''
      }

      <div className='owner-screenshot-previews'>
        <div className='owner-screenshot-previews-inner'>
          
        
          {!isWaiting&&primaryPreviews&&previews ?
            <>
              {requiredDevicesPreviews.includes(device.slice(4)) ?
                <>
                  {previews.find((i)=>i.size===device.slice(4)) ?
                      <CollectionOwnerPreviews data={previews.find((i)=>i.size===device.slice(4))} keys={1} fromPrimary={false} fromItself={false}/>
                  :
                      device.slice(4)>'IPHONE_55' ?
                          previews.find((i)=>i.size==="IPHONE_65") ? 
                              <CollectionOwnerPreviews data={previews.find((i)=>i.size==="IPHONE_65")} keys={1} fromPrimary={false} fromItself={true}/>
                          :
                              primaryPreviews.find((i)=>i.size===device.slice(4)) ?
                                  <CollectionOwnerPreviews data={primaryPreviews.find((i)=>i.size===device.slice(4))} keys={1} fromPrimary={true} fromItself={false}/>
                              :
                                  primaryPreviews.find((i)=>i.size==="IPHONE_65") ?  
                                      <CollectionOwnerPreviews data={primaryPreviews.find((i)=>i.size==="IPHONE_65")} keys={1} fromPrimary={true} fromItself={false}/>   
                                  :
                                      // <div className='no-screenshots-collections previewOwner'>No Previews Available</div>
                                      ''
                      :
                          previews.find((i)=>i.size==="IPHONE_55") ?   
                                  <CollectionOwnerPreviews data={previews.find((i)=>i.size==="IPHONE_55")} keys={1} fromPrimary={false} fromItself={true}/>  
                          :
                              primaryPreviews.find((i)=>i.size===device.slice(4)) ?
                                  <CollectionOwnerPreviews data={primaryPreviews.find((i)=>i.size===device.slice(4))} keys={1} fromPrimary={true} fromItself={false}/>
                              :
                                  primaryPreviews.find((i)=>i.size==="IPHONE_55") ? 
                                          <CollectionOwnerPreviews data={primaryPreviews.find((i)=>i.size==="IPHONE_55")} keys={1} fromPrimary={true} fromItself={false}/>  
                                  :
                  // <div className='no-screenshots-collections previewOwner'>No Previews Available</div>
                  ''
                  }</> : ''}
            
                {requiredIpadDevicesPreviews.includes(device) ? 
                  <>
                      {previews.find((i)=>i.size===device.slice(4)) ? 
                              <CollectionOwnerScreenshots data={previews.find((i)=>i.size===device.slice(4))} keys={1} fromPrimary={false} fromItself={false}/>
                      :
                          requiredIpadDevicesPreviews.indexOf(device)>requiredIpadDevicesPreviews.indexOf('IPAD_PRO_129')?
                              previews.find((i)=>i.size==="IPAD_PRO_3GEN_129") ? 
                                      <CollectionOwnerPreviews data={previews.find((i)=>i.size==="IPAD_PRO_3GEN_129")} keys={1} fromPrimary={false} fromItself={true}/>
                              :
                                  primaryPreviews.find((i)=>i.size===device.slice(4)) ?
                                      <CollectionOwnerPreviews data={primaryPreviews.find((i)=>i.size===device.slice(4))} keys={1} fromPrimary={true} fromItself={false}/>
                                  :
                                      primaryPreviews.find((i)=>i.size==="IPAD_PRO_3GEN_129") ?  
                                              <CollectionOwnerPreviews data={primaryPreviews.find((i)=>i.size==="IPAD_PRO_3GEN_129")} keys={1} fromPrimary={true} fromItself={false}/>   
                                      :
                                              // <div className='no-screenshots-collections previewOwner mm'>No Previews Available</div>
                                              ''
                          :
                              previews.find((i)=>i.size==="IPAD_PRO_129") ?   
                                      <CollectionOwnerPreviews data={previews.find((i)=>i.size==="IPAD_PRO_129")} keys={1} fromPrimary={false} fromItself={true}/>  
                              :
                                  primaryPreviews.find((i)=>i.size===device.slice(4)) ?
                                      <CollectionOwnerPreviews data={primaryPreviews.find((i)=>i.size===device.slice(4))} keys={1} fromPrimary={true} fromItself={false}/>
                                  :
                                      primaryPreviews.find((i)=>i.size==="IPAD_PRO_129") ? 
                                              <CollectionOwnerPreviews data={primaryPreviews.find((i)=>i.size==="IPAD_PRO_129")} keys={1} fromPrimary={true} fromItself={false}/>  
                                      :
                          // <div className='no-screenshots-collections previewOwner'>No Previews Available</div>
                          ''
                          }</> : ''}
            </>
          :
            ''
          }

          {!isWaiting&&primaryScreenshots&&screenshots ? 
            <>
            {requiredDevices.includes(device) ? 
            <>
                {screenshots.find((i)=>i.size===device) ? 
                        <CollectionOwnerScreenshots data={screenshots.find((i)=>i.size===device)} keys={1} fromPrimary={false} fromItself={false}/>
                :
                    device>'APP_IPHONE_55' ?
                        screenshots.find((i)=>i.size==="APP_IPHONE_65") ? 
                                <CollectionOwnerScreenshots data={screenshots.find((i)=>i.size==="APP_IPHONE_65")} keys={1} fromPrimary={false} fromItself={true}/>
                        :
                            primaryScreenshots.find((i)=>i.size===device) ?
                                <CollectionOwnerScreenshots data={primaryScreenshots.find((i)=>i.size===device)} keys={1} fromPrimary={true} fromItself={false}/>
                            :
                                primaryScreenshots.find((i)=>i.size==="APP_IPHONE_65") ?  
                                        <CollectionOwnerScreenshots data={primaryScreenshots.find((i)=>i.size==="APP_IPHONE_65")} keys={1} fromPrimary={true} fromItself={false}/>   
                                :
                                        <div className='no-screenshots-collections'>No Screenshots Available</div>
                    :
                        screenshots.find((i)=>i.size==="APP_IPHONE_55") ?   
                                <CollectionOwnerScreenshots data={screenshots.find((i)=>i.size==="APP_IPHONE_55")} keys={1} fromPrimary={false} fromItself={true}/>  
                        :
                            primaryScreenshots.find((i)=>i.size===device) ?
                                <CollectionOwnerScreenshots data={primaryScreenshots.find((i)=>i.size===device)} keys={1} fromPrimary={true} fromItself={false}/>
                            :
                                primaryScreenshots.find((i)=>i.size==="APP_IPHONE_55") ? 
                                        <CollectionOwnerScreenshots data={primaryScreenshots.find((i)=>i.size==="APP_IPHONE_55")} keys={1} fromPrimary={true} fromItself={false}/>  
                                :
                <div className='no-screenshots-collections'>No Screenshots Available</div>}</> : ''}


                {requiredIpadDevices.includes(device) ? 
                <>
                    {screenshots.find((i)=>i.size===device) ? 
                            <CollectionOwnerScreenshots data={screenshots.find((i)=>i.size===device)} keys={1} fromPrimary={false} fromItself={false}/>
                    :
                        requiredIpadDevices.indexOf(device)>requiredIpadDevices.indexOf('APP_IPAD_PRO_129')?
                            screenshots.find((i)=>i.size==="APP_IPAD_PRO_3GEN_129") ? 
                                    <CollectionOwnerScreenshots data={screenshots.find((i)=>i.size==="APP_IPAD_PRO_3GEN_129")} keys={1} fromPrimary={false} fromItself={true}/>
                            :
                                primaryScreenshots.find((i)=>i.size===device) ?
                                    <CollectionOwnerScreenshots data={primaryScreenshots.find((i)=>i.size===device)} keys={1} fromPrimary={true} fromItself={false}/>
                                :
                                    primaryScreenshots.find((i)=>i.size==="APP_IPAD_PRO_3GEN_129") ?  
                                            <CollectionOwnerScreenshots data={primaryScreenshots.find((i)=>i.size==="APP_IPAD_PRO_3GEN_129")} keys={1} fromPrimary={true} fromItself={false}/>   
                                    :
                                            <div className='no-screenshots-collections'>No Screenshots Available</div>
                        :
                            screenshots.find((i)=>i.size==="APP_IPAD_PRO_129") ?   
                                    <CollectionOwnerScreenshots data={screenshots.find((i)=>i.size==="APP_IPAD_PRO_129")} keys={1} fromPrimary={false} fromItself={true}/>  
                            :
                                primaryScreenshots.find((i)=>i.size===device) ?
                                    <CollectionOwnerScreenshots data={primaryScreenshots.find((i)=>i.size===device)} keys={1} fromPrimary={true} fromItself={false}/>
                                :
                                    primaryScreenshots.find((i)=>i.size==="APP_IPAD_PRO_129") ? 
                                            <CollectionOwnerScreenshots data={primaryScreenshots.find((i)=>i.size==="APP_IPAD_PRO_129")} keys={1} fromPrimary={true} fromItself={false}/>  
                                    :
                    <div className='no-screenshots-collections'>No Screenshots Available</div>}</> : ''}

            </>
          :                     
            <div className="spinner-owner">
                <Spinner/>
            </div>
          }
        </div>
      </div>



      {!isWaiting&&display ? <>
        <OwnerDescription data={display.description.description} type={'Description'}/>
        <OwnerWhatsnew data={display.whatsNew.news} type={"What's New"}/>
        <OwnerKeyword data={display.keyword.keyword_list} type={'Keyword'}/></>
      :
        ''
      }



      <ImportZip pageType={pageType} colId={collectionId}/>

  

    </div></>
  )
}

export default CollectionOwnerPage