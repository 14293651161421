import {createContext, useState, useRef} from 'react'
import axios from 'axios'

const VariableContext = createContext()

export const VariableProvider = ({children}) => {
  const [sidebarToogle, setSidebarToogle] = useState(true)

  const [initialUserData, setInitialUserData] = useState(null)
  const [quota, setQuota] = useState(null)
  const [currentAppId, setCurrentAppId]= useState(0)
  const [currentAppIcon, setCurrentAppIcon] = useState('/assets/Icons/AddAppImage.svg')
  const [currentAppName, setCurrentAppName] = useState('Select Application')
  const [currentAppVersion, setCurrentAppVersion] =useState(null)
  const [currentAppRole, setCurrentAppRole] = useState(null)
  const [currentAppQuota, setCurrentAppQuota] = useState(null)
  const [subWarning, setSubWarning] = useState(null)
  
  const [currentAppLocalizations, setCurrentAppLocalizations] = useState(null)
  const [currentAppPrimaryLocale, setPrimaryLocale] = useState(null)

  const [collectionLocalizations, setCollectionLocalizations] = useState(null)
  const [collectionScreenshotLocalizations, setCollectionScreenshotLocalizations] = useState(null)
  const [collectionNotLocalizations, setCollectionNotLocalizations] = useState(null)
  const [languageSignal, setLanguageSignal] = useState(0)
  const [language, setLanguage] = useState(new Map())
  // const [language, setLanguage] = useState("")
  // const [name, setName] = useState('')
  const [showDevices, setShowDevices] = useState(false)

  const [internalRefreshSmall, setInternalRefreshSmall] = useState(false)
  const [internalRefreshBig, setInternalRefreshBig] = useState(false)
  const [internalPreviewRefreshSmall, setInternalPreviewRefreshSmall] = useState(false)
  const [internalPreviewRefreshBig, setInternalPreviewRefreshBig] = useState(false)

  const [internalIpadPreviewRefreshSmall, setInternalIpadPreviewRefreshSmall] = useState(false)
  const [internalIpadPreviewRefreshBig, setInternalIpadPreviewRefreshBig] = useState(false)
  const [internalIpadRefreshSmall, setInternalIpadRefreshSmall] = useState(false)
  const [internalIpadRefreshBig, setInternalIpadRefreshBig] = useState(false)

  const [currentAppCols, setCurrentAppCols] = useState(null)
  const [selectedAppColName, setSelectedAppColName] = useState(null)
  const [selectedAppColId, setSelectedAppColId] = useState(0)

  const [colDescriptions, setColDescriptions] = useState(null)
  const [colKeywords, setColKeywords] = useState(null)
  const [colWhatsNews, setColWhatsNews] = useState(null)
  const [colScreenshots, setColScreenshots] = useState(null)
  const [colPrimaryScreenshots, setColPrimaryScreenshots] = useState(null)

  const [toogleAll, setToogleAll] = useState(new Map())

  const [versionEdit, setVersionEdit] = useState(null)
  const [appInfoSignal, setAppInfoSignal] = useState(0)

  //screenshot/preview upload-delete progress registry
  // const [percentage, setPercentage] = useState(new Map())
  // const [percentagePreview, setPercentagePreview] = useState(new Map())
  const [registryArray, setRegistryArray] = useState(new Map())
  const [registryPreviewArray, setRegistryPreviewArray] = useState(new Map())

  //selected collection registry
  const appCol = useRef({})

  //create collection/rename collection popup (collection list refresh)
  const[getColList, setGetColList] = useState(0)

  //add member popup (role refresh)
  const[getRoles, setGetRoles] = useState(0)

  //importing registry
  const [importArray, setImportArray] = useState(new Map())
  const [importPercentage, setImportPercentage] = useState(new Map())
  const [summary, setSummary] = useState(null)

  //export signal for screenshots and previews
  const [exportSignal, setExportSignal] = useState(null)
  

  //upload collection
  const [isUploadingDescription, setIsUploadingDescription] = useState({status:false, appID: null, collectionID: null})
  const [isUploadingKeyword, setIsUploadingKeyword] = useState({status:false, appID: null, collectionID: null})
  const [isUploadingWhatsnew, setIsUploadingWhatsnew] = useState({status:false, appID: null, collectionID: null})
  const [isUploadingPhoto, setIsUploadingPhoto] = useState({status:false, appID: null, collectionID: null})
  const [isUploadingPreview, setIsUploadingPreview] = useState({status:false, appID: null, collectionID: null})
  const [isUploading, setIsUploading] = useState({status:false, appID: null, collectionID: null})

  const [uploadMessageDescription, setUploadMessageDescription] = useState([{status:0}])
  const [uploadMessageKeyword, setUploadMessageKeyword] = useState([{status:0}])
  const [uploadMessageWhatsnew, setUploadMessageWhatsnew] = useState([{status:0}])
  const [uploadMessagePhoto, setUploadMessagePhoto] = useState([{status:0}])
  const [uploadMessagePreview, setUploadMessagePreview] = useState([{status:0}]) 
  const [uploadMessage, setUploadMessage] = useState([{status:0}])

  const [uploadSignal, setUploadSignal] = useState(0)
  const [uploadJoinSignal, setUploadJoinSignal] = useState(0)
  const [appUploads, setAppUploads] = useState([])

  const[specialCodes, setSpecialCodes] = useState(null)//app sumo codes
  const[subscriptionLimits, setSubscriptionLimits] = useState(null)//subscription limits

  const [socket, setSocket] = useState(0)
  
  const [lastUpload, setLastUpload] = useState(null)

  const [summaryUpload, setSummaryUpload] = useState(null)
  


    // Screenshot and preview upload tracking (globally)
    const updateUploadStatus = (appId, collectionId, languageCode, deviceSize, status) => {
      setRegistryArray(prevUploadAreas => {
        const newUploadAreas = new Map(prevUploadAreas);
        newUploadAreas.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`, status);
        return newUploadAreas;
      });
    };
    const updateDeleteStatus = (appId, collectionId, languageCode, deviceSize, status) => {
      setRegistryArray(prevUploadAreas => {
        const newDeleteAreas = new Map(prevUploadAreas);
        newDeleteAreas.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, status);
        return newDeleteAreas;
      });
    };

    const updatePreviewUploadStatus = (appId, collectionId, languageCode, deviceSize, status) => {
      setRegistryPreviewArray(prevUploadAreas => {
        const newUploadAreas = new Map(prevUploadAreas);
        newUploadAreas.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`, status);
        return newUploadAreas;
      });
    };
    const updatePreviewDeleteStatus = (appId, collectionId, languageCode, deviceSize, status) => {
      setRegistryPreviewArray(prevUploadAreas => {
        const newDeleteAreas = new Map(prevUploadAreas);
        newDeleteAreas.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, status);
        return newDeleteAreas;
      });
    };


    return (
        <VariableContext.Provider 
            value={{
              sidebarToogle, setSidebarToogle,

              initialUserData,
              currentAppIcon,
              currentAppName,
              currentAppId,
              currentAppLocalizations,
              currentAppPrimaryLocale,
              currentAppCols,
              selectedAppColName,
              selectedAppColId,
              colDescriptions,
              colKeywords,
              colWhatsNews,
              collectionLocalizations,
              language,
              showDevices,
              internalRefreshSmall,
              internalRefreshBig,
              internalPreviewRefreshSmall,
              internalPreviewRefreshBig,
              currentAppVersion,
              currentAppRole,
              internalIpadRefreshSmall,
              internalIpadRefreshBig,
              internalIpadPreviewRefreshSmall,
              internalIpadPreviewRefreshBig,  
              // name,
              colScreenshots,
              setColScreenshots,
              colPrimaryScreenshots,
              setColPrimaryScreenshots,
              summary,
              setSummary,
              versionEdit,
              setVersionEdit,
              collectionNotLocalizations,
              setCollectionNotLocalizations,
              collectionScreenshotLocalizations,
              setCollectionScreenshotLocalizations,
              // setName,
              setInternalIpadRefreshBig, 
              setInternalIpadRefreshSmall,
              setInternalIpadPreviewRefreshBig, 
              setInternalIpadPreviewRefreshSmall,
              setCurrentAppRole, 
              setCurrentAppVersion,
              setCollectionLocalizations,
              setInternalRefreshSmall,
              setInternalRefreshBig,
              setInternalPreviewRefreshSmall,
              setInternalPreviewRefreshBig,
              setShowDevices,
              setLanguage,
              setCurrentAppLocalizations,
              setPrimaryLocale,
              setCurrentAppId,
              setCurrentAppIcon,
              setCurrentAppName,
              setInitialUserData,
              setCurrentAppCols,
              setSelectedAppColName,
              setSelectedAppColId,
              setColDescriptions,
              setColKeywords,
              setColWhatsNews,

              //app wide screenshot/preview upload tracking, collection tracking
              // percentage, setPercentage, percentagePreview, setPercentagePreview,
              registryArray, setRegistryArray, registryPreviewArray, setRegistryPreviewArray,
              appCol,

              //import tracking
              importArray, setImportArray,
              importPercentage, setImportPercentage,

              //export signal for screenshots and previews
              exportSignal ,setExportSignal,
              
              //createCol/renameCol popup/ get collection signal / import signal 
              getColList,
              setGetColList,

              //add delete Language signal
              languageSignal, setLanguageSignal,

              //add member popup /role signal
              getRoles, 
              setGetRoles,

              //version signal
              appInfoSignal, setAppInfoSignal,

              //upload
              isUploading, setIsUploading,//whole collection
              isUploadingDescription, setIsUploadingDescription,
              isUploadingKeyword, setIsUploadingKeyword,
              isUploadingWhatsnew, setIsUploadingWhatsnew,
              isUploadingPreview, setIsUploadingPreview,
              isUploadingPhoto, setIsUploadingPhoto,

              uploadMessageDescription, setUploadMessageDescription,
              uploadMessageKeyword, setUploadMessageKeyword,
              uploadMessageWhatsnew, setUploadMessageWhatsnew,
              uploadMessagePreview, setUploadMessagePreview,
              uploadMessagePhoto, setUploadMessagePhoto,
              uploadMessage, setUploadMessage,

              uploadJoinSignal, setUploadJoinSignal,
              socket, setSocket, uploadSignal, setUploadSignal, 
              lastUpload, setLastUpload, 
              summaryUpload, setSummaryUpload,


              //check these with every request
              appUploads, setAppUploads,
              subWarning, setSubWarning,

              //account limits
              quota, setQuota,
              //app limits
              currentAppQuota, setCurrentAppQuota,

              updateUploadStatus,
              updatePreviewUploadStatus,
              updateDeleteStatus,
              updatePreviewDeleteStatus,

              //appSumo codes
              specialCodes, setSpecialCodes,

              //subscription limits
              subscriptionLimits, setSubscriptionLimits,

              //toogleAll for edit textareas
              toogleAll, setToogleAll,
            }}>
            {children}
        </VariableContext.Provider>
    )
}
export default VariableContext
