import {useParams, useNavigate, Route, Routes} from 'react-router-dom'
import  io  from 'socket.io-client'
import axios from 'axios'
import { useEffect, useState, useContext} from 'react'
import VariableContext from '../userContext/VariableContext';
import './AppInfoPage.css'
import Spinner from '../Misc/Spinner'
import {sortResponse, fireRequestError, fireCustomError, fireCustomSuccessMessage, fireCustomErrorV2 } from '../Common Functions/commonFunctions';
import UploudProgressPopup from '../Popup/UploadProgressPopup';
import DescriptionPage from './DescriptionPage'
import WhatsNewPage from './WhatsNewPage'
import KeywordPage from './KeywordPage'
import ScreenshotPage from './ScreenshotPage'
import OwnerPage from './OwnerPage'
import NotFound404 from '../404/NotFound404';
import AddTeamMemberPopup from '../Popup/AddTeamMemberPopup';
import SummaryPopup from '../Popup/SummaryPopup';
import VersionPopup from '../Popup/VersionPopup';
import AppTeamMember from '../Components/AppTeamMember';
import Swal from 'sweetalert2';
import jurisdiction from '../Jusristiction/juristiction';
import { useLayoutEffect } from 'react';
import SpinnerSmall from '../Misc/SpinnerSmall';
import GoBackButton from '../Components/GoBackButton';
import * as gaEvents from '../ga-utils'
import Qmark2 from '../Misc/Qmark2';


function AppInfoPage() {
  const {initialUserData} = useContext(VariableContext)

  const {currentAppIcon, setCurrentAppIcon} = useContext(VariableContext)
  const {currentAppName, setCurrentAppName} = useContext(VariableContext)
  const {setCurrentAppId} = useContext(VariableContext)
  const {currentAppPrimaryLocale ,setPrimaryLocale} = useContext(VariableContext)
  const {currentAppRole, setCurrentAppRole} = useContext(VariableContext)

  const {subWarning} = useContext(VariableContext)

  const {setCurrentAppLocalizations} = useContext(VariableContext)
  const {setCurrentAppCols} = useContext(VariableContext)
  const {setCurrentAppVersion} = useContext(VariableContext)

  const {isUploadingDescription, setIsUploadingDescription} = useContext(VariableContext)
  const {isUploadingKeyword, setIsUploadingKeyword} = useContext(VariableContext)
  const {isUploadingWhatsnew, setIsUploadingWhatsnew} = useContext(VariableContext)
  const {isUploadingPhoto, setIsUploadingPhoto} = useContext(VariableContext)
  const {isUploadingPreview, setIsUploadingPreview} = useContext(VariableContext)
  const {isUploading ,setIsUploading} = useContext(VariableContext)

  const {uploadMessageDescription, setUploadMessageDescription} = useContext(VariableContext)
  const {uploadMessageKeyword, setUploadMessageKeyword} = useContext(VariableContext)
  const {uploadMessageWhatsnew, setUploadMessageWhatsnew} = useContext(VariableContext)
  const {uploadMessagePhoto, setUploadMessagePhoto} = useContext(VariableContext)
  const {uploadMessagePreview, setUploadMessagePreview} = useContext(VariableContext)
  const {uploadMessage, setUploadMessage} = useContext(VariableContext)

  // const {setLastUpload} =useContext(VariableContext)

  const {socket, setSocket} = useContext(VariableContext)
  const [varr, setVarr] = useState({status: '', arr: []})
  const {uploadSignal, setUploadSignal} = useContext(VariableContext)

  const {uploadJoinSignal, setUploadJoinSignal} = useContext(VariableContext)
  const {appUploads, setAppUploads} = useContext(VariableContext)

  const{getRoles, setGetRoles} = useContext(VariableContext)
  const{summary} = useContext(VariableContext)

  const{appInfoSignal} = useContext(VariableContext)
  const {versionEdit, setVersionEdit} = useContext(VariableContext)

  const [isWaiting, setIsWaiting] = useState(true)
  const [res, setRes] = useState('')
  const [roles,setRoles] =useState(null)
  // const [ownerCount, setOwnerCount] =useState(0)

  const {subscriptionLimits} = useContext(VariableContext)

  let navigate = useNavigate()
  let {appId} = useParams()



  //sync app info
  useLayoutEffect(()=>{
    if(initialUserData){
      let app = initialUserData.roles.find((i)=>i.app_id+'' === appId)
      if(app){
        if(!app.icon){
          setCurrentAppIcon('/assets/Icons/AddAppImage.svg');
        }
        else{
          setCurrentAppIcon(app.icon);
        }
        setCurrentAppName(app.app);
        setCurrentAppId(app.app_id);
        setPrimaryLocale(app.primary_locale)
        setCurrentAppRole(app.role)
      }
      else{
        navigate('/')
      }
    }
  },[initialUserData])


  //get collections (themes)
  useLayoutEffect(()=>{
    var data = '';

    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/app/collection/${appId}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data : data
    };
    axios(config)
    .then(function (response) {
  

      setCurrentAppCols(response.data.data)
      // 
    })
    .catch(function (error) {
    if(error.message === 'Network Error'){
      fireCustomError('NETWORK ERROR. ')
    }else{
      fireRequestError(error)
    }

    });
  
  },[appId])


  useEffect(()=>{
    if(socket!==0){
      if(varr.status==='join')
      {
        if(socket.connected){
          
          join(varr.arr)
          setVarr({status: '', arr: []})
        }
        socket.off('connect')
        socket.on('connect', function() {

          socket.off('connect')
          socket.off('connect_error')
          socket.off('connect_failed')
          socket.off('reconnect_error')
          socket.off('reconnect_failed')
          socket.off('error')
          socket.off('disconnect')
          socket.off('join_error')
          socket.off('uploading')
          socket.off('uploading_error')
          socket.off('description_uploading')
          socket.off('description_error')
          socket.off('keyword_uploading')
          socket.off('keyword_error')
          socket.off('whats_new_uploading')
          socket.off('whats_new_error')
          socket.off('photo_uploading')
          socket.off('photo_error')
          socket.off('preview_uploading')
          socket.off('preview_error')

          join(varr.arr)
          setVarr({status: '', arr: []})
          
          
          
          socket.on('connect_error', function() {
            gaEvents.socketError("connect_error")
            setSocket(0)
            setIsUploadingDescription({status:false, appID: null, collectionID: null})
            setUploadMessageDescription([{status:0}])
            setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
            setUploadMessageWhatsnew([{status:0}])
            setIsUploadingKeyword({status:false, appID: null, collectionID: null})
            setUploadMessageKeyword([{status:0}])
            setIsUploadingPhoto({status:false, appID: null, collectionID: null})
            setUploadMessagePhoto([{status:0}])
            setIsUploading({status:false, appID: null, collectionID: null})
            setUploadMessage([{status:0}])
            fireCustomErrorV2('Socket connection error, try again later')
          })
          socket.on('connect_failed', function() {
            gaEvents.socketError("connect_failed")

            setSocket(0)
            setIsUploadingDescription({status:false, appID: null, collectionID: null})
            setUploadMessageDescription([{status:0}])
            setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
            setUploadMessageWhatsnew([{status:0}])
            setIsUploadingKeyword({status:false, appID: null, collectionID: null})
            setUploadMessageKeyword([{status:0}])
            setIsUploadingPhoto({status:false, appID: null, collectionID: null})
            setUploadMessagePhoto([{status:0}])
            setIsUploading({status:false, appID: null, collectionID: null})
            setUploadMessage([{status:0}])
            fireCustomErrorV2('Socket connection failed, try again later')
          })
          socket.on('reconnect_error', function() {
            gaEvents.socketError("reconnect_error")

            setSocket(0)
            setIsUploadingDescription({status:false, appID: null, collectionID: null})
            setUploadMessageDescription([{status:0}])
            setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
            setUploadMessageWhatsnew([{status:0}])
            setIsUploadingKeyword({status:false, appID: null, collectionID: null})
            setUploadMessageKeyword([{status:0}])
            setIsUploadingPhoto({status:false, appID: null, collectionID: null})
            setUploadMessagePhoto([{status:0}])
            setIsUploading({status:false, appID: null, collectionID: null})
            setUploadMessage([{status:0}])
            fireCustomErrorV2('Socket re-connection attempt error, try again later')
          })
          socket.on('reconnect_failed', function() {
            gaEvents.socketError("reconnect_failed")

            setSocket(0)
            setIsUploadingDescription({status:false, appID: null, collectionID: null})
            setUploadMessageDescription([{status:0}])
            setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
            setUploadMessageWhatsnew([{status:0}])
            setIsUploadingKeyword({status:false, appID: null, collectionID: null})
            setUploadMessageKeyword([{status:0}])
            setIsUploadingPhoto({status:false, appID: null, collectionID: null})
            setUploadMessagePhoto([{status:0}])
            setIsUploading({status:false, appID: null, collectionID: null})
            setUploadMessage([{status:0}])
            fireCustomErrorV2('Socket re-connection failed, try again later')
          })
          socket.on('error', function() {
            gaEvents.socketError("error")

            setSocket(0)
            setIsUploadingDescription({status:false, appID: null, collectionID: null})
            setUploadMessageDescription([{status:0}])
            setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
            setUploadMessageWhatsnew([{status:0}])
            setIsUploadingKeyword({status:false, appID: null, collectionID: null})
            setUploadMessageKeyword([{status:0}])
            setIsUploadingPhoto({status:false, appID: null, collectionID: null})
            setUploadMessagePhoto([{status:0}])
            setIsUploading({status:false, appID: null, collectionID: null})
            setUploadMessage([{status:0}])
            fireCustomErrorV2('Socket connection error, refresh the page')
          })
          socket.on('disconnect', function() {
            
          })
        })


      }
      else{}
    }
  },[varr,socket])


  const join = (check) =>{

    if(check.find((i)=>i.type==='all') && isUploading.status===false){
      setIsUploading({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='all').collection_id})

      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='all').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("Collection")

        setUploadMessage([{status:0}])
        setIsUploading({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        
        socket.disconnect()
        setSocket(0)
      });
      socket.on('uploading', (data) => {
        setUploadMessage([data])
        
        if(data.status==='done'){
          socket.disconnect()
          setSocket(0)
       }
      });
      socket.on('uploading_error', (data) => {
        setUploadMessage([{status:0}])
        setIsUploading({status:false, appID: null, collectionID: null})
        
        socket.disconnect() 
        setSocket(0)
        fireCustomErrorV2(data.message)
      });
    }
    else if (check.find((i)=>i.type==='all') && isUploading.status==='initiated'){
      setIsUploading({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='all').collection_id})
      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='all').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("Collection")

        setUploadMessage([{status:0}])
        setIsUploading({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        
        socket.disconnect()
        setSocket(0)
      });
      socket.on('uploading', (data) => {
        setUploadMessage([data])
        
        if(data.status==='done'){
          socket.disconnect()
          setSocket(0)
       }
     });
     socket.on('uploading_error', (data) => {
      setUploadMessage([{status:0}])
      setIsUploading({status:false, appID: null, collectionID: null})
      
      socket.disconnect()
      setSocket(0)
      fireCustomErrorV2(data.message)
     });
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        position: 'center',
        width: '400',
        text: "There is another upload progress for this collection, please wait for it to finish.",
        backdrop: true,
        showConfirmButton: true,
        showCancelButton: false,     
        confirmButtonColor: '#cf000fe6',    
        confirmButtonText: 'OK',
        allowOutsideClick: false,      
      })
    }
    else if(jurisdiction.upload.includes(currentAppRole) && isUploading.status==='initiated'){
      setIsUploading({status:true, appID: isUploading.appID, collectionID: isUploading.collectionID})
      socket.emit('start_upload', 
      {
        "appId":appId,
        "collectionId":isUploading.collectionID
          
      })
      socket.on('uploading', (data) => {
        setUploadMessage([data])
        
        if(data.status==='done'){
          gaEvents.uploadFinished("Collection")
          socket.disconnect()
          setSocket(0)
       }
     });
     socket.on('uploading_error', (data) => {
      setUploadMessage([{status:0}])
      setIsUploading({status:false, appID: null, collectionID: null})
      
      socket.disconnect()
      setSocket(0)
      fireCustomErrorV2(data.message)
      gaEvents.uploadError("Collection")
    });
    }







    if(check.find((i)=>i.type==='description') && isUploadingDescription.status===false){
      setIsUploadingDescription({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='description').collection_id})

      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='description').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("Description")

        setUploadMessageDescription([{status:0}])
        setIsUploadingDescription({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        // 
      });
      socket.on('description_uploading', (data) => {
        setUploadMessageDescription([data])
        // 
        if(data.status !== 'done'){
          // setUploadMessageDescription([data])
          // 
        }        else{
          socket.off('description_uploading')
        }
     });
     socket.on('description_error', (data) => {
      setUploadMessageDescription([{status:0}])
      setIsUploadingDescription({status:false, appID: null, collectionID: null})
      // 
      fireCustomErrorV2(data.message)
    });
    }
    else if(check.find((i)=>i.type==='description') && isUploadingDescription.status==='initiated'){
      setIsUploadingDescription({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='description').collection_id})
      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='description').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("Description")

        setUploadMessageDescription([{status:0}])
        setIsUploadingDescription({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        // 
      });
      socket.on('description_uploading', (data) => {
        setUploadMessageDescription([data])
        
        if(data.status !== 'done'){
          // setUploadMessageDescription([data])
          // 
        } else{
          socket.off('description_uploading')
        }
     });
     socket.on('description_error', (data) => {
      setUploadMessageDescription([{status:0}])
      setIsUploadingDescription({status:false, appID: null, collectionID: null})
      // 
      fireCustomErrorV2(data.message)
    });
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        position: 'center',
        width: '400',
        text: "There is another upload progress for this collection's description fields, please wait for it to finish.",
        backdrop: true,
        showConfirmButton: true,
        showCancelButton: false,     
        confirmButtonColor: '#cf000fe6',    
        confirmButtonText: 'OK',
        allowOutsideClick: false,      
      })
    }
    else if(jurisdiction.upload.includes(currentAppRole) && isUploadingDescription.status === 'initiated'){
      setIsUploadingDescription({status:true, appID: isUploadingDescription.appID, collectionID: isUploadingDescription.collectionID})

      socket.emit('start_description_upload', 
      {
        "appId":appId,
        "collectionId":isUploadingDescription.collectionID
          
      })
      socket.on('description_uploading', (data) => {
        setUploadMessageDescription([data])
        // 
        if(data.status !== 'done'){

        }
        else{
          gaEvents.uploadFinished("Description")
 

          socket.off('description_uploading')
        }
     });
     socket.on('description_error', (data) => {
      setUploadMessageDescription([{status:0}])
      setIsUploadingDescription({status:false, appID: null, collectionID: null})
      // 
      fireCustomErrorV2(data.message)
      gaEvents.uploadError("Description")

    });
    }







    if(check.find((i)=>i.type==='keyword') && isUploadingKeyword.status===false){
      setIsUploadingKeyword({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='keyword').collection_id})

      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='keyword').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("Keyword")

        setUploadMessageKeyword([{status:0}])
        setIsUploadingKeyword({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        
      });
      socket.on('keyword_uploading', (data) => {
        setUploadMessageKeyword([data])
        
        if(data.status !== 'done'){
          // setUploadMessageKeyword([data])
          
        }else{
          socket.off('keyword_uploading')
        }
      });
      socket.on('keyword_error', (data) => {
        setUploadMessageKeyword([{status:0}])
        setIsUploadingKeyword({status:false, appID: null, collectionID: null})
        
        fireCustomErrorV2(data.message)
      });
      
    }
    else if(check.find((i)=>i.type==='keyword') && isUploadingKeyword.status==='initiated'){
      setIsUploadingKeyword({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='keyword').collection_id})
      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='keyword').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("Keyword")

        setUploadMessageKeyword([{status:0}])
        setIsUploadingKeyword({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        
      });
      socket.on('keyword_uploading', (data) => {
        setUploadMessageKeyword([data])
        
        if(data.status !== 'done'){
          // setUploadMessageKeyword([data])
          
        }else{
          socket.off('keyword_uploading')
        }
     });
     socket.on('keyword_error', (data) => {
      setUploadMessageKeyword([{status:0}])
      setIsUploadingKeyword({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
    });
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        position: 'center',
        width: '400',
        text: "There is another upload progress for this collection's keyword fields, please wait for it to finish.",
        backdrop: true,
        showConfirmButton: true,
        showCancelButton: false,     
        confirmButtonColor: '#cf000fe6',    
        confirmButtonText: 'OK',
        allowOutsideClick: false,      
      })
    }
    else if(jurisdiction.upload.includes(currentAppRole) && isUploadingKeyword.status === 'initiated'){
      setIsUploadingKeyword({status:true, appID: isUploadingKeyword.appID, collectionID: isUploadingKeyword.collectionID})

      socket.emit('start_keyword_upload', 
      {
        "appId":appId,
        "collectionId":isUploadingKeyword.collectionID
          
      })
      socket.on('keyword_uploading', (data) => {
        setUploadMessageKeyword([data])
        
        if(data.status !== 'done'){
          // setUploadMessageKeyword([data])
          
        }else{
          gaEvents.uploadFinished("Keyword")

          socket.off('keyword_uploading')
        }
     });
     socket.on('keyword_error', (data) => {
      setUploadMessageKeyword([{status:0}])
      setIsUploadingKeyword({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
      gaEvents.uploadError("Keyword")

    });
    }






    if(check.find((i)=>i.type==='whatsNew') && isUploadingWhatsnew.status===false){
      setIsUploadingWhatsnew({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='whatsNew').collection_id})

      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='whatsNew').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("WhatsNew")

        setUploadMessageWhatsnew([{status:0}])
        setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        
      });
      socket.on('whats_new_uploading', (data) => {
        if(data.status !== 'done'){
          // setUploadMessageWhatsnew([data])
          
        }else{
          socket.off('whats_new_uploading')
        }
     });
     socket.on('whatsNew_error', (data) => {
      setUploadMessageWhatsnew([{status:0}])
      setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
    });
    }
    else if(check.find((i)=>i.type==='whatsNew') && isUploadingWhatsnew.status==='initiated'){
      setIsUploadingWhatsnew({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='whatsNew').collection_id})
      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='whatsNew').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("WhatsNew")

        setUploadMessageWhatsnew([{status:0}])
        setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        
      });
      socket.on('whats_new_uploading', (data) => {
        setUploadMessageWhatsnew([data])
        
        if(data.status !== 'done'){
          // setUploadMessageWhatsnew([data])
          
        }else{
          socket.off('whats_new_uploading')
        }
     });
     socket.on('whatsNew_error', (data) => {
      setUploadMessageWhatsnew([{status:0}])
      setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
    });
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        position: 'center',
        width: '400',
        text: "There is another upload progress for this collection's whatsNew fields, please wait for it to finish.",
        backdrop: true,
        showConfirmButton: true,
        showCancelButton: false,     
        confirmButtonColor: '#cf000fe6',    
        confirmButtonText: 'OK',
        allowOutsideClick: false,      
      })
    }
    else if(jurisdiction.upload.includes(currentAppRole) && isUploadingWhatsnew.status === 'initiated'){
      setIsUploadingWhatsnew({status:true, appID: isUploadingWhatsnew.appID, collectionID: isUploadingWhatsnew.collectionID})

      socket.emit('start_whats_new_upload', 
      {
        "appId":appId,
        "collectionId":isUploadingWhatsnew.collectionID
          
      })
      socket.on('whats_new_uploading', (data) => {
        setUploadMessageWhatsnew([data])
        
        if(data.status !== 'done'){
          // setUploadMessageWhatsnew([data])
          
        }else{
          gaEvents.uploadFinished("WhatsNew")
          socket.off('whats_new_uploading')
        }
     });
     socket.on('whatsNew_error', (data) => {
      setUploadMessageWhatsnew([{status:0}])
      setIsUploadingWhatsnew({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
      gaEvents.uploadError("WhatsNew")
    });
    }







    if(check.find((i)=>i.type==='photo') && isUploadingPhoto.status===false){
      setIsUploadingPhoto({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='photo').collection_id})

      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='photo').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("Screenshot")

        setUploadMessagePhoto([{status:0}])
        setIsUploadingPhoto({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        
      });
      socket.on('photo_uploading', (data) => {
        setUploadMessagePhoto([data])

        if(data.status !== 'done'){
          // setUploadMessagePhoto([data])
          
        }else{
          socket.off('photo_uploading')
        }
     });
     socket.on('photo_error', (data) => {
      setUploadMessagePhoto([{status:0}])
      setIsUploadingPhoto({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
    });
    }
    else if(check.find((i)=>i.type==='photo') && isUploadingPhoto.status==='initiated'){
      setIsUploadingPhoto({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='photo').collection_id})
      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='photo').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("Screenshot")

        setUploadMessagePhoto([{status:0}])
        setIsUploadingPhoto({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        
      });
      socket.on('photo_uploading', (data) => {
        setUploadMessagePhoto([data])

        if(data.status !== 'done'){
          // setUploadMessagePhoto([data])
          
        }else{
          socket.off('photo_uploading')
        }
     });
     socket.on('photo_error', (data) => {
      setUploadMessagePhoto([{status:0}])
      setIsUploadingPhoto({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
    });
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        position: 'center',
        width: '400',
        text: "There is another upload progress for this collection's screenshot fields, please wait for it to finish.",
        backdrop: true,
        showConfirmButton: true,
        showCancelButton: false,     
        confirmButtonColor: '#cf000fe6',    
        confirmButtonText: 'OK',
        allowOutsideClick: false,      
      })
    }
    else if(jurisdiction.upload.includes(currentAppRole) && isUploadingPhoto.status === 'initiated'){
      setIsUploadingPhoto({status:true, appID: isUploadingPhoto.appID, collectionID: isUploadingPhoto.collectionID})

      socket.emit('start_photo_upload', 
      {
        "appId":appId,
        "collectionId":isUploadingPhoto.collectionID
          
      })
      socket.on('photo_uploading', (data) => {
        setUploadMessagePhoto([data])
        
        if(data.status !== 'done'){
          // setUploadMessagePhoto([data])
          
        }else{
          gaEvents.uploadFinished("Screenshot")
          socket.off('photo_uploading')
        }
     });
     socket.on('photo_error', (data) => {
      setUploadMessagePhoto([{status:0}])
      setIsUploadingPhoto({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
      gaEvents.uploadError("Screenshot")
    });
    }







    if(check.find((i)=>i.type==='preview') && isUploadingPreview.status===false){
      setIsUploadingPreview({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='preview').collection_id})

      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='preview').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("Preview")

        setUploadMessagePreview([{status:0}])
        setIsUploadingPreview({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        
      });
      socket.on('preview_uploading', (data) => {
        setUploadMessagePreview([data])

        if(data.status !== 'done'){
          // setUploadMessagePreview([data])
          
        }else{
          socket.off('preview_uploading')
        }
     });
     socket.on('preview_error', (data) => {
      setUploadMessagePreview([{status:0}])
      setIsUploadingPreview({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
    });
    }
    else if(check.find((i)=>i.type==='preview') && isUploadingPreview.status==='initiated'){
      setIsUploadingPreview({status:true, appID: +appId, collectionID: +check.find((i)=>i.type==='preview').collection_id})
      socket.emit('join_upload', 
      {
          "attemptId":check.find((i)=>i.type==='preview').attempt_id,
          
      })
      socket.on('join_error', (data) => {
        gaEvents.joinError("Preview")
        setUploadMessagePreview([{status:0}])
        setIsUploadingPreview({status:false, appID: null, collectionID: null})
        fireCustomErrorV2(data.message)
        
      });
      socket.on('preview_uploading', (data) => {
        setUploadMessagePreview([data])

        if(data.status !== 'done'){
          // setUploadMessagePreview([data])
          
        }else{
          socket.off('preview_uploading')
        }
     });
     socket.on('preview_error', (data) => {
      setUploadMessagePreview([{status:0}])
      setIsUploadingPreview({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
    });
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        position: 'center',
        width: '400',
        text: "There is another upload progress for this collection's screenshot fields, please wait for it to finish.",
        backdrop: true,
        showConfirmButton: true,
        showCancelButton: false,     
        confirmButtonColor: '#cf000fe6',    
        confirmButtonText: 'OK',
        allowOutsideClick: false,      
      })
    }
    else if(jurisdiction.upload.includes(currentAppRole) && isUploadingPreview.status === 'initiated'){
      setIsUploadingPreview({status:true, appID: isUploadingPreview.appID, collectionID: isUploadingPreview.collectionID})

      socket.emit('start_preview_upload', 
      {
        "appId":appId,
        "collectionId":isUploadingPreview.collectionID
          
      })
      socket.on('preview_uploading', (data) => {
        setUploadMessagePreview([data])
        
        if(data.status !== 'done'){
          // setUploadMessagePreview([data])
          
        }else{
          gaEvents.uploadFinished("Preview")
          socket.off('preview_uploading')
        }
     });
     socket.on('preview_error', (data) => {
      setUploadMessagePreview([{status:0}])
      setIsUploadingPreview({status:false, appID: null, collectionID: null})
      
      fireCustomErrorV2(data.message)
      gaEvents.uploadError("Preview")
    });
    }








};

  useEffect(()=>{
    if(socket!==0){
      if((uploadMessageDescription[0].status==='done' || uploadMessageDescription[0].status===0) && (uploadMessageWhatsnew[0].status==='done' || uploadMessageWhatsnew[0].status===0) && (uploadMessageKeyword[0].status==='done' || uploadMessageKeyword[0].status===0) && (uploadMessagePhoto[0].status==='done' || uploadMessagePhoto[0].status===0) && (uploadMessagePreview[0].status==='done' || uploadMessagePreview[0].status===0)){
          socket.disconnect()
          setSocket(0)
    }}
  },[uploadMessageDescription, uploadMessageKeyword, uploadMessagePhoto, uploadMessageWhatsnew, uploadMessagePreview])

  //updated app info
  useEffect(()=>{
      setIsWaiting(true)
      var config = {
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/app/${appId}`,
          headers: { 
              'Authorization': `Bearer ${localStorage.getItem("token")}`
          }
        };
        axios(config)
        .then(function (response) {
          setRes(response.data.data)  
          setCurrentAppLocalizations(sortResponse(response.data.data))
          setCurrentAppVersion(response.data.data.attributes.versionString)
          setIsWaiting(false)

        })
        .catch(function (error) {
          if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            fireRequestError(error)
          }
          setCurrentAppIcon('/assets/Icons/AddAppImage.svg');
          setCurrentAppName('Select Application');
          setCurrentAppId(0);
          setPrimaryLocale(null)
          setCurrentAppRole(null)

        });
    },[appId, appInfoSignal])

    //upload start join
    useEffect(()=>{

      
      if(jurisdiction.upload.includes(currentAppRole) && uploadSignal){
      
      setUploadSignal(0)

          if((isUploading.status === 'initiated' || isUploading.status === true) && isUploading.appID !== +appId){
            setIsUploading({status: false, appID:null, collectionID: null})
            setUploadMessage([{status:0}])
          }
          if((isUploadingDescription.status === 'initiated' || isUploadingDescription.status === true) && isUploadingDescription.appID !== +appId){
            setIsUploadingDescription({status: false, appID:null, collectionID: null})
            setUploadMessageDescription([{status:0}])
          }
          if((isUploadingKeyword.status === 'initiated' || isUploadingKeyword.status === true) && isUploadingKeyword.appID !== +appId){
            setIsUploadingKeyword({status: false, appID:null, collectionID: null})
            setUploadMessageKeyword([{status:0}])
          }
          if((isUploadingWhatsnew.status === 'initiated' || isUploadingWhatsnew.status === true) && isUploadingWhatsnew.appID !== +appId){
            setIsUploadingWhatsnew({status: false, appID:null, collectionID: null})
            setUploadMessageWhatsnew([{status:0}])
          }
          if((isUploadingPhoto.status === 'initiated' || isUploadingPhoto.status === true) && isUploadingPhoto.appID !== +appId){
            setIsUploadingPhoto({status: false, appID:null, collectionID: null})
            setUploadMessagePhoto([{status:0}])
          }

          if((isUploadingPreview.status === 'initiated' || isUploadingPreview.status === true) && isUploadingPreview.appID !== +appId){
            setIsUploadingPreview({status: false, appID:null, collectionID: null})
            setUploadMessagePreview([{status:0}])
          }
          let check = appUploads.filter((i)=>(i.status==='active')&&(i.app_id=== +appId))
          
      
          if(socket===0){
              
              setSocket(io(`${process.env.REACT_APP_API_URL}?token=${localStorage.getItem("token")}`, { transports: ["websocket"] }))
          }
          setVarr({status: 'join', arr: check})
      
  }},[uploadSignal])


  useEffect(()=>{
    

        if((isUploading.status === 'initiated' || isUploading.status === true) && isUploading.appID !== +appId){
          setIsUploading({status: false, appID:null, collectionID: null})
          setUploadMessage([{status:0}])
        }
        if((isUploadingDescription.status === 'initiated' || isUploadingDescription.status === true) && isUploadingDescription.appID !== +appId){
          setIsUploadingDescription({status: false, appID:null, collectionID: null})
          setUploadMessageDescription([{status:0}])
        }
        if((isUploadingKeyword.status === 'initiated' || isUploadingKeyword.status === true) && isUploadingKeyword.appID !== +appId){
          setIsUploadingKeyword({status: false, appID:null, collectionID: null})
          setUploadMessageKeyword([{status:0}])
        }
        if((isUploadingWhatsnew.status === 'initiated' || isUploadingWhatsnew.status === true) && isUploadingWhatsnew.appID !== +appId){
          setIsUploadingWhatsnew({status: false, appID:null, collectionID: null})
          setUploadMessageWhatsnew([{status:0}])
        }
        if((isUploadingPhoto.status === 'initiated' || isUploadingPhoto.status === true) && isUploadingPhoto.appID !== +appId){
          setIsUploadingPhoto({status: false, appID:null, collectionID: null})
          setUploadMessagePhoto([{status:0}])
        }

        if((isUploadingPreview.status === 'initiated' || isUploadingPreview.status === true) && isUploadingPreview.appID !== +appId){
          setIsUploadingPreview({status: false, appID:null, collectionID: null})
          setUploadMessagePreview([{status:0}])
        }

        let check = appUploads.filter((i)=>(i.status==='active')&&(i.app_id=== +appId))
        
    
        if(socket===0 && check.length>0){
            
            setSocket(io(`${process.env.REACT_APP_API_URL}?token=${localStorage.getItem("token")}`, { transports: ["websocket"] }))
        }
        setVarr({status: 'join', arr: check})
      
      
    },[appId, uploadJoinSignal, appUploads])


    //get team
    useEffect(()=>{
      if ((window.location.pathname === `/${appId}/`)|| (window.location.pathname === `/${appId}`)){
        
      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/role/${appId}`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      };
      
      axios(config)
      .then(function (response) {
        setRoles(response.data.data)
        // setOwnerCount(response.data.data.filter((i)=>i.role==='owner').length)
      })
      .catch(function (error) {
        if(error.message === 'Network Error'){
          fireCustomError('NETWORK ERROR. ')
        }else{
          fireRequestError(error)
        }
      });}
    },[window.location.pathname, appId, getRoles])


    const addMemberPopup = () =>{
      if(jurisdiction.role.includes(currentAppRole)){
        document.getElementById('addMemberPopup').style.display= 'flex';
        document.getElementById('addMemberForm').focus()
      }
    }

    const deleteApp = () =>{
      if(jurisdiction.delete_app.includes(currentAppRole)){
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        position: 'center',
        width: '400',
        text: 'You are about to delete this app from your account. You can not take this action back! ',
        backdrop: true,
        showConfirmButton: true,
        showCancelButton: true,     
        confirmButtonColor: '#cf000fe6',
        cancelButtonColor: '#3A6FF8e6',
        cancelButtonText: 'Cancel',      
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,     
        preConfirm: async () => {
            var data = JSON.stringify({
                "appId": appId,
              });
              
              var config = {
                method: 'delete',
                url: `${process.env.REACT_APP_API_URL}/app`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                    'Content-Type': 'application/json'
                },
                data : data
              };
              
            try {
                const response = await axios(config);
                if(response.status === 200){
                    gaEvents.deleteAppSuccess()
                    fireCustomSuccessMessage('App deleted');
                    navigate(`/`)
                }
            } catch (error) {
              gaEvents.deleteAppFail()
              if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        })
      }
    }

    const newVersion = () =>{
      setVersionEdit(1)
    }
    const editVersion = () =>{
      setVersionEdit(2)
    }




  return (
    <>
      <Routes>

        <Route
          exact
          path="/"
          element={
            <div className="appInfo-content">
              
              <div className="app-title-div">

                <div className="app-info-icon">
                  <GoBackButton/>
                  <img src={currentAppIcon} alt="Applications" />
                  <h1>{currentAppName}</h1>
                </div>
              </div>
              <div className="appInfo-inner-container">
                <div className="appInfo-inner-content">

                {subWarning ? 
                    subWarning.status==="expiring" ?
                        jurisdiction.role.includes(currentAppRole) ?
                            <span className='sub-warning' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                        : 
                            <span className='sub-warning' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner's subscription is expiring.</span>
                    : subWarning.status==="banned" ?
                        jurisdiction.role.includes(currentAppRole) ?
                            <span className='sub-warning' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                        : 
                            <span className='sub-warning' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner's subscription has expired. This application is read only.</span>
                    :     
                      ''
                :
                    ''
                }

                {subscriptionLimits ? subscriptionLimits.unavailableApps.includes(+appId) ? 
                  jurisdiction.role.includes(currentAppRole) ? 
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />You have more applications than what your account type permits. Some of the features concerning this application are disabled.</span>
                  :
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner of this application has more applications than what their account type permits. Some of the features concerning this application are disabled.</span>
                : 
                  ""
                : 
                  ""
                }

                  <div style={{display: 'flex', flexDirection:'column', padding:'33px 46px', backgroundColor:'#fff', borderRadius:'10px'}}>
                    <div className='add-member'>
                      <h2>App Information</h2>
                      <div style={{display:'flex', flexWrap:'wrap'}}>
                        {jurisdiction.version.includes(currentAppRole)&&!isWaiting ? (
                          
                          <button
                            onClick={newVersion}
                            className="add-member-button"
                          >
                            New Version
                          </button>
                        ) : (
                          ""
                        )}
                        {jurisdiction.version.includes(currentAppRole) ?  !isWaiting ? (
                          
                          <button
                            onClick={editVersion}
                            className="delete-app-button"
                          >
                            Edit Version Number
                          </button>
                        ): "" : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="app-data" style={{margin: '33px 0 0 0'}}>
                      
                      <div>
                        <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/version.svg" alt="versionIcon" />
                        <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                          <strong>Version:{" "}
                          {!isWaiting ? (
                            <>{res.attributes.versionString+' '+res.attributes.appStoreState+" "}<Qmark2 link="https://doc.storesyncer.com/get-started/edit-or-create-new-app-version" /></>
                          ) : (
                            <SpinnerSmall />
                          )}
                          </strong>
                        </p>
                      </div>

                      <div>
                        <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/platform.svg" alt="platformIcon" />
                        <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                          <strong>Platform: IOS</strong> 
                        </p>
                      </div>

                      <div>
                        <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/primaryLocal.svg" alt="primaryLocalIcon" />
                        <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                          <strong>Primary Local: {currentAppPrimaryLocale}</strong>
                        </p>
                      </div>

                    </div>
                  </div>

                  <div style={{display: 'flex', flexDirection:'column', padding:'33px 46px', backgroundColor:'#fff', borderRadius:'10px'}}>
                    <div className="add-member">
                      <h2>App Team</h2>
                      <div style={{display:'flex', flexWrap:'wrap'}}>
                        {jurisdiction.role.includes(currentAppRole)&&(initialUserData ? initialUserData.account_type!=='free' : false ) ? (
                          <button
                            onClick={addMemberPopup}
                            className="add-member-button"
                          >
                            Add Member
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>


                    {subscriptionLimits ? subscriptionLimits.currentApp.unavailableMembers.length>0 ? 
                      jurisdiction.role.includes(currentAppRole) ?
                        <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0', marginTop:'25px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />You have more team members than what your account type permits. Some of the features concerning team members are disabled.</span>
                      :
                        <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0', marginTop:'25px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />There are more team members than what owner's account type permits. Some of the features concerning team members are disabled.</span>
                    : 
                      ""
                    : 
                      ""
                    }


                    <p style={{fontWeight:'700', fontSize:'16px', lineHeight:'21px', color:'#676767', padding:'5px 0'}}>
                      {initialUserData ? `${initialUserData.first_name} ${initialUserData.last_name}` : ''}, you are the{" "}
                      <strong>
                        <i style={{color:'#0f044c'}}>{currentAppRole}</i>
                      </strong>{" "}
                      of this app.
                    </p>
                    <div className="appInfo-team-list">
                      {!roles ? (
                        <Spinner />
                      ) : (
                        roles.map((data, index) => {
                          return (
                            <AppTeamMember
                              key={index}
                              data={data}
                              index={index}
                              // ownerCount={ownerCount}
                            />
                          );
                        })
                      )}
                    </div>
                  </div>
                  {jurisdiction.delete_app.includes(currentAppRole) ? (
                    <button
                      onClick={deleteApp}
                      className="delete-app-button del"
                      style={{alignSelf:'flex-end'}}
                    >
                      Delete App
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          }
        ></Route>

        <Route
          exact
          path="/owner/*"
          element={
            jurisdiction.upload.includes(currentAppRole) ? (
              <OwnerPage />
            ) : (
              <NotFound404 />
            )
          }
        ></Route>

        <Route
          exact
          path="/description/*"
          element={
            jurisdiction.description.includes(currentAppRole) ? (
              <DescriptionPage />
            ) : (
              <NotFound404 />
            )
          }
        ></Route>

        <Route
          exact
          path="/screenshot/*"
          element={
            jurisdiction.screenshots.includes(currentAppRole) ? (
              <ScreenshotPage />
            ) : (
              <NotFound404 />
            )
          }
        ></Route>

        <Route
          exact
          path="/whatsnew/*"
          element={
            jurisdiction.whatsnew.includes(currentAppRole) ? (
              <WhatsNewPage />
            ) : (
              <NotFound404 />
            )
          }
        ></Route>

        <Route
          exact
          path="/keyword/*"
          element={
            jurisdiction.keyword.includes(currentAppRole) ? (
              <KeywordPage />
            ) : (
              <NotFound404 />
            )
          }
        ></Route>

        <Route exact path="*" element={<NotFound404 />}></Route>
      </Routes>

      {isUploadingDescription.status ||
      isUploadingKeyword.status ||
      isUploadingWhatsnew.status ||
      isUploadingPhoto.status ||
      isUploadingPreview.status ||
      isUploading.status ? (
        <UploudProgressPopup />
      ) : (
        ""
      )}

      <AddTeamMemberPopup />
      {summary ? <SummaryPopup /> : ""}
      {versionEdit&&!isWaiting ? <VersionPopup id={res.id}/> : ""}
    </>
  );
}

export default AppInfoPage