import {useLayoutEffect, useContext, useState} from 'react'
import { NavLink } from 'react-router-dom';
import VariableContext from '../userContext/VariableContext';
import './GetStartedPage.css'
import Qmark2 from '../Misc/Qmark2';
import VideoPopup from '../Popup/VideoPopup';

function GetstartedPage() {
  const {initialUserData} = useContext(VariableContext)

  const {setCurrentAppIcon} = useContext(VariableContext)
  const {setCurrentAppName} = useContext(VariableContext)
  const {setCurrentAppId} = useContext(VariableContext) 
  const {setPrimaryLocale} = useContext(VariableContext)
  const {setCurrentAppRole} = useContext(VariableContext) 
  
  const [searchTerm, setSearchTerm] = useState('')
  const [showVideo, setShowVideo] = useState(false)

 
  useLayoutEffect(() => {
    if(initialUserData){
      setCurrentAppIcon('/assets/Icons/AddAppImage.svg');
      setCurrentAppName('Select Application');
      setCurrentAppId(0);
      setPrimaryLocale(null)
      setCurrentAppRole(null)
    }
  },[initialUserData])

  const searchApp = (e) =>{
    setSearchTerm(e.target.value.toLowerCase())
    
    for (let i = 0; i<document.getElementsByClassName('welcome-app').length; i++){
      if(document.getElementsByClassName('welcome-app')[i].innerHTML.toLowerCase().indexOf(e.target.value.toLowerCase())<0){
        document.getElementsByClassName('welcome-app')[i].style.display= 'none'
      }
      else{
        document.getElementsByClassName('welcome-app')[i].style.display= 'flex'
      }
    }
  }

  //popup open
  const openPopup = () => {
    document.getElementById('addAppPopup').style.display = 'flex'
    document.getElementById('sideMenu').classList.toggle("invisible-scroll");
  }

  const openVideo = () => {
    setShowVideo(true)
  }
  const closeVideo = () => {
    setShowVideo(false)
  }
  

  return (
    <div className="inner-content">

        <div className='white-div titleArea'>
            <h1>Welcome!</h1>
            <div style={{display:'flex', position:'relative'}}>
              <input onChange={searchApp} value={searchTerm} className='search-app' type='text' placeholder='Search app' max={30}/>
              <img style={{position:'absolute', left:'85%', top:'25%'}} src="/assets/Icons/Search.svg" alt="search" />
            </div>
        </div>

        {initialUserData ? initialUserData.accountSubsStatus.status!=='active' ?
                <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'calc(100% - 70px)', margin:'0px 0 0 35px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{initialUserData.accountSubsStatus.message}</span>
            : 
                ''
            : 
                ''
        }

        <div className='your-apps'>

            <h2>Your Applications</h2>


            {initialUserData ? 
                initialUserData.roles.length !==0 ?
                <span className='welcome-message' style={{ wordBreak:'break-word'}}>Select an app from either here or the side menu on the left and get started.&nbsp;&nbsp;<Qmark2 link='https://doc.storesyncer.com/get-started/add-application-to-storesyncer'/></span> 

                : 
                <>
                    <span className='welcome-message' style={{ wordBreak:'break-word'}}>You have not added any applications yet. You can use "Add App" button below to add an application.&nbsp;&nbsp;<Qmark2 link='https://doc.storesyncer.com/get-started/add-application-to-storesyncer'/></span>
                    <button onClick={openVideo} className='tutorial-button'>How to Add an Application?</button>
                </>
                : ''}

            
            
            <div className='welcome-apps'>

              {initialUserData ? 
                initialUserData.roles.length !==0 ?
                initialUserData.roles.map((app, index)=>{
                return(
                  <NavLink to={`/${app.app_id}`} key={index} className='welcome-app'>
                    <img className='app-icon shadow' src={app.icon ? app.icon : '/assets/Icons/AddAppImage.svg' } alt="appIcon" />
                    <span className='app-name' style={{color:'#0f044c', fontSize:'18px'}}>{app.app}</span>
                  </NavLink>
                )
              }) : '' : ''}


              <img onClick={openPopup} className='app-icon addApp' style={{cursor:'pointer'}}  src={'/assets/Icons/Add-App.svg' } alt="plus" />

            </div>


        </div>

        {showVideo ? 
          <VideoPopup url='https://www.youtube.com/embed/pIFaH7TDC74' func={closeVideo}/>
        :
          ''
        }

    </div> 
  )
}

export default GetstartedPage