import { useEffect } from 'react'
import {useState} from 'react'
import './OwnerLocalItem.css'

function CollectionOwnerLocalItem({data, func, count, class_name}) {

  return (
    <span style={{padding: '15px'}} onClick={()=>{func(data.language_code, data.locale_name)}} className={class_name}>{data.locale_name}&nbsp;&nbsp;{count===0 ? <span className='current-owner-primary'>Primary</span> : ''}</span>
  )
}

export default CollectionOwnerLocalItem