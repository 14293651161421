import {useState} from 'react'
import SpinnerSmall from '../Misc/SpinnerSmall'
import axios from 'axios'
import './AddAppPopup.css'
import { fireRequestError, fireSuccessMessage, fireCustomError } from '../Common Functions/commonFunctions'
import {useNavigate } from 'react-router-dom'
import Qmark from '../Misc/Qmark'
import * as gaEvents from '../ga-utils'

function AddAppPopup() {
    let navigate = useNavigate()

    const[creatingApp, setCreatingApp] =useState(false)
    const[newAppId, setNewAppId] = useState('')
    const[privateKey, setPrivateKey] = useState('')
    const[issuerId, setIssuerId] = useState('')
    const[APIkey, setAPIkey] = useState('')

  

    //popup close
    const closePopup = () => {
        document.getElementById('addAppPopup').style.display = 'none'
        document.getElementById('sideMenu').classList.toggle("invisible-scroll");

        setNewAppId('')
    }

    //popup create app inputs
    const handleAppIdChange = (e) =>{
        setNewAppId(e.target.value)
    }
    const handlePrivateKeyChange = (e) =>{
        setPrivateKey(e.target.value)
    }
    const handleIssuerIdChange = (e) =>{
        setIssuerId(e.target.value)
    }
    const handleAPIkeyChange = (e) =>{
        setAPIkey(e.target.value)
    }

    //popup app create request and close popup
    const createAppE = (e) =>{ 
        e.preventDefault()
        setCreatingApp(true)
        var data = JSON.stringify({
        "appId": newAppId,
        "privateKey": privateKey,
        "apiKey": APIkey,
        "issuerId": issuerId
        });
        
        var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/app`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)        

        .then(function (response) {
            gaEvents.addAppSuccess()
            fireSuccessMessage(response)

            setCreatingApp(false)
            setAPIkey('')
            setIssuerId('')
            setPrivateKey('')
            setNewAppId('')
            document.getElementById('addAppPopup').style.display = 'none'
            navigate(`/${newAppId}`)

        })
        .catch(function (error) {
            gaEvents.addAppFail()
            document.getElementById('addAppPopup').style.display = 'none'
            setCreatingApp(false)
            setAPIkey('')
            setIssuerId('')
            setPrivateKey('')
            setNewAppId('')
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
        });  
    }



    return (
        <div id='addAppPopup' className='add-popup-container'>
            <div className='addPopupBack' onClick={creatingApp ? ()=>{} : ()=>{closePopup()}}></div>
            <div className='add-popup-content'>
                <span className="add-popup-name">Add New App <Qmark link='https://doc.storesyncer.com/get-started/add-application-to-storesyncer'/></span>

                <form className='create-app-form' onSubmit={createAppE}>

                    <input required={true} id='addAppPopupForm' onChange={handleAppIdChange} className="add-popup-form" type="number" min={0} max={9999999999} value={newAppId} placeholder='App ID' maxLength="10"/>

                    <input required={true} id='addAppPopupForm2' onChange={handleAPIkeyChange} className="add-popup-form" type="text" value={APIkey} placeholder='Key ID'/>

                    <input required={true} id='addAppPopupForm3' onChange={handleIssuerIdChange} className="add-popup-form" type="text" value={issuerId} placeholder='Issuer ID'/>

                    <textarea required={true} id='addAppPopupForm4' onChange={handlePrivateKeyChange} className="add-popup-form" rows={4} value={privateKey} 
                    placeholder={
                        `-----BEGIN PRIVATE KEY-----\nXXXXXXXXXXXXXXXXXXXXXXXXX\nXXXXXXXXXXXXXXXXXXXXXXXXX\nXXXXXXXXXXXXXXXXXXXXXXXXX\nXXXXXXXXXXX\n-----END PRIVATE KEY-----`
                    }/>

                    <input type='submit' hidden={true} disabled={creatingApp}/>

                    <div className="addPopupButtons">
                        <button disabled={creatingApp} type='submit' className="add-popup-button">
                            {!creatingApp ? 'Add' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>

                        <span disabled={creatingApp} onClick={!creatingApp ? ()=>closePopup() : ()=>{}} className="add-popup-button2 popupCancel">
                            Cancel 
                        </span>

                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddAppPopup