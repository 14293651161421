import {useNavigate, Link} from 'react-router-dom'
import {useState} from 'react'
import './LoginPage.css'
import axios from 'axios'
import VariableContext from '../userContext/VariableContext';
import { useContext, useLayoutEffect } from 'react';
import {fireRequestError, fireCustomError} from '../Common Functions/commonFunctions';
import ForgotPassword from '../Popup/ForgotPassword';
import * as gaEvents from "../ga-utils"


function LoginPage() {
    //validate token and get user info
    useLayoutEffect(()=>{
      if(localStorage.getItem("token")){
        var config = {
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/auth/validate?code_usage=true`,
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          }
        };
        
        axios(config)
        .then(function (response) {
          setInitialUserData(response.data.data)
          localStorage.setItem("token", response.data.data.token)
          navigate(`/`)
        })
        .catch(function (error) {
          if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            // fireRequestError(error)
          }
        });
      }  
    },[])

    const {setInitialUserData} = useContext(VariableContext)
    const [isWaiting, setIsWaiting] = useState(false)
    const [forgot, setForgot] = useState(false)

    let navigate = useNavigate()

    let count = 2


//Form Elements
    const [emailInput, setEmailInput] = useState('')
    const [passwordInput, setPasswordInput] = useState('')

//Form Error Messages States
    const [emailErrorText, setEmailErrorText] = useState('')
    const [passwordErrorText, setPasswordErrorText] = useState('')

//Empty error Message

    const handleEmailChange = (e) =>{
        setEmailInput(e.target.value)
        setEmailErrorText('')
        count=2
    }
 

    const handlePasswordChange = (e) =>{
        setPasswordInput(e.target.value)
        setPasswordErrorText('')
        count=2
    }
      
    
    const handleSubmit = (e) => {
        e.preventDefault()
        if(count===2){
            if(!passwordInput){
                setPasswordErrorText('Please enter a valid password')
            } else {count=count-1}
            if(!emailInput){
                setEmailErrorText('Please Enter a valid email adress')
            } else {count=count-1}
            if (count===0){
                setIsWaiting(true)

                var data = JSON.stringify({
                    "email": emailInput,
                    "password": passwordInput
                  });
                  
                  var config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}/auth/signIn`,
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then(function (response) {
                    gaEvents.signInSuccess()
                    setIsWaiting(false)
                    setInitialUserData(response.data.data)
                    localStorage.setItem("token", response.data.data.token)
                    navigate(`/`)
                  })
                  .catch(function (error) {
                    gaEvents.signInFail()
                    if(error.message === 'Network Error'){
                      fireCustomError('NETWORK ERROR. ')
                    }else{
                      fireRequestError(error)
                    }
                    
                    setIsWaiting(false)
                  });
            }                  
        }
    }


    const forgotPassword = (e) =>{
      setForgot(true)
    }
    const func = () =>{
      setForgot(false)
    }

  return (
    <div className='login-container'>
        <div className="login-container-left"></div>

        <div className="login-container-right">
            <div className="login-right-formContainer">

                <div className="title-div">
                    <h2 style={{lineHeight:'normal'}}>Sign In to StoreSyncer</h2>
                    <p>New user? <Link to='/register'>Create a FREE account.</Link></p>
                </div>

                <form className="signup-form" onSubmit={handleSubmit}>

                    <div className='form-group'>
                        <div className="form-group-inner">
                            <label>Email</label>
                            <input
                            type="email"
                            className="form-input email"
                            onChange={handleEmailChange}
                            placeholder="email@example.com"
                            />
                        <p className='error-message'>{emailErrorText}</p>
                        </div>
                    </div>

                    <div className='form-group'>
                        <div className="form-group-inner">
                            <label>Password</label>
                            <input
                            type="password"
                            className="form-input password"
                            onChange={handlePasswordChange}
                            placeholder="••••••••"
                            />
                            <p className='error-message'>{passwordErrorText}</p>
                        </div>
                        
                    </div>                
                    <div onClick={forgotPassword} className='forgot-password'>Forgot Password</div>
                    <div className="form-button-group">
                        <button className='form-button' type="submit" >
                            {isWaiting ? <img style={{width :'25px', marginTop: '5px'}} src='/assets/Icons/spinner.gif' alt='Applications'/> : 'Sign In'}</button>
                    </div>
                    
                </form>
            </div>
        </div>
        {forgot ? 
            <ForgotPassword func={func}/> : ''
        }
    </div>
  )
}

export default LoginPage