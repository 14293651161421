import axios from 'axios';
import { useEffect, useContext } from 'react'
import VariableContext from './userContext/VariableContext';

function AxiosProvider() {

    //This component is for regulation axios interceptor. According to response and error results, custom errors, custom messages fire and context states are changed.

    const {setUploadJoinSignal} = useContext(VariableContext)
    const {appUploads, setAppUploads} = useContext(VariableContext)
    const {setSubWarning} = useContext(VariableContext)
    const {setCurrentAppQuota} = useContext(VariableContext)
    const {setSpecialCodes} = useContext(VariableContext)
    const {setSubscriptionLimits} = useContext(VariableContext)


    useEffect(() => {
        const resInterceptor =  function (response) {
 
            setUploadJoinSignal(false)//return to initial upload state
            // Do something with response data
            if(!response.data.appUploads){//upload check
              // do nothing
            }else if(response.data.appUploads.length>0){
                setAppUploads(response.data.appUploads)
                setUploadJoinSignal(true)
            }else{
              if(appUploads.length > 0){
                setAppUploads([])
                setUploadJoinSignal(true)
              }
            }



      
            if(!response.data.subscribeWarning){//subscription warning
      
            }else if(response.data.subscribeWarning.status!=="active"){
              setSubWarning(response.data.subscribeWarning)
            }
            else{
              setSubWarning(null)
            }



      
            if(!response.data.appQuota){//current app quata
      
            }else{
              setCurrentAppQuota(response.data.appQuota)
            }




            if(!response.data.data.special_codes){//appSumo codes
      
            }else{
              setSpecialCodes(response.data.data.special_codes)
            }




            if(!response.data.appQuotaWarning){//subscription limits
      
            }else{
              setSubscriptionLimits(response.data.appQuotaWarning)
            }

       
      
            return response;
          }



        const interceptor =  axios.interceptors.response.use(resInterceptor);
        //clean interceptor so it wont multiply
        return () => {axios.interceptors.response.eject(interceptor);}

    }, [])  

}

export default AxiosProvider