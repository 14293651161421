import { Route, Routes, useNavigate } from 'react-router-dom'
import Sidebar from '../Sections/Sidebar'
import AppInfoPage from './AppInfoPage'
import GetstartedPage from './GetstartedPage'
import './HomePage.css'
import axios from 'axios'
import { useEffect, useContext} from 'react'
import VariableContext from '../userContext/VariableContext';
import {fireCustomError, fireRequestError } from '../Common Functions/commonFunctions'
import ProfilePage from './ProfilePage';
import HelpCenter from './HelpCenter';
import Billing from './Billing'
import Quota from './Quota'


function HomePage() {
  let navigate = useNavigate()

  const {setInitialUserData} = useContext(VariableContext)
  const {sidebarToogle, setSidebarToogle} = useContext(VariableContext)


 
  //validate token and get user info
  useEffect(()=>{
    if(localStorage.getItem("token")){
      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/validate?code_usage=true`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      };
      axios(config)
      .then(function (response) {
        setInitialUserData(response.data.data)
        localStorage.setItem("token", response.data.data.token)
      })
      .catch(function (error) {
        if(error.message === 'Network Error'){
          fireCustomError('NETWORK ERROR. ')
        }else{
          fireRequestError(error)
        }
        navigate('/login');
      });
    }else {
      navigate('/login');
    }
  },[window.location.pathname])

  const closeSidebar = (e) =>{
    if(!document.getElementById('sideMenu').contains(e.target) && window.innerWidth<769){
      let sidebar = document.getElementById('sideMenu')
      let main = document.getElementById('app_content')
      let toogle = document.getElementById('toogle')
      let toogleIcon = document.getElementById('toogleIcon')
      let home = document.getElementById('home_page')
      let appNames = document.querySelectorAll('.side-menu h3')
      let appNames2 = document.querySelectorAll('.side-menu .selected-app-name')
      let appNames3 = document.querySelectorAll('.side-menu .app-name')
      let appNames4 = document.querySelectorAll('.side-menu .add-app-name')
      let icons = document.querySelectorAll('.sidebar-sub-item img')
      let logo = document.querySelector('.logoBig')
      let logo2 = document.querySelector('.logoSmall')
      let logoMain = document.querySelector('.logo')
      let indicator = document.querySelectorAll('.active-url-indicator')
      let ps = document.querySelectorAll('.sidebar-sub-item p')
      let selectIcon = document.getElementById('selectedAppButton')
      if(sidebarToogle===true){
          setSidebarToogle(false)
          sidebar.style.width = '90px'
          sidebar.style.minWidth = '90px'
          toogle.style.left = '90px'
          main.style.marginLeft = '90px'
          toogleIcon.style.transform = 'rotateY(180deg)';
          logo.style.display = 'none'
          logo2.style.display = 'block'
          logoMain.style.padding = '36px 18px'
          selectIcon.style.display = 'none'
          for(let i =0; i<appNames.length; i++){
            appNames[i].style.display='none'
          }
          for(let i =0; i<appNames2.length; i++){
            appNames2[i].style.display='none'
          }
          for(let i =0; i<appNames3.length; i++){
            appNames3[i].style.display='none'
          }
          for(let i =0; i<appNames4.length; i++){
            appNames4[i].style.display='none'
          }
          for(let i =0; i<icons.length; i++){
            icons[i].style.marginLeft='30px'
          }
          for(let i =0; i<indicator.length; i++){
            indicator[i].style.display='none'
          }
          for(let i =0; i<ps.length; i++){
            ps[i].style.visibility='hidden'
          }
      }
   }
}


  return (
    <div onClick={closeSidebar} id='home_page' className='homePage-container'>  
        <Sidebar/>
        <div id='app_content' className="content">
          <Routes>
              <Route exact path='/' element={
                <GetstartedPage/>
              }>
              </Route>
            <Route exact path='/profile' element={
              <ProfilePage/>
            }>
            </Route>
            <Route exact path='/help' element={
              <HelpCenter/>
            }>
            </Route>
            <Route exact path='/subscription' element={
              <Billing/>
            }>
            </Route>
            <Route exact path='/quota' element={
              <Quota/>
            }>
            </Route>
            <Route exact path='/:appId/*' element={
                <AppInfoPage/>
            }>
            </Route> 
          </Routes>    
        </div>
        
    </div> 
  )
}

export default HomePage