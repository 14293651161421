import './CollectionScreenshotItem.css'
import {useState, useLayoutEffect, useContext, useEffect} from 'react'
import axios from 'axios'
import Spinner from '../../../Misc/Spinner'
import SpinnerSmall from '../../../Misc/SpinnerSmall'
import { useParams } from 'react-router-dom'
import VariableContext from '../../../userContext/VariableContext'
import Swal from 'sweetalert2'
import { fireCustomErrorV2, fireRequestError, fireSuccessMessage, sleep, fireCustomError } from '../../../Common Functions/commonFunctions'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import jurisdiction from '../../../Jusristiction/juristiction'
import React from 'react'
import CollectionVideo from './CollectionVideo'
import * as gaEvents from "../../../ga-utils"

function CollectionScreenshotIpadItem({uniqueId, classId, languageCode, localizationId, deviceSize, size, screenshots, primaryLocaleCode, primaryLanguageName, primaryLocaleScreenshots, previews, primaryLocalePreviews, devicePreviewSize}) {

    const [slideOpen, setSlideOpen] = useState(false)

    const playerReference = React.useRef(null);
    const [videoPlay, setVideoPlay] = useState(null)
    const [forAllPreviews, setForAllPreviews] = useState(false)
    const [isWaitingTimeFrame, setIsWaitingTimeFrame] = useState(false)

    const [images, setImages] = useState([])
    const [imageURLs, setImageURLs] = useState([])

    const [previewsUp, setPreviewsUp] = useState([])
    const [previewURLs, setPreviewURLs] = useState([])

    const [isWaiting, setIsWaiting] = useState(false)
    const [isWaitingPreview, setIsWaitingPreview] = useState(false)

    const [dragActive, setDragActive] = useState(false);
    const [dragActivePreview, setDragActivePreview] = useState(false);

    // const {percentage, setPercentage} = useContext(VariableContext)
    // const {percentagePreview, setPercentagePreview} = useContext(VariableContext)
    const [percentagePreview, setPercentagePreview] = useState(new Map())
    const [percentage, setPercentage] = useState(new Map())

    const [screenshotSet, setScreenshotSet] = useState(screenshots.find((i)=>{return i.size === deviceSize}) || false)//global den alınıcak
    const [primaryScreenshotSet, setPrimaryScreenshotSet] = useState(primaryLocaleScreenshots.find((i)=>{return i.size === deviceSize}) || false)//global den alınıcak
    const [forOrder, setForOrder] = useState([])//global kopyası, useEffect [global]

    const [screenshotSetImg, setScreenshotSetImg] = useState(screenshots)//global
    const [primaryScreenshotSetImg, setPrimaryScreenshotSetImg] = useState(primaryLocaleScreenshots)//global

    const [previewSet, setPreviewSet] = useState(previews.find((i)=>{return i.size === devicePreviewSize}) || false)//global den alınıcak
    const [primaryPreviewSet, setPrimaryPreviewSet] = useState(primaryLocalePreviews.find((i)=>{return i.size === devicePreviewSize}) || false)//global den alınıcak
    const [forPreviewOrder, setForPreviewOrder] = useState([])//global kopyası, useEffect [global]

    const [previewSetImg, setPreviewSetImg] = useState(previews)//global
    const [primaryPreviewSetImg, setPrimaryPreviewSetImg] = useState(primaryLocalePreviews)//global

    const {internalIpadRefreshSmall, setInternalIpadRefreshSmall} = useContext(VariableContext)
    const {internalIpadRefreshBig, setInternalIpadRefreshBig} = useContext(VariableContext)
    const [internalIpadRefresh, setInternalIpadRefresh] = useState(false)
    const [testCase, setTestCase] = useState(0)
    const [turn, setTurn] = useState(0)

    const {internalIpadPreviewRefreshSmall, setInternalIpadPreviewRefreshSmall} = useContext(VariableContext)
    const {internalIpadPreviewRefreshBig, setInternalIpadPreviewRefreshBig} = useContext(VariableContext)
    const [internalIpadPreviewRefresh, setInternalIpadPreviewRefresh] = useState(false)
    const [testCasePreview, setTestCasePreview] = useState(0)
    const [turnPreview, setTurnPreview] = useState(0)
    
    const {isUploadingPhoto} = useContext(VariableContext)
    const {isUploadingPreview} = useContext(VariableContext)
    const {isUploading} = useContext(VariableContext)
    const {currentAppRole} = useContext(VariableContext)

    const {registryArray, setRegistryArray} = useContext(VariableContext)
    const {registryPreviewArray, setRegistryPreviewArray} = useContext(VariableContext)
    const {updateUploadStatus} = useContext(VariableContext)
    const {updatePreviewUploadStatus} = useContext(VariableContext)
    const {updateDeleteStatus} = useContext(VariableContext)
    const {updatePreviewDeleteStatus} = useContext(VariableContext)
    
    let {appId, collectionId} = useParams()
    
    let slideIndex=1;

    const errorMessage =
    {
        APP_IPAD_PRO_3GEN_129: 'The dimensions of one or more screenshots are wrong.<br>Screenshots dimensions should be: 2048x2732 2732x2048',
        APP_IPAD_PRO_3GEN_11: 'The dimensions of one or more screenshots are wrong.<br>Screenshots dimensions should be:<br>1488x2266 2266x1488 1668x2388 2388x1668 1640x2360 2360x1640',
        APP_IPAD_PRO_129: 'The dimensions of one or more screenshots are wrong.<br>Screenshots dimensions should be: 2048x2732 2732x2048',
        APP_IPAD_105: 'The dimensions of one or more screenshots are wrong.<br>Screenshots dimensions should be: 1668x2224 2224x1668',
        APP_IPAD_97: 'The dimensions of one or more screenshots are wrong.<br>Screenshots dimensions should be:<br>1536x2008 1536x2048 2048x1496 2048x1536 768x1004 768x1024 1024x748 1024x768'
    }

    const errorMessagePreview =
    {
        IPAD_PRO_3GEN_129: {resolution:'IPAD_PRO_3GEN_129. Preview dimensions should be: 1200x1600 1600x1200.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPAD_PRO_3GEN_129. The file size of one or more previews are bigger than 500MB.'},
        IPAD_PRO_3GEN_11: {resolution:'IPAD_PRO_3GEN_11. Preview dimensions should be: 1200x1600 1600x1200.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPAD_PRO_3GEN_11. The file size of one or more previews are bigger than 500MB.'},
        IPAD_PRO_129: {resolution:'IPAD_PRO_129. Preview dimensions should be: 1200x1600 1600x1200.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPAD_PRO_129. The file size of one or more previews are bigger than 500MB.'},
        IPAD_105: {resolution:'IPAD_105. Preview dimensions should be: 1200x1600 1600x1200.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPAD_105. The file size of one or more previews are bigger than 500MB.'},
        IPAD_97: {resolution:'IPAD_97. Preview dimensions should be: 900x1200 1200x900.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPAD_97. The file size of one or more previews are bigger than 500MB.'},
    }

    //are there any active uploads from input fields or deletions?
    useLayoutEffect(()=>{
        if(registryArray.get(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`)){
            setIsWaiting(true)
        }
        else if(registryArray.get(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`)){
            setIsWaiting(true)
        }
        if(registryPreviewArray.get(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-upload`)){
            setIsWaitingPreview(true)
        }
        else if(registryPreviewArray.get(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`)){
            setIsWaitingPreview(true)
        } 
    },[])

    //change order arrays, for handleOnDragEnd and handleOnDragEndPreview
    useLayoutEffect(()=>{
        if(screenshotSet !== false){
            setForOrder(screenshotSet.screenshots)}
        if(previewSet !== false){
            setForPreviewOrder(previewSet.previews)}
    },[screenshotSet, previewSet])


/////////////////////////////////////////////////////////////
///////////     OPERATIONS FOR SCREENSHOTS      ////////////
/////////////////////////////////////////////////////////////
    useEffect(()=>{
        if(slideOpen===true){
            window.addEventListener('keydown', arrowKeys)
        }
        else{
            window.removeEventListener('keydown', arrowKeys)
        }
        return(()=>
            window.removeEventListener('keydown', arrowKeys)
        )
    },[slideOpen])


    //slide functions for ipad screenshots
    function openLightbox(key) {
        document.getElementById(`lightbox${key}`).style.display = 'block';
        document.getElementById(`lightbox${key}`).focus()
        setSlideOpen(true)
    }
      
    function closeSlide(e, key){
        if(e.target.id ==='next' || e.target.id ==='previous' || e.target.className === 'collection-screenshots-slide'){

        }else{
            closeLightbox(key)
        }
    }

    function closeLightbox(key) {
        setSlideOpen(false)
        document.getElementById(`lightbox${key}`).style.display = 'none';
    }

    function arrowKeys (e){
        if (e.keyCode === 37) {
            e.preventDefault()
            // left arrow
            changeSlide(-1,uniqueId)
         }
         else if (e.keyCode === 39) {
            e.preventDefault()
            // right arrow
            changeSlide(1,uniqueId)
         }  
    }

    function changeSlide(n, key) {
        showSlide(slideIndex += n, key);
    }
    
    function toSlide(n, key) {
        showSlide(slideIndex = n, key);
    }

    function showSlide(n, key) {

        const slides = document.getElementsByClassName(`slide${key}`);

        if (n > slides.length) {
            slideIndex = 1;	
          }

        if (n < 1) {
            slideIndex = slides.length;
        }
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = 'block';
    }

    //change order of screenshots
    function handleOnDragEnd(result) {
        if(jurisdiction.screenshots.includes(currentAppRole)){
            if (!result.destination) return;

            const items = Array.from(forOrder);
            const [reorderedItem] = items.splice(result.source.index, 1);;
            items.splice(result.destination.index, 0, reorderedItem);
            if(isUploading.collectionID!== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId){
                setForOrder(items);
            }

            let order = []
            for (let i=0; i<items.length; i++){
                order.push(items[i].screenshot_id)
                if(i===items.length-1){
                    var data = JSON.stringify({
                        "appId": appId,
                        "collectionId": collectionId,
                        "localizationId": localizationId,
                        "size": deviceSize,
                        "idList": order
                    });
                    
                    var config = {
                        method: 'put',
                        url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/order`,
                        headers: { 
                        'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                        'Content-Type': 'application/json'
                        },
                        data : data
                    };
                    
                    axios(config)
                    .then(function (response) {
                        gaEvents.changeScreenshotOrderSuccess(deviceSize)
                        if(deviceSize==='APP_IPAD_PRO_129'){
                            setInternalIpadRefreshSmall(true)
                        }else if (deviceSize==='APP_IPAD_PRO_3GEN_129'){
                            setInternalIpadRefreshBig(true)
                        }
                        else{
                            setInternalIpadRefresh(true)
                        }
                    })
                    .catch(function (error) {
                        gaEvents.changeScreenshotOrderFail(deviceSize)

                        if(error.message === 'Network Error'){
                            fireCustomError('NETWORK ERROR. ')
                          }else{
                            fireRequestError(error)
                          }
                    });
                }
            }
        }else{
            fireCustomErrorV2('You do not have permission for this operation')
        }

        
    }

    //drag-drop upload
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if(isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !isWaiting && !isWaitingPreview){
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActive(true);
                } else if (e.type === "dragleave") {
                setDragActive(false);
                }
        }
    };

    //check uploaded screenshot limits and initiate tests for validation
    useLayoutEffect(()=>{
        if(images.length!==0){    
            if((images.length > (screenshotSet ? 10-(screenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.length): 10)) ){
                gaEvents.addScreenshotCountFail(deviceSize)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    position: 'center',
                    width: 'max-content',
                    text: 'There can be a maximum amount of 10 screenshots per device',
                    color: 'white',
                    background: 'red',
                    backdrop: true,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'OK',
                    cancelButtonColor: '#f27474',      
                })
                    setImages([])
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }       
                    return
                // error
            }else{

                const newImageUrls = []
                for (const img of images) {
                    newImageUrls.push(URL.createObjectURL(img))
                }
                setTestCase(0)
                setImageURLs(newImageUrls)
    }}},[images])

    //tests for screenshots
    useLayoutEffect(()=>{
        if(images.length !==0 && testCase===0){ 
            if((images.length> (screenshotSet ? 10-(screenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.length): 10)) ){
                gaEvents.addScreenshotCountFail(deviceSize)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    position: 'center',
                    width: 'max-content',
                    text: 'There can be a maximum amount of 10 screenshots per device',
                    color: 'white',
                    background: 'red',
                    backdrop: true,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'OK',
                    cancelButtonColor: '#f27474',
                })
                setImages([])
                if(document.getElementById('chooseFile'+uniqueId)){
                    document.getElementById('chooseFile'+uniqueId).value = ''
                }      
                return
            }else{
                setIsWaiting(true) 
                // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`, true)))
                updateUploadStatus(appId, collectionId, languageCode, deviceSize, true)
                var testFunction = ()=>{
                    var i = 0;
                    var checkNextImage = function () {
                        // Reached end of files, terminate
                        if (i >= imageURLs.length) return;
                        
                        // Create a new image element
                        let imag = new Image();
                
                        // Set the image source to the current file object
                        imag.src = imageURLs[i]
                
                        // Wait for the image to load before checking its size
                        imag.onload = function () {
                            // Get the width and height of the image
                            var width = this.naturalWidth;
                            var height = this.naturalHeight;
                
                            if (deviceSize ==='APP_IPAD_PRO_3GEN_129') {//12.9'' display requirements
                                if((height===2732  && width===2048 ) || (height===2048  && width===2732 )){
                                    setTestCase(current=>current+1)
                                    setTurn(i)                               
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)   
                                }
                            }else if(deviceSize ==='APP_IPAD_PRO_3GEN_11'){//11'' display requirements
                                if((height===2266  && width===1488 ) || (height===1488  && width===2266 ) || (height===2388  && width===1668 ) || (height===1668 && width===2388 ) || (height===2360   && width===1640  ) || (height===1640  && width===2360 )){
                                    setTestCase(current=>current+1)
                                    setTurn(i)                               
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)   
                                }
                            }else if(deviceSize ==='APP_IPAD_PRO_129'){//12.9'' old display requirements
                                if((height===2732  && width===2048 ) || (height===2048  && width===2732 )){
                                    setTestCase(current=>current+1)
                                    setTurn(i)                               
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)   
                                }
                            }else if(deviceSize ==='APP_IPAD_105'){//10.5'' display requirements
                                if((height===2224  && width===1668 ) || (height===1668  && width===2224 )){
                                    setTurn(i)
                                    setTestCase(current=>current+1) 
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)
                                }
                            }else if(deviceSize ==='APP_IPAD_97'){//9.7'' display requirements
                                if((height===2008  && width===1536 ) || (height===2048 && width===1536 ) || (height===1536 && width===2008 ) || (height===1496 && width===2008 ) || (height===1004 && width===768 ) || (height===1024 && width===768) || (height===748 && width===1024 ) || (height===768 && width===1024 )){
                                    setTurn(i)
                                    setTestCase(current=>current+1)     
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)
                                }
                            }
                            i++;
                            checkNextImage();
                        }   
                    }
                    checkNextImage();
                }
                testFunction()

    }}},[imageURLs])

    //when tests end
    useLayoutEffect(()=>{
        //teslerden en az biri başarısız
        if(testCase!==0 && turn===images.length-1 && testCase !== images.length){
            gaEvents.addScreenshotResFail(deviceSize)
            setIsWaiting(false)
            // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`, false)))
            updateUploadStatus(appId, collectionId, languageCode, deviceSize, false)

            setImages([])
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }       
            setTestCase(0)
            setTurn(0)

            Swal.fire({
                icon: 'error',
                title: 'Error!',
                position: 'center',
                width: 'max-content',
                html: ''+errorMessage[deviceSize],
                color: 'white',
                background: '#cf000f',
                backdrop: true,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'OK',
                cancelButtonColor: '#f27474',
                
            })
        }
        //tesler başarılı //upload isteği
        if(testCase === images.length && images.length!== 0){
            
            var dataForm = new FormData();
            dataForm.append('appId', appId);
            dataForm.append('collectionId', collectionId);
            dataForm.append('localizationId', localizationId);
            dataForm.append('size', deviceSize);
            for (const img of images) {
                dataForm.append('photo', img);
            }

            const onUploadProgress = (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                    setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,percent)));
                }
            };

            var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            data : dataForm,
            onUploadProgress
            };

            axios(config)
            .then((response)=>{
                gaEvents.addScreenshotSuccess(deviceSize)
                // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`, false)))
                updateUploadStatus(appId, collectionId, languageCode, deviceSize, false)

                if(deviceSize==='APP_IPAD_PRO_129'){
                    setInternalIpadRefreshSmall(true)
                }else if (deviceSize==='APP_IPAD_PRO_3GEN_129'){
                    setInternalIpadRefreshBig(true)
                }
                else{
                    setInternalIpadRefresh(true)
                }
                fireSuccessMessage(response)
            })
            .catch(function (error) {
                gaEvents.addScreenshotRequestFail(deviceSize)

                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
                setImages([])
                if(document.getElementById('chooseFile'+uniqueId)){
                    document.getElementById('chooseFile'+uniqueId).value = ''
                }      
                setIsWaiting(false)
                // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`, false)))
                updateUploadStatus(appId, collectionId, languageCode, deviceSize, false)

                setTestCase(0)
                setTurn(0)
                setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,0)));
                
            });
        }
    }, [testCase,turn])

    //refresh screenshots according to sizes
    const getScreenshots = async () =>{
    
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/array/${appId}/${collectionId}/${languageCode}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        };
        axios(config)
        .then((response)=>{
            setScreenshotSetImg(response.data.data.sizes)
            setScreenshotSet(response.data.data.sizes.find((i)=>{return i.size === deviceSize}) || false)
            if(languageCode === primaryLocaleCode){
                setPrimaryScreenshotSetImg(response.data.data.sizes)
                setPrimaryScreenshotSet(response.data.data.sizes.find((i)=>{return i.size === deviceSize}) || false)
            }
            setInternalIpadRefreshBig(false)
            setInternalIpadRefresh(false)
            setInternalIpadRefreshSmall(false)
            setImages([])       
            setTestCase(0)
            setTurn(0)
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }
            setIsWaiting(false)
            setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,0)));

        })
        .catch((error)=>{
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            setInternalIpadRefreshBig(false)
            setInternalIpadRefresh(false)
            setInternalIpadRefreshSmall(false)
            setImages([])       
            setTestCase(0)
            setTurn(0)
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }
            setIsWaiting(false)
            setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,0)));

        })
        
    }
    const getPriScreenshots = async () =>{
            if(languageCode !== primaryLocaleCode){
                var config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/array/${appId}/${collectionId}/${primaryLocaleCode}`,
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                };
                axios(config)
                .then((response)=>{
                    setPrimaryScreenshotSetImg(response.data.data.sizes)
                    setPrimaryScreenshotSet(response.data.data.sizes.find((i)=>{return i.size === deviceSize}) || false)
                    setInternalIpadRefreshBig(false)
                    setInternalIpadRefresh(false)
                    setInternalIpadRefreshSmall(false)
                    setImages([])       
                    setTestCase(0)
                    setTurn(0)

                    setIsWaiting(false)
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }
                    setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,0)));

    
                })
                .catch((error)=>{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                    setInternalIpadRefreshBig(false)
                    setInternalIpadRefresh(false)
                    setInternalIpadRefreshSmall(false)
                    setImages([])       
                    setTestCase(0)
                    setTurn(0)
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }
                    setIsWaiting(false)
                    setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,0)));

                })
            }

        
    }
    useLayoutEffect(()=>{
        if(internalIpadRefreshBig === true && size>3){
            Promise.all(
                [getScreenshots(), getPriScreenshots()]
            )
        }
        if(internalIpadRefreshSmall === true && size<=3){
            Promise.all(
                [getScreenshots(), getPriScreenshots()]
            )
        }
        if(internalIpadRefresh === true){
            Promise.all(
                [getScreenshots(), getPriScreenshots()]
            )
        }
    },[internalIpadRefresh, internalIpadRefreshBig, internalIpadRefreshSmall])

    //delete single screenshot
    const deleteScreenShot = (screenshots) =>{
        // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, true)))
        updateDeleteStatus(appId, collectionId, languageCode, deviceSize, true)
        setIsWaiting(true)
        var data = JSON.stringify({
            "appId": appId,
            "collectionId": collectionId,
            "screenshotId": screenshots.screenshot_id
          });
          
          var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem("token")}`, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            gaEvents.deleteScreenshotSuccess(deviceSize)
            // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, false)))
            updateDeleteStatus(appId, collectionId, languageCode, deviceSize, false)

            if(deviceSize==='APP_IPAD_PRO_129'){
                setInternalIpadRefreshSmall(true)
            }else if (deviceSize==='APP_IPAD_PRO_3GEN_129'){
                setInternalIpadRefreshBig(true)
            }
            else{
                setInternalIpadRefresh(true)
            }
            fireSuccessMessage(response)
          })
          .catch(function (error) {
            gaEvents.deleteScreenshotFail(deviceSize)
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, false)))
            updateDeleteStatus(appId, collectionId, languageCode, deviceSize, false)

            setIsWaiting(false)
          });
          
    }

    //delete all screenshots for a device
    const deleteAllScreenshots = () =>{
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            position: 'center',
            width: '400',
            text: 'All screenshots from this device will be deleted',
            backdrop: true,
            showConfirmButton: true,
            showCancelButton: true,     
            confirmButtonColor: '#cf000fe6',
            cancelButtonColor: '#3A6FF8e6',
            cancelButtonText: 'Cancel',      
            confirmButtonText: 'Delete',      
        }).then((result)=>{
            if (result.isConfirmed) {
                // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, true)))
                updateDeleteStatus(appId, collectionId, languageCode, deviceSize, true)

                setIsWaiting(true)
                var data = JSON.stringify({
                    "appId": appId,
                    "collectionId": collectionId,
                    "localizationId": localizationId,
                    "size": deviceSize
                  });
                
                  var config = {
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/allInSizes`,
                    headers: { 
                      'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                
                  axios(config)
                  .then(function (response) {
                    gaEvents.deleteAllScreenshotSuccess(deviceSize)
                    // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, false)))
                    updateDeleteStatus(appId, collectionId, languageCode, deviceSize, false)

                    if(deviceSize==='APP_IPAD_PRO_129'){
                        setInternalIpadRefreshSmall(true)
                    }else if (deviceSize==='APP_IPAD_PRO_3GEN_129'){
                        setInternalIpadRefreshBig(true)
                    }
                    else{
                        setInternalIpadRefresh(true)
                    }
                    fireSuccessMessage(response)
                  })
                  .catch(function (error) {
                    gaEvents.deleteAllScreenshotFail(deviceSize)

                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                    setIsWaiting(false)
                    // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, false)))
                    updateDeleteStatus(appId, collectionId, languageCode, deviceSize, false)

                  });
            }
        })
    }

/////////////////////////////////////////////////////////////
///////////   END OF OPERATIONS FOR SCREENSHOTS   ///////////
/////////////////////////////////////////////////////////////
























//////////////////////////////////////////////////////////
/////////////////OPERATIONS FOR PREVIEWS//////////////////
//////////////////////////////////////////////////////////

    //change order of previews
    function handleOnDragEndPreview(result) {
        if(jurisdiction.screenshots.includes(currentAppRole)){
            if (!result.destination) return;

            const items = Array.from(forPreviewOrder);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            if(isUploading.collectionID!== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId){
                setForPreviewOrder(items);
            }
    
            let order = []
            for (let i=0; i<items.length; i++){
                order.push(items[i].preview_id)
                if(i===items.length-1){
                    var data = JSON.stringify({
                        "appId": appId,
                        "collectionId": collectionId,
                        "localizationId": localizationId,
                        "size": devicePreviewSize,
                        "idList": order
                      });
                      
                      var config = {
                        method: 'put',
                        url: `${process.env.REACT_APP_API_URL}/app/collection/preview/order`,
                        headers: { 
                          'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                          'Content-Type': 'application/json'
                        },
                        data : data
                      };
                      
                      axios(config)
                      .then(function (response) {
                        gaEvents.changePreviewOrderSuccess(devicePreviewSize)
                        if(devicePreviewSize==='IPAD_PRO_129'){
                            setInternalIpadPreviewRefreshSmall(true)
                        }else if (devicePreviewSize==='IPAD_PRO_3GEN_129'){
                            setInternalIpadPreviewRefreshBig(true)
                        }
                        else{
                            setInternalIpadPreviewRefresh(true)
                        }
                      })
                      .catch(function (error) {
                        gaEvents.changePreviewOrderFail(devicePreviewSize)

                        if(error.message === 'Network Error'){
                            fireCustomError('NETWORK ERROR. ')
                          }else{
                            fireRequestError(error)
                          }
                      });
                }
            }
        }else{
            fireCustomErrorV2('You do not have permission for this operation')
        }

        
    }

    //drag-drop state for drop uploads
    const handleDragPreview = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if(isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !isWaitingPreview && !isWaiting){
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActivePreview(true);
                } else if (e.type === "dragleave") {
                setDragActivePreview(false);
                }
        }
    }

    //check uploaded preview limits and initiate tests for validation
    useLayoutEffect(()=>{
        if(previewsUp.length!==0){    
            if((previewsUp.length > (previewSet ? 3-(previewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.length): 3)) ){
                gaEvents.addPreviewCountFail(devicePreviewSize)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    position: 'center',
                    width: 'max-content',
                    text: 'There can be a maximum amount of 3 previews per device',
                    color: 'white',
                    background: 'red',
                    backdrop: true,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'OK',
                    cancelButtonColor: '#f27474',      
                })
                setImages([])
                if(document.getElementById('chooseFile'+uniqueId)){
                    document.getElementById('chooseFile'+uniqueId).value = ''
                }       
                return
                // error
            }else{

                const newPreviewUrls = []
                for (const pre of previewsUp) {
                    newPreviewUrls.push(URL.createObjectURL(pre))
                }
                setTestCasePreview(0)
                setPreviewURLs(newPreviewUrls)
    }}},[previewsUp])

    //tests for previews
    useLayoutEffect(()=>{
        if(previewsUp.length !==0 && testCasePreview===0){ 
            if((previewsUp.length> (previewSet ? 3-(previewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.length): 3)) ){
                gaEvents.addPreviewCountFail(devicePreviewSize)

                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    position: 'center',
                    width: 'max-content',
                    text: 'There can be a maximum amount of 3 previews per device',
                    color: 'white',
                    background: 'red',
                    backdrop: true,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'OK',
                    cancelButtonColor: '#f27474',
                    
                })
                setImages([])
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }       
                return
            }else{
                setIsWaitingPreview(true) 
                // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-upload`, true)));
                updatePreviewUploadStatus(appId, collectionId, languageCode, devicePreviewSize, true)

                var testFunction2 = ()=>{
                    var i = 0;
                    var checkNextPrev = function () {
                        // Reached end of files, terminate
                        if (i >= previewURLs.length) return;
                        
                        const prev = document.createElement('video');
                        prev.setAttribute("id", `video${i+uniqueId+classId}`);
                        prev.setAttribute("src", previewURLs[i]); 
                
                        // Wait for the image to load before checking its size
                        prev.addEventListener('loadedmetadata', function () {
                            // Get the width and height of the image
                            const height = +this.videoHeight;
                            const width = +this.videoWidth;
                            const duration = +this.duration
                
                            if (devicePreviewSize ==='IPAD_PRO_3GEN_129') {//12.9'' display requirements
                                if(((height===1600  && width===1200 ) || (height===1200  && width===1600 )) && ((duration>=15)&&(duration<=30))){
                                    setTestCasePreview(current=>current+1)
                                    setTurnPreview(i)                               
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)   
                                }
                            }else if(devicePreviewSize ==='IPAD_PRO_3GEN_11'){//11'' display requirements
                                if(((height===1600  && width===1200 ) || (height===1200  && width===1600 )) && ((duration>=15)&&(duration<=30))){
                                    setTestCasePreview(current=>current+1)
                                    setTurnPreview(i)                               
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)   
                                }
                            }else if(devicePreviewSize ==='IPAD_PRO_129'){//12.9'' old display requirements
                                if(((height===1600  && width===1200 ) || (height===1200  && width===1600 )) && ((duration>=15)&&(duration<=30))){
                                    setTestCasePreview(current=>current+1)
                                    setTurnPreview(i)                               
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)   
                                }
                            }else if(devicePreviewSize ==='IPAD_105'){//10.5'' display requirements
                                if(((height===1600  && width===1200 ) || (height===1200  && width===1600 )) && ((duration>=15)&&(duration<=30))){
                                    setTurnPreview(i)
                                    setTestCasePreview(current=>current+1) 
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)
                                }
                            }else if(devicePreviewSize ==='IPAD_97'){//9.7'' display requirements
                                if(((height===1200  && width===900 ) || (height===900  && width===1200 )) && ((duration>=15)&&(duration<=30))){
                                    setTurnPreview(i)
                                    setTestCasePreview(current=>current+1)     
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)
                                }
                            }
                            i++;
                            checkNextPrev();
                        }, true)   
                    }
                    checkNextPrev();
                }
                testFunction2()

                        // for(let i=0; i<previewURLs.length; i++){
                        //     const prev = document.createElement('video');
                        //     prev.setAttribute("id", `video${i+uniqueId+classId}`);
                        //     prev.setAttribute("src", previewURLs[i]);                    
                        //     prev.addEventListener('loadedmetadata', async function(e){
                        //         if(i>0){
                        //             await sleep(i*1000)
                        //         }
                        //         const height = +prev.videoHeight;
                        //         const width = +prev.videoWidth;
                        //         const duration = +prev.duration
                        //         if (devicePreviewSize ==='IPAD_PRO_3GEN_129') {//12.9'' display requirements
                        //             if(((height===1600  && width===1200 ) || (height===1200  && width===1600 )) && ((duration>=15)&&(duration<=30))){
                        //                 setTestCasePreview(current=>current+1)
                        //                 setTurnPreview(i)                               
                        //             }
                        //             else{
                        //                 setTestCasePreview(current=>current-1)
                        //                 setTurnPreview(i)   
                        //             }
                        //         }else if(devicePreviewSize ==='IPAD_PRO_3GEN_11'){//11'' display requirements
                        //             if(((height===1600  && width===1200 ) || (height===1200  && width===1600 )) && ((duration>=15)&&(duration<=30))){
                        //                 setTestCasePreview(current=>current+1)
                        //                 setTurnPreview(i)                               
                        //             }
                        //             else{
                        //                 setTestCasePreview(current=>current-1)
                        //                 setTurnPreview(i)   
                        //             }
                        //         }else if(devicePreviewSize ==='IPAD_PRO_129'){//12.9'' old display requirements
                        //             if(((height===1600  && width===1200 ) || (height===1200  && width===1600 )) && ((duration>=15)&&(duration<=30))){
                        //                 setTestCasePreview(current=>current+1)
                        //                 setTurnPreview(i)                               
                        //             }
                        //             else{
                        //                 setTestCasePreview(current=>current-1)
                        //                 setTurnPreview(i)   
                        //             }
                        //         }else if(devicePreviewSize ==='IPAD_105'){//10.5'' display requirements
                        //             if(((height===1600  && width===1200 ) || (height===1200  && width===1600 )) && ((duration>=15)&&(duration<=30))){
                        //                 setTurnPreview(i)
                        //                 setTestCasePreview(current=>current+1) 
                        //             }
                        //             else{
                        //                 setTestCasePreview(current=>current-1)
                        //                 setTurnPreview(i)
                        //             }
                        //         }else if(devicePreviewSize ==='IPAD_97'){//9.7'' display requirements
                        //             if(((height===1200  && width===900 ) || (height===900  && width===1200 )) && ((duration>=15)&&(duration<=30))){
                        //                 setTurnPreview(i)
                        //                 setTestCasePreview(current=>current+1)     
                        //             }
                        //             else{
                        //                 setTestCasePreview(current=>current-1)
                        //                 setTurnPreview(i)
                        //             }
                        //         }
                                
                        //     }, true)
                        // }
    }}},[previewURLs])

    //when tests end
    useLayoutEffect(()=>{
        //at least one test failed
        if(turnPreview===previewsUp.length-1 && testCasePreview !== previewsUp.length){
            gaEvents.addPreviewResFail(devicePreviewSize)

            setIsWaitingPreview(false)
            // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-upload`, false)))
            updatePreviewUploadStatus(appId, collectionId, languageCode, devicePreviewSize, false)

            setPreviewsUp([])
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }       
            setTestCasePreview(0)
            setTurnPreview(0)

            Swal.fire({
                icon: 'error',
                title: 'Error!',
                position: 'center',
                width: 'max-content',
                html: ''+errorMessagePreview[devicePreviewSize].resolution,
                color: 'white',
                background: '#cf000f',
                backdrop: true,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'OK',
                cancelButtonColor: '#f27474',
                
            })
        }
        //all tests are passed
        if(testCasePreview === previewsUp.length && previewsUp.length!== 0){

            var dataForm = new FormData();
            dataForm.append('appId', appId);
            dataForm.append('collectionId', collectionId);
            dataForm.append('localizationId', localizationId);
            dataForm.append('size', devicePreviewSize);
            for (const pre of previewsUp) {
                dataForm.append('preview', pre);
            }

            const onUploadProgress = (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                    setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,percent)));
                }
              };

            var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/preview`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            data : dataForm,
            onUploadProgress
            };

            axios(config)
            .then((response)=>{
                gaEvents.addPreviewSuccess(devicePreviewSize)
                // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-upload`, false)))
                updatePreviewUploadStatus(appId, collectionId, languageCode, devicePreviewSize, false)

                if(devicePreviewSize==='IPAD_PRO_129'){
                    setInternalIpadPreviewRefreshSmall(true)
                }else if (devicePreviewSize==='IPAD_PRO_3GEN_129'){
                    setInternalIpadPreviewRefreshBig(true)
                }
                else{
                    setInternalIpadPreviewRefresh(true)
                }
                fireSuccessMessage(response)
            })
            .catch(function (error) {
                gaEvents.addPreviewRequestFail(devicePreviewSize)

                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
                setPreviewsUp([])
                if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                }       
                setIsWaitingPreview(false)
                // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-upload`, false)))
                updatePreviewUploadStatus(appId, collectionId, languageCode, devicePreviewSize, false)

                setTestCasePreview(0)
                setTurnPreview(0)
                setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,0)));
                
            });
        }
    }, [testCasePreview,turnPreview])

    //refresh previews according to sizes
    const getPreviews = async () =>{
        
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/preview/array/${appId}/${collectionId}/${languageCode}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        };
        axios(config)
        .then((response)=>{
            setPreviewSetImg(response.data.data.sizes)
            setPreviewSet(response.data.data.sizes.find((i)=>{return i.size === devicePreviewSize}) || false)
            if(languageCode === primaryLocaleCode){
                setPrimaryPreviewSetImg(response.data.data.sizes)
                setPrimaryPreviewSet(response.data.data.sizes.find((i)=>{return i.size === devicePreviewSize}) || false)
            }
            setInternalIpadPreviewRefreshBig(false)
            setInternalIpadPreviewRefresh(false)
            setInternalIpadPreviewRefreshSmall(false)
            setPreviewsUp([])       
            setTestCasePreview(0)
            setTurnPreview(0)
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }
            setIsWaitingPreview(false)
            setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,0)));

        })
        .catch((error)=>{
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            setInternalIpadPreviewRefreshBig(false)
            setInternalIpadPreviewRefresh(false)
            setInternalIpadPreviewRefreshSmall(false)
            setPreviewsUp([])       
            setTestCasePreview(0)
            setTurnPreview(0)
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }
            setIsWaitingPreview(false)
            setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,0)));

        })
        
    }
    const getPriPreviews = async () =>{
            if(languageCode !== primaryLocaleCode){
                var config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}/app/collection/preview/array/${appId}/${collectionId}/${primaryLocaleCode}`,
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                };
                axios(config)
                .then((response)=>{
                    setPrimaryPreviewSetImg(response.data.data.sizes)
                    setPrimaryPreviewSet(response.data.data.sizes.find((i)=>{return i.size === deviceSize}) || false)
                    setInternalIpadPreviewRefreshBig(false)
                    setInternalIpadPreviewRefresh(false)
                    setInternalIpadPreviewRefreshSmall(false)
                    setPreviewsUp([])       
                    setTestCasePreview(0)
                    setTurnPreview(0)
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }
                    setIsWaitingPreview(false)
                    setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,0)));


                })
                .catch((error)=>{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                    setInternalIpadPreviewRefreshBig(false)
                    setInternalIpadPreviewRefresh(false)
                    setInternalIpadPreviewRefreshSmall(false)
                    setPreviewsUp([])       
                    setTestCasePreview(0)
                    setTurnPreview(0)
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }
                    setIsWaitingPreview(false)
                    setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,0)));


                })
            }

        
    }
    useLayoutEffect(()=>{
        if(internalIpadPreviewRefreshBig === true && size>3){
            Promise.all(
                [getPreviews(), getPriPreviews()]
            )
        }
        if(internalIpadPreviewRefreshSmall === true && size<=3){
            Promise.all(
                [getPreviews(), getPriPreviews()]
            )
        }
        if(internalIpadPreviewRefresh === true){
            Promise.all(
                [getPreviews(), getPriPreviews()]
            )
        }
    },[internalIpadPreviewRefresh, internalIpadPreviewRefreshBig, internalIpadPreviewRefreshSmall])

    //delete single preview
    const deletePreview = (previews) =>{
        // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, true)))
        updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, true)
        setIsWaitingPreview(true)
        var data = JSON.stringify({
            "appId": appId,
            "collectionId": collectionId,
            "previewId": previews.preview_id
          });
          
          var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/app/collection/preview`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem("token")}`, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            gaEvents.deletePreviewSuccess(devicePreviewSize)
            // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, false)))
            updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, false)

            if(devicePreviewSize==='IPAD_PRO_129'){
                setInternalIpadPreviewRefreshSmall(true)
            }else if (devicePreviewSize==='IPAD_PRO_3GEN_129'){
                setInternalIpadPreviewRefreshBig(true)
            }
            else{
                setInternalIpadPreviewRefresh(true)
            }
            fireSuccessMessage(response)
          })
          .catch(function (error) {
            gaEvents.deletePreviewFail(devicePreviewSize)
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, false)))
            updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, false)

            setIsWaitingPreview(false)
          });
          
    }

    //delete all previews for a device
    const deleteAllPreviews = () =>{
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            position: 'center',
            width: '400',
            text: 'All previews from this device will be deleted',
            backdrop: true,
            showConfirmButton: true,
            showCancelButton: true,     
            confirmButtonColor: '#cf000fe6',
            cancelButtonColor: '#3A6FF8e6',
            cancelButtonText: 'Cancel',      
            confirmButtonText: 'Delete',      
        }).then((result)=>{
            if (result.isConfirmed) {
                // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, true)))
                updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, true)

                setIsWaitingPreview(true)
                var data = JSON.stringify({
                    "appId": appId,
                    "collectionId": collectionId,
                    "localizationId": localizationId,
                    "size": devicePreviewSize
                  });
                
                  var config = {
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_URL}/app/collection/preview/allInSizes`,
                    headers: { 
                      'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                
                  axios(config)
                  .then(function (response) {
                    gaEvents.deleteAllPreviewSuccess(devicePreviewSize)
                    // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, false)))
                    updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, false)

                    if(devicePreviewSize==='IPAD_PRO_129'){
                        setInternalIpadPreviewRefreshSmall(true)
                    }else if (devicePreviewSize==='IPAD_PRO_3GEN_129'){
                        setInternalIpadPreviewRefreshBig(true)
                    }
                    else{
                        setInternalIpadPreviewRefresh(true)
                    }
                    fireSuccessMessage(response)
                  })
                  .catch(function (error) {
                    gaEvents.deleteAllPreviewFail(devicePreviewSize)
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                    setIsWaitingPreview(false)
                    // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, false)))
                    updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, false)

                  });
            }
        })
    }

    const playerReady = (player) => {
        playerReference.current = player;
        
        // handling video player
        player.on('waiting', () => {
        });
        player.on('dispose', () => {
        });
    }

    const oneSecondForward = () =>{
        if(playerReference.current){
            playerReference.current.pause()
            playerReference.current.currentTime(playerReference.current.currentTime()+1)
        } 
    }
    const oneSecondBack = () =>{
        if(playerReference.current){
            playerReference.current.pause()
            playerReference.current.currentTime(playerReference.current.currentTime()-1)
        } 
    }

    const previewSetPoster = (id) =>{
        setIsWaitingTimeFrame(true)
        if(!forAllPreviews){
            var data = JSON.stringify({
                "appId": appId,
                "previewId": id,
                "frameTime": 
                (()=>                                              
                    {if(playerReference.current.currentTime() < 10){ 
                        return '00:00:0'+playerReference.current.currentTime().toFixed(2).split(".")[0]+':'+(playerReference.current.currentTime().toFixed(2).split(".")[1].length===1 ? playerReference.current.currentTime().toFixed(2).split(".")[1]+'0' : playerReference.current.currentTime().toFixed(2).split(".")[1])}
                    else if(playerReference.current.currentTime() < 10 && playerReference.current.currentTime() ===0){
                        return '00:00:01:00'}
                    else
                        return '00:00:'+playerReference.current.currentTime().toFixed(2).split(".")[0]+':'+(playerReference.current.currentTime().toFixed(2).split(".")[1].length===1 ? playerReference.current.currentTime().toFixed(2).split(".")[1]+'0' : playerReference.current.currentTime().toFixed(2).split(".")[1])})()
            });
        }else{
            var data = JSON.stringify({
                "appId": appId,
                "collectionId": collectionId,
                "frameTime": 
                (()=>                                              
                    {if(playerReference.current.currentTime() < 10){ 
                        return '00:00:0'+playerReference.current.currentTime().toFixed(2).split(".")[0]+':'+(playerReference.current.currentTime().toFixed(2).split(".")[1].length===1 ? playerReference.current.currentTime().toFixed(2).split(".")[1]+'0' : playerReference.current.currentTime().toFixed(2).split(".")[1])}
                    else if(playerReference.current.currentTime() < 10 && playerReference.current.currentTime() ===0){
                        return '00:00:01:00'}
                    else
                        return '00:00:'+playerReference.current.currentTime().toFixed(2).split(".")[0]+':'+(playerReference.current.currentTime().toFixed(2).split(".")[1].length===1 ? playerReference.current.currentTime().toFixed(2).split(".")[1]+'0' : playerReference.current.currentTime().toFixed(2).split(".")[1])})()
            });
        }

        
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/preview/frameTimeCode`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then(function (response) {
            gaEvents.setPreviewPosterSuccess(devicePreviewSize)
            setTimeout(()=>{
                setIsWaitingPreview(true)
                if(!forAllPreviews){
                    if(devicePreviewSize==='IPAD_PRO_129'){
                        setInternalIpadPreviewRefreshSmall(true)
                    }else if (devicePreviewSize==='IPAD_PRO_3GEN_129'){
                        setInternalIpadPreviewRefreshBig(true)
                    }
                    else{
                        setInternalIpadPreviewRefresh(true)
                    }
                }else{
                    setInternalIpadPreviewRefreshSmall(true)
                    setInternalIpadPreviewRefreshBig(true)
                }

                setIsWaitingTimeFrame(false)
                setVideoPlay(null)
                setForAllPreviews(false)
                fireSuccessMessage(response)
            },2000)

        })
        .catch(function (error) {
            gaEvents.setPreviewPosterFail(devicePreviewSize)
            setIsWaitingTimeFrame(false)
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
        })
    }

/////////////////////////////////////////////////////////////////
/////////////////END OF OPERATIONS FOR PREVIEWS//////////////////
/////////////////////////////////////////////////////////////////
































/////////////////////////////////////////////////////////////
///////////             COMMON OPERATIONS         ///////////
/////////////////////////////////////////////////////////////


    //hide-show delete icon also any component is neeeded
    const makeVisible = (id) =>{
        document.getElementById(id).style.display= 'flex'
    }
    const makeInvisible = (id) =>{
        document.getElementById(id).style.display= 'none'
    }

    // triggers when an upload input is filled
    const onImageUpload = (e)=>{
        let i = 0;
        let imageArr = []
        let previewArr = []
        // 1.048.576
        if (e.target.files && e.target.files[0]) {

            for(; i<e.target.files.length; i++ ){
                if(e.target.files[i].type === "image/png" || e.target.files[i].type === "image/jpg" || e.target.files[i].type === "image/jpeg"){
                    imageArr.push(e.target.files[i])
                }
                else if(e.target.files[i].type === "video/mp4" || e.target.files[i].type === "video/mov" || e.target.files[i].type === "video/m4v"){
                    if(e.target.files[i].size / 1048576 <= 500){
                        previewArr.push(e.target.files[i])  
                    }
                    else{
                        gaEvents.addPreviewSizeFail(devicePreviewSize)
                        fireCustomErrorV2(e.target.files[i].name+' '+e.target.files[i].type+'.'+' '+'Size bigger than 500MB. Please make sure all previews are at most 500MB.')
                        document.getElementById('chooseFile'+uniqueId).value = ''
                        return
                    }
                }
                else{
                    fireCustomErrorV2(e.target.files[i].name+' '+e.target.files[i].type+'.'+' '+'Please make sure selected files are in correct format. Allowed formats are: image/png, image/jpg, image/jpeg, video/mp4, video/mov, video/m4v.')
                    document.getElementById('chooseFile'+uniqueId).value = ''
                    return
                }
                if(i===(e.target.files.length)-1){
                    setPreviewsUp([...previewArr])
                    setImages([...imageArr])
                }
            }
        }
    }

    // triggers when screenshots or previews or both are dropped, checks file format, displays error is wrong, else each file is sent to correct state for tests
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        let i = 0;
        let imageArr = []
        let previewArr = []
        if(isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !(isWaiting&&isWaitingPreview)){
            setDragActive(false);
            setDragActivePreview(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                for(; i<e.dataTransfer.files.length; i++ ){
                    if(e.dataTransfer.files[i].type === "image/png" || e.dataTransfer.files[i].type === "image/jpg" || e.dataTransfer.files[i].type === "image/jpeg"){
                        imageArr.push(e.dataTransfer.files[i])
                    }
                    else if(e.dataTransfer.files[i].type === "video/mp4" || e.dataTransfer.files[i].type === "video/mov" || e.dataTransfer.files[i].type === "video/m4v"){
                        if(e.dataTransfer.files[i].size / 1048576 <= 500){
                            previewArr.push(e.dataTransfer.files[i])  
                        }
                        else{
                            gaEvents.addPreviewSizeFail(devicePreviewSize)

                            fireCustomErrorV2(e.dataTransfer.files[i].name+' '+e.dataTransfer.files[i].type+'.'+' '+'Size bigger than 500MB. Please make sure all previews are at most 500MB.')
                            document.getElementById('chooseFile'+uniqueId).value = ''
                            return
                        }
                    }
                    else{
                        fireCustomErrorV2(e.dataTransfer.files[i].name+' '+e.dataTransfer.files[i].type+'.'+' '+'Please make sure selected files are in correct format. Allowed formats are: image/png, image/jpg, image/jpeg, video/mp4, video/mov, video/m4v.')
                        document.getElementById('chooseFile'+uniqueId).value = ''
                        return
                    }

                    if(i===(e.dataTransfer.files.length)-1){
                        setPreviewsUp([...previewArr])
                        setImages([...imageArr])
                    }
                }
            }
        }
    }

    //display selected device
    const openScreenshots = () =>{
        document.getElementById('collection-previews-screenshots'+uniqueId).classList.toggle('unhid')
        // document.getElementById('collection-screenshot-set'+uniqueId).classList.toggle('unhid')
        // document.getElementById('collection-preview-set'+uniqueId).classList.toggle('unhid')
        // document.getElementById('collection-screenshot-edit'+uniqueId+'ipad').classList.toggle('unhid')
        document.getElementById('collection-screenshot-icon'+uniqueId).classList.toggle('rotate')            

    }

    //display 3gen 126 and 129 on initial render
    useEffect(() => {
        if(deviceSize==='APP_IPAD_PRO_3GEN_129' || deviceSize==='APP_IPAD_PRO_129'){
            openScreenshots()
        }
    }, [])


    
/////////////////////////////////////////////////////////////
///////////        END OF COMMON OPERATIONS       ///////////
/////////////////////////////////////////////////////////////






















    return (
        <div id={`${uniqueId+classId}`} className={`collection-screenshot-item ${classId}`}> 

            <div onClick={()=>openScreenshots()} className='collection-screenshot-device-name'>
                {deviceSize === 'APP_IPAD_PRO_3GEN_129' ? 
                    <span className='device-title'><p><strong>iPad Pro </strong>(6th Gen)<strong> 12.9" Display</strong></p></span> :
                    deviceSize === 'APP_IPAD_PRO_3GEN_11' ? 
                    <span className='device-title'><p><strong>iPad 11" Display</strong> (Optional)</p></span> :
                    deviceSize === 'APP_IPAD_PRO_129' ? 
                    <span className='device-title'><p><strong>iPad Pro </strong>(2nd Gen) <strong>12.9" Display</strong></p></span> :
                    deviceSize === 'APP_IPAD_105' ? 
                    <span className='device-title'><p><strong>iPad 10.5" Display</strong> (Optional)</p></span> : 
                    deviceSize === 'APP_IPAD_97' ?  
                    <span className='device-title'><p><strong>iPad 9.7" Display</strong> (Optional)</p></span> : 
                'Unknown Device'}

                <img id={'collection-screenshot-icon'+uniqueId} className='collection-screenshot-icon' src="/assets/Icons/down-arrow-black.svg" alt="down_arrow" />
            </div>


            <div id={'collection-previews-screenshots'+uniqueId} className='collection-previews-screenshots'>

                <div style={{display:'flex', overflow: 'overlay', marginBottom: '15px', gap:'5px'}}>
                    <div id={'collection-preview-set'+uniqueId} className='collection-preview-set'>

                        <div className={`collection-screenshot-set-info ${previewSet  ? '' : 'if-set-from-primay'}`}>
                            {/* dolu mu boş mu */}
                            {!previewSet ? 
                            //Boş
                                //cihaz 3 den büyük mü
                                size > 3 ?
                                    //büyük
                                    //kendisinde 5 cihaz var mı 
                                    (previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}) || false) ?
                                        //var
                                        <span className='additional-collection-info'>Using 12.9" Display</span>
                                    :
                                        //yok
                                        //primary de varmı cihazdan
                                        primaryPreviewSet ? 
                                            //var
                                            <span className='additional-collection-info' > 
                                                {
                                                    devicePreviewSize === 'IPAD_PRO_3GEN_129' ? 
                                                    <span>Using {`${primaryLanguageName}`} 12.9" Display</span> :
                                                    devicePreviewSize === 'IPAD_PRO_3GEN_11' ?  
                                                    <span>Using {`${primaryLanguageName}`} 11" Display</span> : 
                                                ''}
                                            </span>
                                        :
                                            //yok
                                            //primaryde 5 var mı
                                            (primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}) || false) ?
                                                //var
                                                <span className='additional-collection-info'>Using {`${primaryLanguageName}`} 12.9" Display</span>
                                            :
                                                //yok
                                                <span className='additional-collection-info'>&nbsp;</span>
                                :
                                //cihaz 3 den küçük veya eşit
                                //kendisinde 3 cihaz var mı 
                                (previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}) || false) ?
                                    //var
                                    <span className='additional-collection-info'>Using 12.9" Display</span>
                                :
                                    //yok                   
                                    //primary de varmı cihazdan
                                    primaryPreviewSet ? 
                                    //var
                                    <span className='additional-collection-info' > 
                                        {
                                            devicePreviewSize === 'IPAD_PRO_129' ? 
                                            <span>Using {`${primaryLanguageName}`} 12.9" Display</span> :
                                            devicePreviewSize === 'IPAD_105' ?  
                                            <span>Using {`${primaryLanguageName}`} 10.5" Display</span> : 
                                            devicePreviewSize === 'IPAD_97' ?  
                                            <span>Using {`${primaryLanguageName}`} 9.7" Display</span> : 
                                        ''}
                                    </span>
                                :
                                    //yok
                                    //primaryde 3 var mı
                                    (primaryPreviewSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}) || false) ?
                                        //var
                                        <span className='additional-collection-info'>Using {`${primaryLanguageName}`} 12.9" Display</span>
                                    :
                                        //yok
                                        <span className='additional-collection-info'>&nbsp;</span>
                            :
                            //Dolu
                                ''            
                            }


                            <span style={{fontSize:'0.9rem'}}>
                                {
                                    !previewSet ?
                                    //Boş
                                    //cihaz 3 den büyük mü
                                    size > 3 ?
                                        //cihaz 3 den büyük
                                        //kendisinde 5 var mı
                                        (previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}) || false) ?
                                            //kendisinde 5 var
                                            <span>{previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}).previews.length}/3</span>
                                        :
                                            //kendisinde 5 yok
                                            //primary de cihazın kendi var mı
                                            primaryPreviewSet ?
                                                //primary de cihazın kendisi var
                                                <span>{primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.length}/3</span>
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 5 var mı
                                                (primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}) || false) ?
                                                    //primary de 5 var 
                                                    <span>{primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}).previews.length}/3</span>
                                                :
                                                    //primary de 5 yok
                                                    '0/3'  
                                    :
                                        //cihaz 3 e eşit ya da küçük
                                        //primary de cihazın kendi var mı
                                        primaryPreviewSet ?
                                            //primary de cihazın kendisi var
                                            <span>{primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.length}/3</span>
                                        :
                                            //primary de cihazın kendisi yok
                                            //primary de 3 var mı
                                            (primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}) || false) ?
                                                //primary de 3 var 
                                                <span>{primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}).previews.length}/3</span>
                                            :
                                                //primary de 3 yok
                                                '0/3'
                                :
                                    //Dolu
                                    <span>{previewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.length}/3</span>
                                }
                            </span>
                        </div>

                        <div id={`collection-row-previews-${uniqueId}`} className={`collection-row`} onDragEnter={handleDragPreview}>
                            {!isWaitingPreview ?
                                //cihazın seçilen localde fotosu dolu mu   
                                !previewSet ?
                                    //Boş
                                    //cihaz 3 den büyük mü
                                    size > 3 ?
                                        //cihaz 3 den büyük
                                        //kendisinde 5 var mı
                                        (previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}) || false) ?
                                            //kendisinde 5 var
                                            previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}).previews.map((previews, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection">
                                                                <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                        </div>
                                            )})
                                        :
                                            //kendisinde 5 yok
                                            //primary de cihazın kendi var mı
                                            primaryPreviewSet ?
                                                //primary de cihazın kendisi var
                                                primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.map((previews, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection">
                                                                <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                        </div>
                                                )})
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 5 var mı
                                                (primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}) || false) ?
                                                    //primary de 5 var 
                                                    primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}).previews.map((previews, index)=>{
                                                        return(
                                                            <div key={index + uniqueId} className="collection-col missing-collection">
                                                                <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                            </div>
                                                            
                                                    )})
                                                :
                                                    //primary de 5 yok
                                                    <div className='drag-file-empty' id={"drag-file-empty-preview"+uniqueId} >No Previews available</div>  

                                    :
                                        //cihaz 3 eşit ya da küçük
                                        //kendisinde 3 var mı
                                        (previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}) || false) ?
                                            //kendisinde 3 var
                                            previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}).previews.map((previews, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection">
                                                            <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                            <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                        </div>
                                            )})
                                        :
                                            //kendisinde 3 yok
                                            //primary de cihazın kendi var mı
                                            primaryPreviewSet ?
                                                //primary de cihazın kendisi var
                                                primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.map((previews, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection">
                                                            <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                            <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                        </div>
                                                )})
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 3 var mı
                                                (primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}) || false) ?
                                                    //primary de 3 var 
                                                    primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}).previews.map((previews, index)=>{
                                                        return(
                                                            <div key={index + uniqueId} className="collection-col missing-collection">
                                                                <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                            </div>
                                                    )})
                                                :
                                                    //primary de 3 yok
                                                    <div className='drag-file-empty' id={"drag-file-empty"+uniqueId} >No Previews available</div>  

                                :
                                    //Dolu
                                    <DragDropContext onDragEnd={handleOnDragEndPreview}>
                                        <Droppable droppableId="collection-col-list-preview" direction="horizontal">
                                            {(provided) => (
                                                <ul className="collection-col-list-preview" {...provided.droppableProps} ref={provided.innerRef}>
                                                    {forPreviewOrder.map((previews, index)=>{
                                                        return(
                                                            <Draggable key={index + uniqueId + 'previews'} draggableId={uniqueId+index + 'previews'} index={index}>
                                                                {(provided) => (   
                                                                    <li id={previews.preview_id} className={`collection-col ${uniqueId+index}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                        <div onMouseOver={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeVisible(`delete-preview${uniqueId+index}`)} onMouseLeave={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeInvisible(`delete-preview${uniqueId+index}`)} onClick={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>{deletePreview(previews)}} className='delete-preview' id={`delete-preview${uniqueId+index}`}>
                                                                            <img src="/assets/Icons/delete.svg" alt="del" />
                                                                            
                                                                        </div>
                                                                        
                                                                        <img onMouseOver={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeVisible(`delete-preview${uniqueId+index}`)} onMouseLeave={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeInvisible(`delete-preview${uniqueId+index}`)} className='collection-previews' 
                                                                        src={
                                                                            `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`
                                                                            // '/assets/Images/image_placeholder.jpg'
                                                                            } alt='appImage'/>
                                                                        <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                                </li>
                                                                )}        
                                                            </Draggable>
                                                    )})}
                                                    {provided.placeholder}
                                                </ul>
                                            )}
                                        </Droppable>
                                    </DragDropContext>                   
                            : 
                                <div className='spinner-full'><Spinner/>{percentagePreview.get(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`)>0 ? <span style={{marginTop: '0px', fontSize: '0.8rem', marginLeft: '1px'}}>{percentagePreview.get(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`)}%</span> : ''}</div>
                            }

                            { dragActivePreview && isUploadingPreview.collectionID !== +collectionId && isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && !isWaiting && !isWaitingPreview && <div className='drag-file-element' id={"drag-file-element-preview"+uniqueId} onDragEnter={handleDragPreview} onDragLeave={handleDragPreview} onDragOver={handleDragPreview} onDrop={handleDrop} style={{width : document.getElementById(`collection-row-previews-${uniqueId}`).scrollWidth+'px'}}></div> }    
                        </div>

                    </div>


                    <div id={'collection-screenshot-set'+uniqueId} className='collection-screenshot-set '>
                        <div className={`collection-screenshot-set-info ${screenshotSet  ? '' : 'if-set-from-primay'}`}>
                            {/* dolu mu boş mu */}
                            {!screenshotSet ? 
                            //Boş
                                //cihaz 3 den büyük mü
                                size > 3 ?
                                    //büyük
                                    //kendisinde 5 cihaz var mı 
                                    (screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}) || false) ?
                                        //var
                                        <span className='additional-info'>Using 12.9" Display</span>
                                    :
                                        //yok
                                        //primary de varmı cihazdan
                                        primaryScreenshotSet ? 
                                            //var
                                            <span className='additional-info' > 
                                                {
                                                    deviceSize === 'APP_IPAD_PRO_3GEN_129' ? 
                                                    <span>Using {`${primaryLanguageName}`} 12.9" Display</span> :
                                                    deviceSize === 'APP_IPAD_PRO_3GEN_11' ?  
                                                    <span>Using {`${primaryLanguageName}`} 11" Display</span> : 
                                                ''}
                                            </span>
                                        :
                                            //yok
                                            //primaryde 5 var mı
                                            (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}) || false) ?
                                                //var
                                                <span className='additional-info'>Using {`${primaryLanguageName}`} 12.9" Display</span>
                                            :
                                                //yok
                                                <span className='additional-info'>&nbsp;</span>
                                :
                                //cihaz 3 den küçük veya eşit
                                //kendisinde 3 cihaz var mı 
                                (screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}) || false) ?
                                    //var
                                    <span className='additional-info'>Using 12.9" Display</span>
                                :
                                    //yok                   
                                    //primary de varmı cihazdan
                                    primaryScreenshotSet ? 
                                    //var
                                    <span className='additional-info' > 
                                        {
                                            deviceSize === 'APP_IPAD_PRO_129' ? 
                                            <span>Using {`${primaryLanguageName}`} 12.9" Display</span> :
                                            deviceSize === 'APP_IPAD_105' ?  
                                            <span>Using {`${primaryLanguageName}`} 10.5" Display</span> : 
                                            deviceSize === 'APP_IPAD_97' ?  
                                            <span>Using {`${primaryLanguageName}`} 9.7" Display</span> : 
                                        ''}
                                    </span>
                                :
                                    //yok
                                    //primaryde 3 var mı
                                    (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}) || false) ?
                                        //var
                                        <span className='additional-info'>Using {`${primaryLanguageName}`} 12.9" Display</span>
                                    :
                                        //yok
                                        <span className='additional-info'>&nbsp;</span>
                            :
                            //Dolu
                                ''            
                            }


                            <span style={{fontSize:'0.9rem'}}>
                                {
                                    !screenshotSet ?
                                    //Boş
                                    //cihaz 3 den büyük mü
                                    size > 3 ?
                                        //cihaz 3 den büyük
                                        //kendisinde 5 var mı
                                        (screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}) || false) ?
                                            //kendisinde 5 var
                                            <span>{screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}).screenshots.length}/10</span>
                                        :
                                            //kendisinde 5 yok
                                            //primary de cihazın kendi var mı
                                            primaryScreenshotSet ?
                                                //primary de cihazın kendisi var
                                                <span>{primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.length}/10</span>
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 5 var mı
                                                (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}) || false) ?
                                                    //primary de 5 var 
                                                    <span>{primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}).screenshots.length}/10</span>
                                                :
                                                    //primary de 5 yok
                                                    '0/10'  
                                    :
                                        //cihaz 3 e eşit ya da küçük
                                        //primary de cihazın kendi var mı
                                        primaryScreenshotSet ?
                                            //primary de cihazın kendisi var
                                            <span>{primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.length}/10</span>
                                        :
                                            //primary de cihazın kendisi yok
                                            //primary de 3 var mı
                                            (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}) || false) ?
                                                //primary de 3 var 
                                                <span>{primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}).screenshots.length}/10</span>
                                            :
                                                //primary de 3 yok
                                                '0/10'
                                :
                                    //Dolu
                                    <span>{screenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.length}/10</span>
                                }
                            </span>
                        </div>

                        <div id={`collection-row-${uniqueId}`} className={`collection-row`} onDragEnter={handleDrag}>
                            {!isWaiting ?
                                //cihazın seçilen localde fotosu dolu mu   
                                !screenshotSet ?
                                    //Boş
                                    //cihaz 3 den büyük mü
                                    size > 3 ?
                                        //cihaz 3 den büyük
                                        //kendisinde 5 var mı
                                        (screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}) || false) ?
                                            //kendisinde 5 var
                                            screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}).screenshots.map((screenshots, index)=>{
                                                    return(
                                                            <div
                                                                key={index + uniqueId}
                                                                className="col missing-collection">
                                                                    <img className='collection-screenshots-ipad' 
                                                                        src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                                        onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                            </div>
                                            )})
                                        :
                                            //kendisinde 5 yok
                                            //primary de cihazın kendi var mı
                                            primaryScreenshotSet ?
                                                //primary de cihazın kendisi var
                                                primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.map((screenshots, index)=>{
                                                    return(
                                                        <div
                                                            key={index + uniqueId}
                                                            className="col missing-collection">
                                                                <img className='collection-screenshots-ipad' 
                                                                    src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                                    onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                        </div>
                                                )})
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 5 var mı
                                                (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}) || false) ?
                                                    //primary de 5 var 
                                                    primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}).screenshots.map((screenshots, index)=>{
                                                        return(
                                                            <div
                                                                key={index + uniqueId}
                                                                className="col missing-collection">
                                                                    <img className='collection-screenshots-ipad' 
                                                                        src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                                        onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                            </div>
                                                    )})
                                                :
                                                    //primary de 5 yok
                                                    <div className='drag-file-empty' id={"drag-file-empty"+uniqueId} onDragEnter={handleDrag}>
                                                        {isUploading.collectionID!==+collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId ? 
                                                            <>Choose file(s) or drag up to 10 screenshots here.</>
                                                        :
                                                            <>Please wait for uploading process to be over.</>
                                                        }
                                                    </div>  
                                    :
                                        //cihaz 3 eşit ya da küçük
                                        //kendisinde 3 var mı
                                        (screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}) || false) ?
                                            //kendisinde 3 var
                                            screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}).screenshots.map((screenshots, index)=>{
                                                    return(
                                                        <div
                                                            key={index + uniqueId}
                                                            className="col missing-collection">
                                                                <img className='collection-screenshots-ipad' 
                                                                    src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                                    onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                        </div>
                                            )})
                                        :
                                            //kendisinde 3 yok
                                            //primary de cihazın kendi var mı
                                            primaryScreenshotSet ?
                                                //primary de cihazın kendisi var
                                                primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.map((screenshots, index)=>{
                                                    return(
                                                        <div
                                                            key={index + uniqueId}
                                                            className="col missing-collection">
                                                                <img className='collection-screenshots-ipad' 
                                                                    src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                                    onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                        </div>
                                                )})
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 3 var mı
                                                (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}) || false) ?
                                                    //primary de 3 var 
                                                    primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}).screenshots.map((screenshots, index)=>{
                                                        return(
                                                            <div
                                                                key={index + uniqueId}
                                                                className="col missing-collection">
                                                                    <img className='collection-screenshots-ipad' 
                                                                        src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                                        onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} alt='appImage'/>
                                                            </div>
                                                    )})
                                                :
                                                    //primary de 3 yok
                                                    <div className='drag-file-empty' id={"drag-file-empty"+uniqueId} onDragEnter={handleDrag}>
                                                        {isUploading.collectionID!==+collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId ? 
                                                            <>Choose file(s) or drag up to 10 screenshots here.</>
                                                        :
                                                            <>Please wait for uploading process to be over.</>
                                                        }
                                                    </div>   
                
                                :
                                    //Dolu
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId="collection-col-list" direction="horizontal">
                                            {(provided) => (
                                                <ul className="collection-col-list" {...provided.droppableProps} ref={provided.innerRef}>
                                                    {forOrder.map((screenshots, index)=>{
                                                        return(
                                                            <Draggable key={index + uniqueId} draggableId={uniqueId+index} index={index}>
                                                                {(provided) => (   
                                                                    <li id={screenshots.screenshot_id} className={`collection-col ${uniqueId+index}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                        <div onMouseOver={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeVisible(`delete-screenshot${uniqueId+index}`)} onMouseLeave={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeInvisible(`delete-screenshot${uniqueId+index}`)} onClick={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>{deleteScreenShot(screenshots)}} className='delete-screenshot' id={`delete-screenshot${uniqueId+index}`}>
                                                                            <img src="/assets/Icons/delete.svg" alt="del" />
                                                                        </div>

                                                                        <img onMouseOver={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeVisible(`delete-screenshot${uniqueId+index}`)} onMouseLeave={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeInvisible(`delete-screenshot${uniqueId+index}`)} className='collection-screenshots-ipad' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                                        onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} 
                                                                        alt='appImage'/>
                                                                    </li>
                                                                )}        
                                                            </Draggable>
                                                    )})}
                                                    {provided.placeholder}
                                                </ul>
                                            )}
                                        </Droppable>
                                    </DragDropContext>                   
                            : 
                                <div className='spinner-full'><Spinner/>{percentage.get(`${appId}-${collectionId}-${languageCode}-${deviceSize}`)>0 ? <span style={{marginTop: '0px', fontSize: '0.8rem', marginLeft: '1px'}}>{percentage.get(`${appId}-${collectionId}-${languageCode}-${deviceSize}`)}%</span> : ''}</div>
                            } 
                            { dragActive && isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !isWaiting && !isWaitingPreview && <div className='drag-file-element' id={"drag-file-element"+uniqueId} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} style={{width : document.getElementById(`collection-row-${uniqueId}`).scrollWidth+'px'}}></div> }    
                        </div>     
                    </div>
                </div>

                <div id={'collection-screenshot-edit'+uniqueId+'ipad'} className="collection-screenshot-edit">
                        {/* upload */}
                        <input disabled={isWaiting || isWaitingPreview || isUploading.collectionID === +collectionId ? true : false} multiple={true} hidden={true} className='collection-screenshot-upload' type="file" accept="image/png, image/jpeg, image/jpg, video/mp4, video/mov, video/m4v" id={'chooseFile'+uniqueId} 
                        onChange={onImageUpload}
                        />
                        <label hidden={isWaiting || isWaitingPreview || isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId} className='collection-screenshot-upload-label' htmlFor={'chooseFile'+uniqueId}>Choose File</label>
                        {isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !isWaiting&&!isWaitingPreview ? 
                        <>
                            {previewSet ? <> 
                                <span className='edit-seperator'>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                <span onClick={()=>{deleteAllPreviews(previewSetImg)}} className='collection-screenshot-delete-label'>Delete All Previews</span> </>                      
                            : ''}
                            {screenshotSet ? <> 
                                <span className='edit-seperator'>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                <span onClick={()=>{deleteAllScreenshots(screenshotSetImg)}} className='collection-screenshot-delete-label'>Delete All Screenshots</span> </>                      
                            : ''}


                        </> : ''}
                        {/* upload */} 
                </div>

            </div>    



            <div id={`lightbox${uniqueId}`} onClick={(e)=>closeSlide(e,uniqueId)} className={`collection-modal modal${uniqueId}`}>
                <span className="collection-close pointer" onClick={()=>{closeLightbox(uniqueId)}}>&times;</span>
                <div className="collection-modal-content">
                    <p className='collection-screenshot-slide-device-name'>
                        {deviceSize === 'APP_IPAD_PRO_3GEN_129' ? 
                        <span>iPad Pro (6th Gen) <strong>12.9" Display</strong></span> :
                        deviceSize === 'APP_IPAD_PRO_3GEN_11' ? 
                        <span>iPad <strong>11" Display</strong></span> :
                        deviceSize === 'APP_IPAD_PRO_129' ? 
                        <span>iPad Pro (2nd Gen) <strong>12.9" Display</strong></span> :
                        deviceSize === 'APP_IPAD_105' ? 
                        <span>iPad <strong>10.5" Display</strong></span> : 
                        deviceSize === 'APP_IPAD_97' ?  
                        <span>iPad <strong>9.7" Display</strong></span> : 
                        'Unknown Device'}
                    </p>
                    {!screenshotSet ?
                            //Boş
                            //cihaz 3 den büyük mü
                            size > 3 ?
                                //cihaz 3 den büyük
                                //kendisinde 5 var mı
                                (screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}) || false) ?
                                    //kendisinde 5 var
                                    screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}).screenshots.map((screenshots, index2)=>{
                                            return(
                                                <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/></div>
                                    )})
                                :
                                    //kendisinde 5 yok
                                    //primary de cihazın kendi var mı
                                    primaryScreenshotSet ?
                                        //primary de cihazın kendisi var
                                        primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.map((screenshots, index2)=>{
                                            return(
                                                <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/></div>
                                        )})
                                    :
                                        //primary de cihazın kendisi yok
                                        //primary de 5 var mı
                                        (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}) || false) ?
                                            //primary de 5 var 
                                            primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_3GEN_129'}).screenshots.map((screenshots, index2)=>{
                                                return(
                                                    <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/></div>
                                            )})
                                        :
                                            //primary de 5 yok
                                            ''  
                            :
                                //cihaz 3 eşit ya da küçük
                                //kendisinde 3 var mı
                                (screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}) || false) ?
                                    //kendisinde 55 var
                                    screenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}).screenshots.map((screenshots, index2)=>{
                                            return(
                                                <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/></div>
                                    )})
                                :
                                    //kendisinde 3 yok
                                    //primary de cihazın kendi var mı
                                    primaryScreenshotSet ?
                                        //primary de cihazın kendisi var
                                        primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.map((screenshots, index2)=>{
                                            return(
                                                <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/></div>
                                        )})
                                    :
                                        //primary de cihazın kendisi yok
                                        //primary de 3 var mı
                                        (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}) || false) ?
                                            //primary de 3 var 
                                            primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPAD_PRO_129'}).screenshots.map((screenshots, index2)=>{
                                                return(
                                                    <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/></div>
                                            )})
                                        :
                                            //primary de 3 yok
                                            ''  
                        :
                            //Dolu
                            screenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.map((screenshots, index2)=>{
                                return(
                                    <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/></div>
                            )})  }                  
                    <span id='previous' className="previous" onClick={()=>{changeSlide(-1,uniqueId)}}>&#10094;</span>
                    <span id='next' className="next" onClick={()=>{changeSlide(1,uniqueId)}}>&#10095;</span>
                </div>
            </div>



            <div id={`video${uniqueId}`} className="collection-video-div" style={videoPlay ? {display: 'flex'} : {display: 'none'}}>
                    
                    <span onClick={!isWaitingTimeFrame ? ()=>{setVideoPlay(null);setForAllPreviews(false)} : ()=>{}} className='collection-close pointer'>&times;</span>
                    
                    <div className="collection-modal-content">
                        <p className='collection-screenshot-slide-device-name'>
                            {deviceSize === 'APP_IPAD_PRO_3GEN_129' ? 
                            <span>iPad Pro (6th Gen) <strong>12.9" Display</strong></span> :
                            deviceSize === 'APP_IPAD_PRO_3GEN_11' ? 
                            <span>iPad <strong>11" Display</strong></span> :
                            deviceSize === 'APP_IPAD_PRO_129' ? 
                            <span>iPad Pro (2nd Gen) <strong>12.9" Display</strong></span> :
                            deviceSize === 'APP_IPAD_105' ? 
                            <span>iPad <strong>10.5" Display</strong></span> : 
                            deviceSize === 'APP_IPAD_97' ?  
                            <span>iPad <strong>9.7" Display</strong></span> : 
                            'Unknown Device'}
                        </p>
                        {!previewSet ?
                                //Boş
                                //cihaz 3 den büyük mü
                                size > 3 ?
                                    //cihaz 3 den büyük
                                    //kendisinde 5 var mı
                                    (previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}) || false) ?
                                        //kendisinde 5 var
                                        previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}).previews.map((previews, index)=>{
                                                return(
                                                    <span key={index + uniqueId}>
                                                        {videoPlay==="video" + index + uniqueId ?
                                                                    <div className='collection-video-inner'>

                                                                        <CollectionVideo onReady={playerReady} options={
                                                                            {
                                                                                fill: true,
                                                                                fluid: false,
                                                                                autoplay: true,
                                                                                controls: true,
                                                                                controlBar: {
                                                                                    pictureInPictureToggle: false
                                                                                },
                                                                                preload: "metadata",
                                                                                sources: [
                                                                                    {
                                                                                        src:
                                                                                        `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                        // type: "application/x-mpegURL"
                                                                                    }
                                                                                ]
                                                                            }
                                                                        }/>
                                                                    </div>
                                                        : ''
                                                        }
                                                    </span>
                                        )})
                                    :
                                        //kendisinde 5 yok
                                        //primary de cihazın kendi var mı
                                        primaryPreviewSet ?
                                            //primary de cihazın kendisi var
                                            primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.map((previews, index)=>{
                                                return(
                                                    <span key={index + uniqueId}>
                                                        {videoPlay==="video" + index + uniqueId ?
                                                                    <div className='collection-video-inner'>
                                                                        <CollectionVideo onReady={playerReady} options={
                                                                            {
                                                                                fill: true,
                                                                                fluid: false,
                                                                                autoplay: true,
                                                                                controls: true,
                                                                                controlBar: {
                                                                                    pictureInPictureToggle: false
                                                                                },
                                                                                preload: "metadata",
                                                                                sources: [
                                                                                    {
                                                                                        src:
                                                                                        `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                        // type: "application/x-mpegURL"
                                                                                    }
                                                                                ]
                                                                            }
                                                                        }/>
                                                                    </div>
                                                        : ''
                                                        }
                                                    </span>
                                            )})
                                        :
                                            //primary de cihazın kendisi yok
                                            //primary de 5 var mı
                                            (primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}) || false) ?
                                                //primary de 5 var 
                                                primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_3GEN_129'}).previews.map((previews, index)=>{
                                                    return(
                                                        <span key={index + uniqueId}>
                                                            {videoPlay==="video" + index + uniqueId ?
                                                                        <div className='collection-video-inner'>
                                                                            <CollectionVideo onReady={playerReady} options={
                                                                                {
                                                                                    fill: true,
                                                                                    fluid: false,
                                                                                    autoplay: true,
                                                                                    controls: true,
                                                                                    controlBar: {
                                                                                        pictureInPictureToggle: false
                                                                                    },
                                                                                    preload: "metadata",
                                                                                    sources: [
                                                                                        {
                                                                                            src:
                                                                                            `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                            // type: "application/x-mpegURL"
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            }/>
                                                                        </div>
                                                            : ''
                                                            }
                                                        </span>
                                                )})
                                            :
                                                //primary de 5 yok
                                                ''  
                                :
                                    //cihaz 3 eşit ya da küçük
                                    //kendisinde 3 var mı
                                    (previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}) || false) ?
                                        //kendisinde 55 var
                                        previewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}).previews.map((previews, index)=>{
                                                return(
                                                    <span key={index + uniqueId}>
                                                        {videoPlay==="video" + index + uniqueId ?
                                                                    <div className='collection-video-inner'>
                                                                        <CollectionVideo onReady={playerReady} options={
                                                                            {
                                                                                fill: true,
                                                                                fluid: false,
                                                                                autoplay: true,
                                                                                controls: true,
                                                                                controlBar: {
                                                                                    pictureInPictureToggle: false
                                                                                },
                                                                                preload: "metadata",
                                                                                sources: [
                                                                                    {
                                                                                        src:
                                                                                        `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                        // type: "application/x-mpegURL"
                                                                                    }
                                                                                ]
                                                                            }
                                                                        }/>
                                                                    </div>
                                                        : ''
                                                        }
                                                    </span>
                                        )})
                                    :
                                        //kendisinde 3 yok
                                        //primary de cihazın kendi var mı
                                        primaryPreviewSet ?
                                            //primary de cihazın kendisi var
                                            primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.map((previews, index)=>{
                                                return(
                                                    <span key={index + uniqueId}>
                                                        {videoPlay==="video" + index + uniqueId ?
                                                                    <div className='collection-video-inner'>
                                                                        <CollectionVideo onReady={playerReady} options={
                                                                            {
                                                                                fill: true,
                                                                                fluid: false,
                                                                                autoplay: true,
                                                                                controls: true,
                                                                                controlBar: {
                                                                                    pictureInPictureToggle: false
                                                                                },
                                                                                preload: "metadata",
                                                                                sources: [
                                                                                    {
                                                                                        src:
                                                                                        `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                        // type: "application/x-mpegURL"
                                                                                    }
                                                                                ]
                                                                            }
                                                                        }/>
                                                                    </div>

                                                        : ''
                                                        }
                                                    </span>
                                            )})
                                        :
                                            //primary de cihazın kendisi yok
                                            //primary de 3 var mı
                                            (primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}) || false) ?
                                                //primary de 3 var 
                                                primaryPreviewSetImg.find((i)=>{return i.size === 'IPAD_PRO_129'}).previews.map((previews, index)=>{
                                                    return(
                                                        <span key={index + uniqueId}>
                                                        {videoPlay==="video" + index + uniqueId ?
                                                                    <div className='collection-video-inner'>
                                                                        <CollectionVideo onReady={playerReady} options={
                                                                            {
                                                                                fill: true,
                                                                                fluid: false,
                                                                                autoplay: true,
                                                                                controls: true,
                                                                                controlBar: {
                                                                                    pictureInPictureToggle: false
                                                                                },
                                                                                preload: "metadata",
                                                                                sources: [
                                                                                    {
                                                                                        src:
                                                                                        `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                        // type: "application/x-mpegURL"
                                                                                    }
                                                                                ]
                                                                            }
                                                                        }/>
                                                                    </div>
                                                        : ''
                                                        }
                                                    </span>
                                                )})
                                            :
                                                //primary de 3 yok
                                                ''  
                            :
                                //Dolu
                                previewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.map((previews, index)=>{
                                    return(
                                        <span key={index + uniqueId}>
                                            {videoPlay==="video" + index + uniqueId ?
                                                <div className='collection-video-inner'>

                                                    <CollectionVideo onReady={playerReady} options={
                                                        {
                                                            fill: true,
                                                            fluid: false,
                                                            autoplay: true,
                                                            controls: true,
                                                            controlBar: {
                                                                pictureInPictureToggle: false
                                                            },
                                                            preload: "metadata",
                                                            sources: [
                                                                {
                                                                    src:
                                                                    `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                    // type: "video/mp4"
                                                                }
                                                            ]
                                                        }
                                                    }/>
                                                    <div className='poster-check'>
                                                        <label>
                                                            <input onChange={(e)=>{setForAllPreviews(e.target.checked)}} type="checkbox"/>Apply for all previews in this collection.
                                                        </label>
                                                    </div>
                                                    <div className='video-buttons'>
                                                        <span onClick={oneSecondBack}>
                                                            <img style={{height:'35px'}} src='/assets/Icons/right.svg'/>
                                                        </span>

                                                        <button className='set-poster' onClick={()=>previewSetPoster(previews.preview_id)}>
                                                            {isWaitingTimeFrame ? 
                                                                    <SpinnerSmall/>
                                                                :
                                                                    'Set Poster Frame'
                                                            }
                                                            
                                                        </button>
                                                        
                                                        <span onClick={oneSecondForward}>
                                                            <img style={{height:'35px'}} src='/assets/Icons/left.svg'/>
                                                        </span>
                                                    </div>

                                                </div>
                                            : 
                                                ''
                                            }
                                        </span>
                                )})  }                  
                    </div>
            </div>



        </div>
    )
}

export default CollectionScreenshotIpadItem