import './OwnerWhatsnew.css'

function OwnerWhatsnew({data, type}) {
  return (
    <div className="owner-whatsnew-item">

    <div className="owner-whatsnew-div">

        <div className="owner-section-name">
            <p className='owner-name'>{type} </p>
        </div>
        <p className='owner-whatsnew-count' style={{display:'none'}}>{!data ? 0 : data.length}/4000</p>

    </div>

    <div className="owner-whatsnew-field">
        <textarea readOnly={true} className='owner-whatsnew-textarea' name="owner-whatsnew-textarea" cols="50" rows="4" value={!data ? '' : data} 
        ></textarea>
    </div>

  </div>
  )
}

export default OwnerWhatsnew