import { useContext } from "react"
import Spinner from "../Misc/Spinner"
import VariableContext from "../userContext/VariableContext"

function NotFound404() {

  const {currentAppRole} = useContext(VariableContext)

  return (
    <>
        {currentAppRole ? 
            <img src="/assets/Icons/404.svg" alt="404" />
            : <Spinner/>
        }
    </>
  )
}

export default NotFound404