import {useContext, useEffect, useState} from 'react'
import VariableContext from '../../userContext/VariableContext';
import './CollectionOwnerBox.css'
import CollectionOwnerBoxInner from './CollectionOwnerBoxInner';
import CollectionOwnerBoxInnerWithDumy from './CollectionOwnerBoxInnerWithDumy';
import CollectionOwnerBoxInnerWithDumy2 from './CollectionOwnerBoxInnerWithDumy2';
import Spinner from '../../Misc/Spinner';
import { useParams } from 'react-router-dom';
import { fireCustomRequestError, openCreateCollectionPopup, fireCustomError, fireRequestError } from '../../Common Functions/commonFunctions';
import axios from 'axios';
import Swal from 'sweetalert2';
import CreateCollectionPopup from '../../Popup/CreateCollectionPopup'
import NewZip from '../../Popup/NewZip';
import GoBackButton from '../GoBackButton';
import ChangeCollectionNamePopupOwner from '../../Popup/ChangeCollectionNamePopupOwner'
import jurisdiction from '../../Jusristiction/juristiction';

function CollectionOwnerBox() {
  const [isWaiting, setIsWaiting] = useState(false)
  const {currentAppCols,setCurrentAppCols} = useContext(VariableContext)
  const {setLastUpload} = useContext(VariableContext)
  const {getColList} = useContext(VariableContext)
  const {subWarning} = useContext(VariableContext)

  const [selectedCol, setSelecteCol] = useState(null)

  const {isUploadingDescription} = useContext(VariableContext)
  const {isUploadingKeyword,} = useContext(VariableContext)
  const {isUploadingWhatsnew,} = useContext(VariableContext)
  const {isUploadingPhoto,} = useContext(VariableContext)
  const {isUploadingPreview,} = useContext(VariableContext)
  const {isUploading} = useContext(VariableContext)


  const {subscriptionLimits} = useContext(VariableContext)
  const {currentAppRole} = useContext(VariableContext)


  
  let {appId} = useParams()


      //get collections (themes)
      useEffect(()=>{
        setIsWaiting(true)
        var data = '';
    
        var config = {
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/app/collection/${appId}`,
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
          data : data
        };
        axios(config)
        .then(function (response) {
          setIsWaiting(false)
          setCurrentAppCols(response.data.data)
        })
        .catch(function (error) {
          setIsWaiting(false)
          if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            fireRequestError(error)
        }

        });
      
      },[appId, getColList])


      useEffect(()=>{
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/uploadAttempt?appId=${appId}`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          };
          
          axios(config)
          .then(function (response) {
            if(response.data.data.length !== 0){
              if(response.data.data.filter((i)=>{return (i.status==='done')&&(i.type==='all')&&(i.app_id=== +appId)}).length!==0){
                setLastUpload(0+response.data.data.filter((i)=>{return (i.status==='done')&&(i.type==='all')&&(i.app_id=== +appId)})[0].collection_id)
              }
              else{
                setLastUpload(null)
              }
      
            }
          })
          .catch(function (error) {
            if(error.message === 'Network Error'){
              fireCustomError('NETWORK ERROR. ')
            }else{
              fireRequestError(error)
            }
          })
        
      },[appId, isUploading])



      const selectCol = (id) =>{
          setSelecteCol(id)
      }


  return (<>
    <div className="owner-title-div titleArea">
      <GoBackButton/>

      <h1 id='colName'>Collections</h1>

    </div>
    <div className="owner-inner-content">

        {subWarning ? 
            subWarning.status==="expiring" ?
                <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0', marginTop:'35px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
            : subWarning.status==="banned" ?
                <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0', marginTop:'35px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
            :     
              ''
        : 
            ''
        }

        {subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.length>0 ? 
            jurisdiction.role.includes(currentAppRole) ? 
            <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0', marginTop:'35px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />You have more collections than what your account type permits. Some of the features concerning collections are disabled.</span>
        :
            <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0', marginTop:'35px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />There are more collections than what owner's account type permits. Some of the features concerning collections are disabled.</span>
        : 
          ""
        : 
          ""
        }

        

        <div className="owner-collection-select">

            {isWaiting ?                  
                <div className="spinner-owner">
                    <Spinner/>
                </div> : 
                
                !currentAppCols ?                        
                <div className="spinner-owner">
                    <Spinner/>
                </div> 
            :
                currentAppCols.length===0 ? 
                  <>
                    <div className='newCol'>
                      
                      <img style={isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status ? {cursor:'not-allowed', margin:'0'} : {cursor:'pointer', margin:'0'}} onClick={isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status ? ()=>{} : openCreateCollectionPopup} className='app-icon addApp' src={'/assets/Icons/Add-App.svg' } alt="plus" />
                        
                      
                      <CreateCollectionPopup pageType={'owner'}/>
                      <NewZip pageType={'owner'}/>
                    </div>
                    <div className='owner-collection' style={{visibility: 'hidden'}}>

                    </div>
                  </>
                :
                currentAppCols.map((data, index)=>{
                    if(index< currentAppCols.length&&index!==currentAppCols.length-1){
                        return (
                            <CollectionOwnerBoxInner key={index} data={data} index={index} func={selectCol}/>
                        )
                    }else if(index===currentAppCols.length-1&&index % 2 === 0){
                        return (
                            <CollectionOwnerBoxInnerWithDumy key={index} data={data} index={index} func={selectCol}/>
                        )
                    }else{
                        return (
                            <CollectionOwnerBoxInnerWithDumy2 key={index} data={data} index={index} func={selectCol}/>
                        )
                    }
                })

            }

   

        </div>
    </div>

    {selectedCol ? 
        <ChangeCollectionNamePopupOwner colId={selectedCol} func={selectCol}/> : ''
    }

    </>

  )
}

export default CollectionOwnerBox