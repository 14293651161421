import {useContext} from 'react'
import VariableContext from '../userContext/VariableContext';
import {useParams} from 'react-router-dom'
import {NavLink} from 'react-router-dom'
import './CollectionButtonsItem.css'
// import Spinner from '../Misc/Spinner';
import { openCreateCollectionPopup, openChangeCollectionNamePopup } from '../Common Functions/commonFunctions';
import SpinnerSmall from '../Misc/SpinnerSmall';
import jurisdiction from '../Jusristiction/juristiction';
import { AiTwotoneLock, AiTwotoneUnlock } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';

function CollectionButtonsItem({isWaitingCol, pageType}) {
  const {currentAppCols} = useContext(VariableContext)
  const {currentAppId} = useContext(VariableContext)

  const {isUploadingDescription} = useContext(VariableContext)
  const {isUploadingKeyword,} = useContext(VariableContext)
  const {isUploadingWhatsnew,} = useContext(VariableContext)
  const {isUploadingPhoto,} = useContext(VariableContext)
  const {isUploadingPreview,} = useContext(VariableContext)
  const {isUploading} = useContext(VariableContext)

  const {currentAppVersion} = useContext(VariableContext)
  const {currentAppRole} = useContext(VariableContext)
  const {appCol} = useContext(VariableContext)

  const{subscriptionLimits} = useContext(VariableContext)
 
  let {appId} = useParams()

  const showMenu = (e) =>{
    if(document.getElementById('threeDotColMenu')){
      let rect = e.target.getBoundingClientRect()
      document.getElementById('threeDotColMenu').classList.add('three-dot-col-menu-open')
      document.getElementById('threeDotColMenu').style.top = (rect.top+32)+'px'
      document.getElementById('threeDotColMenu').style.left = (rect.left-131)+'px'

      window.addEventListener('click', documentClickHandler);
    }

  }

  const documentClickHandler = function(e) {
    
    if(document.getElementById('threeDotColMenu')){
      const isClickedOutside = !document.getElementById('threeDotColMenu').contains(e.target);
      let isMenu = !e.target.classList.contains('colmenu') && !e.target.classList.contains('colmenu1')
      
      if (isClickedOutside && isMenu) {
        // Hide the menu
        document.getElementById('threeDotColMenu').classList.remove('three-dot-col-menu-open')


          // Remove the event handler
          window.removeEventListener('click', documentClickHandler);
      }
      if(e.target.classList.contains('colCustomize')){
        document.getElementById('threeDotColMenu').classList.remove('three-dot-col-menu-open')


        // Remove the event handler
        window.removeEventListener('click', documentClickHandler);
      }
    }
    else{
      window.removeEventListener('click', documentClickHandler);
    }
  } 


  const updateAppCol = (collectionId) =>{
      appCol.current[appId] = collectionId
  }
  const selectCurrent = () =>{
      appCol.current[appId] = ''
  }

  return (

    <div className="collection-select">
    {!isWaitingCol ? 
    
      <div id='colButtons' className='collection-buttons'>

        <NavLink to={`/${appId}/${pageType}`} className={() =>
        window.location.pathname===`/${currentAppId}/${pageType}` || window.location.pathname===`/${currentAppId}/${pageType}/` ? 'active-linkb' : 'cbi current-link'}>
          <div onClick={selectCurrent}>{currentAppVersion ? '(v'+currentAppVersion+')' : <SpinnerSmall/>}</div>
        </NavLink>

        {!currentAppCols ? '' :currentAppCols.map((data, index)=>{
        return (
          <NavLink key={index} to={`/${appId}/${pageType}/${data.collection_id}`} className={({ isActive }) =>isActive ? 'active-linkb' : 'cbi'}
          >

              {subscriptionLimits ? 
                  subscriptionLimits.currentApp.unavailableCollections.includes(data.collection_id) ? 
                      <img style={{marginRight:'5px', opacity:"0.9"}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" /> 
                  :   
                      <>
                          {data.locked ? 
                            <AiTwotoneLock size={30}/>
                          :
                            <AiTwotoneUnlock size={30}/>
                          }
                      </>
                  :   
                      <>
                          {data.locked ? 
                            <AiTwotoneLock size={30}/>
                          :
                            <AiTwotoneUnlock size={30}/>
                          }
                      </>
              }  



              <div onClick={()=>updateAppCol(data.collection_id)} className={`collection-select-button`}>{data.collection_title}</div>

              {jurisdiction.collection.includes(currentAppRole) ? 
                // <>
                //   <img onClick={(e)=>showMenu(e)} id={'colmenu'+index} className='colmenu colmenu1' src="/assets/Icons/kebab-blue.svg" alt="" />
                //   <img onClick={(e)=>showMenu(e)} id={'colmenu'+index} className='colmenu colmenu2' src="/assets/Icons/kebab-white.svg" alt="" />
                // </>
                <div className='colmenu' style={{marginRight:'-8px', width:'max-content', height:'max-content', display:'flex', alignItems:'center', justifyContent:'center'}} onClick={(e)=>showMenu(e)}>
                  <BsThreeDotsVertical  className='colmenu1' size={22} />
                </div>
                // ''
              :
                  ''
              }
          </NavLink>
        )
        })}

        {pageType==='owner' || !jurisdiction.collection.includes(currentAppRole) ? '' :
            <button disabled={isUploading.status || isUploadingDescription.status || isUploadingKeyword.status || isUploadingPhoto.status || isUploadingPreview.status || isUploadingWhatsnew.status} onClick={openCreateCollectionPopup} className={`collection-select-button-add`}>
              <img style={{width:'18px', height:'18px'}} src="/assets/Icons/document.svg" alt="" />
            </button>
        }
      </div> 
      : 
        <div className='collection-buttons'>

          <NavLink to={`/${appId}/${pageType}`} className={() =>
          window.location.pathname===`/${currentAppId}/${pageType}` || window.location.pathname===`/${currentAppId}/${pageType}/` ? 'active-linkb' : 'cbi'}>
            <div>{currentAppVersion ? '(v'+currentAppVersion+')' : <SpinnerSmall/>}</div>
          </NavLink>

          <SpinnerSmall/>


          
        </div>
      }
    </div>

  )
}

export default CollectionButtonsItem