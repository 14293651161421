import ReactGA from "react-ga4";

export const initGA = (id) => {
    ReactGA.initialize(id);
}


//network error
export const networkError = () =>{
    ReactGA.event({
        category: "Network",
        action: "Network Error",
        label: "Server Error or Internet Connection",
    })
}


//sign in
export const signInSuccess = () =>{
    ReactGA.event({
        category: "Account",
        action: "Sign In Success",
        label: "User Sign In",
    })
} 
export const signInFail = () =>{//error.response.data.message
    ReactGA.event({
        category: "Account",
        action: "Sign In Failed",
        label: "User Sign In Failed",
    })
} 



//register
export const registerSuccess = () =>{
    ReactGA.event({
        category: "Account",
        action: "Register Success",
        label: "User Registered",
    })
} 
export const registerFail = () =>{//error.response.data.message
    ReactGA.event({
        category: "Account",
        action: "Register Failed",
        label: "User Register Failed",
    })
}



//profile
    //change password
    export const changePassSuccess = () =>{
        ReactGA.event({
            category: "Account",
            action: "Change Password Success",
            label: "User Changed Password Success",
        })
    } 
    export const changePassFail = () =>{
        ReactGA.event({
            category: "Account",
            action: "Change Password Failed",
            label: "User Change Password Failed",
        })
    } 
    
    
    //change email
    export const changeMailSuccess = () =>{
        ReactGA.event({
            category: "Account",
            action: "Change Mail Success",
            label: "User Changed Mail Success",
        })
    } 
    export const changeMailFail = () =>{
        ReactGA.event({
            category: "Account",
            action: "Change Mail Failed",
            label: "User Change Mail Failed",
        })
    }  


    //forgat Password
    export const forgatPassMailSent = () =>{
        ReactGA.event({
            category: "Account",
            action: "Forgat Pass Mail Sent",
            label: "User Forgat Pass Mail Sent",
        })
    }
    export const forgatPassMailFail = () =>{
        ReactGA.event({
            category: "Account",
            action: "Forgat Pass Mail Sent",
            label: "User Forgat Pass Mail Fail",
        })
    }
    export const forgatPassChangeSuccess = () =>{
        ReactGA.event({
            category: "Account",
            action: "Forgat Pass Change Success",
            label: "User Forgat Pass Change Success",
        })
    }
    export const forgatPassChangeFail = () =>{
        ReactGA.event({
            category: "Account",
            action: "Forgat Pass Change Failed",
            label: "User Forgat Pass Change Failed",
        })
    }


    //email confirmation
    export const mailConfirmSuccess = () =>{
        ReactGA.event({
            category: "Account",
            action: "Mail Confirm Success",
            label: "User Confirmed Email",
        })
    }
    export const mailConfirmFail = () =>{
        ReactGA.event({
            category: "Account",
            action: "Mail Confirm Failed",
            label: "User Confirm Email Failed",
        })
    }




//app
    //add app
    export const addAppSuccess = () =>{
        ReactGA.event({
            category: "App",
            action: "Add App Success",
            label: "User Added an App",
        })
    } 
    export const addAppFail = () =>{//error.response.data.message
        ReactGA.event({
            category: "App",
            action: "Add App Failed",
            label: "User Add App Failed",
        })
    } 



    //delete app
    export const deleteAppSuccess = () =>{
        ReactGA.event({
            category: "App",
            action: "Add App Success",
            label: "User Deleteed an App",
        })
    } 
    export const deleteAppFail = () =>{//error.response.data.message
        ReactGA.event({
            category: "App",
            action: "Delete App Failed",
            label: "User Delete App Failed",
        })
    } 



    //app version
    export const versionEditSuccess = () =>{
        ReactGA.event({
            category: "App",
            action: "Version Edit Success",
            label: "User Edited an App's Version",
        })
    } 
    export const versionEditFail = () =>{//error.response.data.message
        ReactGA.event({
            category: "App",
            action: "Version Edit Failed",
            label: "User Edited Version Failed",
        })
    } 
    export const versionNewSuccess = () =>{
        ReactGA.event({
            category: "App",
            action: "New Version Success",
            label: "User Created New Version",
        })
    } 
    export const versionNewFail = () =>{//error.response.data.message
        ReactGA.event({
            category: "App",
            action: "New Verison Failed",
            label: "User Created New Version Failed",
        })
    } 



    //team
        //add member
        export const addMemberSuccess = () =>{
            ReactGA.event({
                category: "App",
                action: "Add Member Success",
                label: "User Add Member Success",
            })
        }
        export const addMemberFail = () =>{//error.response.data.message
            ReactGA.event({
                category: "App",
                action: "Add Member Failed",
                label: "User Add Member Failed",
            })
        } 


        //edit member
        export const editMemberSuccess = () =>{
            ReactGA.event({
                category: "App",
                action: "Edit Member Role Success",
                label: "User Edit Member Success",
            })
        }
        export const editMemberFail = () =>{//error.response.data.message
            ReactGA.event({
                category: "App",
                action: "Edit Member Failed",
                label: "User Edit Member Failed",
            })
        } 


        //delete member
        export const deleteMemberSuccess = () =>{
            ReactGA.event({
                category: "App",
                action: "Edit Member Role Success",
                label: "User Edit Member Success",
            })
        }
        export const deleteMemberFail = () =>{//error.response.data.message
            ReactGA.event({
                category: "App",
                action: "Delete Member Failed",
                label: "User Delete Member Failed",
            })
        } 


        //transfer app
        export const transferOwnershipSuccess = () =>{
            ReactGA.event({
                category: "App",
                action: "Transfer App Ownership Success",
                label: "User Transfer App Ownership Success",
            })
        }
        export const transferOwnershipFail = () =>{//error.response.data.message
            ReactGA.event({
                category: "App",
                action: "Transfer App Ownership Failed",
                label: "User Transfer App Ownership Failed",
            })
        } 



//collections
    //create collection
    export const createCollection = (type) =>{
        ReactGA.event({
            category: "Collection",
            action: "Create Collection Success",
            label: "User Created Collection from Collections Page",
        })
        ReactGA.event({
            category: "Collection",
            action: "Create Collection"+" "+type,
            label: "User Created Collection from"+" "+type,
        })
    }


    export const createCollectionFail = () =>{//error.response.data.message
        ReactGA.event({
            category: "Collection",
            action: "Create Collection Failed",
            label: "User Create Collection Failed",
        })
    }


    //create from file
        //user has/not template file
        export const userHasTemplateFileCol = () =>{
            ReactGA.event({
                category: "Collection",
                action: "User NewCol Template Have",
                label: "User NewCol Dont Have Template",
            })
        }
        export const userHasNotTemplateFileCol = () =>{
            ReactGA.event({
                category: "Collection",
                action: "User NewCol Template Doesnt Have ",
                label: "User NewCol Has Template",
            })
        }


        //get template file details
        export const getTeplateOptionsColSuccess = () =>{
            ReactGA.event({
                category: "Collection",
                action: "Get NewCol Template Options Success",
                label: "User NewCol Get Template Options Success",
            })
        }
        export const getTeplateOptionsColFail = () =>{//error.response.data.message
            ReactGA.event({
                category: "Collection",
                action: "Get NewCol Template Options Failed",
                label: "User NewCol Get Template Options Failed",
            })
        }


        //download template
        export const downloadTemplateColSuccess = () =>{
            ReactGA.event({
                category: "Collection",
                action: "Download NewCol Template Success",
                label: "User NewCol Download Template Success",
            })
        }
        export const downloadTemplateColFail = () =>{//error.response.data.message
            ReactGA.event({
                category: "Collection",
                action: "Download NewCol Template Failed",
                label: "User NewCol Download Template Failed",
            })
        }


        //upload template file
        export const importProcessColSuccess = () =>{
            ReactGA.event({
                category: "Collection",
                action: "File NewCol Create Success",
                label: "User NewCol File Create Success",
            })
        }
        export const importProcessColFail = () =>{//error.response.data.message
            ReactGA.event({
                category: "Collection",
                action: "File NewCol Create Failed",
                label: "User NewCol File Create Failed",
            })
        }

    //rename collection
    export const renameCollection = () =>{
        ReactGA.event({
            category: "Collection",
            action: "Rename Collection Success",
            label: "User Renamed Collection",
        })
    }

    export const renameCollectionFail = () =>{//error.response.data.message
        ReactGA.event({
            category: "Collection",
            action: "Rename Collection Failed",
            label: "User Rename Collection Failed",
        })
    }


    //delete collection
    export const deleteCollectionSuccess = (type) =>{
        ReactGA.event({
            category: "Collection",
            action: "Delete Collection Success",
            label: "User Deleted Collection",
        })
        ReactGA.event({
            category: "Collection",
            action: "Delete Collection from"+" "+type,
            label: "User Deleted Collection from"+" "+type,
        })
    }
    export const deleteCollectionFail = () =>{//error.response.data.message
        ReactGA.event({
            category: "Collection",
            action: "Delete Collection Failed",
            label: "User Delete Collection Failed",
        })
    }


    //add localization
    export const addLanguage = () =>{
        ReactGA.event({
            category: "Collection",
            action: "Add Language Success",
            label: "User Added Language",
        })
    }

    export const addLanguageFail = () =>{//error.response.data.message
        ReactGA.event({
            category: "Collection",
            action: "Add Language Failed",
            label: "User Add Language Failed",
        })
    }


    //delete localization
    export const deleteLanguage = () =>{
        ReactGA.event({
            category: "Collection",
            action: "Add Language Success",
            label: "User Added Language",
        })
    }

    export const deleteLanguageFail = () =>{//error.response.data.message
        ReactGA.event({
            category: "Collection",
            action: "Add Language Failed",
            label: "User Add Language Failed",
        })
    }



//description
export const editDescriptionSuccess = () =>{
    ReactGA.event({
        category: "Description",
        action: "Edit Description Success",
        label: "User Edited Description",
    })
}
export const editDescriptionFail = () =>{//error.response.data.message
    ReactGA.event({
        category: "Description",
        action: "Edit Description Failed",
        label: "User Edit Description Failed",
    })
}



//screenshots
    //add
    export const addScreenshotSuccess = (size) =>{
        ReactGA.event({
            category: "Screenshot",
            action: size+" "+"Add Screenshot Success",
            label:  size+" "+"User Added Screenshot",
        })
        ReactGA.event({
            category: "Screenshot",
            action: "Add Screenshot Success",
            label:  "User Added Screenshot",
        })
    }
    export const addScreenshotRequestFail = (label) =>{
        ReactGA.event({
            category: "Screenshot",
            action: "Add Screenshot Failed",
            label: label,
        })
        ReactGA.event({
            category: "Screenshot",
            action: "Add Screenshot Failed, Request",
            label: label,
        })
    }
    export const addScreenshotCountFail = (label) =>{//error.response.data.message
        ReactGA.event({
            category: "Screenshot",
            action: "Add Screenshot Failed",
            label: label,
        })
        ReactGA.event({
            category: "Screenshot",
            action: "Add Screenshot Failed, Too Many",
            label: label,
        })
    }
    export const addScreenshotResFail = (label) =>{//error.response.data.message
        ReactGA.event({
            category: "Screenshot",
            action: "Add Screenshot Failed",
            label: label,
        })
        ReactGA.event({
            category: "Screenshot",
            action: "Add Screenshot Failed, Res",
            label: label,
        })
    }
    export const addScreenshotSizeFail = (label) =>{//error.response.data.message
        ReactGA.event({
            category: "Screenshot",
            action: "Add Screenshot Failed",
            label: label,
        })
        ReactGA.event({
            category: "Screenshot",
            action: "Add Screenshot Failed, Size",
            label: label,
        })
    }
    //delete
    export const deleteScreenshotSuccess = (size) =>{
        ReactGA.event({
            category: "Screenshot",
            action: size+" "+"Delete Screenshot Success",
            label:  size+" "+"User Deleted Screenshot",
        })
        ReactGA.event({
            category: "Screenshot",
            action: "Delete Screenshot Success",
            label: "User Deleted Screenshot",
        })
    }
    export const deleteAllScreenshotSuccess = (size) =>{
        ReactGA.event({
            category: "Screenshot",
            action: size+" "+"Delete All Screenshos Success",
            label:  size+" "+"User Deleted All Screenshots",
        })
        ReactGA.event({
            category: "Screenshot",
            action: "Delete All Screenshot Success",
            label: "User Deleted All Screenshots",
        })
    }
    export const deleteScreenshotFail = (size) =>{//error.response.data.message
        ReactGA.event({
            category: "Screenshots",
            action: "Delete Screenshots Failed",
            label: size+" "+"User Delete Screenshot Failed",
        })
    }
    export const deleteAllScreenshotFail = (size) =>{//error.response.data.message
        ReactGA.event({
            category: "Screenshot",
            action: "Delete All Screenshot Failed",
            label: size+" "+"User Delete All Screenshots Failed",
        })
    }
    export const changeScreenshotOrderSuccess = (size) =>{
        ReactGA.event({
            category: "Screenshot",
            action: size+" "+"Change Order Success",
            label: size+" "+"User Changed Screenshot Order",
        })
        ReactGA.event({
            category: "Screenshot",
            action: "Change Screenshot Order Success",
            label: "User Changed Screenshot Order",
        })
    }
    export const changeScreenshotOrderFail = (size) =>{
        ReactGA.event({
            category: "Screenshot",
            action: "Change Screenshot Order Failed",
            label: size+" "+"User Change Screenshot Order Failed",
        })
    }



//previews
    //add
    export const addPreviewSuccess = (size) =>{
        ReactGA.event({
            category: "Preview",
            action: size+" "+"Add Preview Success",
            label:  size+" "+"User Added Preview",
        })
        ReactGA.event({
            category: "Preview",
            action: "Add Preview Success",
            label:  "User Added Preview",
        })
    }
    export const addPreviewRequestFail = (label) =>{
        ReactGA.event({
            category: "Preview",
            action: "Add Preview Failed",
            label: label,
        })
        ReactGA.event({
            category: "Preview",
            action: "Add Preview Failed, Request",
            label: label,
        })
    }
    export const addPreviewCountFail = (label) =>{//error.response.data.message
        ReactGA.event({
            category: "Preview",
            action: "Add Preview Failed",
            label: label,
        })
        ReactGA.event({
            category: "Preview",
            action: "Add Preview Failed, Too Many",
            label: label,
        })
    }
    export const addPreviewResFail = (label) =>{//error.response.data.message
        ReactGA.event({
            category: "Preview",
            action: "Add Preview Failed",
            label: label,
        })
        ReactGA.event({
            category: "Preview",
            action: "Add Preview Failed, Res, Duration",
            label: label,
        })
    }
    export const addPreviewSizeFail = (label) =>{//error.response.data.message
        ReactGA.event({
            category: "Preview",
            action: "Add Preview Failed",
            label: label,
        })
        ReactGA.event({
            category: "Preview",
            action: "Add Preview Failed, Size",
            label: label,
        })
    }
    //delete
    export const deletePreviewSuccess = (size) =>{
        ReactGA.event({
            category: "Preview",
            action: size+" "+"Delete Preview Success",
            label:  size+" "+"User Deleted Preview",
        })
        ReactGA.event({
            category: "Preview",
            action: "Delete Preview Success",
            label: "User Deleted Preview",
        })
    }
    export const deleteAllPreviewSuccess = (size) =>{
        ReactGA.event({
            category: "Preview",
            action: size+" "+"Delete All Preview Success",
            label:  size+" "+"User Deleted All Preview",
        })
        ReactGA.event({
            category: "Preview",
            action: "Delete All Preview Success",
            label: "User Deleted All Preview",
        })
    }
    export const deletePreviewFail = (size) =>{//error.response.data.message
        ReactGA.event({
            category: "Preview",
            action: "Delete Preview Failed",
            label: size+" "+"User Delete Preview Failed",
        })
    }
    export const deleteAllPreviewFail = (size) =>{//error.response.data.message
        ReactGA.event({
            category: "Preview",
            action: "Delete All Preview Failed",
            label: size+" "+"User Delete All Preview Failed",
        })
    }
    //poster
    export const setPreviewPosterSuccess = (size) =>{
        ReactGA.event({
            category: "Preview",
            action: size+" "+"Set Preview Poster Success",
            label: size+" "+"Preview Poster Success",
        })
        ReactGA.event({
            category: "Preview",
            action: "Set Preview Poster Success",
            label: "User Set Preview Poster Success",
        })
    }
    export const setPreviewPosterFail = (size) =>{//error.response.data.message
        ReactGA.event({
            category: "Preview",
            action: "Set Preview Poster Failed",
            label: size+" "+"User Set Preview Poster Failed",
        })
    }
    export const changePreviewOrderSuccess = (size) =>{
        ReactGA.event({
            category: "Preview",
            action: "Change Preview Order Success",
            label: size+" "+"User Changed Preview Order",
        })
    }
    export const changePreviewOrderFail = (size) =>{
        ReactGA.event({
            category: "Preview",
            action: "Change Preview Order Failed",
            label: size+" "+"User Change Preview Order Failed",
        })
    }



//whatsnew
export const editWhatsNewSuccess = () =>{
    ReactGA.event({
        category: "WhatsNew",
        action: "Edit WhatsNew Success",
        label: "User Edited WhatsNew",
    })
}
export const editWhatsNewFail = () =>{//error.response.data.message
    ReactGA.event({
        category: "WhatsNew",
        action: "Edit WhatsNew Failed",
        label: "User Edit WhatsNew Failed",
    })
}



//keyword
export const editKeywordSuccess = () =>{
    ReactGA.event({
        category: "Keyword",
        action: "Edit Keyword Success",
        label: "User Edited Keyword",
    })
}
export const editKeywordFail = () =>{//error.response.data.message
    ReactGA.event({
        category: "Keyword",
        action: "Edit Keyword Failed",
        label: "User Edit Keyword Failed",
    })
}



//import 
    //user has/not template file
    export const userHasTemplateFile = (type) =>{
        ReactGA.event({
            category: "Import",
            action: "User Template Have",
            label: "User Has Template",
        })
        ReactGA.event({
            category: "Import",
            action: "User Template Have"+" "+type,
            label: type+" "+"User Has Template",
        })
    }
    export const userHasNotTemplateFile = (type) =>{
        ReactGA.event({
            category: "Import",
            action: "User Template Have Not",
            label: "User Has Not Template",
        })
        ReactGA.event({
            category: "Import",
            action: "User Template Doesnt Have"+" "+type,
            label: type+" "+"User doesnt have Template",
        })
    }


    //get template file details
    export const getTeplateOptionsSuccess = (type) =>{
        ReactGA.event({
            category: "Import",
            action: "Get Template Options Success",
            label: "User Get Template Options Success",
        })
        ReactGA.event({
            category: "Import",
            action: "Get Template Options Success"+" "+type,
            label: type+" "+"User Get Template Options Success",
        })
    }
    export const getTeplateOptionsFail = (type) =>{//error.response.data.message
        ReactGA.event({
            category: "Import",
            action: "Get Template Options Failed",
            label: type+" "+"User Get Template Options Failed",
        })
    }


    //download template
    export const downloadTemplateSuccess = (type) =>{
        ReactGA.event({
            category: "Import",
            action: "Download Template Success",
            label: "User Download Template Success",
        })
        ReactGA.event({
            category: "Import",
            action: "Download Template Success"+" "+type,
            label: type+" "+"User Download Template Success",
        })
    }
    export const downloadTemplateFail = (type) =>{//error.response.data.message
        ReactGA.event({
            category: "Import",
            action: "Download Template Failed",
            label: type+" "+"User Download Template Failed",
        })
    }


    //upload template file
    export const importProcessSuccess = (type) =>{
        ReactGA.event({
            category: "Import",
            action: "File Upload/Import Success",
            label: "User Upload/Import Template Success",
        })
        ReactGA.event({
            category: "Import",
            action: "File Upload/Import Success"+" "+type,
            label: type+" "+"User Upload/Import Template Success",
        })
    }
    export const importProcessFail = (type) =>{//error.response.data.message
        ReactGA.event({
            category: "Import",
            action: "File Upload/Import Failed",
            label: type+" "+"User Upload/Import Template Failed",
        })
    }



//export
    export const exportSuccess = (type) =>{
        ReactGA.event({
            category: "Export",
            action: "Export Success",
            label: "User Export Success",
        })
        ReactGA.event({
            category: "Export",
            action: "Export Success"+" "+type,
            label: type+" "+"Export Success",
        })
    }
    export const exportFail = (type) =>{
        ReactGA.event({
            category: "Export",
            action: "Export Failed",
            label: "User Export Failed",
        })
        ReactGA.event({
            category: "Export",
            action: "Export Failed"+" "+type,
            label: type+" "+"Export Failed",
        })
    }



//upload
    //initiate upload
    export const uploadStarted = (type) => {
        ReactGA.event({
            category: "Upload",
            action: "Upload Started",
            label: "User Initiated an Upload",
        })
        ReactGA.event({
            category: "Upload",
            action: type+" "+"Upload Started",
            label: "User Initiated"+" "+type+" "+"Upload",
        })
    }
    export const uploadStartFail = (type) => {
        ReactGA.event({
            category: "Upload",
            action: "Upload Start Failed",
            label: "User Initiate an Upload Failed",
        })
        ReactGA.event({
            category: "Upload",
            action: type+" "+"Upload Failed to Start",
            label: "User Initiated"+" "+type+" "+"Upload",
        })
    }


    //upload finished(user saw)
    export const uploadFinished = (type) => {
        ReactGA.event({
            category: "Upload",
            action: "Upload Finished",
            label: "User Confirmed Upload Finish",
        })
        ReactGA.event({
            category: "Upload",
            action: type+" "+"Upload Finished",
            label: "User Confirmed"+" "+type+" "+"Upload Finish",
        })
    }


    //upload error
    export const uploadError = (type) => {
        ReactGA.event({
            category: "Upload",
            action: "Upload Error",
            label: "There Was An Upload Error",
        })
        ReactGA.event({
            category: "Upload",
            action: type+" "+"Upload Error",
            label: "There was a"+" "+type+" "+"Upload Error",
        })
    }
    export const joinError = (type) => {
        ReactGA.event({
            category: "Upload",
            action: "Upload Join Error",
            label: "There Was An Join Error",
        })
        ReactGA.event({
            category: "Upload",
            action: type+" "+"Upload Join Error",
            label: "There was a"+" "+type+" "+"Join Error",
        })
    }


    //socket error
    export const socketError = (type) => {
        ReactGA.event({
            category: "Upload",
            action: "Socket"+" "+type,
            label: "There was a"+" "+type+" "+"Error",
        })
    }



//subscribe
    //purchased
    export const checkoutFinished = (type) =>{
        ReactGA.event({
            category: "Subscription",
            action: type+" "+"Started",
            label: "User Started"+" "+type,
        })
    } 
    
    
    //checkout started
    export const checkoutStarted = (type) =>{
        ReactGA.event({
            category: "Subscription",
            action: "Checkout Started"+" "+type,
            label: "User Started"+" "+type+" "+"Checkout",
        })
        ReactGA.event({
            category: "Subscription",
            action: "Checkout Started",
            label: "User Started Checkout",
        })
    }      

    //checkout cancelled
    export const checkoutCanceled = (type) =>{
        ReactGA.event({
            category: "Subscription",
            action: "Checkout Canceled"+" "+type,
            label: "User Canceled"+" "+type+" "+"Checkout",
        })
        ReactGA.event({
            category: "Subscription",
            action: "Checkout Canceled",
            label: "User Canceled Checkout",
        })
    }   



//help
export const sendMessageSuccess = () =>{
    ReactGA.event({
        category: "Help",
        action: "Messege Sent",
        label: "User Sent Message Help Center",
    })
}
export const sendMessageFail = () =>{
    ReactGA.event({
        category: "Help",
        action: "Messege Failed",
        label: "User Sent Message Help Center Failed",
    })
}