import { useLayoutEffect, useState, useContext } from 'react'
// import { NavLink } from 'react-router-dom'
import axios from 'axios'
import VariableContext from '../userContext/VariableContext';
import Spinner from '../Misc/Spinner'
import SpinnerMid from '../Misc/SpinnerMid'
import SpinnerSmall from '../Misc/SpinnerSmall'
import {useParams, useNavigate} from 'react-router-dom'
import CollectionScreenshotNotLocalItem from '../Items/CollectionItems/Screenshot/CollectionScreenshotNotLocalItem'
import CollectionScreenshotLocalItem from '../Items/CollectionItems/Screenshot/CollectionScreenshotLocalItem'
import './CollectionScreenshotPage.css'
import langJSON from '../flagsLanguages/lang.json'
import CollectionScreenshotItem from '../Items/CollectionItems/Screenshot/CollectionScreenshotItem';
import CollectionScreenshotIpadItem from '../Items/CollectionItems/Screenshot/CollectionScreenshotIpadItem';
import { fireCustomRequestError, sortResponse, sortResponseCol, fireCustomError, sortValue, fireSuccessMessage, fireExportMessage, openChangeCollectionNamePopup, fireRequestError, fireCustomErrorV2 } from '../Common Functions/commonFunctions';
import ChangeCollectionNamePopup from '../Popup/ChangeCollectionNamePopup'
import ColMenu from '../Components/ColMenu';
import jurisdiction from '../Jusristiction/juristiction';
import ImportZip from '../Popup/ImportZip';
import AskScreenshotUpload from '../Popup/AskScreenshotUpload';
import Export from '../Popup/Export';
import Swal from 'sweetalert2';
import { statusListMetadata } from '../StatusList/status';
import * as gaEvents from "../ga-utils"

function CollectionScreenshotPage({pageType}) {
    const[isWaiting, setIsWaiting] = useState(false)
    const[isWaitingSets, setIsWaitingSets] = useState(false)
    const[isWaitingSets2, setIsWaitingSets2] = useState(false)
    const[isWaitingSets3, setIsWaitingSets3] = useState(false)
    const[isWaitingSets4, setIsWaitingSets4] = useState(false)

    const[isWaiting3, setIsWaiting3] = useState(false)

    const[localizationId, setLocalizationId] = useState(null)
    const[primaryLocalizationId, setPrimaryLocalizationId] = useState(null)
    const[primary_locale, setPrimary_Locale] = useState(null)
    const[primaryName, setPrimaryName] = useState(null)

    const[primaryScreenshots, setPrimaryScreenshots] = useState(null)
    const[screenshots, setScreenshots] = useState(null)

    const[primaryPreviews, setPrimaryPreviews] = useState(null)
    const[previews, setPreviews] = useState(null)

    const {language, setLanguage} = useContext(VariableContext)
    const {showDevices, setShowDevices} = useContext(VariableContext)
    const [name, setName] = useState('')

    const [uploadQuestion, setUploadQuestion] = useState(false)

    const {collectionScreenshotLocalizations, setCollectionScreenshotLocalizations} = useContext(VariableContext)
    const {collectionNotLocalizations, setCollectionNotLocalizations} = useContext(VariableContext)
    const {languageSignal} = useContext(VariableContext)
    const {currentAppPrimaryLocale} = useContext(VariableContext)
    const {currentAppRole} = useContext(VariableContext)
    const {subWarning} = useContext(VariableContext)
    const {setCurrentAppLocalizations}= useContext(VariableContext)

    const {isUploading} = useContext(VariableContext)
    const {isUploadingPhoto} = useContext(VariableContext)
    const {isUploadingPreview} = useContext(VariableContext)
    const {setUploadSignal} = useContext(VariableContext)

    const {getColList, setGetColList} = useContext(VariableContext)
    const {setCurrentAppCols} = useContext(VariableContext)
    const {appCol} = useContext(VariableContext)

    const {importPercentage, setImportPercentage} = useContext(VariableContext)
    const {importArray, setImportArray} = useContext(VariableContext)

    const {subscriptionLimits} = useContext(VariableContext)

    let {appId,collectionId} = useParams()
    let navigate = useNavigate()

    const baseIpadList = [
        {deviceName: 'APP_IPAD_PRO_3GEN_11', size: 4},
        {deviceName: 'APP_IPAD_105', size: 2},
        {deviceName: 'APP_IPAD_97', size: 1}
    ]
    
    const baseDeviceList = [
        'APP_IPHONE_67',
        'APP_IPHONE_61',
        'APP_IPHONE_58',
        'APP_IPHONE_47',
        'APP_IPHONE_40',
        'APP_IPHONE_35'
    ]

    const requiredDevices = [
        'APP_IPHONE_65',
        'APP_IPHONE_55'
    ]
    const requiredDevicesIpad = [
        {deviceName: 'APP_IPAD_PRO_3GEN_129', size: 5},
        {deviceName: 'APP_IPAD_PRO_129', size: 3},
    ]
        
    
    
    // get locale code and arrange capital letters
    let makeLocaleLetters = (arr) =>{
        let a = arr.toUpperCase()
        let b = arr
        let c = ''
        if(a.length<=5){
            if(a.length===2){
                return arr
            }
            else{
                c =b.slice(0,3).concat(a.slice(3, arr.length))
            }
        }
        else if (arr.length>5){
            a = a.slice(3, 4);
            b = b.slice(0, 3);
            c = arr.slice(4,arr.length)
            c = b.concat(a).concat(c)
        }
        return c
    }



    //get collection localizations
    const getCollectionLocalizations = async (controller)=>{
            if(currentAppPrimaryLocale){
            setIsWaiting(true)
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/collection/language/${appId}/${collectionId}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },signal: controller.signal
            };
            
            axios(config)
            .then(function (response) {
                setCollectionScreenshotLocalizations(sortResponseCol(response.data.data, currentAppPrimaryLocale))
                let temp1= sortResponseCol(response.data.data, currentAppPrimaryLocale)
                let temp2 = temp1[0].language_code.toLowerCase()
                setPrimaryName(langJSON[temp2].nameEnglish)
                setPrimary_Locale(temp1[0].language_code)
                setPrimaryLocalizationId(temp1[0].screenshot_localization_id)
                // if(language===''){
                //     setLanguage(temp1[0].language_code.toLowerCase())
                //     setName(temp1[0].locale_name)
                //     setLocalizationId(temp1[0].screenshot_localization_id)
                // }
                // else{
                //     if(temp1.find((i)=>i.language_code.toLowerCase()===language.toLowerCase())){
                //         setLocalizationId(response.data.data.find(local=> local.language_code.toLowerCase()===language.toLowerCase()).screenshot_localization_id)
                //         setName(response.data.data.find(local=> local.language_code.toLowerCase()===language.toLowerCase()).locale_name)
                //     }
                //     else{
                //         setLanguage(temp1[0].language_code.toLowerCase())
                //         setName(temp1[0].locale_name)
                //         setLocalizationId(temp1[0].screenshot_localization_id)
                //     }
                // }
                if(!language.get(`${appId}`)){
                    setLanguage(new Map (language.set(`${appId}`, temp1[0].language_code.toLowerCase())))
                    setName(temp1[0].locale_name)
                    setLocalizationId(temp1[0].screenshot_localization_id)
                }
                else{
                    if(temp1.find((i)=>i.language_code.toLowerCase()===language.get(`${appId}`).toLowerCase())){
                        setLocalizationId(response.data.data.find(local=> local.language_code.toLowerCase()===language.get(`${appId}`).toLowerCase()).screenshot_localization_id)
                        setName(response.data.data.find(local=> local.language_code.toLowerCase()===language.get(`${appId}`).toLowerCase()).locale_name)
                    }
                    else{
                        setLanguage(new Map (language.set(`${appId}`, temp1[0].language_code.toLowerCase())))
                        setName(temp1[0].locale_name)
                        setLocalizationId(temp1[0].screenshot_localization_id)
                    }
                }
                setIsWaiting(false) 
            })
            .catch(function (error) {
                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
            });
        }
    }

    const getCollectionNotLocalizations = async (controller) =>{
        if(currentAppPrimaryLocale){
            setIsWaiting(true)
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/collection/import/options?appId=${appId}&collectionId=${collectionId}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },sinal: controller.signal
            };
            
            axios(config)
            .then(function (response) {
                setCollectionNotLocalizations(sortValue(response.data.data.textualData.notHas))
                setIsWaiting(false)
            })
            .catch(function (error) {
                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
            });
          }
    }
    useLayoutEffect(()=>{
        const controller = new AbortController();
        Promise.all(
            [getCollectionLocalizations(controller), getCollectionNotLocalizations(controller)]
        )
        return ()=>{
            controller.abort()
        }
    },[appId, collectionId, currentAppPrimaryLocale, getColList, languageSignal])



    //getScreenshots
    const getColScreenshots = async (controller) =>{
        if(localizationId){
            setIsWaitingSets(true)
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/array/${appId}/${collectionId}/${makeLocaleLetters(language.get(`${appId}`).toLowerCase())}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },signal: controller.signal
            };
            axios(config)
            .then(function (response) {               
                    setScreenshots(response.data.data.sizes)
                    if(primaryLocalizationId=== localizationId){
                        setPrimaryScreenshots(response.data.data.sizes)
                    }
                    setIsWaitingSets(false)
            })
            .catch(function (error) {
                if(error.code === 'ERR_CANCELED'){
                    //do nothing
                }
                else{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                }
                // setIsWaitingSets(false)
            })
        }
    }
    const getColPriScreenshots = async (controller) =>{
        if(primaryLocalizationId && localizationId!==primaryLocalizationId){
            setIsWaitingSets2(true)

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/array/${appId}/${collectionId}/${primary_locale}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },signal: controller.signal
            };
            axios(config)
            .then(function (response) {
                setPrimaryScreenshots(response.data.data.sizes)     
                setIsWaitingSets2(false)  
            })
            .catch(function (error) {
                if(error.code === 'ERR_CANCELED'){
                    //do nothing
                }
                else{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                }
                // setIsWaitingSets2(false)
            })
        }
    }
    //getPreviews
    const getColPreviews = async (controller) =>{
        if(localizationId){
            setIsWaitingSets3(true)
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/collection/preview/array/${appId}/${collectionId}/${makeLocaleLetters(language.get(`${appId}`).toLowerCase())}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },signal: controller.signal
            };
            axios(config)
            .then(function (response) {               
                    setPreviews(response.data.data.sizes)
                    if(primaryLocalizationId=== localizationId){
                        setPrimaryPreviews(response.data.data.sizes)
                    }
                    setIsWaitingSets3(false)
            })
            .catch(function (error) {
                if(error.code === 'ERR_CANCELED'){
                    //do nothing
                }
                else{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                }
                // setIsWaitingSets(false)
            })
        }
    }
    const getColPriPreviews = async (controller) =>{
        if(primaryLocalizationId && localizationId!==primaryLocalizationId){
            setIsWaitingSets4(true)

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/collection/preview/array/${appId}/${collectionId}/${primary_locale}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },signal: controller.signal
            };
            axios(config)
            .then(function (response) {
                setPrimaryPreviews(response.data.data.sizes)     
                setIsWaitingSets4(false)  
            })
            .catch(function (error) {
                if(error.code === 'ERR_CANCELED'){
                    //do nothing
                }
                else{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                }
                // setIsWaitingSets2(false)
            })
        }
    }
    useLayoutEffect(()=>{
        const controller = new AbortController();
        Promise.all(
            [getColScreenshots(controller),getColPriScreenshots(controller), getColPreviews(controller), getColPriPreviews(controller)]
        )
        return ()=>{
            controller.abort()
        }
    },[language ,localizationId, getColList, languageSignal])



    //open dropdown
    const handleClickk = () =>{   
        const localList = document.getElementById("opacityDiv")
        const localListIcon = document.getElementById('screenshotSelectLocalIcon')
        localListIcon.classList.toggle("collection-screenshot-local-list-rotate-icon")
        localList.classList.toggle('collection-screenshot-local-list-visible')
        window.addEventListener('click', closeLocalListt)

  
    }
    //closing dropdown
    const closeLocalListt = (event) =>{
        
            if ((event.target.className !== "collection-screenshot-local-item") && (event.target.className !== "collection-screenshot-select-local") && (event.target.className !== "collection-screenshot-select-local-icon") && (event.target.className !== "collection-screenshot-select-local-language") && (event.target.className !== "collection-screenshot-select-local-icon collection-screenshot-local-list-rotate-icon") && (event.target.className !== "collection-screenshot-local-list collection-screenshot-local-list-visible") && (event.target.className !== "collection-screenshot-local-list")) 
            
            {
                window.removeEventListener('click', closeLocalListt)
                const localList = document.getElementById("opacityDiv")
                const localListIcon = document.getElementById('screenshotSelectLocalIcon')
                if(localListIcon){localListIcon.classList.remove("collection-screenshot-local-list-rotate-icon")}
                if(localList){localList.classList.remove('collection-screenshot-local-list-visible')}
                
            }
            
    }
    //get language and localId from language dropdown click, this function is get parameters from child component
    const handle = (localizationId, locale, name) =>{
        setLocalizationId(localizationId)
        // setLanguage(locale.toLowerCase())
        setLanguage(new Map (language.set(`${appId}`, locale.toLowerCase())))
        setName(name)
    }

    //show hide optional media devices 
    const showHideDevices = () =>{
        setShowDevices(!showDevices)
    }

    const uploadScreenshots = () =>{
        Swal.fire({
            icon: 'question',
            title: 'Upload Initiated',
            position: 'center',
            width: '400',
            text: 'You are about to upload this collections screenshots and/or previews to App Store Connect. Are you sure?',
            backdrop: true,
            showConfirmButton: true,
            showCancelButton: true,     
            confirmButtonColor: '#3A6FF8e6',
            cancelButtonColor: '#cf000fe6',
            cancelButtonText: 'Cancel',      
            confirmButtonText: 'Upload',
            showLoaderOnConfirm: true,     
            preConfirm: async () => {
                setIsWaiting3(true)  
                var config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}/app/${appId}`,
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                    
                };
                try {
                    const response = await axios(config);
                    if(response.status === 200){
                        setCurrentAppLocalizations(sortResponse(response.data.data))
                        if(statusListMetadata.includes(response.data.data.attributes.appStoreState)){
                            setUploadQuestion(true) 
                        }
                        else{
                            gaEvents.uploadStartFail("pageType")
                            fireCustomErrorV2(`You can not upload any metadata to an app which is ${response.data.data.attributes.appStoreState}`)
                        }
                        setIsWaiting3(false)   
                    }
                } catch (error) {
                    gaEvents.uploadStartFail("pageType")
                    setIsWaiting3(false)
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                    }else{
                      fireRequestError(error)
                    } 
                }
              },
              allowOutsideClick: () => !Swal.isLoading()
            })
    }

    const func = (bool) =>{
        setUploadQuestion(bool)
    }

    
      const exportCollection = () =>{
        if(pageType!=='screenshot'){
          fireExportMessage()
          var data = '';
      
          var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/${appId}`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            setCurrentAppCols(response.data.data)
      
            var data = JSON.stringify({
              "appId": appId,
              "collectionId": collectionId,
              "title": response.data.data.find((i)=>i.collection_id===+collectionId).collection_title
            });
            
            var config = {
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/app/collection/${pageType==='whatsnew' ? 'whatsNew' : pageType}/export`,
              headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios(config)
            .then(function (response) {
                gaEvents.exportSuccess(pageType)
                let a = document.createElement('a');
                a.href = `${process.env.REACT_APP_API_URL}/${response.data.data.path}`;
                a.download = `${response.data.data.path}`.split("/")[2]
                a.click();
                Swal.close()
            }).catch(function (error) {
                gaEvents.exportFail(pageType)
                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
              });
        })
          .catch(function (error) {
            gaEvents.exportFail(pageType)
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
          });
        }else{
          document.getElementById('exportAppPopup'+collectionId).style.display = 'flex'
        }
        
      }
    
      const importCollection = () =>{
          document.getElementById('importAppPopup'+collectionId).style.display = 'flex'
      }


        return(<>
        <div id='screenShotInner' className="screenshot-inner-content"> 
            <div style={{padding:'18px 0'}} className='language-menu-div'>
                <div className='language-menu-div-inner'
                            style={importArray.get(`${appId}-${collectionId}-${pageType}`) || 
                            (isUploading.status&&isUploading.collectionID === +collectionId) || 
                            (isUploadingPreview.status&&isUploadingPreview.collectionID === +collectionId) || 
                            (isUploadingPhoto.status&&isUploadingPhoto.collectionID === +collectionId) ||         
                            (subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.includes(+collectionId) : false) ||
                            (subWarning ? subWarning.status==="expiring" || subWarning.status==="banned" : false ) ?  
                            {justifyContent:'space-between', width:'100%', marginLeft:'25px'} : {marginLeft:'25px'}}>

                    {/* {importArray.get(`${appId}-${collectionId}-${pageType}`) ? 
                        <span className='edit-warning' style={{display: 'flex', alignItems: 'center'}}><SpinnerSmall/><img style={{marginRight:'10px'}} src="/assets/Icons/excla.svg" alt="!!!" />Importing...&nbsp;{importPercentage.get(`${appId}-${collectionId}-${pageType}`)}</span>
                    : 
                        ''
                    } */}


                    {subWarning ? 
                        subWarning.status==="expiring" ?
                            jurisdiction.role.includes(currentAppRole) ?
                                <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                            : 
                                <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner's subscription is expiring.</span>
                        : subWarning.status==="banned" ?
                            jurisdiction.role.includes(currentAppRole) ?
                                <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                            : 
                                <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', marginLeft:'0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner's subscription has expired. This application is read only.</span>
                        :     
                            ''
                    :
                        ''
                    }


                    {isUploading.status&&true ? isUploading.collectionID === +collectionId ? 
                        <span className='edit-warning'><img style={{marginRight:'10px'}} src="/assets/Icons/excla.svg" alt="!!!" /><span className='resposive-edit-warning-inner'>&nbsp;Upload</span><span className='edit-warning-inner'>You can not edit this collection while is being uploaded</span></span>
                    :
                        ''
                    : 
                        ''
                    }
                    {(isUploadingPhoto.status||isUploadingPreview.status)&&true ? (isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId) ? 
                        <span className='edit-warning'><img style={{marginRight:'10px'}} src="/assets/Icons/excla.svg" alt="!!!" /><span className='resposive-edit-warning-inner'>&nbsp;Upload</span><span className='edit-warning-inner'>You can not edit screenshots or previews while they are being uploaded</span></span>
                    :
                        ''
                    : 
                        ''
                    }

                    {subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.includes(+collectionId) ? 
                        jurisdiction.role.includes(currentAppRole) ? 
                            <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />You have more collections than what your account type permits. Some of the features concerning this collection are disabled.</span>
                        :
                            <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />There are more collections than what owner's account type permits. Some of the features concerning this collection are disabled.</span>   
                    : 
                        ""
                    : 
                        ""
                    }



                    <div 
                        style={importArray.get(`${appId}-${collectionId}-${pageType}`) ||
                            (isUploading.status&&isUploading.collectionID === +collectionId) || 
                            (isUploadingPreview.status&&isUploadingPreview.collectionID === +collectionId) || 
                            (isUploadingPhoto.status&&isUploadingPhoto.collectionID === +collectionId) ||
                            (subscriptionLimits ? subscriptionLimits.currentApp.unavailableCollections.includes(+collectionId) : false) ||
                            (subWarning ? subWarning.status==="expiring" || subWarning.status==="banned" : false ) ? 
                            {display:'flex', alignItems:'center', justifyContent:'center', gap:'10px', marginLeft:"auto"} : {display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}
                    >

                    {!isWaiting && screenshots && primaryScreenshots && previews && primaryPreviews ? 
                        <>
                            {jurisdiction.collection.includes(currentAppRole) ?
                                <>
                                    <button disabled={isUploading.status || isUploadingPhoto.status || isUploadingPreview.status} onClick={uploadScreenshots} className='col-menu-upload'>{isWaiting3 || isUploading.status || isUploadingPhoto.status || isUploadingPreview.status ? <SpinnerSmall/> : 'Upload Media'}</button>
                                    <div className='divider-menu'></div>

                                </>
                            : 
                                ''
                            }
                            <button disabled={isUploading.collectionID===+collectionId || isUploadingPhoto.collectionID === +collectionId  || isUploadingPreview.collectionID === +collectionId} onClick={importCollection} className='col-menu-button'>Import</button>
                            <button onClick={exportCollection} className='col-menu-button-ex'>Export</button>
                        </> 
                    : ''}

                </div>          




                </div>
                <span className='language-menu-language'>
                    <div id='screenshotSelectLocal' onClick={()=>{handleClickk()}} className='collection-screenshot-select-local'>
                        <span className='collection-screenshot-select-local-language'>{!isWaiting ? name : <SpinnerSmall/>}</span>
                        <img id='screenshotSelectLocalIcon' className='collection-screenshot-select-local-icon' src="/assets/Icons/down-arrow-black.svg" alt="AppImage" />
                    </div>
                    <div id='opacityDiv' className="collection-opacity-div">   
                        <div id='arrowUp' className='collection-arrow-up'></div>
                        <div id='arrowUp2' className='collection-arrow-up2'></div>
                        <div id='screenshotLocalList' className="collection-screenshot-local-list">
                            {!isWaiting ? 
                                <>
                                    <span style={{padding:'15px 0 0 10px', color:'#bbb'}}>LOCALIZED</span>

                                    {!collectionScreenshotLocalizations ? '' :collectionScreenshotLocalizations.map((data, index)=>{
                                        return (
                                            <CollectionScreenshotLocalItem func={!isWaitingSets ? handle : ()=>{}} key={index+'x'} data={data} count={index} class_name={!isWaitingSets ? 'screenshot-local-item' : 'screenshot-local-item-disabled'}></CollectionScreenshotLocalItem>
                                        )
                                    })}

                                    <span style={{padding:'0px 10px', color:'#bbb'}}>NOT LOCALIZED</span>

                                    {!collectionNotLocalizations ? '' :collectionNotLocalizations.map((data, index)=>{
                                    return (
                                    
                                        <CollectionScreenshotNotLocalItem func={!isWaitingSets ? handle : ''} key={index+'y'} data={data} count={index} class_name={!isWaitingSets ? 'screenshot-local-item-not' : 'screenshot-local-item-not-disabled'}
                                        ></CollectionScreenshotNotLocalItem>
                                    
                                    )
                                })}

                                </>
                            :
                            <div className="spinner">
                                <SpinnerMid/>
                            </div>
                            }
                        </div>
                    </div> 
                </span>

                
            </div>
            <div className="collection-screenshot-select-div">
           
              


            </div>





            {!isWaitingSets && !isWaitingSets2 && !isWaitingSets3 && !isWaitingSets4 ? 
                !(screenshots && primaryScreenshots && previews && primaryPreviews) ? <div className='spinner'><Spinner/></div> :
       
            requiredDevices.map((device, index)=>{
                if(index===0){
                    return(
                        <span className='encap-media' key={index}>
                            
                            <CollectionScreenshotItem uniqueId={index+'colItemReq'} classId='colItem' languageCode={makeLocaleLetters(language.get(`${appId}`).toLowerCase())} localizationId={localizationId} deviceSize={device} screenshots={screenshots} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} previews={previews} primaryLocalePreviews={primaryPreviews} devicePreviewSize={device.slice(4)} 
                            />
                        </span>
                    )
                }else{
                    return(
                        <span className='encap-media' key={index}>
                            
                            <CollectionScreenshotItem uniqueId={index+'colItemReq'} classId='colItem' languageCode={makeLocaleLetters(language.get(`${appId}`).toLowerCase())} localizationId={localizationId} deviceSize={device} screenshots={screenshots} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} previews={previews} primaryLocalePreviews={primaryPreviews} devicePreviewSize={device.slice(4)} 
                            />
                        </span>
                    )
                }
            })
            : 
                <div className='spinner'><Spinner/></div>
            }

            {!isWaitingSets && !isWaitingSets2 && !isWaitingSets3 && !isWaitingSets4 ? 
                !(screenshots && primaryScreenshots && previews && primaryPreviews) ? '' :

                    requiredDevicesIpad.map((device, index)=>{
                        if(index===0){
                            return(
                                <span className='encap-media' key={index}>
                                    
                                    <CollectionScreenshotIpadItem uniqueId={index+'colIpadItemReq'} classId='colIpadItem' languageCode={makeLocaleLetters(language.get(`${appId}`).toLowerCase())} localizationId={localizationId} deviceSize={device.deviceName} size={device.size} screenshots={screenshots} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} previews={previews} primaryLocalePreviews={primaryPreviews} devicePreviewSize={device.deviceName.slice(4)}
                                    />
                                </span>
                            )
                        }
                        else{
                            return(
                                <span className='encap-media' key={index}>
                                    <CollectionScreenshotIpadItem uniqueId={index+'colIpadItemReq'} classId='colIpadItem' languageCode={makeLocaleLetters(language.get(`${appId}`).toLowerCase())} localizationId={localizationId} deviceSize={device.deviceName} size={device.size} screenshots={screenshots} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} previews={previews} primaryLocalePreviews={primaryPreviews} devicePreviewSize={device.deviceName.slice(4)}
                                    />
  
                                </span>
                            )
                        }

                    })
            : 
                ''
            }

                {!isWaitingSets && !isWaitingSets2 && !isWaitingSets3 && !isWaitingSets4 ? 
                    !(screenshots && primaryScreenshots && previews && primaryPreviews) ? '' :

                        <span className='show-media-devices'>
                            <span id='showHideMedia' onClick={()=>showHideDevices()}>
                                {!showDevices ? 'Show all media devices' : 'Hide media devices'}
                            </span>
                        </span>
                    : 
                        ''
                }
{/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            {!isWaitingSets && !isWaitingSets2 && !isWaitingSets3 && !isWaitingSets4 ? 
                !(screenshots && primaryScreenshots && previews && primaryPreviews) ? '' :
       
            baseDeviceList.map((device, index)=>{      
                    return(
                        <span className={!showDevices ? 'hidden-devices' : 'visible-devices encap-media'} key={index}>
                            
                            <CollectionScreenshotItem uniqueId={index+'colItem'} classId='colItem' languageCode={makeLocaleLetters(language.get(`${appId}`).toLowerCase())} localizationId={localizationId} deviceSize={device} screenshots={screenshots} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} previews={previews} primaryLocalePreviews={primaryPreviews} devicePreviewSize={device.slice(4)} 
                            />
                        </span>
                    )
            })
            : 
                ''
            }


            {!isWaitingSets && !isWaitingSets2 && !isWaitingSets3 && !isWaitingSets4 ? 
                !(screenshots && primaryScreenshots && previews && primaryPreviews) ? '' :

                    baseIpadList.map((device, index)=>{
                            return(
                                <span className={!showDevices ? 'hidden-devices' : 'visible-devices encap-media'} key={index}>

                                    <CollectionScreenshotIpadItem uniqueId={index+'colIpadItem'} classId='colIpadItem' languageCode={makeLocaleLetters(language.get(`${appId}`).toLowerCase())} localizationId={localizationId} deviceSize={device.deviceName} size={device.size} screenshots={screenshots} primaryLocaleCode={primary_locale} primaryLanguageName={primaryName} primaryLocaleScreenshots={primaryScreenshots} previews={previews} primaryLocalePreviews={primaryPreviews} devicePreviewSize={device.deviceName.slice(4)}
                                    />
                                </span>
                            )
                    })
            : 
                ''
            }
            


            <ChangeCollectionNamePopup colId={collectionId}/>

        </div>
        <ColMenu pageType={pageType}/>
        <ImportZip pageType={pageType} colId={collectionId}/>
        <Export pageType={pageType} colId={collectionId}/>
        {uploadQuestion ? 
            <AskScreenshotUpload func={func}/>
        :
            ''
        }
        </>
        
        )
}   

export default CollectionScreenshotPage