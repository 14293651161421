import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import VariableContext from '../userContext/VariableContext';
import {fireCustomError, fireRequestError} from '../Common Functions/commonFunctions'
import SpinnerSmall from "../Misc/SpinnerSmall";
import GoBackButton from "../Components/GoBackButton";
import './HelpCenter.css'
import * as gaEvents from "../ga-utils"
import Swal from "sweetalert2";

function HelpCenter() {
    const {initialUserData, setInitialUserData} = useContext(VariableContext)
    const [contactEmail, setContactEmail] = useState('')
	const [contactName, setContactName] = useState('')
	const [contactMessage, setContactMessage] = useState('')
    const [isWaiting, setIsWaiting] = useState(false)

    let navigate = useNavigate()

    //validate token and get user info
    useEffect(()=>{
    if(localStorage.getItem("token")){
        
        var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/validate?code_usage=true`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        };
        axios(config)
        .then(function (response) {
            setInitialUserData(response.data.data)
            localStorage.setItem("token", response.data.data.token)
        })
        .catch(function (error) {
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
            localStorage.removeItem("token")
            navigate('/login')
        });
    }else {
        navigate('/login')
    }
    },[])

	const sendMessage = (e) =>{
		e.preventDefault()
		setIsWaiting(true)
		var data = JSON.stringify({
			"email":contactEmail,
			"subject":`Message From StoreSyncer App - ${contactName} - ${contactEmail}`,
			"message": contactMessage
		});

		var config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_API_URL}/support`,
			headers: {'Content-Type': 'application/json' },
			data : data
		};

		axios(config)
		.then(function (response) {
            gaEvents.sendMessageSuccess()
		    setIsWaiting(false)
			setContactEmail('')
			setContactName('')
			setContactMessage('')
            Swal.fire({
                position: 'top',
                width: 'max-content',
                html: 'Message Sent',
                color: 'white',
                background: '#71c456',
                showConfirmButton: false,
                showCancelButton: false, 
                timer: 2000,
                toast: true  
            })
		})
		.catch(function (error) {
		    setIsWaiting(false)
            gaEvents.sendMessageFail()
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
		});

	}

    
 

  return (
    <div className="help-content">
        <div className="help-title-div titleArea">
            <GoBackButton/>
            <h1>Help Center</h1>
        </div>
        <div className="help-inner-container">
            {initialUserData ? initialUserData.accountSubsStatus.status!=='active' ?
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', margin:'0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{initialUserData.accountSubsStatus.message}</span>
                : 
                    ''
                : 
                    ''
            }

            <div className="help-contact">
                <div className="help-contact-inner">
                    <div className="help-contact-div support-div">
                        <h2>Tech Support</h2>
                        <img src="/assets/Icons/support.svg" alt="support_icon" />
                        <p>Are you having trouble?</p>
                        <a href="mailto:support@storesyncer.com">support@storesyncer.com</a>
                    </div>
                    <div className="help-contact-div info-div">
                        <h2>Questions & Feedback</h2>
                        <img src="/assets/Icons/ask.svg" alt="ask_icon" />
                        <p>Have questions, feedback?</p>
                        <a href="mailto:info@storesyncer.com">info@storesyncer.com</a>
                    </div>
                    <div className="help-contact-div info-div">
                        <h2>Documentation</h2>
                        <img src="/assets/Icons/doc.svg" alt="doc_icon" />
                        <p>All you need to learn.</p>
                        <a target="_blank" rel="noopener noreferrer" href="https://doc.storesyncer.com">doc.storesyncer.com</a>
                    </div>
                </div>
            </div>

            <div className="help-form">
                
                <form onSubmit={sendMessage}>
                    <h2>Contact Us</h2>
                    <input required={true} onChange={(e)=>setContactName(e.target.value)} value={contactName} disabled={isWaiting} placeholder="Name" type="text" />

                    <input required={true} onChange={(e)=>setContactEmail(e.target.value)} value={contactEmail} disabled={isWaiting} placeholder="Email" type="text" />

                    <textarea required={true} onChange={(e)=>setContactMessage(e.target.value)} value={contactMessage} disabled={isWaiting} placeholder="What do you want to talk about?" cols="30" rows="10"></textarea>

                    <button type="submit" disabled={isWaiting} className="add-member-button">{isWaiting ? <SpinnerSmall/> : "Send Message"}</button>
                </form>
            </div>

            <div className="help-links">
                <a target="_blank" rel="noopenner norefferer" href="https://www.storesyncer.com/termsConditions">Terms and Conditions</a>
                <a target="_blank" rel="noopenner norefferer" href="https://www.storesyncer.com/cookiePolicy">Cookie Policy</a>
                <a target="_blank" rel="noopenner norefferer" href="https://www.storesyncer.com/privacyPolicy">Privacy Policy</a>
            </div>




        </div>


    </div>
  )
}

export default HelpCenter