import {useContext, useState} from 'react'
import SpinnerSmall from '../Misc/SpinnerSmall'
import VariableContext from '../userContext/VariableContext'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './AddTeamMemberPopup.css'
import { fireRequestError, fireSuccessMessage, fireCustomError } from '../Common Functions/commonFunctions'
import * as gaEvents from '../ga-utils'
import Qmark from '../Misc/Qmark'

function AddTeamMemberPopup() {

    let {appId} = useParams()

    const[addingMember, setAddingMember] = useState(false)
    const[email, setEmail] = useState('')
    const[role, setRole] = useState('')

    const{getRoles,setGetRoles} = useContext(VariableContext)

    //popup close
    const closePopup = () => {
        document.getElementById('addMemberPopup').style.display = 'none'
        setEmail('')
        setRole('')
    }
    //popup email input
    const handleEmailChange = (e) =>{
        setEmail(e.target.value)
    }

    const handleRoleChange = (e) =>{
        setRole(e.target.value)
        
    }

    //popup request and close popup
    const addMemberE = (e) =>{ 
        e.preventDefault()
        setAddingMember(true)
        var data = JSON.stringify({
            "email": email,
            "appId": appId,
            "role": role

        });
        
        var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/role`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then(function (response) {
        gaEvents.addMemberSuccess()
        document.getElementById('addMemberPopup').style.display = 'none'
        setAddingMember(false)
        setGetRoles(getRoles+1)
        setEmail('')
        setRole('')
        fireSuccessMessage(response)
        })
        .catch(function (error) {
        gaEvents.addMemberFail()
        document.getElementById('addMemberPopup').style.display = 'none'
        setAddingMember(false)
        // setGetRoles(true)
        setEmail('')
        setRole('')
        
        if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            fireRequestError(error)
          }
        });   
    }



    return (
        <div id='addMemberPopup' className='add-member-popup-container'>
            <div className='addMemberPopupBack' onClick={addingMember ? ()=>{} : ()=>{closePopup()}}></div>
            <div className='add-member-popup-content'>
                <span className="add-member-popup-name">Add New Member <Qmark link='https://doc.storesyncer.com/get-started/invite-team-members-assign-roles'/></span>

                <form className='add-member-form' onSubmit={addMemberE}>
                    <input required={true} id='addMemberForm' onChange={handleEmailChange} className="add-member-popup-form" type="email" value={email} placeholder='Email address for new member'/>







                    <select required={true} onChange={handleRoleChange} value={role} className="add-member-popup-role">
                        <option defaultValue={true} disabled="disabled" value="">Select Role</option>
                       
                        <option value="app-manager">App Manager</option>
                        <option value="designer">Designer</option>
                        <option value="content-manager">Content Manager</option>
                    </select>







                    <input type='submit' hidden={true} disabled={addingMember}/>
                    <div className="addMemberPopupButtons">
                        <button disabled={addingMember} type='submit' className="add-member-popup-button">
                            {!addingMember ? 'Confirm' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>
                        <span disabled={addingMember} onClick={!addingMember ? ()=>closePopup() : ()=>{}} className="add-member-popup-button2 popupCancel">
                            Cancel 
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddTeamMemberPopup