import {useContext, useState} from 'react'
import SpinnerSmall from '../Misc/SpinnerSmall'
import VariableContext from '../userContext/VariableContext'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './VersionPopup.css'
import { fireRequestError, fireSuccessMessage, fireCustomError } from '../Common Functions/commonFunctions'
import * as gaEvents from '../ga-utils'
import Qmark from '../Misc/Qmark'

function VersionPopup({id}) {

    let {appId} = useParams()
    const {versionEdit, setVersionEdit} = useContext(VariableContext)
    const {appInfoSignal, setAppInfoSignal} = useContext(VariableContext)

    const [changingVersion, setChangingVersion] = useState(false)
    const [newVersion, setNewVersion] = useState('')

    //popup create collection close
    const closePopup = () => {
        setVersionEdit(null)
        setNewVersion('')
    }
    //popup create collection name input
    const handleChange = (e) =>{
        setNewVersion(e.target.value)
    }

    //popup collection create request and close popup
    const updateVersion = (e) =>{
        e.preventDefault()
        setChangingVersion(true)
        var data = JSON.stringify({
        "appId": appId,
        "versionString" : newVersion,
        "versionId": id
        });
        
        var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/app/version/update`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then(function (response) {
        setTimeout(()=>{
            gaEvents.versionEditSuccess()
            setChangingVersion(false)
            setNewVersion('')
            setVersionEdit(null)
            setAppInfoSignal(appInfoSignal+1)
            fireSuccessMessage(response)
        },2000)
        })
        .catch(function (error) {
        gaEvents.versionEditFail()
        setChangingVersion(false)
        setNewVersion('')
        
        if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            fireRequestError(error)
          }
        });  
    }

    const createVersion = (e) =>{ 
        e.preventDefault()
        setChangingVersion(true)
        var data = JSON.stringify({
        "appId": appId,
        "versionString" : newVersion,
        });
        
        var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/app/version`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then(function (response) {
        setTimeout(()=>{
            gaEvents.versionNewSuccess()
            setChangingVersion(false)
            setVersionEdit(null)
            setNewVersion('')
            fireSuccessMessage(response)
            setAppInfoSignal(appInfoSignal+1)
        },2000)
        })
        .catch(function (error) {
        gaEvents.versionNewFail()
        setChangingVersion(false)
        setNewVersion('')
        
        if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            fireRequestError(error)
          }
        });   
    }



    return (
        <div id='versionPopup' className='version-popup-container'>
            <div className='versionPopupBack' onClick={changingVersion ? ()=>{} : ()=>{closePopup()}}></div>
            <div className='version-popup-content'>
                {versionEdit===1 ? 
                    <span className="version-popup-name">Create New Version <Qmark link="https://doc.storesyncer.com/get-started/edit-or-create-new-app-version" /></span> : 
                    <span className="version-popup-name">Change Version Number <Qmark link="https://doc.storesyncer.com/get-started/edit-or-create-new-app-version" /></span>}
                

                <form className='version-form' onSubmit={versionEdit===1 ? createVersion : updateVersion}>
                    <input id='versionForm' onChange={handleChange} className="version-popup-form" type="text" value={newVersion} placeholder='New Version Number' maxLength="15" required={true}/>
                    <input type='submit' hidden={true} disabled={changingVersion}/>
                    <div className="versionPopupButtons">
                        <button disabled={changingVersion} type='submit' className="version-popup-button">
                            {!changingVersion ? 'OK' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>
                        <span disabled={changingVersion} onClick={!changingVersion ? ()=>closePopup() : ()=>{}} className="version-popup-button2 popupCancel">
                            Cancel 
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default VersionPopup