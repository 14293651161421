import {useState, useEffect} from 'react'
import SpinnerSmall from '../Misc/SpinnerSmall'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import './ForgotPasswordPage.css'
import { fireRequestError, fireSuccessMessage, fireCustomError } from '../Common Functions/commonFunctions'
import * as gaEvents from "../ga-utils"

function ForgotPassword({func}) {

    let {token} = useParams()
    let navigate = useNavigate()

    const[emailing, setEmailing] = useState(false)

    const [pass, setPass] = useState('')
    const [confirmPasswordInput, setConfirmPasswordInput] = useState('')

    const [pwSTR, setPwStr] = useState(0)

    const [passwordNotValidError, setPasswordNotValidError] = useState('')
    const [passwordErrorText, setPasswordErrorText] = useState('')

    const [pwRules, setPwRules] = useState({
        length: false,
        capital: false,
        lower: false,
        number: false,
        symbol: false,
    })

    let count =2

    useEffect(() => {
        let validRuleCount = 0
    
        Object.entries(pwRules).map(([key, value]) => {
          if (value) {
            validRuleCount++
          }
        })
    
        setPwStr(validRuleCount)
      }, [pwRules])

    // useEffect(()=>{

    // },[count])


    // input
    const handlePasswordChange = (e) =>{
        setPass(e.target.value)
        setPasswordErrorText('')
        setPasswordNotValidError('')
        count=2
      
        let _rules = {
            length: false,
            capital: false,
            number: false,
            symbol: false,
          }
      
          //Check password rules
          if (e.target.value === '') {
            setPwStr(0)
          } else {
            if (e.target.value.length >= 8) {
              _rules.length = true
            }
            if (/[A-Z]/.test(e.target.value)) {
              // Check if password contains capital letter
              _rules.capital = true
            }
            if (/[a-z]/.test(e.target.value)) {
                // Check if password contains capital letter
                _rules.lower = true
              }
            if (/\d/.test(e.target.value)) {
              // Check if password contains number
              _rules.number = true
            }
            if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e.target.value)) {
              // Check if password contains special character
              _rules.symbol = true
            }
          }
          setPwRules(_rules)
    }
    const handleConfirmPasswordChange = (e) =>{
        setConfirmPasswordInput(e.target.value)
        setPasswordErrorText('')
        count=2
    
    }
    //popup request and close popup
    const sendMail = (e) =>{ 
        e.preventDefault()
        if(count===2){
            if (!pwRules.length || !pwRules.capital || !pwRules.lower || !pwRules.number || !pwRules.symbol) {
                setPasswordNotValidError('Please enter a valid password')
            }else{count = count-1} if (pass !== confirmPasswordInput) {
                setPasswordErrorText('Passwords do not match!')
            }else{count = count-1}

            if(count===0){
                setEmailing(true)
                var data = JSON.stringify({
                "token" : token,
                "newPassword": pass,

                });
                
                var config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/auth/forgetPassword/change`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                    'Content-Type': 'application/json'
                },
                data : data
                };
                
                axios(config)
                .then(function (response) {
                    gaEvents.forgatPassChangeSuccess()
                    setEmailing(false)
                    localStorage.clear()
                    navigate('/')
                    fireSuccessMessage(response)
                })
                .catch(function (error) {
                    gaEvents.forgatPassChangeFail()
                    setEmailing(false)
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                    }else{
                        fireRequestError(error)
                    }
                });  
            }
        }
    }




    return (
        <div id='forgotPpassPopup' className='forgot-pass-page-popup-container'>
            <div className='forgot-pass-page-popup-content'>
                <span className="forgot-pass-page-popup-name">New Password</span>
                <br />
                <form className='forgot-pass-page-form' onSubmit={sendMail}>

                <div className='form-group'>
                            <div className="form-group-inner">
                                <label>Password</label>
                                <input
                                type="password"
                                className="form-input password"
                                onChange={handlePasswordChange}
                                placeholder="••••••••"
                                />
                                <p className='error-message'>{passwordNotValidError}</p>
                            </div>
                            
                        </div>

                        <div className='password-strength-meter'>
                            <div className={`meter ${pwSTR >= 1 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div> 
                            <div className={`meter ${pwSTR >= 2 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                            <div className={`meter ${pwSTR >= 3 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                            <div className={`meter ${pwSTR >= 4 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                            <div className={`meter ${pwSTR >= 5 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                        </div>
                        <div className='password-message'>
                            Minimum 8 characters including uppacase, lowercase letters, numbers and symbols.
                        </div>
                        <br />
                        <div className='form-group'>
                            <div className="form-group-inner">
                                <label>Confirm Password</label>
                                <input
                                type="password"
                                className="form-input password"
                                onChange={handleConfirmPasswordChange}
                                placeholder="••••••••"
                                />
                            <p className='error-message'>{passwordErrorText}</p>
                            </div>
                        </div>
                        
                    <div className="forgotPpassPopupButtons">
                        <button type='submit' disabled={emailing} className="forgot-pass-page-popup-button">
                            {!emailing ? 'Confirm' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword