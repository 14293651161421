import EditTeamMemberPopup from '../Popup/EditTeamMemberPopup'
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import VariableContext from '../userContext/VariableContext';
import axios from 'axios';
import { useContext} from 'react';
import { fireCustomError, fireRequestError, fireSuccessMessage, roleColor } from '../Common Functions/commonFunctions';
import './AppTeamMember.css'
import jurisdiction from '../Jusristiction/juristiction';
import * as gaEvents from '../ga-utils'

function AppTeamMember({data, index}) {
    const{getRoles,setGetRoles} = useContext(VariableContext)
    // const[isWaiting, setIsWaiting] = useState(false)
    const {currentAppRole, setCurrentAppRole} = useContext(VariableContext)
    const {initialUserData} = useContext(VariableContext)
    const {subscriptionLimits} = useContext(VariableContext)

    let {appId} = useParams()
    let navigate = useNavigate()

    const openThreeDotMenu = (index) =>{
        document.getElementById('threeDotMenu'+index).classList.toggle('three-dot-menu-open');
        window.addEventListener('click', closeThreeDotMenu)
    }
    const closeThreeDotMenu = (e) =>{
        if(e.target.className!=='three-dot-menu-icon'+index && e.target.className!=='three-dot-menu'+index && e.target.className!=='memberCustomize'+index && e.target.className!== `dot_menu${index}`){

            window.removeEventListener('click', closeThreeDotMenu)
            const menu = document.getElementById('threeDotMenu'+index)
            if(menu){
            document.getElementById('threeDotMenu'+index).classList.remove('three-dot-menu-open'); 
            }
        }
    }

    const openEditPopup = (index) =>{
        if(jurisdiction.role.includes(currentAppRole)){
            document.getElementById('editMemberPopup'+index).style.display = 'flex'
        }
    }

    const deleteMember = () =>{
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            position: 'center',
            width: '400',
            text: 'You are about to remove this member',
            backdrop: true,
            showConfirmButton: true,
            showCancelButton: true,     
            confirmButtonColor: '#cf000fe6',
            cancelButtonColor: '#3A6FF8e6',
            cancelButtonText: 'Cancel',      
            confirmButtonText: 'Remove',      
        }).then((result)=>{
            if (result.isConfirmed) {
                // setIsWaiting(true)
                var data0 = JSON.stringify({
                    "userId": data.user_id,
                    "appId": appId
                  });
                
                  var config = {
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_URL}/role`,
                    headers: { 
                      'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                      'Content-Type': 'application/json'
                    },
                    data : data0
                  };
                
                  axios(config)
                  .then(function (response) {
                    gaEvents.deleteMemberSuccess()
                    // setIsWaiting(false)
                    if(jurisdiction.role.includes(currentAppRole)){
                        setGetRoles(getRoles+1)
                        fireSuccessMessage(response)
                        if(initialUserData.user_id === data.user_id){
                            navigate('/')
                        }
                    }else{
                        navigate('/')
                    }

                  })
                  .catch(function (error) {
                    gaEvents.deleteMemberFail()
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                  });
            }
        })
    }

    // const ownerAlert = () =>{
    //     Swal.fire({
    //         icon: 'warning',
    //         title: 'Warning!',
    //         position: 'center',
    //         width: '400',
    //         html: 'You are sole owner of this app. You can not leave your team without another owner present.',
    //         backdrop: true,
    //         showConfirmButton: false,
    //         showCancelButton: true,     
    //         cancelButtonColor: '#3A6FF8e6',
    //         cancelButtonText: 'OK',            
    //     })
    // }

    const transferOwnership = () =>{
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            position: 'center',
            width: '400',
            html: 'You are about to trasfer ownership of this app. You can not take this action back',
            backdrop: true,
            showConfirmButton: true,
            showCancelButton: true,     
            confirmButtonColor: '#3A6FF8e6',
            confirmButtonText: 'Transfer',
            cancelButtonColor:'#F3802D',
            cancelButtonText: 'Cancel',            
        }).then((result)=>{
            if (result.isConfirmed) {
                var dataT = JSON.stringify({
                    "email": data.email,
                    "appId": appId
                  });
                  
                  var config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_URL}/role/transferOwnership`,
                    headers: { 
                      'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                      'Content-Type': 'application/json'
                    },
                    data : dataT
                  };
                  
                  axios(config)
                  .then(function (response) {
                        gaEvents.transferOwnershipSuccess()
                        setGetRoles(getRoles+1)
                        setCurrentAppRole('app-manager')
                        fireSuccessMessage(response)
                   
                  })
                  .catch(function (error) {
                        gaEvents.transferOwnershipFail()
                        if(error.message === 'Network Error'){
                            fireCustomError('NETWORK ERROR. ')
                        }else{
                            fireRequestError(error)
                        }
                  });
            }
        })
    }
    
  return (
    <div key={index} className='appInfo-team-member' style={subscriptionLimits ? subscriptionLimits.currentApp.unavailableMembers.includes(data.user_id) ? {backgroundColor:'#FFF2F2'} : {} : {}}>
        <div className='member-avatar' style={{backgroundColor: roleColor(data.role)}} src="/assets/Images/avatar.png" alt="avatar">{data.first_name.length>0 ? data.first_name[0].toUpperCase() : '?'}</div>
        <div className="member-name member">
            {data.first_name==="" && data.last_name === "" ? 'Waiting for confirmation' : `${data.first_name} ${data.last_name}`} 
        </div>
        <div className="member-title member">
            <i>{data.role}</i>
        </div>
        <div className="member-contact member">
            <a href={'mailto:'+data.email} style={{textDecoration:'none', color:'black'}}>{data.email}</a>
        </div>

        {jurisdiction.role.includes(currentAppRole) ?
            initialUserData.user_id !== data.user_id ? 
                <div onClick={()=>openThreeDotMenu(index)} className={`three-dot-menu-icon ripple three-dot-menu-icon${index}`}>
                    <img className={`dot_menu${index}`} src="/assets/Icons/kebab_menu.svg" alt="dot_menu" />
                </div> : ''
        :
            ''
        }

        <div id={'threeDotMenu'+index} className={`three-dot-menu three-dot-menu${index}`}>
            {jurisdiction.role.includes(currentAppRole) ?
                initialUserData.user_id === data.user_id ? 
                    ''
                :
                    <div onClick={()=>openEditPopup(index)} className={`member-edit memberCustomize memberCustomize${index}`}>Edit</div>
            : 
                ''
            }

            {jurisdiction.role.includes(currentAppRole) ?
                initialUserData.user_id === data.user_id ? 
                    ''
                : 
                    <div onClick={deleteMember} className={`member-delete memberCustomize memberCustomize${index}`}>Delete</div>
            : 
                    ''
            }

            {jurisdiction.role.includes(currentAppRole) ?
                initialUserData.user_id === data.user_id ? 
                    ''
                : 
                    <div onClick={transferOwnership} className={`member-transfer memberCustomize memberCustomize${index}`}>Transfer Ownership</div>
            : 
                    ''
            }
        </div>

        <EditTeamMemberPopup userId={data.user_id} index={index}/>
    </div>
  )
}

export default AppTeamMember