const prices = {
    free: {
        subtitle:"You only need to sign up",
        prompt: "Free Subscription is a way to try StoreSyncer and prove that StoreSyncer can make a positive impact on your efficiency.",
        apps: {
            text:"Cannot work with multiple applications, only one",
            flag:false
        },
        collections: {
            text:"Up to 2 collections per application",
            flag:true
        },
        team: {
            text:"No team work",
            flag: false
        },
        metadata: {
            text: "Descriptions, Keywords, What's New, Screenshots",
            flag: true
        },
        importExportPreview: {
            text:"Cannot import previews, no export",
            flag: false
        },
        uploads:{
            text:"3 App Store Connect uploads each for textual data and screenshots",
            flag: true
        }
    },
    premium: {
        month:"$14.99",
        total: "$179.88",
        subtitle:"Experience every feature",
        subtitleTrial: "Start your 15-day free trial",
        prompt: "For small teams who would like to accelerate their release process while staying organized and expand their business.",
        apps: {
            text:"Up to 4 applications",
            flag:true
        },
        collections: {
            text:"Up to 4 collections per application",
            flag:true
        },
        team: {
            text:"Up to 4 team members per application",
            flag: true
        },
        metadata: {
            text: "Descriptions, Keywords, What's New, Screenshots and Previews",
            flag: true
        },
        importExportPreview: {
            text:"Unlimited import and export",
            flag: true
        },
        uploads:{
            text:"16 App Store Connect uploads each for textual data, screenshots and previews",
            flag: true
        },
        priceId: "price_1Mrc9vGJOFkqEXzGfIxgdDFU"
    },
    premiumYear: {
        month:"$12.50",
        total: "$149.90",
        profit: "$29.98",
        priceId:"price_1MrcATGJOFkqEXzGdLU00C3q"
    },
    premiumPlus: {
        month:"$29.99",
        total: "$359.88",
        subtitle:"Fully utilized store management",
        prompt: "For fast-growing organizations and medium to large teams looking to collaboratively manage their storefonts.",
        apps: {
            text:"Up to 10 applications",
            flag:true
        },
        collections: {
            text:"Up to 8 collections per application",
            flag:true
        },
        team: {
            text:"Up to 10 team members per application",
            flag: true
        },
        metadata: {
            text: "Descriptions, Keywords, What's New, Screenshots and Previews",
            flag: true
        },
        importExportPreview: {
            text:"Unlimited import and export",
            flag: true
        },
        uploads:{
            text:"40 App Store Connect uploads each for textual data and screenshots",
            flag: true
        },
        priceId:"price_1Mrc9oGJOFkqEXzG7mWGh0l4"
    },
    premiumPlusYear: {
        month:"$24.99",
        total: "$299.90",
        profit: "$59.98",
        priceId: "price_1MrcAxGJOFkqEXzG62rIhJai"
    }
}

export default prices