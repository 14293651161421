import './OwnerScreenshots.css'

function CollectionOwnerScreenshots({data, keys, fromItself, fromPrimary}) {
    let slideIndex=1;

    function openLightbox(key) {
        document.getElementById(`lightbox${key}`).style.display = 'flex';
    }
      
    function closeLightbox(key) {
        document.getElementById(`lightbox${key}`).style.display = 'none';
    }

    function changeSlide(n, key) {
        showSlide(slideIndex += n, key);
    }
    
    function toSlide(n, key) {
        showSlide(slideIndex = n, key);
    }

    function showSlide(n, key) {

        const slides = document.getElementsByClassName(`slide${key}`);

        if (n > slides.length) {
            slideIndex = 1;	
          }

        if (n < 1) {
            slideIndex = slides.length;
        }
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = 'block';
    }

  return (
    <div className="current-owner-screenshot-item"> 

    <div className='current-owner-screenshot-set screenshotOwner'>
        {fromPrimary ? 
            <span style={{fontWeight:'100', fontSize: '0.8rem', marginBottom:'15px'}}>
                {data.size === 'APP_IPHONE_35' ? 
                <span>Using English (U.S.) 3.5" Display</span> :
                data.size === 'APP_IPHONE_40' ? 
                <span>Using English (U.S.) 4" Display</span> :
                data.size === 'APP_IPHONE_47' ? 
                <span>Using English (U.S.) 4.7" Display</span> :
                data.size === 'APP_IPHONE_55' ? 
                <span>Using English (U.S.) 5.5" Display</span> : 
                data.size === 'APP_IPHONE_61' ?  
                <span>Using English (U.S.) 6.1" Display</span> : 
                data.size === 'APP_IPHONE_65' ?  
                <span>Using English (U.S.) 6.5" Display</span> : 
                data.size === 'APP_IPHONE_67' ?  
                <span>Using English (U.S.) 6.7" Display</span> : 
                data.size === 'APP_IPAD_PRO_3GEN_11' ?  
                <span>Using English (U.S.) 12.9" Display</span> : 
                data.size === 'APP_IPAD_105' ?  
                <span>Using English (U.S.) 10.5" Display</span> : 
                data.size === 'APP_IPAD_97' ?  
                <span>Using English (U.S.) 9.7" Display</span> : 
                data.size === 'APP_IPAD_PRO_3GEN_129' ?  
                <span>Using English (U.S.) 12.9" Display</span> : 
                data.size === 'APP_IPAD_PRO_129' ?  
                <span>Using English (U.S.) 12.9" Display</span> : 
                ''}
            </span>
        :''}
        {fromItself ? 
            <span style={{fontWeight:'100', fontSize: '0.8rem', marginBottom:'15px'}}>
                {data.size === 'APP_IPHONE_35' ? 
                <span>Using 3.5" Display</span> :
                data.size === 'APP_IPHONE_40' ? 
                <span>Using 4" Display</span> :
                data.size === 'APP_IPHONE_47' ? 
                <span>Using 4.7" Display</span> :
                data.size === 'APP_IPHONE_55' ? 
                <span>Using 5.5" Display</span> : 
                data.size === 'APP_IPHONE_61' ?  
                <span>Using 6.1" Display</span> : 
                data.size === 'APP_IPHONE_65' ?  
                <span>Using 6.5" Display</span> : 
                data.size === 'APP_IPHONE_67' ?  
                <span>Using 6.7" Display</span> : 
                data.size === 'APP_IPAD_PRO_3GEN_11' ?  
                <span>Using 12.9" Display</span> : 
                data.size === 'APP_IPAD_105' ?  
                <span>Using 10.5" Display</span> : 
                data.size === 'APP_IPAD_97' ?  
                <span>Using 9.7" Display</span> : 
                data.size === 'APP_IPAD_PRO_3GEN_129' ?  
                <span>Using 12.9" Display</span> : 
                data.size === 'APP_IPAD_PRO_129' ?  
                <span>Using 12.9" Display</span> : 
                ''}
            </span>
        :''}
        <div className={`current-owner-screenshot-field ${fromItself||fromPrimary ? "current-owner-opacity" : ''}`}>
            {data.screenshots.map((screenshots, index)=>{
                return(
                    <div key={index + 'w'} className="owner-col"><img className='current-owner-screenshots' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} onClick={()=>{openLightbox(keys);toSlide(index+1,keys)}} alt='appImage'/></div>
                    
                )
            })}
        </div>
    </div>

    <div id={`lightbox${keys}`} className={`owner-modal owner-modal${keys}`}>
        <span className="owner-close pointer" onClick={()=>{closeLightbox(keys)}}>&times;</span>
        <div className="owner-modal-content">
            {data.screenshots.map((screenshots, index2)=>{
                return(
                    <div key={index2 + 'e'} className={`slide${keys} owner-slide-style`}><img className='current-owner-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} onClick={()=>{openLightbox(keys);toSlide(index2+1,keys)}} alt='appImage'/></div>
                    
                )
            })}
            <span className="owner-previous" onClick={()=>{changeSlide(-1,keys)}}>&#10094;</span>
            <span className="owner-next" onClick={()=>{changeSlide(1,keys)}}>&#10095;</span>
        </div>
    </div>

</div>
  )
}

export default CollectionOwnerScreenshots