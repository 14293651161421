import {Link} from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {useState, useEffect} from 'react'
import './RegisterPage.css'
import axios from 'axios'
import MailButton from '../Components/MailButton'
import { fireRequestError, fireCustomError } from '../Common Functions/commonFunctions'
import * as gaEvents from "../ga-utils"

function RegisterPage() {
    let navigate = useNavigate()
    useEffect(()=>{
        if(localStorage.getItem("token")){
          var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/auth/validate?code_usage=true`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
          };
          
          axios(config)
          .then(function (response) {
            // 
            localStorage.setItem("token", response.data.data.token)
            navigate('/')
          })
          .catch(function (error) {
            if(error.message === 'Network Error'){
              fireCustomError('NETWORK ERROR. ')
            }else{
              fireRequestError(error)
            }
          });
        }
        // else{
        //     navigate('/register');
        // }
      },[])

    const [isWaiting, setIsWaiting] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    // let navigate = useNavigate()

    let count = 5

//Form Elements

    const [nameInput, setNameInput] = useState('')
    const [surnameInput, setSurnameInput] = useState('')
    const [emailInput, setEmailInput] = useState('')
    const [passwordInput, setPasswordInput] = useState('')
    const [confirmPasswordInput, setConfirmPasswordInput] = useState('')
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [pwSTR, setPwStr] = useState(0)
    const [promoCode, setPromoCode] = useState("")

//Form rule states

    const [pwRules, setPwRules] = useState({
        length: false,
        capital: false,
        lower: false,
        number: false,
        symbol: false,
    })

//Form Error Messages States

    const [emailErrorText, setEmailErrorText] = useState('')
    const [passwordErrorText, setPasswordErrorText] = useState('')
    const [passwordNotValidError, setPasswordNotValidError] = useState('')
    const [nameErrorText, setNameErrorText] = useState('')
    // const [surNameErrorText, setSurNameErrorText] = useState('')
    const [termsErrorText, setTermErrorText] = useState('')

//Empty error Message

    // const emptyError = "Can not be empty!"

    const handleNameChange = (e) =>{
        setNameInput(e.target.value)
        setNameErrorText('')
        count = 5
    }
    const handleSurNameChange = (e) =>{
        setSurnameInput(e.target.value)
        setNameErrorText('')
        count = 5
    }
    const handleEmailChange = (e) =>{
        setEmailInput(e.target.value)
        setEmailErrorText('')
        count = 5
    }
    const handleCheckbox = () =>{
        setAcceptTerms(!acceptTerms)
        setTermErrorText('')
        count = 5
    }
    const handleConfirmPasswordChange = (e) =>{
        setConfirmPasswordInput(e.target.value)
        setPasswordErrorText('')
        count = 5
    }
    const handlePasswordChange = (e) =>{
        setPasswordInput(e.target.value)
        setPasswordErrorText('')
        setPasswordNotValidError('')
        count = 5
        let _rules = {
            length: false,
            capital: false,
            number: false,
            symbol: false,
          }
      
          //Check password rules
          if (e.target.value === '') {
            setPwStr(0)
          } else {
            if (e.target.value.length >= 8) {
              _rules.length = true
            }
            if (/[A-Z]/.test(e.target.value)) {
              // Check if password contains capital letter
              _rules.capital = true
            }
            if (/[a-z]/.test(e.target.value)) {
                // Check if password contains capital letter
                _rules.lower = true
              }
            if (/\d/.test(e.target.value)) {
              // Check if password contains number
              _rules.number = true
            }
            if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e.target.value)) {
              // Check if password contains special character
              _rules.symbol = true
            }
          }
          setPwRules(_rules)
    }
    const handlePromoCodeChange = (e) =>{
        setPromoCode(e.target.value)
    }
      
        useEffect(() => {
          let validRuleCount = 0
      
          Object.entries(pwRules).map(([key, value]) => {
            if (value) {
              validRuleCount++
            }
          })
      
          setPwStr(validRuleCount)
        }, [pwRules])


        const handleSubmit = (e) => {
            e.preventDefault()
            if(count===5){
                if (!pwRules.length || !pwRules.capital || !pwRules.lower || !pwRules.number || !pwRules.symbol) {
                    setPasswordNotValidError('Please enter a valid password')
                }else{count = count-1} if (passwordInput !== confirmPasswordInput) {
                    setPasswordErrorText('Passwords do not match!')
                }else{count = count-1} if (!emailInput) {
                    setEmailErrorText('Please enter your e-mail')
                }else{count = count-1} if (!nameInput || !surnameInput) {
                    setNameErrorText('Please enter your name and surname')
                }else{count = count-1} if (!acceptTerms) {
                    setTermErrorText('You must agree terms and conditions!')
                }else{count = count-1}
                if(count===0){
                    setIsWaiting(true)
                    var data = JSON.stringify({
                        "firstName": nameInput,
                        "lastName": surnameInput,
                        "email": emailInput,
                        "password": passwordInput,
                        "promoCode": promoCode
                      });
                      
                      var config = {
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}/auth/signUp`,
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                        data : data
                      };
                      
                      axios(config)
                      .then(function (response) {
                        gaEvents.registerSuccess()
                        localStorage.setItem("emailConfirmationSent", "true")
                        setIsWaiting(false)
                        setSubmitted(true)
                      })
                      .catch(function (error) {
                        gaEvents.registerFail()
                        setIsWaiting(false)
                        if(error.message === 'Network Error'){
                          fireCustomError('NETWORK ERROR. ')
                        }else{
                          fireRequestError(error)
                        }
                      });
                }
            }
            
        }

  return (
    <div className='login-container'>
        <div className="login-container-left"></div>

        <div className="login-container-right">
            <div className="login-right-formContainer">
                {submitted ? 
                <>
                    <div className="title-div-submitted">
                        <h2>Registration Form Submitted</h2>
                        <p>
                            Please confirm your email address, check your inbox for confirmation link. Do not forget to check your <strong>spam</strong> folder.
                        </p>
                        <div className='mail-button-container'>
                            <MailButton icon='/assets/Icons/icons8-microsoft-outlook-48.svg' inbox='https://outlook.live.com/' placeholder='Outlook' color='#0277bd'/>
                            <MailButton icon='/assets/Icons/icons8-gmail-48.svg' inbox='https://gmail.com' placeholder='Gmail' color='#e53935'/>
                            <MailButton icon='/assets/Icons/Yandex_Mail_icon.svg' inbox='https://mail.yandex.com/' placeholder='Yandex' color='#181c32'/>
                        </div>


                    </div>
                </> : 
                <>
                    <div className="title-div">
                        <h2>Create an Account</h2>
                        <p>Already have an account? <Link to='/login'>Sign in here</Link></p>
                    </div>

                    <form className="signup-form" onSubmit={handleSubmit}>

                        <div className='form-group form-flag'>
                            <div className="form-group-inner">
                                <label>Name</label>
                                <input
                                type="text"
                                className="form-input name"
                                placeholder="Enter your name"
                                onChange={handleNameChange}
                                />
                            </div>

                            <div className="form-group-inner">
                                <label>Surname</label>
                                <input
                                type="text"
                                className="form-input surname"
                                placeholder="Enter your surname"
                                onChange={handleSurNameChange}
                                />
                            </div>
                        </div>

                        <div className="form-name-error">
                                <p className='error-message'>{nameErrorText}</p>
                            </div>

                        <div className='form-group'>
                            <div className="form-group-inner">
                                <label>Email</label>
                                <input
                                type="email"
                                className="form-input email"
                                onChange={handleEmailChange}
                                placeholder="email@example.com"
                                />
                            <p className='error-message'>{emailErrorText}</p>
                            </div>
                        </div>

                        <div className='form-group'>
                            <div className="form-group-inner">
                                <label>Password</label>
                                <input
                                type="password"
                                className="form-input password"
                                onChange={handlePasswordChange}
                                placeholder="••••••••"
                                />
                                <p className='error-message'>{passwordNotValidError}</p>
                            </div>
                            
                        </div>

                        <div className='password-strength-meter'>
                            <div className={`meter ${pwSTR >= 1 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div> 
                            <div className={`meter ${pwSTR >= 2 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                            <div className={`meter ${pwSTR >= 3 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                            <div className={`meter ${pwSTR >= 4 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                            <div className={`meter ${pwSTR >= 5 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                        </div>
                        <div className='password-message'>
                            Minimum 8 characters including uppacase, lowercase letters, numbers and symbols.
                        </div>

                        <div className='form-group'>
                            <div className="form-group-inner">
                                <label>Confirm Password</label>
                                <input
                                type="password"
                                className="form-input password"
                                onChange={handleConfirmPasswordChange}
                                placeholder="••••••••"
                                />
                            <p className='error-message'>{passwordErrorText}</p>
                            </div>
                        </div>

                        <div className='form-group' style={{marginBottom:'25px'}}>
                            <div className="form-group-inner">
                                <label>Promo Code</label>
                                <input
                                type="text"
                                className="form-input password"
                                onChange={handlePromoCodeChange}
                                placeholder="Enter your promo code"
                                />
                            
                            </div>
                        </div>

                        <div className='form-accept-terms'>
                            <div className='form-accept-terms-inner'>
                                <input className='checkbox-input' type="checkbox" id="checkbox1" name="checkbox1" onChange={handleCheckbox}/>
                                <label> I agree to <a href='https://www.storesyncer.com/termsConditions' target='_blank'>terms and conditions.</a></label>
                            </div>
                            <p className='error-message'>{termsErrorText}</p>

                        </div>
                        

                        <div className="form-button-group">
                            <button className='form-button' type="submit" disabled={isWaiting}>
                                {isWaiting ? <img style={{width :'25px', marginTop: '5px'}} src='/assets/Icons/spinner.gif' alt='Applications'/> : 'Create Account'}</button>
                        </div>
                        
                    </form>
                </>
                }
            </div>
        </div>
    </div>
  )
}

export default RegisterPage