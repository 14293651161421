import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import VariableContext from '../userContext/VariableContext';
import {fireCustomError, fireRequestError} from '../Common Functions/commonFunctions'
import Spinner from "../Misc/Spinner";
import SpinnerSmall from "../Misc/Spinner";
import GoBackButton from "../Components/GoBackButton";
import './Quota.css'

function Quota() {
    const {initialUserData, setInitialUserData} = useContext(VariableContext)
    const {quota, setQuota} = useContext(VariableContext)

    let navigate = useNavigate()

    //validate token and get user info
    useEffect(()=>{
    if(localStorage.getItem("token")){
        var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/validate?code_usage=true`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        };
        axios(config)
        .then(function (response) {
            setInitialUserData(response.data.data)
            localStorage.setItem("token", response.data.data.token)
        })
        .catch(function (error) {
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
            localStorage.removeItem("token")
            navigate('/login')
        });
    }else {
        navigate('/login')
    }
    },[])

    useEffect(()=>{
        var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/quota`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        };
        axios(config)
        .then(function (response) {
            setQuota(response.data.data)
           
        })
        .catch(function (error) {
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
        });
    },[])

  return (
    <div className="billing-content">
      <div className="billing-title-div titleArea">
        <GoBackButton />
        <h1>Quota</h1>
      </div>

      <div className="billing-inner-container">

        {initialUserData ? initialUserData.accountSubsStatus.status!=='active' ?
                <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', margin:'33px 0 -33px 0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{initialUserData.accountSubsStatus.message}</span>
            : 
                ''
            : 
                ''
        }

        <div className="app-data" style={{margin: '33px 0 0 0'}}>            
            <div>
                <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/username.svg" alt="versionIcon" />
                <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                <strong>
                {initialUserData ? (
                    initialUserData.first_name+' '+initialUserData.last_name
                ) : (
                    <SpinnerSmall />
                )}
                </strong>
                </p>
            </div>

            <div>
                <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/usermail.svg" alt="platformIcon" />
                <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                <strong>{initialUserData ? initialUserData.email : <SpinnerSmall />}</strong> 
                </p>
            </div>

            <div>
                <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/useraccount.svg" alt="primaryLocalIcon" />
                <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                <strong>{initialUserData ? initialUserData.account_type: <SpinnerSmall />}</strong>{' '}<span style={{ fontStyle:'italic'}}>{initialUserData ? initialUserData.account_type!=="free"&&<>({initialUserData.accountSubsStatus.status!=='active' ? "Expired" : "Expires"} on {initialUserData.subscription_expires_at.slice(0,10)})</>: ''}</span>
                </p>
            </div>
        </div> 

        {/* Application Quota */}
        <div className="billing-info-div">

          <h2 className="billing-sub">Application Quota</h2>
          
          {quota ? (
            <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
            {/* No of apps */}
              <div className="quota-data-item">
                <img src="/assets/Icons/app_num.svg" alt="app" />
                <h3>Number of Applications: </h3>
                <div className="quota-bar">
                  <div
                    style={
                      (quota.ownerships.length * 100) / quota.max_app_count < 50
                        ? {
                            width: `${
                              (quota.ownerships.length * 100) /
                              quota.max_app_count
                            }%`,
                            backgroundColor: "#14CC26",
                          }
                        : (quota.ownerships.length * 100) /
                            quota.max_app_count >=
                            50 &&
                          (quota.ownerships.length * 100) /
                            quota.max_app_count <
                            75
                        ? {
                            width: `${
                              (quota.ownerships.length * 100) /
                              quota.max_app_count
                            }%`,
                            backgroundColor: "#F3802D",
                          }
                        : {
                            width: `${
                              (quota.ownerships.length * 100) /
                              quota.max_app_count
                            }%`,
                            backgroundColor: "red",
                          }
                    }
                    className="quota-bar-inner"
                  ></div>
                </div>
                <span>&nbsp;{quota.ownerships.length} of {quota.max_app_count}</span>
              </div>
            </div>
          ) : (
            <p><Spinner /></p>
          )}
        </div>

        {/* Upload Quota */}
        <div className="billing-info-div">

          <h2 className="billing-sub">App Store Connect Upload Quota</h2>
          
          {quota ? (
            <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
            {/* No of text */}
            <div className="quota-data-item">
                <img src="/assets/Icons/text_upload.svg" alt="text_upload" />

                <h3>Text Uploads to App Store Connect: </h3>
                <div className="quota-bar">
                  <div
                    style={
                      ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) / quota.max_textual_upload_appstore < 50
                        ? {
                            width: `${
                              ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) /
                              quota.max_textual_upload_appstore
                            }%`,
                            backgroundColor: "#14CC26",
                          }
                        : ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) /
                            quota.max_textual_upload_appstore >=
                            50 &&
                          ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) /
                            quota.max_textual_upload_appstore <
                            75
                        ? {
                            width: `${
                              ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) /
                              quota.max_textual_upload_appstore
                            }%`,
                            backgroundColor: "#f3e22d",
                          }
                        : {
                            width: `${
                              ((quota.max_textual_upload_appstore-quota.textual_upload_appstore) * 100) /
                              quota.max_textual_upload_appstore
                            }%`,
                            backgroundColor: "red",
                          }
                    }
                    className="quota-bar-inner"
                  ></div>
                </div>
                <span>&nbsp;{(quota.max_textual_upload_appstore-quota.textual_upload_appstore)} of {quota.max_textual_upload_appstore}</span>
              </div>

            {/* No of screenshots */}
              <div className="quota-data-item">
                <img src="/assets/Icons/screenshot_upload.svg" alt="screenshoot_upload" />

                <h3>Screenshot Uploads to App Store Connect: </h3>
                <div className="quota-bar">
                  <div
                    style={
                      ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) / quota.max_screenshot_upload_appstore < 50
                        ? {
                            width: `${
                              ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) /
                              quota.max_screenshot_upload_appstore
                            }%`,
                            backgroundColor: "#14CC26",
                          }
                        : ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) /
                            quota.max_screenshot_upload_appstore >=
                            50 &&
                          ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) /
                            quota.max_screenshot_upload_appstore <
                            75
                        ? {
                            width: `${
                              ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) /
                              quota.max_screenshot_upload_appstore
                            }%`,
                            backgroundColor: "#f3e22d",
                          }
                        : {
                            width: `${
                              ((quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore) * 100) /
                              quota.max_screenshot_upload_appstore
                            }%`,
                            backgroundColor: "red",
                          }
                    }
                    className="quota-bar-inner"
                  ></div>
                </div>
                <span>&nbsp;{(quota.max_screenshot_upload_appstore-quota.screenshot_upload_appstore)} of {quota.max_screenshot_upload_appstore}</span>
              </div>

            {/* No of previews */}
              <div className="quota-data-item">
                <img src="/assets/Icons/preview_upload.svg" alt="preview_upload" />

                <h3>Preview Uploads to App Store Connect: </h3>
                {quota.preview_data_storage===0 ?
                  <img src="/assets/Icons/x.svg" alt="negative_mark" />
                :
                  <>
                    <div className="quota-bar">
                        <div
                        style={
                            ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) / quota.max_preview_upload_appstore < 50
                            ? {
                                width: `${
                                    ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) /
                                    quota.max_preview_upload_appstore
                                }%`,
                                backgroundColor: "#14CC26",
                                }
                            : ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) /
                                quota.max_preview_upload_appstore >=
                                50 &&
                                ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) /
                                quota.max_preview_upload_appstore <
                                75
                            ? {
                                width: `${
                                    ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) /
                                    quota.max_preview_upload_appstore
                                }%`,
                                backgroundColor: "#f3e22d",
                                }
                            : {
                                width: `${
                                    ((quota.max_preview_upload_appstore-quota.preview_upload_appstore) * 100) /
                                    quota.max_preview_upload_appstore
                                }%`,
                                backgroundColor: "red",
                                }
                        }
                        className="quota-bar-inner"
                        ></div>
                    </div>
                    <span>&nbsp;{(quota.max_preview_upload_appstore-quota.preview_upload_appstore)} of {quota.max_preview_upload_appstore}</span>  
                  </>  
                }
              </div>

            </div>
          ) : (
            <p><Spinner /></p>
          )}
        </div>

        {/* Team Quota */}
        <div className="billing-info-div">

          <h2 className="billing-sub">Team Member Quota</h2>
          
          {quota ? (
            <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
                {/* Team Work */}
                <div className="quota-data-item">
                    {quota.max_team_member_per_app===1 ? <>
                        <img src="/assets/Icons/team_number.svg" alt="team_number" />
                        <h3>
                            Team Work:&nbsp;&nbsp;
                            {quota.max_team_member_per_app===1 ? 
                                <img src="/assets/Icons/x.svg" alt="nagative_mark" />
                            :
                                <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                            }
                        </h3></>
                    :   <>
                        <img src="/assets/Icons/team_number.svg" alt="team_number" />   
                        <h3>
                            Maximum Team Member per Application:&nbsp;&nbsp;
                            <span>{quota.max_team_member_per_app}</span>  
                        </h3></>
                    }

                </div>

                {quota.max_team_member_per_app!==1&&quota.ownerships.length!==0 ? 
                    <ul className="quota-data-item-list">
                        {quota.ownerships.map((app,i)=>{
                            return(
                                <li key={i} className="quota-data-item">
                                  {app.icon ? 
                                    <img className="quota-app-icon" src={app.icon} alt="_icon" />
                                  :
                                    <img className="quota-app-icon" src='/assets/Icons/AddAppImage.svg' alt="_icon" />
                                  }
                                    <span>{app.app}</span>  
                                    <div className="quota-bar">
                                        <div
                                            style={
                                                (app.member_count * 100) / quota.max_team_member_per_app < 50
                                                ? {
                                                    width: `${
                                                        (app.member_count * 100) /
                                                        quota.max_team_member_per_app
                                                    }%`,
                                                    backgroundColor: "#14CC26",
                                                    }
                                                : (app.member_count * 100) /
                                                    quota.max_team_member_per_app >=
                                                    50 &&
                                                    (app.member_count * 100) /
                                                    quota.max_team_member_per_app <
                                                    75
                                                ? {
                                                    width: `${
                                                        (app.member_count * 100) /
                                                        quota.max_team_member_per_app
                                                    }%`,
                                                    backgroundColor: "#f3e22d",
                                                    }
                                                : {
                                                    width: `${
                                                        (app.member_count * 100) /
                                                        quota.max_team_member_per_app
                                                    }%`,
                                                    backgroundColor: "red",
                                                    }
                                            }
                                            className="quota-bar-inner"
                                        ></div>
                                    </div>
                                    <span>&nbsp;{app.member_count} of {quota.max_team_member_per_app}</span>  
                                </li>
                            )
                        })}
            
                    </ul>
                :
                    ''
                }
            </div>
          ) : (
            <p><Spinner /></p>
          )}
        </div>

        {/* Collection Quota */}
        <div className="billing-info-div">

          <h2 className="billing-sub">Collection Quota</h2>
          
          {quota ? (
            <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
            {/* no of collections */}
                <div className="quota-data-item">
                    <img src="/assets/Icons/collection_num.svg" alt="collection_num" />
                    <h3>Max Collection per Application:</h3>
                    <span>{quota.max_collection_per_app}</span>
                </div>

                {quota.ownerships.length!==0 ?
                    <ul className="quota-data-item-list">
                        {quota.ownerships.map((app,i)=>{
                            return(
                                <li key={i} className="quota-data-item">
                                    <img className="quota-app-icon" src={app.icon} alt="_icon" />
                                    <span>{app.app}</span>  
                                    <div className="quota-bar">
                                        <div
                                            style={
                                                (app.collection_count * 100) / quota.max_collection_per_app < 50
                                                ? {
                                                    width: `${
                                                        (app.collection_count * 100) /
                                                        quota.max_collection_per_app
                                                    }%`,
                                                    backgroundColor: "#14CC26",
                                                    }
                                                : (app.collection_count * 100) /
                                                    quota.max_collection_per_app >=
                                                    50 &&
                                                    (app.collection_count * 100) /
                                                    quota.max_collection_per_app <
                                                    75
                                                ? {
                                                    width: `${
                                                        (app.collection_count * 100) /
                                                        quota.max_collection_per_app
                                                    }%`,
                                                    backgroundColor: "#f3e22d",
                                                    }
                                                : {
                                                    width: `${
                                                        (app.collection_count * 100) /
                                                        quota.max_collection_per_app
                                                    }%`,
                                                    backgroundColor: "red",
                                                    }
                                            }
                                            className="quota-bar-inner"
                                        ></div>
                                    </div>
                                    <span>&nbsp;{app.collection_count} of {quota.max_collection_per_app}</span>  
                                </li>
                            )
                        })}
            
                    </ul>
                :
                    ''
                }
                
            </div>
          ) : (
            <p><Spinner /></p>
          )}
        </div>

        {/* Metadata */}
        <div className="billing-info-div">

          <h2 className="billing-sub">Metadata</h2>
          
          {quota ? (
            <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
                {/* Textual bool */}
                <div className="quota-data-item">
                   <img src="/assets/Icons/text_metadata.svg" alt="text_metadata" />
                    <h3>
                    Textual Data:&nbsp;&nbsp;
                    {quota.textual_data_storage ?
                        <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                    :
                        <img src="/assets/Icons/x.svg" alt="negative_mark" />
                    }
                    </h3>
                </div>
                {/* Screenshot bool */}
                <div className="quota-data-item">
                    <img src="/assets/Icons/image_metadata.svg" alt="image_metadata" />
                    <h3>
                    Screenshots:&nbsp;&nbsp;
                    {quota.screenshot_data_storage ?
                        <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                    :
                        <img src="/assets/Icons/x.svg" alt="negative_mark" />
                    }
                    </h3>
                </div>
                {/* Preview bool */}
                <div className="quota-data-item">
                    <img src="/assets/Icons/preview_metadata.svg" alt="preview_metadata" />
                    <h3>
                    Previews:&nbsp;&nbsp;
                    {quota.preview_data_storage ?
                        <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                    :
                        <img src="/assets/Icons/x.svg" alt="negative_mark" />
                    }
                    </h3>
                </div>
            </div>
          ) : (
            <p><Spinner /></p>
          )}
        </div>

        {/* Import & Export */}
        <div className="billing-info-div">

          <h2 className="billing-sub">Import & Export</h2>
          
          {quota ? (
            <div className="quota-data" style={{ margin: "30px 0 0 0" }}>
                {/* Import */}
                <div className="quota-data-item">
                    <img src="/assets/Icons/import_arrow.svg" alt="import_arrow" />
                    <h3>
                    Import Metadata:&nbsp;&nbsp;
                    <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                    </h3>
                </div>

                <ul className="quota-data-item-list">
                    <li className="quota-data-item">
                        <span>&nbsp;Import Textual Data</span>
                        {quota.textual_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                    </li>
                    <li className="quota-data-item">
                        <span>&nbsp;Import Screenshots</span>
                        {quota.screenshot_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                    </li>
                    <li className="quota-data-item">
                        <span>&nbsp;Import Previews</span>
                        {quota.preview_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                    </li>
                </ul>

                {/* Export */}
                <div className="quota-data-item">
                    <img src="/assets/Icons/export_arrow.svg" alt="export_arrow" />
                    <h3>
                    Export Metadata:&nbsp;&nbsp;
                    {quota.export_storage===0 ? 
                        <img src="/assets/Icons/x.svg" alt="negative_mark" />
                    :
                        <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                    }
                    </h3>
                </div>

                {quota.export_storage===0 ? 
                    ''
                :
                    <ul className="quota-data-item-list">
                        <li className="quota-data-item">
                            <span>&nbsp;Export Textual Data</span>
                            {quota.textual_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                        </li>
                        <li className="quota-data-item">
                            <span>&nbsp;Export Screenshots</span>
                            {quota.screenshot_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                        </li>
                        <li className="quota-data-item">
                            <span>&nbsp;Export Previews</span>
                            {quota.preview_data_storage ? <img src="/assets/Icons/tik.svg" alt="positive_mark" /> : <img src="/assets/Icons/x.svg" alt="negative_mark" />}
                        </li>
                    </ul>
                }

            </div>
          ) : (
            <p><Spinner /></p>
          )}
        </div>




        { quota ?
            quota.account_type==='free' ? 
              ''
            :
              <a className='manage-sub' href={`https://billing.stripe.com/p/login/cN28wD2gYfAn01OaEE?prefilled_email=${initialUserData ? initialUserData.email : ''}`} rel='noopener noreferrer' >
                  <p>Manage Subscription</p>
              </a>
        :
            ''
        }  
      </div>

    </div>
  );
}

export default Quota