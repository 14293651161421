import './OwnerDescription.css'

function OwnerDescription({data, type}) {


  return (
    <div className="owner-description-item">

      <div className="owner-description-div">

          <div className="owner-section-name">
              <p className='owner-name'>{type} </p>
          </div>
          <p className='owner-description-count' style={{display:'none'}}>{!data ? 0 : data.length}/4000</p>

      </div>

      <div className="owner-description-field">
          <textarea readOnly={true} className='owner-description-textarea' name="owner-description-textarea" cols="50" rows="10" value={!data ? '' : data} 
          ></textarea>
      </div>

    </div>
  )
}

export default OwnerDescription