
  import * as gaEvents from "../ga-utils"
  const Swal = require('sweetalert2')
  const langJSON = require('../flagsLanguages/lang.json')


  //popup create collection open
  export const openCreateCollectionPopup = () => {
    document.getElementById('collectionPopup').style.display = 'flex'
    // document.getElementById('popupForm').focus()
  }

  export const openChangeCollectionNamePopup = () => {
    document.getElementById('changeNamePopup').style.display = 'flex'
    document.getElementById('nameForm').focus()
  }

  //fire error alert for requests
  export const fireRequestError = (error) =>{
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      position: 'center',
      width: '400',
      html: ''+error.response.data.message,
      color: 'white',
      background: '#cf000f',
      backdrop: true,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'OK',
      cancelButtonColor: '#f27474',      
  })
  }

  //fire error alert for requests with custom error
  export const fireCustomRequestError = (error) =>{
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      position: 'center',
      width: '400',
      html: `${error.response.data.data}<br>`+
      'Please reload the page or try again later',
      color: 'white',
      background: '#cf000f',
      backdrop: true,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'OK',
      cancelButtonColor: '#f27474',      
  })
  }

    //fire error alert for custom error
    export const fireCustomError = (error) =>{
      gaEvents.networkError()
      
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        position: 'center',
        width: '400',
        html: error+'Please reload the page or try again later',
        color: 'white',
        background: '#cf000f',
        backdrop: true,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'OK',
        cancelButtonColor: '#f27474',      
    })
    }

        //fire error alert for custom error
        export const fireCustomErrorV2 = (error) =>{
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            position: 'center',
            width: '400',
            html: error,
            color: 'white',
            background: '#cf000f',
            backdrop: true,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'OK',
            cancelButtonColor: '#f27474',      
        })
        }

  //fire success messages
  export const fireSuccessMessage = (response) =>{
    Swal.fire({
      position: 'top',
      width: 'max-content',
      html: ''+response.data.message,
      color: 'white',
      background: '#71c456',
      showConfirmButton: false,
      showCancelButton: false, 
      timer: 2000,
      toast: true
          
  })
  }
  
  //fire success messages
  export const fireCustomSuccessMessage = (response) =>{
    Swal.fire({
      position: 'top',
      width: 'max-content',
      html: ''+response,
      color: 'white',
      background: '#71c456',
      showConfirmButton: false,
      showCancelButton: false, 
      timer: 2000,
      toast: true
          
  })
  }
  //fire export message
  export const fireExportMessage = () =>{
    Swal.fire({
      position: 'top',
      width: 'max-content',
      html: 'Exporting, this might take a while...',
      color: 'white',
      background: '#71c456',
      showConfirmButton: false,
      showCancelButton: false, 
      toast: true
          
  })
  }
  //fire file message
  export const fireFileMessage = () =>{
    Swal.fire({
      position: 'top',
      width: 'max-content',
      html: 'Downloading template file...',
      color: 'white',
      background: '#71c456',
      showConfirmButton: false,
      showCancelButton: false, 
      toast: true
          
  })
  }


  //finds primary locale and puts it on top of the response(object array), sorts rest in alphabetically
  export let sortResponse = (unsorted) => {
    let a = unsorted.localizations
    let b = 0
    for(let i = 0; i< a.length; i++){
        let temp = a[i].attributes.locale.toLowerCase()
        a[i].attributes.locale_name=langJSON[temp].nameEnglish
        if(unsorted.info.primary_locale === a[i].attributes.locale){
            b=i;
        }
    }
    let x = a[b]
    a.splice(b,1)
    a.sort((x,y)=>{
        return x.attributes.locale_name < y.attributes.locale_name ? -1 : x.attributes.locale_name > y.attributes.locale_name ? 1:0
    })
    a.unshift(x)
    return a
  }

  //finds primary locale and puts it on top of the response(object array), sorts rest in alphabetically
  export let sortResponseCol = (unsorted, primary_locale) => {
    let a = unsorted
    let b = 0
    for(let i = 0; i< a.length; i++){
        let temp = a[i].language_code.toLowerCase()
        a[i].locale_name=langJSON[temp].nameEnglish
        if(primary_locale === a[i].language_code){
            b=i;
        }
    }
    let x = a[b]
    a.splice(b,1)
    a.sort((x,y)=>{
        return x.locale_name < y.locale_name ? -1 : x.locale_name > y.locale_name ? 1:0
    })
    a.unshift(x)
    return a
  }



  export let sortValue = (unsorted) =>{
    let sortThis = unsorted
    sortThis.sort((x,y)=>{
      return x.label < y.label ? -1 : x.label > y.label ? 1:0
    })
    return sortThis
  }


  //finds primary locale and puts it on top of the response(object array), sorts rest in alphabetically
  export let sortValuePri = (unsorted, primary_locale) => {
    let a = unsorted
    let b = 0
    for(let i = 0; i< a.length; i++){
        if(primary_locale === a[i].value){
            b=i;
        }
    }
    let x = a[b]
    a.splice(b,1)
    a.sort((x,y)=>{
        return x.label < y.label ? -1 : x.label > y.label ? 1:0
    })
    a.unshift(x)
    return a
  }

  //sleep function for delay
  export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  //role colors
  export function roleColor(role) {
    if(role==='owner'){
      return '#1C1C1C'
    }else if(role==='content-manager'){
      return '#71C456'
    }else if(role==='designer'){
      return '#F3802D'
    }else if(role==='app-manager'){
      return '#461BF0'
    }
  }

