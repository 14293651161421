import React from 'react'

function Qmark({link}) {
  return (
    <div className='qmark-div'>
        <a className='qmark' href={link} target='_blank' rel="noopenner noreferrer"><img style={{width:'20px', height:'auto'}} src="/assets/Icons/qmark.png" alt="qmark"/></a>
    </div>
  )
}

export default Qmark