import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import VariableContext from '../userContext/VariableContext';
import Spinner from '../Misc/Spinner'
import {useParams} from 'react-router-dom'
import CurrentDescriptionItem from '../Items/CurrentItems/Description/CurrentDescriptionItem'
import {fireCustomRequestError, sortResponse, fireRequestError, fireCustomError } from '../Common Functions/commonFunctions';
import ScreenshotLocalItem from '../Items/CurrentItems/Screenshot/ScreenshotLocalItem'
import SpinnerSmall from '../Misc/SpinnerSmall';
import jurisdiction from '../Jusristiction/juristiction';

function CurrentDescriptionPage() {
    const[isWaiting, setIsWaiting] = useState(false)
    const [name, setName] = useState('')
  
    const {currentAppLocalizations, setCurrentAppLocalizations} = useContext(VariableContext)
    const {currentAppRole} = useContext(VariableContext)
    const {subWarning} = useContext(VariableContext)
    const [language, setLanguage] = useState('')

    // const {isUploading} = useContext(VariableContext)


    let {appId} = useParams()

    //get current descriptions on apple store
    useEffect(()=>{

        setIsWaiting(true)
        const controller = new AbortController();
        
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/${appId}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            signal: controller.signal
        };
        axios(config)
        .then(function (response) {
            setCurrentAppLocalizations(sortResponse(response.data.data))
            let temp1= sortResponse(response.data.data)
            let temp2 = response.data.data.info.primary_locale.toLowerCase()

            if(language===''){
                setLanguage(temp2)
                setName(temp1[0].attributes.locale_name)

            }
            else{
                setLanguage(temp2)
                // setName(response.data.data.localizations.find((local)=> local.attributes.locale.toLowerCase()===language).attributes.locale_name)
                setName(temp1[0].attributes.locale_name)


            }
            setIsWaiting(false)   
        })
        .catch(function (error) {
            // setIsWaiting(true)
            if(error.code === 'ERR_CANCELED'){
                //do nothing
            }
            else{
                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
            }
        });
        return ()=>{
            controller.abort()
        }

    },[appId])


    // useEffect(()=>{
    //     if(currentAppLocalizations!==null){  
    //         setTimeout(()=>{
    //         if(language){
    //             scrollToLanguage(currentAppLocalizations.find((local)=> local.attributes.locale.toLowerCase()===language).attributes.locale_name)
    //         }
    //         },500)
    //     } 
    // },[currentAppLocalizations])


    const handleClick = () =>{   
        const localList = document.getElementById("opacityDiv")
        const localListIcon = document.getElementById('screenshotSelectLocalIcon')
        localListIcon.classList.toggle("screenshot-local-list-rotate-icon")
        localList.classList.toggle('screenshot-local-list-visible')
        window.addEventListener('click', closeLocalList)

  
    }

    const closeLocalList = (event) =>{
        
        if ((event.target.className !== "screenshot-local-item") && (event.target.className !== "screenshot-select-local") && (event.target.className !== "screenshot-select-local-icon") && (event.target.className !== "screenshot-select-local-language") && (event.target.className !== "screenshot-select-local-icon screenshot-local-list-rotate-icon") && (event.target.className !== "screenshot-local-list screenshot-local-list-visible") && (event.target.className !== "screenshot-local-list"))   
            {
                window.removeEventListener('click', closeLocalList)
                const localList = document.getElementById("opacityDiv")
                const localListIcon = document.getElementById('screenshotSelectLocalIcon')
                if(localListIcon){localListIcon.classList.remove("screenshot-local-list-rotate-icon")}
                if(localList){localList.classList.remove('screenshot-local-list-visible')}                
            }           
    }

    const handle = (localizationId, locale, name) =>{
        // setLocalizationId(localizationId)
        setLanguage(locale.toLowerCase())
        setName(name) 
        scrollToLanguage(name)   
    }

    const scrollToLanguage = (id) => {
        document.getElementById(id).scrollIntoView(
          {
            behavior: 'smooth'
            // ,block: "end", inline: "nearest"
          }
        );
      }

    return (
    <>
        {subWarning ? 
            subWarning.status==="expiring" ?
                jurisdiction.role.includes(currentAppRole) ?
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'calc(100% - 70px)', margin:'35px 0 0 35px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                : 
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'calc(100% - 70px)', margin:'35px 0 0 35px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner's subscription is expiring.</span>
            : subWarning.status==="banned" ?
                jurisdiction.role.includes(currentAppRole) ?
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'calc(100% - 70px)', margin:'35px 0 0 35px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{subWarning.message}</span>
                : 
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'calc(100% - 70px)', margin:'35px 0 0 35px'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />Owner's subscription has expired. This application is read only.</span>
            :     
                ''
        :
            ''
        }
        {!isWaiting ? 
            <div className="description-inner-content current-inner-container">
                <span></span>


                <div className="screenshot-select-div">
                    <div id='screenshotSelectLocal' onClick={()=>{handleClick()}} className='screenshot-select-local'>
                        <span className='screenshot-select-local-language'>{name}</span>
                        <img id='screenshotSelectLocalIcon' className='screenshot-select-local-icon' src="/assets/Icons/down-arrow-black.svg" alt="AppImage" />
                    </div>
                    <div id='opacityDiv' className="opacity-div">   
                        <div id='arrowUp' className='arrow-up'></div>
                        <div id='arrowUp2' className='arrow-up2'></div>
                        <div id='screenshotLocalList' className="screenshot-local-list">
                            <>
                                {!currentAppLocalizations ? '' :currentAppLocalizations.map((data, index)=>{
                                    return (
                                        <ScreenshotLocalItem func={handle} key={index+'x'} data={data} count={index} class_name={ 'screenshot-local-item'}></ScreenshotLocalItem>
                                        // adı değişecek
                                    )
                                })}
                            </>
                        </div>
                    </div> 
                </div>

                {!currentAppLocalizations ?             
                    <div className="spinner">
                        <Spinner/>
                    </div> 
                :
                    currentAppLocalizations.map((data, index)=>{
                        return (
                            <CurrentDescriptionItem key={index} data={data} count={index}></CurrentDescriptionItem>
                        )
                })}
            </div> 
        : 
            <div className="description-inner-content current-inner-container">

                <span className='current-description-wait-refresh'><span className='current-description-wait-refresh-inner'><SpinnerSmall/>&nbsp;Fetching updated data...</span></span>

                {!currentAppLocalizations ?             
                    <div className="spinner">
                        <Spinner/>
                    </div> 
                :
                    currentAppLocalizations.map((data, index)=>{
                        return (
                            <CurrentDescriptionItem key={index} data={data} count={index} isWaiting={isWaiting}></CurrentDescriptionItem>
                        )
                })}
            </div> 
        }
    </>
        
        
    )
}   

export default CurrentDescriptionPage