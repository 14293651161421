import { useEffect, useContext } from 'react'
import {useState, useRef} from 'react'
import './CollectionDescriptionItem.css'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import { fireCustomRequestError, fireSuccessMessage, fireCustomError, fireRequestError } from '../../../Common Functions/commonFunctions'
import VariableContext from '../../../userContext/VariableContext'
import * as gaEvents from "../../../ga-utils"


function CollectionDescriptionItem({data, count}) {
  const [text, setText] = useState('')
  const [oldText, setOldText] = useState('')
  const [toogleEdit, setToogleEdit] = useState(false)
  const [textLenght, setTextLenght] = useState(0)
  const timer = useRef(null)

  const [name] = useState(data.locale_name)

  const {isUploadingDescription} = useContext(VariableContext)
  const {isUploading} = useContext(VariableContext)

  const {toogleAll, setToogleAll} = useContext(VariableContext)


  let {appId, collectionId} = useParams()
  

  useEffect(()=>{
    if(data.description!==null){
      setTextLenght(data.description.length)
      setOldText(data.description)
      setText(data.description)
    }
    // setLanguage((data.language_code).toLowerCase())
  },[])


  useEffect(()=>{
    if(text.length>4000){
      setTextLenght(4000)
    }else{
      setTextLenght(text.length)
    }
    clearTimeout(timer.current)
    timer.current=setTimeout(updateItem,3000) 

  },[text])


  const handleChange = (e) =>{
    setText(e.target.value.slice(0, 4000))

  }


  const updateItem = () =>{
    clearTimeout(timer.current)
    if(oldText!==text)
    {    
      var change = JSON.stringify({
      "appId": appId,
      "collectionId": collectionId,
      "descriptionId": data.description_id,
      "desc": text
      });
      var config = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/app/collection/description`,
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem("token")}`, 
          'Content-Type': 'application/json',
        },
        data : change
      };
      axios(config)
      .then(function (response) {
        gaEvents.editDescriptionSuccess()

        fireSuccessMessage(response)
        setOldText(text)

      })
      .catch(function (error) {
        gaEvents.editDescriptionFail()

        setText(oldText)
        if(error.message === 'Network Error'){
          fireCustomError('NETWORK ERROR. ')
        }else{
          fireRequestError(error)
        }
      });
    }
  }


  const updateOldText = () =>{
    setOldText(text)
  }

  const toogleEditOnOff = () =>{
    setToogleEdit(!toogleEdit); 
    if(toogleEdit){
      //do nothing
    }else{
      setTimeout(()=>{
        document.getElementById(name).getElementsByTagName('textarea')[0].focus()
      },150)
    }
  }

  useEffect(()=>{
    setToogleEdit(toogleAll.get(`description.${appId}.${collectionId}`))
  },[toogleAll])

  return (
  <div id={name} className="description-item">
      {count===0 ? 
        <button className='toogleAll-button' onClick={()=>{
          if(!toogleAll.get(`description.${appId}.${collectionId}`)){
            setToogleAll(new Map(toogleAll.set(`description.${appId}.${collectionId}`, true)))
          }else{
            setToogleAll(new Map(toogleAll.set(`description.${appId}.${collectionId}`, false)))
          }
        }}>
          {!toogleAll.get(`description.${appId}.${collectionId}`) ?
            "Edit Toogle All"
          :
            "Done Toogle All"
          }
        </button> 
      :
        ""
      }
      <div className="country-div">

        <div className="country-flag-name">
            <p className='country-name'>{name} {count===0 ? <span className='primary'>- Primary</span> : ''} </p>
            <p style={{color:'#ccc'}}>|</p>
            <p onClick={isUploading.collectionID === +collectionId || isUploadingDescription.collectionID === +collectionId ? ()=>{} :toogleEditOnOff} style={!toogleEdit ? {color:'#3A6FF8', cursor:'pointer', lineHeight : '0'} : {color:'#3A6FF8', cursor:'pointer', lineHeight : '0'}}>{!toogleEdit ? 'Edit' : 'Done'}</p>
            {/* <label class="switch">
              <input onChange={()=>{setToogleEdit(!toogleEdit); document.getElementById(name).getElementsByTagName('textarea')[0].focus()}} id={name+'whatsnewInput'} type="checkbox"/>
              <span class="slider round"></span>
            </label> */}
        </div>

        <p className='character-count'>{textLenght}/4000</p>

      </div>

    <div className="description-field">
        <textarea className='description-textarea' name="description-textarea" cols="50" rows="10" value={text} 
        onChange={handleChange} onBlur={updateItem} onFocus={updateOldText} disabled={!toogleEdit || isUploading.collectionID === +collectionId || isUploadingDescription.collectionID === +collectionId} readOnly={isUploading.collectionID === +collectionId || isUploadingDescription.collectionID === +collectionId || !toogleEdit ? true : false} style={toogleEdit ? {border: '1px solid #3A6FF8'} : {border: 'none'}}
        ></textarea>
    </div>

  </div>
  )
}

export default CollectionDescriptionItem