import {useState, useLayoutEffect, useContext, useEffect} from 'react'
import axios from 'axios'
import './CollectionScreenshotItem.css'
import Spinner from '../../../Misc/Spinner'
import SpinnerSmall from '../../../Misc/SpinnerSmall'
import { useParams } from 'react-router-dom'
import VariableContext from '../../../userContext/VariableContext'
import Swal from 'sweetalert2'
import { fireRequestError, fireSuccessMessage, fireCustomErrorV2, sleep, fireCustomError } from '../../../Common Functions/commonFunctions'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import jurisdiction from '../../../Jusristiction/juristiction'
import React from 'react'
import CollectionVideo from './CollectionVideo'
import * as gaEvents from "../../../ga-utils"

function CollectionScreenshotItem({uniqueId, classId ,languageCode, localizationId, deviceSize, screenshots, primaryLocaleCode, primaryLanguageName, primaryLocaleScreenshots, previews, primaryLocalePreviews, devicePreviewSize}) {

    const [slideOpen, setSlideOpen] = useState(false)
    
    const playerReference = React.useRef(null);
    const [videoPlay, setVideoPlay] = useState(null)
    const [forAllPreviews, setForAllPreviews] = useState(false)
    const [isWaitingTimeFrame, setIsWaitingTimeFrame] = useState(false)

    const [images, setImages] = useState([])
    const [imageURLs, setImageURLs] = useState([])

    const [previewsUp, setPreviewsUp] = useState([])
    const [previewURLs, setPreviewURLs] = useState([])

    const [isWaiting, setIsWaiting] = useState(false)
    const [isWaitingPreview, setIsWaitingPreview] = useState(false)

    const [dragActive, setDragActive] = useState(false);
    const [dragActivePreview, setDragActivePreview] = useState(false);

    // const {percentage, setPercentage} = useContext(VariableContext)
    // const {percentagePreview, setPercentagePreview} = useContext(VariableContext)
    const [percentagePreview, setPercentagePreview] = useState(new Map())
    const [percentage, setPercentage] = useState(new Map())

    const [screenshotSet, setScreenshotSet] = useState(screenshots.find((i)=>{return i.size === deviceSize}) || false)//global den alınıcak
    const [primaryScreenshotSet, setPrimaryScreenshotSet] = useState(primaryLocaleScreenshots.find((i)=>{return i.size === deviceSize}) || false)//global den alınıcak
    const [forOrder, setForOrder] = useState([])//global kopyası, useEffect [global]

    const [screenshotSetImg, setScreenshotSetImg] = useState(screenshots)//global
    const [primaryScreenshotSetImg, setPrimaryScreenshotSetImg] = useState(primaryLocaleScreenshots)//global

    const [previewSet, setPreviewSet] = useState(previews.find((i)=>{return i.size === devicePreviewSize}) || false)//global den alınıcak
    const [primaryPreviewSet, setPrimaryPreviewSet] = useState(primaryLocalePreviews.find((i)=>{return i.size === devicePreviewSize}) || false)//global den alınıcak
    const [forPreviewOrder, setForPreviewOrder] = useState([])//global kopyası, useEffect [global]
    const [previewSetImg, setPreviewSetImg] = useState(previews)//global
    const [primaryPreviewSetImg, setPrimaryPreviewSetImg] = useState(primaryLocalePreviews)//global

    const {internalRefreshSmall, setInternalRefreshSmall} = useContext(VariableContext)
    const {internalRefreshBig, setInternalRefreshBig} = useContext(VariableContext)
    const [internalRefresh, setInternalRefresh] = useState(false)
    const [testCase, setTestCase] = useState(0)
    const [turn, setTurn] = useState(0)

    const {internalPreviewRefreshSmall, setInternalPreviewRefreshSmall} = useContext(VariableContext)
    const {internalPreviewRefreshBig, setInternalPreviewRefreshBig} = useContext(VariableContext)
    const [internalPreviewRefresh, setInternalPreviewRefresh] = useState(false)
    const [testCasePreview, setTestCasePreview] = useState(0)
    const [turnPreview, setTurnPreview] = useState(0)
    
    const {isUploadingPhoto} = useContext(VariableContext)
    const {isUploadingPreview} = useContext(VariableContext)
    const {isUploading} = useContext(VariableContext)
    const {currentAppRole} = useContext(VariableContext)

    const {registryArray, setRegistryArray} = useContext(VariableContext)
    const {registryPreviewArray, setRegistryPreviewArray} = useContext(VariableContext)
    const {updateUploadStatus} = useContext(VariableContext)
    const {updatePreviewUploadStatus} = useContext(VariableContext)
    const {updateDeleteStatus} = useContext(VariableContext)
    const {updatePreviewDeleteStatus} = useContext(VariableContext)

    let slideIndex=1;
    let {appId, collectionId} = useParams()

    const errorMessage =
    {
        APP_IPHONE_67: 'APP_IPHONE_67. The dimensions of one or more screenshots are wrong.<br>Screenshot dimensions should be: 1290x2796 2796x1290',
        APP_IPHONE_65: 'APP_IPHONE_65. The dimensions of one or more screenshots are wrong.<br>Screenshot dimensions should be: 1242x2688 2688x1242 1284x2778 2778x1284',
        APP_IPHONE_61: 'APP_IPHONE_61. The dimensions of one or more screenshots are wrong.<br>Screenshot dimensions should be: 1179x2556 2556x1179',
        APP_IPHONE_58: 'APP_IPHONE_58. The dimensions of one or more screenshots are wrong.<br>Screenshot dimensions should be:<br>1125x2436 2436x1125 1080x2340 2340x1080 2532x1170 1170x2532',
        APP_IPHONE_55: 'APP_IPHONE_55. The dimensions of one or more screenshots are wrong.<br>Screenshot dimensions should be: 1242x2208 2208x1242',
        APP_IPHONE_47: 'APP_IPHONE_47. The dimensions of one or more screenshots are wrong.<br>Screenshot dimensions should be: 750x1334 1334x750',
        APP_IPHONE_40: 'APP_IPHONE_40. The dimensions of one or more screenshots are wrong.<br>Screenshot dimensions should be:<br>640x1096 640x1136 1136x600 1136x640',
        APP_IPHONE_35: 'APP_IPHONE_35. The dimensions of one or more screenshots are wrong.<br>Screenshot dimensions should be:<br>640x920 640x960 960x600 960x640',
    }

    const errorMessagePreview =
    {
        IPHONE_67: {resolution:'IPHONE_67. Preview dimensions should be: 886x1920 1920x886.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPHONE_67. The file size of one or more previews are bigger than 500MB.'},
        IPHONE_65: {resolution:'IPHONE_65. Preview dimensions should be: 886x1920 1920x886.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPHONE_65. The file size of one or more previews are bigger than 500MB.'},
        IPHONE_61: {resolution:'IPHONE_61. Preview dimensions should be: 886x1920 1920x886.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPHONE_61. The file size of one or more previews are bigger than 500MB.'},
        IPHONE_58: {resolution:'IPHONE_58. Preview dimensions should be: 886x1920 1920x886.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPHONE_58. The file size of one or more previews are bigger than 500MB.'},
        IPHONE_55: {resolution:'IPHONE_55. Preview dimensions should be: 1080x1920 1920x1080.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPHONE_55. The file size of one or more previews are bigger than 500MB.'},
        IPHONE_47: {resolution:'IPHONE_47. Preview dimensions should be: 750x1334 1334x750.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPHONE_47. The file size of one or more previews are bigger than 500MB.'},
        IPHONE_40: {resolution:'IPHONE_40. Preview dimensions should be: 1080x1920 1920x1080.<br>Length must be between 15 seconds minimum and 30 seconds maximum.',
                    size: 'IPHONE_40. The file size of one or more previews are bigger than 500MB.'},
        IPHONE_35: 'IPHONE_35. No previews are allowed for this device.'
    }

    //are there any active uploads from input fields or deletions?
    useLayoutEffect(()=>{
        if(registryArray.get(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`)===true){
            setIsWaiting(true)
        }
        else if(registryArray.get(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`)===true){
            setIsWaiting(true)
        }
        // else{
        //     setIsWaiting(false)
        // }
        if(registryPreviewArray.get(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-upload`)===true){
            setIsWaitingPreview(true)
        }
        else if(registryPreviewArray.get(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`)===true){
            setIsWaitingPreview(true)
        }
        // else {
        //     setIsWaitingPreview(false)
        // }  
    },[])

    //change order arrays for handleOnDragEnd and handleOnDragEndPreview
    useLayoutEffect(()=>{
        if(screenshotSet !== false){
            setForOrder(screenshotSet.screenshots)}
        if(previewSet !== false){
            setForPreviewOrder(previewSet.previews)}
    },[screenshotSet, previewSet])




/////////////////////////////////////////////////////////////
/////////////////OPERATIONS FOR SCREENSHOTS//////////////////
/////////////////////////////////////////////////////////////

    //slide functions for screenshots


    useEffect(()=>{
        if(slideOpen===true){
            window.addEventListener('keydown', arrowKeys)
        }
        else{
            window.removeEventListener('keydown', arrowKeys)
        }
        return(()=>
            window.removeEventListener('keydown', arrowKeys)
        )
    },[slideOpen])

    function openLightbox(key) {
        document.getElementById(`lightbox${key}`).style.display = 'block';
        document.getElementById(`lightbox${key}`).focus()
        setSlideOpen(true)

    }
     
    function closeSlide(e, key){
        if(e.target.id ==='collection-next' || e.target.id ==='collection-previous' || e.target.className === 'collection-screenshots-slide'){

        }else{
            closeLightbox(key)
        }
    }

    function closeLightbox(key) {
        setSlideOpen(false)
        document.getElementById(`lightbox${key}`).style.display = 'none';
    }

    function arrowKeys (e){
        if (e.keyCode === 37) {
            e.preventDefault()
            // left arrow
            changeSlide(-1,uniqueId)
         }
         else if (e.keyCode === 39) {
            e.preventDefault()
            // right arrow
            changeSlide(1,uniqueId)
         }  
    }

    function changeSlide(n, key) {
        showSlide(slideIndex += n, key);
    }
    
    function toSlide(n, key) {
        showSlide(slideIndex = n, key);
    }

    function showSlide(n, key) {

        const slides = document.getElementsByClassName(`slide${key}`);

        if (n > slides.length) {
            slideIndex = 1;	
            }

        if (n < 1) {
            slideIndex = slides.length;
        }
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = 'block';
    }

    //change order of screenshots
    function handleOnDragEnd(result) {
        if(jurisdiction.screenshots.includes(currentAppRole)){
            if (!result.destination) return;

            const items = Array.from(forOrder);
            const [reorderedItem] = items.splice(result.source.index, 1);;
            items.splice(result.destination.index, 0, reorderedItem);
            if(isUploading.collectionID!== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId){
                setForOrder(items);
            }
    
            let order = []
            for (let i=0; i<items.length; i++){
                order.push(items[i].screenshot_id)
                if(i===items.length-1){
                    var data = JSON.stringify({
                        "appId": appId,
                        "collectionId": collectionId,
                        "localizationId": localizationId,
                        "size": deviceSize,
                        "idList": order
                        });
                        
                        var config = {
                        method: 'put',
                        url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/order`,
                        headers: { 
                            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                            'Content-Type': 'application/json'
                        },
                        data : data
                        };
                        
                        axios(config)
                        .then(function (response) {
                        gaEvents.changeScreenshotOrderSuccess(deviceSize)
                        if(deviceSize==='APP_IPHONE_55'){
                            setInternalRefreshSmall(true)
                        }else if (deviceSize==='APP_IPHONE_65'){
                            setInternalRefreshBig(true)
                        }
                        else{
                            setInternalRefresh(true)
                        }
                        })
                        .catch(function (error) {
                            gaEvents.changeScreenshotOrderFail(deviceSize)

                            if(error.message === 'Network Error'){
                                fireCustomError('NETWORK ERROR. ')
                              }else{
                                fireRequestError(error)
                              }
                        });
                }
            }
        }else{
            fireCustomErrorV2('You do not have permission for this operation')
        }

        
    }

    //drag-drop state for drop uploads
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if(isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !isWaiting && !isWaitingPreview){
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActive(true);
                } else if (e.type === "dragleave") {
                setDragActive(false);
                }
        }
    };

    //check uploaded screenshot limits and initiate tests for validation
    useLayoutEffect(()=>{
        if(images.length!==0){    
            if((images.length > (screenshotSet ? 10-(screenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.length): 10)) ){
                gaEvents.addScreenshotCountFail(deviceSize)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    position: 'center',
                    width: 'max-content',
                    text: 'There can be a maximum amount of 10 screenshots per device',
                    color: 'white',
                    background: 'red',
                    backdrop: true,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'OK',
                    cancelButtonColor: '#f27474',      
                })
                setImages([])
                if(document.getElementById('chooseFile'+uniqueId)){
                    document.getElementById('chooseFile'+uniqueId).value = ''
                }       
                return
                // error
            }else{

                const newImageUrls = []
                for (const img of images) {
                    newImageUrls.push(URL.createObjectURL(img))
                }
                setTestCase(0)
                setImageURLs(newImageUrls)
    }}},[images])

    //tests for screenshots
    useLayoutEffect(()=>{
        if(images.length !==0 && testCase===0){ 
            if((images.length> (screenshotSet ? 10-(screenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.length): 10)) ){
                gaEvents.addScreenshotCountFail(deviceSize)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    position: 'center',
                    width: 'max-content',
                    text: 'There can be a maximum amount of 10 screenshots per device',
                    color: 'white',
                    background: 'red',
                    backdrop: true,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'OK',
                    cancelButtonColor: '#f27474',
                    
                })
                setImages([])
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }       
                return
            }else{
                setIsWaiting(true) 
                // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`, true)))
                updateUploadStatus(appId, collectionId, languageCode, deviceSize, true)


                var testFunction = ()=>{
                    var i = 0;
                    var checkNextImage = function () {
                        // Reached end of files, terminate
                        if (i >= imageURLs.length) return;
                        
                        // Create a new image element
                        let imag = new Image();
                
                        // Set the image source to the current file object
                        imag.src = imageURLs[i]
                
                        // Wait for the image to load before checking its size
                        imag.onload = function () {
                            // Get the width and height of the image
                            var width = this.naturalWidth;
                            var height = this.naturalHeight;
                
                            if (deviceSize ==='APP_IPHONE_67') {//6.7'' display requirements
                                if((height===1290 && width===2796) || (height===2796 && width===1290)){
                                    setTestCase(current=>current+1)
                                    setTurn(i)                               
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)   
                                }
                            }else if(deviceSize ==='APP_IPHONE_65'){//6.5'' display requirements
                                if((height===2778 && width===1284) || (height===2688 && width===1242) || (height===1284 && width===2778) || (height===1242 && width===2688)){
                                    setTestCase(current=>current+1)
                                    setTurn(i)                               
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)   
                                }
                            }else if(deviceSize ==='APP_IPHONE_61'){//6.1'' display requirements
                                if((height===2556 && width===1179) || (height===1179 && width=== 2556)){
                                    setTestCase(current=>current+1)
                                    setTurn(i)                               
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)   
                                }
                            }else if(deviceSize ==='APP_IPHONE_58'){//5.8'' display requirements
                                if((height===2532 && width===1170) || (height===2436 && width===1125) || (height===2340 && width===1080) || (height===1170 && width===2532) || (height===1125 && width===2436) || (height===1080 && width===2340)){
                                    setTurn(i)
                                    setTestCase(current=>current+1) 
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)
                                }
                            }else if(deviceSize ==='APP_IPHONE_55'){//5.5'' display requirements
                                if((height===2208 && width===1242) || (height===1242 && width===2208)){
                                    setTurn(i)
                                    setTestCase(current=>current+1)     
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)
                                }
                            }else if(deviceSize ==='APP_IPHONE_47'){//4.7'' display requirements
                                if((height===1334 && width===750) || (height===750 && width===1334)){
                                    setTurn(i)
                                    setTestCase(current=>current+1)
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)    
                                }
                            }else if(deviceSize ==='APP_IPHONE_40'){//4'' display requirements
                                if((height===1096 && width===640) || (height===1136 && width===640) || (height===600 && width===1136) || (height===640 && width===1136)){
                                    setTurn(i)
                                    setTestCase(current=>current+1)   
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)                            
                                }
                            }else if(deviceSize ==='APP_IPHONE_35'){//3.5'' display requirements
                                if((height===920 && width===640) || (height===960 && width===640) || (height===600 && width===960) || (height===640 && width===960)){
                                    setTurn(i)
                                    setTestCase(current=>current+1) 
                                }
                                else{
                                    setTestCase(current=>current-1)
                                    setTurn(i)
                                }
                            }
                            i++;
                            checkNextImage();
                        }   
                    }
                    checkNextImage();
                }
                testFunction()

                // for(let i=0; i<imageURLs.length; i++){
                //     const imag = new Image();
                //     imag.src = imageURLs[i];
                //     imag.onload = (e) => {
                //         const height = +e.target.height;
                //         const width = +e.target.width;
                //         if (deviceSize ==='APP_IPHONE_67') {//6.7'' display requirements
                //             if((height===1290 && width===2796) || (height===2796 && width===1290)){
                //                 setTestCase(current=>current+1)
                //                 setTurn(i)                               
                //             }
                //             else{
                //                 setTestCase(current=>current-1)
                //                 setTurn(i)   
                //             }
                //         }else if(deviceSize ==='APP_IPHONE_65'){//6.5'' display requirements
                //             if((height===2778 && width===1284) || (height===2688 && width===1242) || (height===1284 && width===2778) || (height===1242 && width===2688)){
                //                 setTestCase(current=>current+1)
                //                 setTurn(i)                               
                //             }
                //             else{
                //                 setTestCase(current=>current-1)
                //                 setTurn(i)   
                //             }
                //         }else if(deviceSize ==='APP_IPHONE_61'){//6.1'' display requirements
                //             if((height===2556 && width===1179) || (height===1179 && width=== 2556)){
                //                 setTestCase(current=>current+1)
                //                 setTurn(i)                               
                //             }
                //             else{
                //                 setTestCase(current=>current-1)
                //                 setTurn(i)   
                //             }
                //         }else if(deviceSize ==='APP_IPHONE_58'){//5.8'' display requirements
                //             if((height===2532 && width===1170) || (height===2436 && width===1125) || (height===2340 && width===1080) || (height===1170 && width===2532) || (height===1125 && width===2436) || (height===1080 && width===2340)){
                //                 setTurn(i)
                //                 setTestCase(current=>current+1) 
                //             }
                //             else{
                //                 setTestCase(current=>current-1)
                //                 setTurn(i)
                //             }
                //         }else if(deviceSize ==='APP_IPHONE_55'){//5.5'' display requirements
                //             if((height===2208 && width===1242) || (height===1242 && width===2208)){
                //                 setTurn(i)
                //                 setTestCase(current=>current+1)     
                //             }
                //             else{
                //                 setTestCase(current=>current-1)
                //                 setTurn(i)
                //             }
                //         }else if(deviceSize ==='APP_IPHONE_47'){//4.7'' display requirements
                //             if((height===1334 && width===750) || (height===750 && width===1334)){
                //                 setTurn(i)
                //                 setTestCase(current=>current+1)
                //             }
                //             else{
                //                 setTestCase(current=>current-1)
                //                 setTurn(i)    
                //             }
                //         }else if(deviceSize ==='APP_IPHONE_40'){//4'' display requirements
                //             if((height===1096 && width===640) || (height===1136 && width===640) || (height===600 && width===1136) || (height===640 && width===1136)){
                //                 setTurn(i)
                //                 setTestCase(current=>current+1)   
                //             }
                //             else{
                //                 setTestCase(current=>current-1)
                //                 setTurn(i)                            
                //             }
                //         }else if(deviceSize ==='APP_IPHONE_35'){//3.5'' display requirements
                //             if((height===920 && width===640) || (height===960 && width===640) || (height===600 && width===960) || (height===640 && width===960)){
                //                 setTurn(i)
                //                 setTestCase(current=>current+1) 
                //             }
                //             else{
                //                 setTestCase(current=>current-1)
                //                 setTurn(i)
                //             }
                //         }
                //     }
                // }
    }}},[imageURLs])

    //when tests end
    useLayoutEffect(()=>{
        //at least one test failed
        if(turn===images.length-1 && testCase !== images.length){
            gaEvents.addScreenshotResFail(deviceSize)
            setIsWaiting(false)
            // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`, false)))
            updateUploadStatus(appId, collectionId, languageCode, deviceSize, false)
            setImages([])
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }       
            setTestCase(0)
            setTurn(0)

            Swal.fire({
                icon: 'error',
                title: 'Error!',
                position: 'center',
                width: 'max-content',
                html: ''+errorMessage[deviceSize],
                color: 'white',
                background: '#cf000f',
                backdrop: true,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'OK',
                cancelButtonColor: '#f27474',
                
            })
        }
        //all tests are passed
        if(testCase === images.length && images.length!== 0){

            var dataForm = new FormData();
            dataForm.append('appId', appId);
            dataForm.append('collectionId', collectionId);
            dataForm.append('localizationId', localizationId);
            dataForm.append('size', deviceSize);
            for (const img of images) {
                dataForm.append('photo', img);
            }
            
            const onUploadProgress = (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                    setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,percent)));
                }
              };

            var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            data : dataForm,
            onUploadProgress
            };
            
            
            axios(config)
            .then((response)=>{
                gaEvents.addScreenshotSuccess(deviceSize)
                // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`, false)))
                updateUploadStatus(appId, collectionId, languageCode, deviceSize, false)
                if(deviceSize==='APP_IPHONE_55'){
                    setInternalRefreshSmall(true)
                }else if (deviceSize==='APP_IPHONE_65'){
                    setInternalRefreshBig(true)
                }
                else{
                    setInternalRefresh(true)
                }
                fireSuccessMessage(response)
            })
            .catch(function (error) {
                gaEvents.addScreenshotRequestFail(deviceSize)

                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
                setImages([])
                if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                }       
                setIsWaiting(false)
                // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-upload`, false)))
                updateUploadStatus(appId, collectionId, languageCode, deviceSize, false)
                setTestCase(0)
                setTurn(0)
                setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,0)));
                
            });
        }
    }, [testCase,turn])

    //refresh screenshots according to sizes
    const getScreenshots = async () =>{
        
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/array/${appId}/${collectionId}/${languageCode}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        };
        axios(config)
        .then((response)=>{
            setScreenshotSetImg(response.data.data.sizes)
            setScreenshotSet(response.data.data.sizes.find((i)=>{return i.size === deviceSize}) || false)
            if(languageCode === primaryLocaleCode){
                setPrimaryScreenshotSetImg(response.data.data.sizes)
                setPrimaryScreenshotSet(response.data.data.sizes.find((i)=>{return i.size === deviceSize}) || false)
            }
            setInternalRefreshBig(false)
            setInternalRefresh(false)
            setInternalRefreshSmall(false)
            setImages([])       
            setTestCase(0)
            setTurn(0)
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }
            setIsWaiting(false)
            setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,0)));

        })
        .catch((error)=>{
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            setInternalRefreshBig(false)
            setInternalRefresh(false)
            setInternalRefreshSmall(false)
            setImages([])       
            setTestCase(0)
            setTurn(0)
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }
            setIsWaiting(false)
            setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,0)));

        })
        
    }
    const getPriScreenshots = async () =>{
            if(languageCode !== primaryLocaleCode){
                var config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/array/${appId}/${collectionId}/${primaryLocaleCode}`,
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                };
                axios(config)
                .then((response)=>{
                    setPrimaryScreenshotSetImg(response.data.data.sizes)
                    setPrimaryScreenshotSet(response.data.data.sizes.find((i)=>{return i.size === deviceSize}) || false)
                    setInternalRefreshBig(false)
                    setInternalRefresh(false)
                    setInternalRefreshSmall(false)
                    setImages([])       
                    setTestCase(0)
                    setTurn(0)
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }
                    setIsWaiting(false)
                    setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,0)));


                })
                .catch((error)=>{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                    setInternalRefreshBig(false)
                    setInternalRefresh(false)
                    setInternalRefreshSmall(false)
                    setImages([])       
                    setTestCase(0)
                    setTurn(0)
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }
                    setIsWaiting(false)
                    setPercentage(new Map(percentage.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}`,0)));


                })
            }

        
    }
    useLayoutEffect(()=>{
        if(internalRefreshBig === true && deviceSize>'APP_IPHONE_55'){
            Promise.all(
                [getScreenshots(), getPriScreenshots()]
            )
        }
        if(internalRefreshSmall === true && deviceSize<='APP_IPHONE_55'){
            Promise.all(
                [getScreenshots(), getPriScreenshots()]
            )
        }
        if(internalRefresh === true){
            Promise.all(
                [getScreenshots(), getPriScreenshots()]
            )
        }
    },[internalRefresh, internalRefreshBig, internalRefreshSmall])

    //delete single screenshot
    const deleteScreenShot = (screenshots) =>{
        // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, true)))
        updateDeleteStatus(appId, collectionId, languageCode, deviceSize, true)
        setIsWaiting(true)
        var data = JSON.stringify({
            "appId": appId,
            "collectionId": collectionId,
            "screenshotId": screenshots.screenshot_id
          });
          
          var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem("token")}`, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            gaEvents.deleteScreenshotSuccess(deviceSize)
            // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, false)))
            updateDeleteStatus(appId, collectionId, languageCode, deviceSize, false)

            if(deviceSize==='APP_IPHONE_55'){
                setInternalRefreshSmall(true)
            }else if (deviceSize==='APP_IPHONE_65'){
                setInternalRefreshBig(true)
            }
            else{
                setInternalRefresh(true)
            }
            fireSuccessMessage(response)
          })
          .catch(function (error) {
            gaEvents.deleteScreenshotFail(deviceSize)
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, false)))
            updateDeleteStatus(appId, collectionId, languageCode, deviceSize, false)

            setIsWaiting(false)
          });
          
    }

    //delete all screenshots for a device
    const deleteAllScreenshots = () =>{
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            position: 'center',
            width: '400',
            text: 'All screenshots from this device will be deleted',
            backdrop: true,
            showConfirmButton: true,
            showCancelButton: true,     
            confirmButtonColor: '#cf000fe6',
            cancelButtonColor: '#3A6FF8e6',
            cancelButtonText: 'Cancel',      
            confirmButtonText: 'Delete',      
        }).then((result)=>{
            if (result.isConfirmed) {
                // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, true)))
                updateDeleteStatus(appId, collectionId, languageCode, deviceSize, true)

                setIsWaiting(true)
                var data = JSON.stringify({
                    "appId": appId,
                    "collectionId": collectionId,
                    "localizationId": localizationId,
                    "size": deviceSize
                  });
                
                  var config = {
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_URL}/app/collection/screenshot/allInSizes`,
                    headers: { 
                      'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                
                  axios(config)
                  .then(function (response) {
                    gaEvents.deleteAllScreenshotSuccess(deviceSize)
                    // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, false)))
                    updateDeleteStatus(appId, collectionId, languageCode, deviceSize, false)


                    if(deviceSize==='APP_IPHONE_55'){
                        setInternalRefreshSmall(true)
                    }else if (deviceSize==='APP_IPHONE_65'){
                        setInternalRefreshBig(true)
                    }
                    else{
                        setInternalRefresh(true)
                    }
                    fireSuccessMessage(response)
                  })
                  .catch(function (error) {
                    gaEvents.deleteAllScreenshotFail(deviceSize)

                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                    setIsWaiting(false)
                    // setRegistryArray(new Map(registryArray.set(`${appId}-${collectionId}-${languageCode}-${deviceSize}-delete`, false)))
                    updateDeleteStatus(appId, collectionId, languageCode, deviceSize, false)


                  });
            }
        })
    }
    
////////////////////////////////////////////////////////////////////
/////////////////END OF OPERATIONS FOR SCREENSHOTS//////////////////
////////////////////////////////////////////////////////////////////




























//////////////////////////////////////////////////////////
/////////////////OPERATIONS FOR PREVIEWS//////////////////
//////////////////////////////////////////////////////////

    //change order of previews
    function handleOnDragEndPreview(result) {
        if(jurisdiction.screenshots.includes(currentAppRole)){
            if (!result.destination) return;

            const items = Array.from(forPreviewOrder);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            if(isUploading.collectionID!== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId){
                setForPreviewOrder(items);
            }
    
            let order = []
            for (let i=0; i<items.length; i++){
                order.push(items[i].preview_id)
                if(i===items.length-1){
                    var data = JSON.stringify({
                        "appId": appId,
                        "collectionId": collectionId,
                        "localizationId": localizationId,
                        "size": devicePreviewSize,
                        "idList": order
                      });
                      
                      var config = {
                        method: 'put',
                        url: `${process.env.REACT_APP_API_URL}/app/collection/preview/order`,
                        headers: { 
                          'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                          'Content-Type': 'application/json'
                        },
                        data : data
                      };
                      
                      axios(config)
                      .then(function (response) {
                        if(devicePreviewSize==='IPHONE_55'){
                            setInternalPreviewRefreshSmall(true)
                        }else if (devicePreviewSize==='IPHONE_65'){
                            setInternalPreviewRefreshBig(true)
                        }
                        else{
                            setInternalPreviewRefresh(true)
                        }
                      })
                      .catch(function (error) {
                        if(error.message === 'Network Error'){
                            fireCustomError('NETWORK ERROR. ')
                          }else{
                            fireRequestError(error)
                          }
                      });
                }
            }
        }else{
            fireCustomErrorV2('You do not have permission for this operation')
        }

        
    }

    //drag-drop state for drop uploads
    const handleDragPreview = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if(isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !isWaitingPreview && !isWaiting){
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActivePreview(true);
                } else if (e.type === "dragleave") {
                setDragActivePreview(false);
                }
        }
    }

    //check uploaded preview limits and initiate tests for validation
    useLayoutEffect(()=>{
        if(previewsUp.length!==0){    
            if((previewsUp.length > (previewSet ? 3-(previewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.length): 3)) ){

                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    position: 'center',
                    width: 'max-content',
                    text: 'There can be a maximum amount of 3 previews per device',
                    color: 'white',
                    background: 'red',
                    backdrop: true,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'OK',
                    cancelButtonColor: '#f27474',      
                })
                setImages([])
                if(document.getElementById('chooseFile'+uniqueId)){
                    document.getElementById('chooseFile'+uniqueId).value = ''
                }       
                return
                // error
            }else{

                const newPreviewUrls = []
                for (const pre of previewsUp) {
                    newPreviewUrls.push(URL.createObjectURL(pre))
                }
                setTestCasePreview(0)
                setPreviewURLs(newPreviewUrls)
    }}},[previewsUp])

    //tests for previews
    useLayoutEffect(()=>{
        if(previewsUp.length !==0 && testCasePreview===0){ 
            if((previewsUp.length> (previewSet ? 3-(previewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.length): 3)) ){
                gaEvents.addPreviewCountFail(devicePreviewSize)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    position: 'center',
                    width: 'max-content',
                    text: 'There can be a maximum amount of 3 previews per device',
                    color: 'white',
                    background: 'red',
                    backdrop: true,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'OK',
                    cancelButtonColor: '#f27474',
                    
                })
                setImages([])
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }       
                return
            }else{
                setIsWaitingPreview(true) 
                // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-upload`, true)));
                updatePreviewUploadStatus(appId, collectionId, languageCode, devicePreviewSize, true)

                var testFunction2 = ()=>{
                    var i = 0;
                    var checkNextPrev = function () {
                        // Reached end of files, terminate
                        if (i >= previewURLs.length) return;
                        
                        const prev = document.createElement('video');
                        prev.setAttribute("id", `video${i+uniqueId+classId}`);
                        prev.setAttribute("src", previewURLs[i]); 
                
                        // Wait for the image to load before checking its size
                        prev.addEventListener('loadedmetadata', function () {
                            // Get the width and height of the image
                            const height = +this.videoHeight;
                            const width = +this.videoWidth;
                            const duration = +this.duration
                
                            if (devicePreviewSize ==='IPHONE_67') {//6.7'' display requirements
                                if(((height===886 && width===1920) || (height===1920 && width===886)) && ((duration>=15)&&(duration<=30))){
                                    setTestCasePreview(current=>current+1)
                                    setTurnPreview(i)                               
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)   
                                }
                            }else if(devicePreviewSize ==='IPHONE_65'){//6.5'' display requirements
                                if(((height===886 && width===1920) || (height===1920 && width===886)) && ((duration>=15)&&(duration<=30))){
                                    setTestCasePreview(current=>current+1)
                                    setTurnPreview(i)                               
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)   
                                }
                            }else if(devicePreviewSize ==='IPHONE_61'){//6.1'' display requirements
                                if(((height===886 && width===1920) || (height===1920 && width===886)) && ((duration>=15)&&(duration<=30))){
                                    setTestCasePreview(current=>current+1)
                                    setTurnPreview(i)                               
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)   
                                }
                            }else if(devicePreviewSize ==='IPHONE_58'){//5.8'' display requirements
                                if(((height===886 && width===1920) || (height===1920 && width===886)) && ((duration>=15)&&(duration<=30))){
                                    setTurnPreview(i)
                                    setTestCasePreview(current=>current+1) 
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)
                                }
                            }else if(devicePreviewSize ==='IPHONE_55'){//5.5'' display requirements
                                if(((height===1080 && width===1920) || (height===1920 && width===1080)) && ((duration>=15)&&(duration<=30))){
                                    setTurnPreview(i)
                                    setTestCasePreview(current=>current+1)     
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)
                                }
                            }else if(devicePreviewSize ==='IPHONE_47'){//4.7'' display requirements
                                if(((height===1334 && width===750) || (height===750 && width===1334)) && ((duration>=15)&&(duration<=30))){
                                    setTurnPreview(i)
                                    setTestCasePreview(current=>current+1)
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)    
                                }
                            }else if(devicePreviewSize ==='IPHONE_40'){//4'' display requirements
                                if(((height===1080 && width===1920) || (height===1920 && width===1080)) && ((duration>=15)&&(duration<=30))){
                                    setTurnPreview(i)
                                    setTestCasePreview(current=>current+1)   
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)                            
                                }
                            }else if(devicePreviewSize ==='IPHONE_35'){//3.5'' display requirements
                                if(true){
                                    setTurnPreview(i)
                                    setTestCasePreview(current=>current+1) 
                                }
                                else{
                                    setTestCasePreview(current=>current-1)
                                    setTurnPreview(i)
                                }
                            }
                            i++;
                            checkNextPrev();
                        }, true)   
                    }
                    checkNextPrev();
                }
                testFunction2()

                // for(let i=0; i<previewURLs.length; i++){

                //     const prev = document.createElement('video');
                //     prev.setAttribute("id", `video${i+uniqueId+classId}`);
                //     prev.setAttribute("src", previewURLs[i]);                    
                //     prev.addEventListener('loadedmetadata', async function(e){
                //         if(i>0){
                //             await sleep(i*1000)
                //         }
                //         const height = +prev.videoHeight;
                //         const width = +prev.videoWidth;
                //         const duration = +prev.duration
                //         if (devicePreviewSize ==='IPHONE_67') {//6.7'' display requirements
                //             if(((height===886 && width===1920) || (height===1920 && width===886)) && ((duration>=15)&&(duration<=30))){
                //                 setTestCasePreview(current=>current+1)
                //                 setTurnPreview(i)                               
                //             }
                //             else{
                //                 setTestCasePreview(current=>current-1)
                //                 setTurnPreview(i)   
                //             }
                //         }else if(devicePreviewSize ==='IPHONE_65'){//6.5'' display requirements
                //             if(((height===886 && width===1920) || (height===1920 && width===886)) && ((duration>=15)&&(duration<=30))){
                //                 setTestCasePreview(current=>current+1)
                //                 setTurnPreview(i)                               
                //             }
                //             else{
                //                 setTestCasePreview(current=>current-1)
                //                 setTurnPreview(i)   
                //             }
                //         }else if(devicePreviewSize ==='IPHONE_61'){//6.1'' display requirements
                //             if(((height===886 && width===1920) || (height===1920 && width===886)) && ((duration>=15)&&(duration<=30))){
                //                 setTestCasePreview(current=>current+1)
                //                 setTurnPreview(i)                               
                //             }
                //             else{
                //                 setTestCasePreview(current=>current-1)
                //                 setTurnPreview(i)   
                //             }
                //         }else if(devicePreviewSize ==='IPHONE_58'){//5.8'' display requirements
                //             if(((height===886 && width===1920) || (height===1920 && width===886)) && ((duration>=15)&&(duration<=30))){
                //                 setTurnPreview(i)
                //                 setTestCasePreview(current=>current+1) 
                //             }
                //             else{
                //                 setTestCasePreview(current=>current-1)
                //                 setTurnPreview(i)
                //             }
                //         }else if(devicePreviewSize ==='IPHONE_55'){//5.5'' display requirements
                //             if(((height===1080 && width===1920) || (height===1920 && width===1080)) && ((duration>=15)&&(duration<=30))){
                //                 setTurnPreview(i)
                //                 setTestCasePreview(current=>current+1)     
                //             }
                //             else{
                //                 setTestCasePreview(current=>current-1)
                //                 setTurnPreview(i)
                //             }
                //         }else if(devicePreviewSize ==='IPHONE_47'){//4.7'' display requirements
                //             if(((height===1334 && width===750) || (height===750 && width===1334)) && ((duration>=15)&&(duration<=30))){
                //                 setTurnPreview(i)
                //                 setTestCasePreview(current=>current+1)
                //             }
                //             else{
                //                 setTestCasePreview(current=>current-1)
                //                 setTurnPreview(i)    
                //             }
                //         }else if(devicePreviewSize ==='IPHONE_40'){//4'' display requirements
                //             if(((height===1080 && width===1920) || (height===1920 && width===1080)) && ((duration>=15)&&(duration<=30))){
                //                 setTurnPreview(i)
                //                 setTestCasePreview(current=>current+1)   
                //             }
                //             else{
                //                 setTestCasePreview(current=>current-1)
                //                 setTurnPreview(i)                            
                //             }
                //         }else if(devicePreviewSize ==='IPHONE_35'){//3.5'' display requirements
                //             if(true){
                //                 setTurnPreview(i)
                //                 setTestCasePreview(current=>current+1) 
                //             }
                //             else{
                //                 setTestCasePreview(current=>current-1)
                //                 setTurnPreview(i)
                //             }
                //         }
                        
                //     }, true)
                // }
    }}},[previewURLs])

    //when tests end
    useLayoutEffect(()=>{
        //at least one test failed
        if(turnPreview===previewsUp.length-1 && testCasePreview !== previewsUp.length){
            gaEvents.addPreviewSizeFail(devicePreviewSize)
            setIsWaitingPreview(false)
            // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-upload`, false)))
            updatePreviewUploadStatus(appId, collectionId, languageCode, devicePreviewSize, false)
            setPreviewsUp([])
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }       
            setTestCasePreview(0)
            setTurnPreview(0)

            Swal.fire({
                icon: 'error',
                title: 'Error!',
                position: 'center',
                width: 'max-content',
                html: ''+errorMessagePreview[devicePreviewSize].resolution,
                color: 'white',
                background: '#cf000f',
                backdrop: true,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'OK',
                cancelButtonColor: '#f27474',
                
            })
        }
        //all tests are passed
        if(testCasePreview === previewsUp.length && previewsUp.length!== 0){


            var dataForm = new FormData();
            dataForm.append('appId', appId);
            dataForm.append('collectionId', collectionId);
            dataForm.append('localizationId', localizationId);
            dataForm.append('size', devicePreviewSize);
            for (const pre of previewsUp) {
                dataForm.append('preview', pre);
            }

            const onUploadProgress = (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                    setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,percent)));
                }
              };

            var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/preview`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            data : dataForm,
            onUploadProgress
            };

            axios(config)
            .then((response)=>{
                gaEvents.addPreviewSuccess(devicePreviewSize)
                // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-upload`, false)))
                updatePreviewUploadStatus(appId, collectionId, languageCode, devicePreviewSize, false)

                if(devicePreviewSize==='IPHONE_55'){
                    setInternalPreviewRefreshSmall(true)
                }else if (devicePreviewSize==='IPHONE_65'){
                    setInternalPreviewRefreshBig(true)
                }
                else{
                    setInternalPreviewRefresh(true)
                }
                fireSuccessMessage(response)
            })
            .catch(function (error) {
                gaEvents.addPreviewRequestFail(devicePreviewSize)
                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
                setPreviewsUp([])
                if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                }       
                setIsWaitingPreview(false)
                // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-upload`, false)))
                updatePreviewUploadStatus(appId, collectionId, languageCode, devicePreviewSize, false)

                setTestCasePreview(0)
                setTurnPreview(0)
                setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,0)));
                
            });
        }
    }, [testCasePreview,turnPreview])

    //refresh previews according to sizes
    const getPreviews = async () =>{
        
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/preview/array/${appId}/${collectionId}/${languageCode}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        };
        axios(config)
        .then((response)=>{
            setPreviewSetImg(response.data.data.sizes)
            setPreviewSet(response.data.data.sizes.find((i)=>{return i.size === devicePreviewSize}) || false)
            if(languageCode === primaryLocaleCode){
                setPrimaryPreviewSetImg(response.data.data.sizes)
                setPrimaryPreviewSet(response.data.data.sizes.find((i)=>{return i.size === devicePreviewSize}) || false)
            }
            setInternalPreviewRefreshBig(false)
            setInternalPreviewRefresh(false)
            setInternalPreviewRefreshSmall(false)
            setPreviewsUp([])       
            setTestCasePreview(0)
            setTurnPreview(0)
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }
            setIsWaitingPreview(false)
            setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,0)));

        })
        .catch((error)=>{
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            setInternalPreviewRefreshBig(false)
            setInternalPreviewRefresh(false)
            setInternalPreviewRefreshSmall(false)
            setPreviewsUp([])       
            setTestCasePreview(0)
            setTurnPreview(0)
            if(document.getElementById('chooseFile'+uniqueId)){
                document.getElementById('chooseFile'+uniqueId).value = ''
            }
            setIsWaitingPreview(false)
            setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,0)));

        })
        
    }
    const getPriPreviews = async () =>{
            if(languageCode !== primaryLocaleCode){
                var config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}/app/collection/preview/array/${appId}/${collectionId}/${primaryLocaleCode}`,
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                };
                axios(config)
                .then((response)=>{
                    setPrimaryPreviewSetImg(response.data.data.sizes)
                    setPrimaryPreviewSet(response.data.data.sizes.find((i)=>{return i.size === deviceSize}) || false)
                    setInternalPreviewRefreshBig(false)
                    setInternalPreviewRefresh(false)
                    setInternalPreviewRefreshSmall(false)
                    setPreviewsUp([])       
                    setTestCasePreview(0)
                    setTurnPreview(0)
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }
                    setIsWaitingPreview(false)
                    setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,0)));


                })
                .catch((error)=>{
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                    setInternalPreviewRefreshBig(false)
                    setInternalPreviewRefresh(false)
                    setInternalPreviewRefreshSmall(false)
                    setPreviewsUp([])       
                    setTestCasePreview(0)
                    setTurnPreview(0)
                    if(document.getElementById('chooseFile'+uniqueId)){
                        document.getElementById('chooseFile'+uniqueId).value = ''
                    }
                    setIsWaitingPreview(false)
                    setPercentagePreview(new Map(percentagePreview.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`,0)));


                })
            }

        
    }
    useLayoutEffect(()=>{
        if(internalPreviewRefreshBig === true && devicePreviewSize>'IPHONE_55'){
            Promise.all(
                [getPreviews(), getPriPreviews()]
            )
        }
        if(internalPreviewRefreshSmall === true && devicePreviewSize<='IPHONE_55'){
            Promise.all(
                [getPreviews(), getPriPreviews()]
            )
        }
        if(internalPreviewRefresh === true){
            Promise.all(
                [getPreviews(), getPriPreviews()]
            )
        }
    },[internalPreviewRefresh, internalPreviewRefreshBig, internalPreviewRefreshSmall])

    //delete single preview
    const deletePreview = (previews) =>{
        // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, true)))
        updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, true)
        setIsWaitingPreview(true)
        var data = JSON.stringify({
            "appId": appId,
            "collectionId": collectionId,
            "previewId": previews.preview_id
          });
          
          var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/app/collection/preview`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem("token")}`, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            gaEvents.deletePreviewSuccess(devicePreviewSize)
            // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, false)))
            updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, false)

            if(devicePreviewSize==='IPHONE_55'){
                setInternalPreviewRefreshSmall(true)
            }else if (devicePreviewSize==='IPHONE_65'){
                setInternalPreviewRefreshBig(true)
            }
            else{
                setInternalPreviewRefresh(true)
            }
            fireSuccessMessage(response)
          })
          .catch(function (error) {
            gaEvents.deletePreviewFail(devicePreviewSize)

            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, false)))
            updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, false)

            setIsWaitingPreview(false)
          });
          
    }

    //delete all previews for a device
    const deleteAllPreviews = () =>{
        Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            position: 'center',
            width: '400',
            text: 'All previews from this device will be deleted',
            backdrop: true,
            showConfirmButton: true,
            showCancelButton: true,     
            confirmButtonColor: '#cf000fe6',
            cancelButtonColor: '#3A6FF8e6',
            cancelButtonText: 'Cancel',      
            confirmButtonText: 'Delete',      
        }).then((result)=>{
            if (result.isConfirmed) {
                gaEvents.deleteAllPreviewSuccess(devicePreviewSize)

                // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, true)))
                updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, true)

                setIsWaitingPreview(true)
                var data = JSON.stringify({
                    "appId": appId,
                    "collectionId": collectionId,
                    "localizationId": localizationId,
                    "size": devicePreviewSize
                  });
                
                  var config = {
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_URL}/app/collection/preview/allInSizes`,
                    headers: { 
                      'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                
                  axios(config)
                  .then(function (response) {
                    gaEvents.deleteAllPreviewFail(devicePreviewSize)

                    // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, false)))
                    updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, false)

                    if(devicePreviewSize==='IPHONE_55'){
                        setInternalPreviewRefreshSmall(true)
                    }else if (devicePreviewSize==='IPHONE_65'){
                        setInternalPreviewRefreshBig(true)
                    }
                    else{
                        setInternalPreviewRefresh(true)
                    }
                    fireSuccessMessage(response)
                  })
                  .catch(function (error) {
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                      }else{
                        fireRequestError(error)
                      }
                    setIsWaitingPreview(false)
                    // setRegistryPreviewArray(new Map(registryPreviewArray.set(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}-delete`, false)))
                    updatePreviewDeleteStatus(appId, collectionId, languageCode, devicePreviewSize, false)


                  });
            }
        })
    }

    const playerReady = (player) => {
        playerReference.current = player;
        
        // handling video player
        player.on('waiting', () => {
        });
        player.on('dispose', () => {
        });
    }

    const oneSecondForward = () =>{
        if(playerReference.current){
            playerReference.current.pause()
            playerReference.current.currentTime(playerReference.current.currentTime()+1)
        } 
    }
    const oneSecondBack = () =>{
        if(playerReference.current){
            playerReference.current.pause()
            playerReference.current.currentTime(playerReference.current.currentTime()-1)
        } 
    }

    const previewSetPoster = (id) =>{
        setIsWaitingTimeFrame(true)
        if(!forAllPreviews){
            var data = JSON.stringify({
                "appId": appId,
                "previewId": id,
                "frameTime": 
                (()=>                                              
                    {if(playerReference.current.currentTime() < 10){ 
                        return '00:00:0'+playerReference.current.currentTime().toFixed(2).split(".")[0]+':'+(playerReference.current.currentTime().toFixed(2).split(".")[1].length===1 ? playerReference.current.currentTime().toFixed(2).split(".")[1]+'0' : playerReference.current.currentTime().toFixed(2).split(".")[1])}
                    else if(playerReference.current.currentTime() < 10 && playerReference.current.currentTime() ===0){
                        return '00:00:01:00'}
                    else
                        return '00:00:'+playerReference.current.currentTime().toFixed(2).split(".")[0]+':'+(playerReference.current.currentTime().toFixed(2).split(".")[1].length===1 ? playerReference.current.currentTime().toFixed(2).split(".")[1]+'0' : playerReference.current.currentTime().toFixed(2).split(".")[1])})()
            });
        }else{
            var data = JSON.stringify({
                "appId": appId,
                "collectionId": collectionId,
                "frameTime": 
                (()=>                                              
                    {if(playerReference.current.currentTime() < 10){ 
                        return '00:00:0'+playerReference.current.currentTime().toFixed(2).split(".")[0]+':'+(playerReference.current.currentTime().toFixed(2).split(".")[1].length===1 ? playerReference.current.currentTime().toFixed(2).split(".")[1]+'0' : playerReference.current.currentTime().toFixed(2).split(".")[1])}
                    else if(playerReference.current.currentTime() < 10 && playerReference.current.currentTime() ===0){
                        return '00:00:01:00'}
                    else
                        return '00:00:'+playerReference.current.currentTime().toFixed(2).split(".")[0]+':'+(playerReference.current.currentTime().toFixed(2).split(".")[1].length===1 ? playerReference.current.currentTime().toFixed(2).split(".")[1]+'0' : playerReference.current.currentTime().toFixed(2).split(".")[1])})()
            });
        }

        
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/preview/frameTimeCode`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem("token")}`, 
                'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then(function (response) {
            gaEvents.setPreviewPosterSuccess(devicePreviewSize)
            setTimeout(()=>{
                setIsWaitingPreview(true)
                if(!forAllPreviews){
                    if(devicePreviewSize==='IPHONE_55'){
                        setInternalPreviewRefreshSmall(true)
                    }else if (devicePreviewSize==='IPHONE_65'){
                        setInternalPreviewRefreshBig(true)
                    }
                    else{
                        setInternalPreviewRefresh(true)
                    }
                }else{
                    setInternalPreviewRefreshSmall(true)
                    setInternalPreviewRefreshBig(true)
                }
                setIsWaitingTimeFrame(false)
                setVideoPlay(null)
                setForAllPreviews(false)
                fireSuccessMessage(response)
            },2000)

        })
        .catch(function (error) {
            gaEvents.setPreviewPosterFail(devicePreviewSize)

            setIsWaitingTimeFrame(false)
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
        })
    }

/////////////////////////////////////////////////////////////////
/////////////////END OF OPERATIONS FOR PREVIEWS//////////////////
/////////////////////////////////////////////////////////////////





















////////////////////////////////////////////////////
/////////////////COMMON OPERATIONS//////////////////
////////////////////////////////////////////////////

    // triggers when an upload input is filled
    const onImageUpload = (e)=>{
        let i = 0;
        let imageArr = []
        let previewArr = []
        // 1.048.576
        if (e.target.files && e.target.files[0]) {
             

            for(; i<e.target.files.length; i++ ){
                if(e.target.files[i].type === "image/png" || e.target.files[i].type === "image/jpg" || e.target.files[i].type === "image/jpeg"){
                    imageArr.push(e.target.files[i])
                }
                else if(e.target.files[i].type === "video/mp4" || e.target.files[i].type === "video/mov" || e.target.files[i].type === "video/m4v"){
                    if(e.target.files[i].size / 1048576 <= 500){
                        previewArr.push(e.target.files[i])  
                    }
                    else{
                        gaEvents.addPreviewSizeFail(devicePreviewSize)
                        fireCustomErrorV2(e.target.files[i].name+' '+e.target.files[i].type+'.'+' '+'Size bigger than 500MB. Please make sure all previews are at most 500MB.')
                        document.getElementById('chooseFile'+uniqueId).value = ''
                        return
                    }
                }
                else{
                    fireCustomErrorV2(e.target.files[i].name+' '+e.target.files[i].type+'.'+' '+'Please make sure selected files are in correct format. Allowed formats are: image/png, image/jpg, image/jpeg, video/mp4, video/mov, video/m4v.')
                    document.getElementById('chooseFile'+uniqueId).value = ''
                    return
                }
                if(i===(e.target.files.length)-1){
                    setPreviewsUp([...previewArr])
                    setImages([...imageArr])
                }
            }
        }
    }

    // triggers when screenshots or previews or both are dropped, checks file format, displays error is wrong, else each file is sent to correct state for tests
    const handleDrop = function(e) {
         
        e.preventDefault();
        e.stopPropagation();
        let i = 0;
        let imageArr = []
        let previewArr = []
        if(isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !(isWaiting&&isWaitingPreview)){
            setDragActive(false);
            setDragActivePreview(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                for(; i<e.dataTransfer.files.length; i++ ){
                    if(e.dataTransfer.files[i].type === "image/png" || e.dataTransfer.files[i].type === "image/jpg" || e.dataTransfer.files[i].type === "image/jpeg"){
                        imageArr.push(e.dataTransfer.files[i])
                    }
                    else if(e.dataTransfer.files[i].type === "video/mp4" || e.dataTransfer.files[i].type === "video/mov" || e.dataTransfer.files[i].type === "video/m4v"){
                        if(e.dataTransfer.files[i].size / 1048576 <= 500){
                            previewArr.push(e.dataTransfer.files[i])  
                        }
                        else{
                            gaEvents.addPreviewSizeFail(devicePreviewSize)
                            fireCustomErrorV2(e.dataTransfer.files[i].name+' '+e.dataTransfer.files[i].type+'.'+' '+'Size bigger than 500MB. Please make sure all previews are at most 500MB.')
                            document.getElementById('chooseFile'+uniqueId).value = ''
                            return
                        }
                    }
                    else{
                        fireCustomErrorV2(e.dataTransfer.files[i].name+' '+e.dataTransfer.files[i].type+'.'+' '+'Please make sure selected files are in correct format. Allowed formats are: image/png, image/jpg, image/jpeg, video/mp4, video/mov, video/m4v.')
                        document.getElementById('chooseFile'+uniqueId).value = ''
                        return
                    }

                    if(i===(e.dataTransfer.files.length)-1){
                        setPreviewsUp([...previewArr])
                        setImages([...imageArr])
                    }
                }
            }
        }
    };

    //display 65 and 55 on initial render
    useEffect(() => {
        if(deviceSize==='APP_IPHONE_65' || deviceSize==='APP_IPHONE_55'){
            openScreenshots()
        }
    }, [])

    //display selected device 
    const openScreenshots = () =>{
        document.getElementById('collection-previews-screenshots'+uniqueId).classList.toggle('unhid')
        // document.getElementById('collection-screenshot-set'+uniqueId).classList.toggle('unhid')
        // document.getElementById('collection-preview-set'+uniqueId).classList.toggle('unhid')
        // document.getElementById('collection-screenshot-edit'+uniqueId).classList.toggle('unhid')
        document.getElementById('collection-screenshot-icon'+uniqueId).classList.toggle('rotate')            
    }

    //hide-show delete icon also any component is neeeded
    const makeVisible = (id) =>{
        document.getElementById(id).style.display= 'flex'
    }
    const makeInvisible = (id) =>{
        document.getElementById(id).style.display= 'none'
    }

///////////////////////////////////////////////////////////
/////////////////END OF COMMON OPERATIONS//////////////////
///////////////////////////////////////////////////////////

 










//render
    return (
        <div id={`${uniqueId+classId}`} className={`collection-screenshot-item ${classId}`}> 
        
            <div onClick={()=>openScreenshots()} className='collection-screenshot-device-name'>
                {deviceSize === 'APP_IPHONE_35' ? 
                <span className='device-title'><p><strong>iPhone 3.5" Display</strong> (Optional)</p></span> :
                deviceSize === 'APP_IPHONE_40' ? 
                <span className='device-title'><p><strong>iPhone 4" Display</strong> (Optional)</p></span> :
                deviceSize === 'APP_IPHONE_47' ? 
                <span className='device-title'><p><strong>iPhone 4.7" Display</strong> (Optional)</p></span> :
                deviceSize === 'APP_IPHONE_55' ? 
                <span className='device-title'><p><strong>iPhone 5.5" Display</strong></p></span> :
                deviceSize === 'APP_IPHONE_58' ? 
                <span className='device-title'><p><strong>iPhone 5.8" Display</strong> (Optional)</p></span> :  
                deviceSize === 'APP_IPHONE_61' ?  
                <span className='device-title'><p><strong>iPhone 6.1" Display</strong> (Optional)</p></span> : 
                deviceSize === 'APP_IPHONE_65' ?  
                <span className='device-title'><p><strong>iPhone 6.5" Display</strong></p></span> : 
                deviceSize === 'APP_IPHONE_67' ?  
                <span className='device-title'><p><strong>iPhone 6.7" Display</strong> (Optional)</p></span> : 
                'Unknown Device'}

                <img id={'collection-screenshot-icon'+uniqueId} className='collection-screenshot-icon' src="/assets/Icons/down-arrow-black.svg" alt="down_arrow" />
            </div>

            <div id={'collection-previews-screenshots'+uniqueId} className='collection-previews-screenshots'>

                <div style={{display:'flex', overflow: 'overlay', marginBottom: '15px', gap:'5px'}}>

                    <div id={'collection-preview-set'+uniqueId} className='collection-preview-set'>

                        <div className={`collection-preview-set-info ${previewSet  ? '' : 'if-collection-set-from-primay'}`}>
                            {/* dolu mu boş mu */}
                            {!previewSet ? 
                            //Boş
                                //cihaz 55 den büyük mü
                                devicePreviewSize > 'IPHONE_55' ?
                                    //büyük
                                    //kendisinde 65 cihaz var mı 
                                    (previewSetImg.find((i)=>{return i.size === 'IPHONE_65'}) || false) ?
                                        //var
                                        <span className='additional-collection-info'>Using 6.5" Display</span>
                                    :
                                        //yok
                                        //primary de varmı cihazdan
                                        primaryPreviewSet ? 
                                            //var
                                            <span className='additional-collection-info' > 
                                                {
                                                    devicePreviewSize === 'IPHONE_58' ? 
                                                    <span>Using {`${primaryLanguageName}`} 5.8" Display</span> :
                                                    devicePreviewSize === 'IPHONE_61' ?  
                                                    <span>Using {`${primaryLanguageName}`} 6.1" Display</span> : 
                                                    devicePreviewSize === 'IPHONE_65' ?  
                                                    <span>Using {`${primaryLanguageName}`} 6.5" Display</span> : 
                                                    devicePreviewSize === 'IPHONE_67' ?  
                                                    <span>Using {`${primaryLanguageName}`} 6.7" Display</span> : 
                                                ''}
                                            </span>
                                        :
                                            //yok
                                            //primaryde 65 var mı
                                            (primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_65'}) || false) ?
                                                //var
                                                <span className='additional-collection-info'>Using {`${primaryLanguageName}`} 6.5" Display</span>
                                            :
                                                //yok
                                                <span className='additional-collection-info'>&nbsp;</span>
                                :
                                //cihaz 55 den küçük veya eşit
                                //kendisinde 55 cihaz var mı 
                                (previewSetImg.find((i)=>{return i.size === 'IPHONE_55'}) || false) ?
                                    //var
                                    <span className='additional-collection-info'>Using 5.5" Display</span>
                                :
                                    //yok                   
                                    //primary de varmı cihazdan
                                    primaryPreviewSet ? 
                                    //var
                                    <span className='additional-collection-info' > 
                                        {
                                            devicePreviewSize === 'IPHONE_35' ? 
                                            <span>Using {`${primaryLanguageName}`} 3.5" Display</span> :
                                            devicePreviewSize === 'IPHONE_40' ?  
                                            <span>Using {`${primaryLanguageName}`} 4" Display</span> : 
                                            devicePreviewSize === 'IPHONE_47' ?  
                                            <span>Using {`${primaryLanguageName}`} 4.7" Display</span> : 
                                            devicePreviewSize === 'IPHONE_55' ?  
                                            <span>Using {`${primaryLanguageName}`} 5.5" Display</span> : 
                                        ''}
                                    </span>
                                :
                                    //yok
                                    //primaryde 55 var mı
                                    (primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_55'}) || false) ?
                                        //var
                                        <span className='additional-collection-info'>Using {`${primaryLanguageName}`} 5.5" Display</span>
                                    :
                                        //yok
                                        <span className='additional-collection-info'>&nbsp;</span>
                            :
                            //Dolu
                                ''            
                            }


                            <span style={{marginLeft: '15px', fontSize: '0.9rem'}}>
                                {
                                    !previewSet ?
                                    //Boş
                                    //cihaz 55 den büyük mü
                                    devicePreviewSize > 'IPHONE_55' ?
                                        //cihaz 55 den büyük
                                        //kendisinde 65 var mı
                                        (previewSetImg.find((i)=>{return i.size === 'IPHONE_65'}) || false) ?
                                            //kendisinde 65 var
                                            <span>{previewSetImg.find((i)=>{return i.size === 'IPHONE_65'}).previews.length}/3</span>
                                        :
                                            //kendisinde 65 yok
                                            //primary de cihazın kendi var mı
                                            primaryPreviewSet ?
                                                //primary de cihazın kendisi var
                                                <span>{primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.length}/3</span>
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 65 var mı
                                                (primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_65'}) || false) ?
                                                    //primary de 65 var 
                                                    <span>{primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_65'}).previews.length}/3</span>
                                                :
                                                    //primary de 65 yok
                                                    '0/3'  
                                    :
                                        //cihaz 55 e eşit ya da küçük
                                        //primary de cihazın kendi var mı
                                        primaryPreviewSet ?
                                            //primary de cihazın kendisi var
                                            <span>{primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.length}/3</span>
                                        :
                                            //primary de cihazın kendisi yok
                                            //primary de 55 var mı
                                            (primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_55'}) || false) ?
                                                //primary de 55 var 
                                                <span>{primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_55'}).previews.length}/3</span>
                                            :
                                                //primary de 55 yok
                                                '0/3'
                                :
                                    //Dolu
                                    <span>{previewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.length}/3</span>
                                }
                            </span>
                        </div>

                        <div id={`collection-row-previews-${uniqueId}`} className={`collection-row`} onDragEnter={handleDragPreview}>
                            {!isWaitingPreview ?
                                //cihazın seçilen localde fotosu dolu mu   
                                !previewSet ?
                                    //Boş
                                    //cihaz 55 den büyük mü
                                    devicePreviewSize > 'IPHONE_55' ?
                                        //cihaz 55 den büyük
                                        //kendisinde 65 var mı
                                        (previewSetImg.find((i)=>{return i.size === 'IPHONE_65'}) || false) ?
                                            //kendisinde 65 var
                                            previewSetImg.find((i)=>{return i.size === 'IPHONE_65'}).previews.map((previews, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection">
                                                                <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                        </div>
                                            )})
                                        :
                                            //kendisinde 65 yok
                                            //primary de cihazın kendi var mı
                                            primaryPreviewSet ?
                                                //primary de cihazın kendisi var
                                                primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.map((previews, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection">
                                                                <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                        </div>
                                                )})
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 65 var mı
                                                (primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_65'}) || false) ?
                                                    //primary de 65 var 
                                                    primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_65'}).previews.map((previews, index)=>{
                                                        return(
                                                            <div key={index + uniqueId} className="collection-col missing-collection">
                                                                <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                            </div>
                                                            
                                                    )})
                                                :
                                                    //primary de 65 yok
                                                    <div className='drag-file-empty' id={"drag-file-empty-preview"+uniqueId} >No Previews available</div>  

                                    :
                                        //cihaz 55 eşit ya da küçük
                                        //kendisinde 55 var mı
                                        (previewSetImg.find((i)=>{return i.size === 'IPHONE_55'}) || false) ?
                                            //kendisinde 55 var
                                            previewSetImg.find((i)=>{return i.size === 'IPHONE_55'}).previews.map((previews, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection">
                                                            <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                            <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                        </div>
                                            )})
                                        :
                                            //kendisinde 55 yok
                                            //primary de cihazın kendi var mı
                                            primaryPreviewSet ?
                                                //primary de cihazın kendisi var
                                                primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.map((previews, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection">
                                                            <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                            <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                        </div>
                                                )})
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 55 var mı
                                                (primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_55'}) || false) ?
                                                    //primary de 55 var 
                                                    primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_55'}).previews.map((previews, index)=>{
                                                        return(
                                                            <div key={index + uniqueId} className="collection-col missing-collection">
                                                                <img className='collection-previews' src={`${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`}  alt='appImage'/>

                                                                <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                            </div>
                                                    )})
                                                :
                                                    //primary de 55 yok
                                                    <div className='drag-file-empty' id={"drag-file-empty"+uniqueId} >No Previews available</div>  

                                :
                                    //Dolu
                                    <DragDropContext onDragEnd={handleOnDragEndPreview}>
                                        <Droppable droppableId="collection-col-list-preview" direction="horizontal">
                                            {(provided) => (
                                                <ul className="collection-col-list-preview" {...provided.droppableProps} ref={provided.innerRef}>
                                                    {forPreviewOrder.map((previews, index)=>{
                                                        return(
                                                            <Draggable key={index + uniqueId + 'previews'} draggableId={uniqueId+index + 'previews'} index={index}>
                                                                {(provided) => (   
                                                                    <li id={previews.preview_id} className={`collection-col ${uniqueId+index}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                        <div onMouseOver={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeVisible(`delete-preview${uniqueId+index}`)} onMouseLeave={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeInvisible(`delete-preview${uniqueId+index}`)} onClick={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>{deletePreview(previews)}} className='delete-preview' id={`delete-preview${uniqueId+index}`}>
                                                                            <img src="/assets/Icons/delete.svg" alt="del" />
                                                                            
                                                                        </div>
                                                                        
                                                                        <img onMouseOver={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeVisible(`delete-preview${uniqueId+index}`)} onMouseLeave={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeInvisible(`delete-preview${uniqueId+index}`)} className='collection-previews' 
                                                                        src={
                                                                            `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.image_path}`
                                                                            // '/assets/Images/image_placeholder.jpg'
                                                                            } alt='appImage'/>
                                                                        <button onClick={()=>setVideoPlay("video" + index + uniqueId )} className='play-button'>►</button>
                                                                </li>
                                                                )}        
                                                            </Draggable>
                                                    )})}
                                                    {provided.placeholder}
                                                </ul>
                                            )}
                                        </Droppable>
                                    </DragDropContext>                   
                            : 
                                <div className='spinner-full'><Spinner/>{percentagePreview.get(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`)>0 ? <span style={{marginTop: '0px', fontSize: '0.8rem', marginLeft: '1px'}}>{percentagePreview.get(`${appId}-${collectionId}-${languageCode}-${devicePreviewSize}`)}%</span> : ''}</div>
                            }

                            { dragActivePreview && isUploadingPreview.collectionID !== +collectionId && isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !isWaiting && !isWaitingPreview && <div className='drag-file-element' id={"drag-file-element-preview"+uniqueId} onDragEnter={handleDragPreview} onDragLeave={handleDragPreview} onDragOver={handleDragPreview} onDrop={handleDrop} style={{width : document.getElementById(`collection-row-previews-${uniqueId}`).scrollWidth+'px'}}></div> }    
                        </div>

                    </div>


                    <div id={'collection-screenshot-set'+uniqueId} className='collection-screenshot-set'>

                        <div className={`collection-screenshot-set-info ${screenshotSet  ? '' : 'if-collection-set-from-primay'}`}>
                            {/* dolu mu boş mu */}
                            {!screenshotSet ? 
                            //Boş
                                //cihaz 55 den büyük mü
                                deviceSize > 'APP_IPHONE_55' ?
                                    //büyük
                                    //kendisinde 65 cihaz var mı 
                                    (screenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}) || false) ?
                                        //var
                                        <span className='additional-collection-info'>Using 6.5" Display</span>
                                    :
                                        //yok
                                        //primary de varmı cihazdan
                                        primaryScreenshotSet ? 
                                            //var
                                            <span className='additional-collection-info' > 
                                                {
                                                    deviceSize === 'APP_IPHONE_58' ? 
                                                    <span>Using {`${primaryLanguageName}`} 5.8" Display</span> :
                                                    deviceSize === 'APP_IPHONE_61' ?  
                                                    <span>Using {`${primaryLanguageName}`} 6.1" Display</span> : 
                                                    deviceSize === 'APP_IPHONE_65' ?  
                                                    <span>Using {`${primaryLanguageName}`} 6.5" Display</span> : 
                                                    deviceSize === 'APP_IPHONE_67' ?  
                                                    <span>Using {`${primaryLanguageName}`} 6.7" Display</span> : 
                                                ''}
                                            </span>
                                        :
                                            //yok
                                            //primaryde 65 var mı
                                            (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}) || false) ?
                                                //var
                                                <span className='additional-collection-info'>Using {`${primaryLanguageName}`} 6.5" Display</span>
                                            :
                                                //yok
                                                <span className='additional-collection-info'>&nbsp;</span>
                                :
                                //cihaz 55 den küçük veya eşit
                                //kendisinde 55 cihaz var mı 
                                (screenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_55'}) || false) ?
                                    //var
                                    <span className='additional-collection-info'>Using 5.5" Display</span>
                                :
                                    //yok                   
                                    //primary de varmı cihazdan
                                    primaryScreenshotSet ? 
                                    //var
                                    <span className='additional-collection-info' > 
                                        {
                                            deviceSize === 'APP_IPHONE_35' ? 
                                            <span>Using {`${primaryLanguageName}`} 3.5" Display</span> :
                                            deviceSize === 'APP_IPHONE_40' ?  
                                            <span>Using {`${primaryLanguageName}`} 4" Display</span> : 
                                            deviceSize === 'APP_IPHONE_47' ?  
                                            <span>Using {`${primaryLanguageName}`} 4.7" Display</span> : 
                                            deviceSize === 'APP_IPHONE_55' ?  
                                            <span>Using {`${primaryLanguageName}`} 5.5" Display</span> : 
                                        ''}
                                    </span>
                                :
                                    //yok
                                    //primaryde 55 var mı
                                    (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_55'}) || false) ?
                                        //var
                                        <span className='additional-collection-info'>Using {`${primaryLanguageName}`} 5.5" Display</span>
                                    :
                                        //yok
                                        <span className='additional-collection-info'>&nbsp;</span>
                            :
                            //Dolu
                                ''            
                            }


                            <span style={{fontSize: '0.9rem'}}>
                                {
                                    !screenshotSet ?
                                    //Boş
                                    //cihaz 55 den büyük mü
                                    deviceSize > 'APP_IPHONE_55' ?
                                        //cihaz 55 den büyük
                                        //kendisinde 65 var mı
                                        (screenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}) || false) ?
                                            //kendisinde 65 var
                                            <span>{screenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}).screenshots.length}/10</span>
                                        :
                                            //kendisinde 65 yok
                                            //primary de cihazın kendi var mı
                                            primaryScreenshotSet ?
                                                //primary de cihazın kendisi var
                                                <span>{primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.length}/10</span>
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 65 var mı
                                                (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}) || false) ?
                                                    //primary de 65 var 
                                                    <span>{primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}).screenshots.length}/10</span>
                                                :
                                                    //primary de 65 yok
                                                    '0/10'  
                                    :
                                        //cihaz 55 e eşit ya da küçük
                                        //primary de cihazın kendi var mı
                                        primaryScreenshotSet ?
                                            //primary de cihazın kendisi var
                                            <span>{primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.length}/10</span>
                                        :
                                            //primary de cihazın kendisi yok
                                            //primary de 55 var mı
                                            (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_55'}) || false) ?
                                                //primary de 55 var 
                                                <span>{primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_55'}).screenshots.length}/10</span>
                                            :
                                                //primary de 55 yok
                                                '0/10'
                                :
                                    //Dolu
                                    <span>{screenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.length}/10</span>
                                }
                            </span>
                        </div>

                        <div id={`collection-row-${uniqueId}`} className={`collection-row`} onDragEnter={handleDrag}>
                            {!isWaiting ?
                                //cihazın seçilen localde fotosu dolu mu   
                                !screenshotSet ?
                                    //Boş
                                    //cihaz 55 den büyük mü
                                    deviceSize > 'APP_IPHONE_55' ?
                                        //cihaz 55 den büyük
                                        //kendisinde 65 var mı
                                        (screenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}) || false) ?
                                            //kendisinde 65 var
                                            screenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}).screenshots.map((screenshots, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection"><img className='collection-screenshots' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                        onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} 
                                                        alt='appImage'/>
                                                        </div>
                                            )})
                                        :
                                            //kendisinde 65 yok
                                            //primary de cihazın kendi var mı
                                            primaryScreenshotSet ?
                                                //primary de cihazın kendisi var
                                                primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.map((screenshots, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection"><img className='collection-screenshots' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                        onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} 
                                                        alt='appImage'/>
                                                        </div>
                                                )})
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 65 var mı
                                                (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}) || false) ?
                                                    //primary de 65 var 
                                                    primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}).screenshots.map((screenshots, index)=>{
                                                        return(
                                                            <div key={index + uniqueId} className="collection-col missing-collection"><img className='collection-screenshots' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                            onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} 
                                                            alt='appImage'/>
                                                            </div>
                                                    )})
                                                :
                                                    //primary de 65 yok
                                                    <div className='drag-file-empty' id={"drag-file-empty"+uniqueId} onDragEnter={handleDrag}>
                                                        {isUploading.collectionID!==+collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId ? 
                                                            <>Choose file(s) or drag up to 10 screenshots here.</>
                                                        :
                                                            <>Please wait for uploading process to be over.</>
                                                        }
                                                    </div> 

                                    :
                                        //cihaz 55 eşit ya da küçük
                                        //kendisinde 55 var mı
                                        (screenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_55'}) || false) ?
                                            //kendisinde 55 var
                                            screenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_55'}).screenshots.map((screenshots, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection"><img className='collection-screenshots' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                        onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} 
                                                        alt='appImage'/>
                                                        </div>
                                            )})
                                        :
                                            //kendisinde 55 yok
                                            //primary de cihazın kendi var mı
                                            primaryScreenshotSet ?
                                                //primary de cihazın kendisi var
                                                primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.map((screenshots, index)=>{
                                                    return(
                                                        <div key={index + uniqueId} className="collection-col missing-collection"><img className='collection-screenshots' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                        onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} 
                                                        alt='appImage'/>
                                                        </div>
                                                )})
                                            :
                                                //primary de cihazın kendisi yok
                                                //primary de 55 var mı
                                                (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_55'}) || false) ?
                                                    //primary de 55 var 
                                                    primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_55'}).screenshots.map((screenshots, index)=>{
                                                        return(
                                                            <div key={index + uniqueId} className="collection-col missing-collection"><img className='collection-screenshots' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                            onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} 
                                                            alt='appImage'/>
                                                            </div>
                                                    )})
                                                :
                                                    //primary de 55 yok
                                                    <div className='drag-file-empty' id={"drag-file-empty"+uniqueId} onDragEnter={handleDrag}>
                                                        {isUploading.collectionID!==+collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId  ? 
                                                            <>Choose file(s) or drag up to 10 screenshots here.</>
                                                        :
                                                            <>Please wait for uploading process to be over.</>
                                                        }
                                                    </div>  

                                :
                                    //Dolu
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId="collection-col-list" direction="horizontal">
                                            {(provided) => (
                                                <ul className="collection-col-list" {...provided.droppableProps} ref={provided.innerRef}>
                                                    {forOrder.map((screenshots, index)=>{
                                                        return(
                                                            <Draggable key={index + uniqueId} draggableId={uniqueId+index} index={index}>
                                                                {(provided) => (   
                                                                    <li id={screenshots.screenshot_id} className={`collection-col ${uniqueId+index}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                        <div onMouseOver={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeVisible(`delete-screenshot${uniqueId+index}`)} onMouseLeave={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeInvisible(`delete-screenshot${uniqueId+index}`)} onClick={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>{deleteScreenShot(screenshots)}} className='delete-screenshot' id={`delete-screenshot${uniqueId+index}`}>
                                                                            <img src="/assets/Icons/delete.svg" alt="del" />
                                                                        </div>

                                                                        <img onMouseOver={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeVisible(`delete-screenshot${uniqueId+index}`)} onMouseLeave={isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId ? ()=>{} : ()=>makeInvisible(`delete-screenshot${uniqueId+index}`)} className='collection-screenshots' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} 
                                                                        onClick={()=>{openLightbox(uniqueId);toSlide(index+1,uniqueId)}} 
                                                                        alt='appImage'/>
                                                                    </li>
                                                                )}        
                                                            </Draggable>
                                                    )})}
                                                    {provided.placeholder}
                                                </ul>
                                            )}
                                        </Droppable>
                                    </DragDropContext>                                                                        
                            : 
                                <div className='spinner-full'><Spinner/>{percentage.get(`${appId}-${collectionId}-${languageCode}-${deviceSize}`)>0 ? <span style={{marginTop: '0px', fontSize: '0.8rem', marginLeft: '1px'}}>{percentage.get(`${appId}-${collectionId}-${languageCode}-${deviceSize}`)}%</span> : ''}</div>
                            }
                            { dragActive && isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !(isWaiting&&isWaitingPreview) && <div className='drag-file-element' id={"drag-file-element"+uniqueId} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} style={{width : document.getElementById(`collection-row-${uniqueId}`).scrollWidth+'px'}}></div> }     
                        </div>

                    </div>

                
                </div>

                <div id={'collection-screenshot-edit'+uniqueId} className="collection-screenshot-edit">
                        {/* upload */}
                        <input disabled={isWaiting || isWaitingPreview || isUploading.collectionID === +collectionId ? true : false} multiple={true} hidden={true} className='collection-screenshot-upload' type="file" accept="image/png, image/jpeg, image/jpg, video/mp4, video/mov, video/m4v" id={'chooseFile'+uniqueId} 
                        onChange={onImageUpload}
                        />
                        <label hidden={isWaiting || isWaitingPreview || isUploading.collectionID === +collectionId || isUploadingPhoto.collectionID === +collectionId || isUploadingPreview.collectionID === +collectionId} className='collection-screenshot-upload-label' htmlFor={'chooseFile'+uniqueId}>Choose File</label>
                        {isUploading.collectionID !== +collectionId && isUploadingPhoto.collectionID !== +collectionId && isUploadingPreview.collectionID !== +collectionId && !isWaiting&&!isWaitingPreview ? 
                        <>
                            {previewSet ? <> 
                                <span className='edit-seperator'>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                <span onClick={()=>{deleteAllPreviews(previewSetImg)}} className='collection-screenshot-delete-label'>Delete All Previews</span> </>                      
                            : ''}
                            {screenshotSet ? <> 
                                <span className='edit-seperator'>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                <span onClick={()=>{deleteAllScreenshots(screenshotSetImg)}} className='collection-screenshot-delete-label'>Delete All Screenshots</span> </>                      
                            : ''}


                        </> : ''}
                        {/* upload */} 
                </div>

            </div>


            <div id={`lightbox${uniqueId}`} onClick={(e)=>closeSlide(e,uniqueId)} className={`collection-modal modal${classId}`}>
                <span className="collection-close pointer" onClick={()=>{closeLightbox(uniqueId)}}>&times;</span>
                <div className="collection-modal-content">
                    <p className='collection-screenshot-slide-device-name'>
                        {deviceSize === 'APP_IPHONE_35' ? 
                        <span>iPhone <strong>3.5" Display</strong></span> :
                        deviceSize === 'APP_IPHONE_40' ? 
                        <span>iPhone <strong>4" Display</strong></span> :
                        deviceSize === 'APP_IPHONE_47' ? 
                        <span>iPhone <strong>4.7" Display</strong></span> :
                        deviceSize === 'APP_IPHONE_55' ? 
                        <span>iPhone <strong>5.5" Display</strong></span> :
                        deviceSize === 'APP_IPHONE_58' ? 
                        <span>iPhone <strong>5.8" Display</strong></span> : 
                        deviceSize === 'APP_IPHONE_61' ?  
                        <span>iPhone <strong>6.1" Display</strong></span> : 
                        deviceSize === 'APP_IPHONE_65' ?  
                        <span>iPhone <strong>6.5" Display</strong></span> : 
                        deviceSize === 'APP_IPHONE_67' ?  
                        <span>iPhone <strong>6.7" Display</strong></span> : 
                        'Unknown Device'}
                    </p>
                    {
                        !screenshotSet ?
                        //Boş
                        //cihaz 55 den büyük mü
                        deviceSize > 'APP_IPHONE_55' ?
                            //cihaz 55 den büyük
                            //kendisinde 65 var mı
                            (screenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}) || false) ?
                                //kendisinde 65 var
                                screenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}).screenshots.map((screenshots, index2)=>{
                                        return(
                                            <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/></div>
                                )})
                            :
                                //kendisinde 65 yok
                                //primary de cihazın kendi var mı
                                primaryScreenshotSet ?
                                    //primary de cihazın kendisi var
                                    primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.map((screenshots, index2)=>{
                                        return(
                                            <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/></div>
                                    )})
                                :
                                    //primary de cihazın kendisi yok
                                    //primary de 65 var mı
                                    (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}) || false) ?
                                        //primary de 65 var 
                                        primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_65'}).screenshots.map((screenshots, index2)=>{
                                            return(
                                                <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name}`} alt='appImage'/></div>
                                        )})
                                    :
                                        //primary de 65 yok
                                        ''  
                        :
                            //cihaz 55 e eşit ya da küçük
                            //primary de cihazın kendi var mı
                            primaryScreenshotSet ?
                                //primary de cihazın kendisi var
                                primaryScreenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.map((screenshots, index2)=>{
                                    return(
                                        <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name}`} alt='appImage'/></div>
                                )})
                            :
                                //primary de cihazın kendisi yok
                                //primary de 55 var mı
                                (primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_55'}) || false) ?
                                    //primary de 55 var 
                                    primaryScreenshotSetImg.find((i)=>{return i.size === 'APP_IPHONE_55'}).screenshots.map((screenshots, index2)=>{
                                        return(
                                            <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name}`} alt='appImage'/></div>
                                    )})
                                :
                                    //primary de 55 yok
                                    ''
                    :
                        //Dolu
                        screenshotSetImg.find((i)=>{return i.size === deviceSize}).screenshots.map((screenshots, index2)=>{
                            return(
                                <div key={index2+uniqueId} className={`slide${uniqueId} collection-slide-style`}><img className='collection-screenshots-slide' src={`${process.env.REACT_APP_API_URL}${screenshots.path.slice(6)}${screenshots.file_name.slice(0,screenshots.file_name.lastIndexOf("."))+"_sm.jpeg"}`} alt='appImage'/></div>
                        )})  
                    }
                    <span id='collection-previous' className="collection-previous" onClick={()=>{changeSlide(-1,uniqueId)}}>&#10094;</span>
                    <span id='collection-next' className="collection-next" onClick={()=>{changeSlide(1,uniqueId)}}>&#10095;</span>
                </div>
            </div>


            <div id={`video${uniqueId}`} className="collection-video-div" style={videoPlay ? {display: 'flex'} : {display: 'none'}}>
            
                <span onClick={!isWaitingTimeFrame ? ()=>{setVideoPlay(null);setForAllPreviews(false)} : ()=>{}} className='collection-close pointer'>&times;</span>
                
                <div className="collection-modal-content">
                    <p className='collection-screenshot-slide-device-name'>
                        {devicePreviewSize === 'IPHONE_35' ? 
                        <span>iPhone <strong>3.5" Display</strong></span> :
                        devicePreviewSize === 'IPHONE_40' ? 
                        <span>iPhone <strong>4" Display</strong></span> :
                        devicePreviewSize === 'IPHONE_47' ? 
                        <span>iPhone <strong>4.7" Display</strong></span> :
                        devicePreviewSize === 'IPHONE_55' ? 
                        <span>iPhone <strong>5.5" Display</strong></span> : 
                        devicePreviewSize === 'IPHONE_61' ?  
                        <span>iPhone <strong>6.1" Display</strong></span> : 
                        devicePreviewSize === 'IPHONE_65' ?  
                        <span>iPhone <strong>6.5" Display</strong></span> : 
                        devicePreviewSize === 'IPHONE_67' ?  
                        <span>iPhone <strong>6.7" Display</strong></span> : 
                        'Unknown Device'}
                    </p>
                    {!previewSet ?
                            //Boş
                            //cihaz 55 den büyük mü
                            devicePreviewSize > 'IPHONE_55' ?
                                //cihaz 55 den büyük
                                //kendisinde 65 var mı
                                (previewSetImg.find((i)=>{return i.size === 'IPHONE_65'}) || false) ?
                                    //kendisinde 65 var
                                    previewSetImg.find((i)=>{return i.size === 'IPHONE_65'}).previews.map((previews, index)=>{
                                            return(
                                                <span key={index + uniqueId}>
                                                    {videoPlay==="video" + index + uniqueId ?
                                                                <div className='collection-video-inner'>

                                                                    <CollectionVideo onReady={playerReady} options={
                                                                        {
                                                                            fill: true,
                                                                            fluid: false,
                                                                            autoplay: true,
                                                                            controls: true,
                                                                            controlBar: {
                                                                                pictureInPictureToggle: false
                                                                            },
                                                                            preload: "metadata",
                                                                            sources: [
                                                                                {
                                                                                    src:
                                                                                    `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                    // type: "application/x-mpegURL"
                                                                                }
                                                                            ]
                                                                        }
                                                                    }/>
                                                                </div>
                                                    : ''
                                                    }
                                                </span>
                                    )})
                                :
                                    //kendisinde 65 yok
                                    //primary de cihazın kendi var mı
                                    primaryPreviewSet ?
                                        //primary de cihazın kendisi var
                                        primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.map((previews, index)=>{
                                            return(
                                                <span key={index + uniqueId}>
                                                    {videoPlay==="video" + index + uniqueId ?
                                                                <div className='collection-video-inner'>
                                                                    <CollectionVideo onReady={playerReady} options={
                                                                        {
                                                                            fill: true,
                                                                            fluid: false,
                                                                            autoplay: true,
                                                                            controls: true,
                                                                            controlBar: {
                                                                                pictureInPictureToggle: false
                                                                            },
                                                                            preload: "metadata",
                                                                            sources: [
                                                                                {
                                                                                    src:
                                                                                    `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                    // type: "application/x-mpegURL"
                                                                                }
                                                                            ]
                                                                        }
                                                                    }/>
                                                                </div>
                                                    : ''
                                                    }
                                                </span>
                                        )})
                                    :
                                        //primary de cihazın kendisi yok
                                        //primary de 65 var mı
                                        (primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_65'}) || false) ?
                                            //primary de 65 var 
                                            primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_65'}).previews.map((previews, index)=>{
                                                return(
                                                    <span key={index + uniqueId}>
                                                        {videoPlay==="video" + index + uniqueId ?
                                                                    <div className='collection-video-inner'>
                                                                        <CollectionVideo onReady={playerReady} options={
                                                                            {
                                                                                fill: true,
                                                                                fluid: false,
                                                                                autoplay: true,
                                                                                controls: true,
                                                                                controlBar: {
                                                                                    pictureInPictureToggle: false
                                                                                },
                                                                                preload: "metadata",
                                                                                sources: [
                                                                                    {
                                                                                        src:
                                                                                        `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                        // type: "application/x-mpegURL"
                                                                                    }
                                                                                ]
                                                                            }
                                                                        }/>
                                                                    </div>
                                                        : ''
                                                        }
                                                    </span>
                                            )})
                                        :
                                            //primary de 65 yok
                                            ''  
                            :
                                //cihaz 55 eşit ya da küçük
                                //kendisinde 55 var mı
                                (previewSetImg.find((i)=>{return i.size === 'IPHONE_55'}) || false) ?
                                    //kendisinde 55 var
                                    previewSetImg.find((i)=>{return i.size === 'IPHONE_55'}).previews.map((previews, index)=>{
                                            return(
                                                <span key={index + uniqueId}>
                                                    {videoPlay==="video" + index + uniqueId ?
                                                                <div className='collection-video-inner'>
                                                                    <CollectionVideo onReady={playerReady} options={
                                                                        {
                                                                            fill: true,
                                                                            fluid: false,
                                                                            autoplay: true,
                                                                            controls: true,
                                                                            controlBar: {
                                                                                pictureInPictureToggle: false
                                                                            },
                                                                            preload: "metadata",
                                                                            sources: [
                                                                                {
                                                                                    src:
                                                                                    `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                    // type: "application/x-mpegURL"
                                                                                }
                                                                            ]
                                                                        }
                                                                    }/>
                                                                </div>
                                                    : ''
                                                    }
                                                </span>
                                    )})
                                :
                                    //kendisinde 55 yok
                                    //primary de cihazın kendi var mı
                                    primaryPreviewSet ?
                                        //primary de cihazın kendisi var
                                        primaryPreviewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.map((previews, index)=>{
                                            return(
                                                <span key={index + uniqueId}>
                                                    {videoPlay==="video" + index + uniqueId ?
                                                                <div className='collection-video-inner'>
                                                                    <CollectionVideo onReady={playerReady} options={
                                                                        {
                                                                            fill: true,
                                                                            fluid: false,
                                                                            autoplay: true,
                                                                            controls: true,
                                                                            controlBar: {
                                                                                pictureInPictureToggle: false
                                                                            },
                                                                            preload: "metadata",
                                                                            sources: [
                                                                                {
                                                                                    src:
                                                                                    `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                    // type: "application/x-mpegURL"
                                                                                }
                                                                            ]
                                                                        }
                                                                    }/>
                                                                </div>

                                                    : ''
                                                    }
                                                </span>
                                        )})
                                    :
                                        //primary de cihazın kendisi yok
                                        //primary de 55 var mı
                                        (primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_55'}) || false) ?
                                            //primary de 55 var 
                                            primaryPreviewSetImg.find((i)=>{return i.size === 'IPHONE_55'}).previews.map((previews, index)=>{
                                                return(
                                                    <span key={index + uniqueId}>
                                                    {videoPlay==="video" + index + uniqueId ?
                                                                <div className='collection-video-inner'>
                                                                    <CollectionVideo onReady={playerReady} options={
                                                                        {
                                                                            fill: true,
                                                                            fluid: false,
                                                                            autoplay: true,
                                                                            controls: true,
                                                                            controlBar: {
                                                                                pictureInPictureToggle: false
                                                                            },
                                                                            preload: "metadata",
                                                                            sources: [
                                                                                {
                                                                                    src:
                                                                                    `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                                    // type: "application/x-mpegURL"
                                                                                }
                                                                            ]
                                                                        }
                                                                    }/>
                                                                </div>
                                                    : ''
                                                    }
                                                </span>
                                            )})
                                        :
                                            //primary de 55 yok
                                            ''  
                        :
                            //Dolu
                            previewSetImg.find((i)=>{return i.size === devicePreviewSize}).previews.map((previews, index)=>{
                                return(
                                    <span key={index + uniqueId}>
                                    {videoPlay==="video" + index + uniqueId ?
                                                <div className='collection-video-inner'>

                                                    <CollectionVideo onReady={playerReady} options={
                                                        {
                                                            fill: true,
                                                            fluid: false,
                                                            autoplay: true,
                                                            controls: true,
                                                            controlBar: {
                                                                pictureInPictureToggle: false
                                                            },
                                                            preload: "metadata",
                                                            sources: [
                                                                {
                                                                    src:
                                                                    `${process.env.REACT_APP_API_URL}${previews.video_path.slice(6)}${previews.file_name}`,
                                                                    // type: "video/mp4"
                                                                }
                                                            ]
                                                        }
                                                    }/>
                                                    <div className='poster-check'>
                                                        <label>
                                                            <input onChange={(e)=>{setForAllPreviews(e.target.checked)}} type="checkbox"/>Apply for all previews in this collection.
                                                        </label>
                                                    </div>
                                                    <div className='video-buttons'>
                                                        <span onClick={oneSecondBack}>
                                                            <img style={{height:'35px'}} src='/assets/Icons/right.svg'/>
                                                        </span>

                                                        <button className='set-poster' onClick={()=>previewSetPoster(previews.preview_id)}>
                                                            {isWaitingTimeFrame ? 
                                                                    <SpinnerSmall/>
                                                                :
                                                                    'Set Poster Frame'
                                                            }
                                                            
                                                        </button>
                                                        
                                                        <span onClick={oneSecondForward}>
                                                            <img style={{height:'35px'}} src='/assets/Icons/left.svg'/>
                                                        </span>
                                                    </div>

                                                </div>
                                    : ''
                                    }
                                </span>
                            )})  }                  
                </div>
            </div>

            

        </div>
    )

}

export default CollectionScreenshotItem