  //jurisdiction matrix
  const jurisdiction ={
    whatsnew : ['owner', 'content-manager', 'app-manager'],
    keyword : ['owner', 'app-manager'],
    description : ['owner', 'content-manager', 'app-manager'],
    screenshots : ['owner', 'designer', 'app-manager'],
    order : ['owner', 'app-manager'],
    upload : ['owner', 'app-manager'],
    delete : ['owner', 'app-manager'],
    version: ['owner', 'app-manager'],
    role : ['owner'],
    delete_app : ['owner'],
    add_app : ['owner', 'content-manager', 'app-manager', 'designer'],
    collection : ['owner', 'app-manager']
  }
  export default jurisdiction