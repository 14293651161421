import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import VariableContext from '../userContext/VariableContext';
import {fireCustomError, fireRequestError, fireSuccessMessage} from '../Common Functions/commonFunctions'
import SpinnerSmall from "../Misc/SpinnerSmall";
import GoBackButton from "../Components/GoBackButton";
import DeleteAccount from "../Popup/DeleteAccount";
import './ProfilePage.css'
import * as gaEvents from "../ga-utils"
import Swal from "sweetalert2";

function ProfilePage() {
    const {initialUserData, setInitialUserData} = useContext(VariableContext)
    const {specialCodes, setSpecialCodes} = useContext(VariableContext)

    const [newEmail, setNewEmail] = useState('')
    const [pass, setPass] = useState('')

    const [isWaiting, setIsWaiting] = useState(false)
    const [isWaiting2, setIsWaiting2] = useState(false)
    const [isWaiting3, setIsWaiting3] = useState(false)
    const [isWaiting4, setIsWaiting4] = useState(false)
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')

    const [passwordNotValidError, setPasswordNotValidError] = useState('')

    const [deleteAcc, setDeleteAcc] = useState(false)
    const [enterCode, setEnterCode] = useState("")
    const [enterCode2, setEnterCode2] = useState("")
    const [codeNumber, setCodeNumber] = useState("")

    const [pwSTR, setPwStr] = useState(0)
    const [pwRules, setPwRules] = useState({
        length: false,
        capital: false,
        lower: false,
        number: false,
        symbol: false,
    })


    let navigate = useNavigate()

    //validate token and get user info
    useEffect(()=>{
    if(localStorage.getItem("token")){
        setIsWaiting(true)
        
        var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/validate?code_usage=true`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        };
        axios(config)
        .then(function (response) {
            setInitialUserData(response.data.data)
            localStorage.setItem("token", response.data.data.token)
            setIsWaiting(false)
        })
        .catch(function (error) {
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
            }else{
                fireRequestError(error)
            }
            localStorage.removeItem("token")
            navigate('/login')
        });
    }else {
        navigate('/login')
    }
    },[])

    
    useEffect(() => {
        let validRuleCount = 0
    
        Object.entries(pwRules).map(([key, value]) => {
          if (value) {
            validRuleCount++
          }
        })
    
        setPwStr(validRuleCount)
    }, [pwRules])


    const handleEmailChange = (e) => {
        setNewEmail(e.target.value)
    }
    const handleCodeChange = (e) => {
        setEnterCode(e.target.value)
    }
    const handleCodeChange2 = (e) => {
        setEnterCode2(e.target.value)
    }

    const handleCurrentPassChange = (e) =>{
        setPass(e.target.value)
    }

    const changeEmail = (e) =>{
        e.preventDefault()
        setIsWaiting2(true)
        var data = JSON.stringify({
            "oldEmail": initialUserData.email,
            "password": pass,
            "newEmail": newEmail
          });
          
          var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/auth/changeEmail`,
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            gaEvents.changeMailSuccess()
            fireSuccessMessage(response)
            setPass('')
            setNewEmail('')
            setIsWaiting2(false)
            setInitialUserData({...initialUserData, email: newEmail})
          })
          .catch(function (error) {
            gaEvents.changeMailFail()
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
                setPass('')
                setNewEmail('')
                setIsWaiting2(false)
            }else{
                fireRequestError(error)
                setPass('')
                setNewEmail('')
                setIsWaiting2(false)
            }
          });
    }

    const submitAppSumoCode = (e) =>{
        e.preventDefault()
        setIsWaiting4(true)
        var data = JSON.stringify({
            "codes": codeNumber==="1" ? [enterCode] : codeNumber==="2" ? [enterCode, enterCode2] : ''
          });
          
          var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/auth/useSpecialCode`,
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            fireSuccessMessage(response)
            setIsWaiting4(false)
            setEnterCode("")
            if(localStorage.getItem("token")){

                
                var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/auth/validate?code_usage=true`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
                };
                axios(config)
                .then(function (response) {
                    setInitialUserData(response.data.data)
                    localStorage.setItem("token", response.data.data.token)
                    setIsWaiting4(false)
                })
                .catch(function (error) {
                    if(error.message === 'Network Error'){
                        fireCustomError('NETWORK ERROR. ')
                    }else{
                        fireRequestError(error)
                    }
                    localStorage.removeItem("token")
                    navigate('/login')
                });
            }else {
                navigate('/login')
            }
          })
          .catch(function (error) {
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
                setIsWaiting4(false)
            }else{
                fireRequestError(error)
                setIsWaiting4(false)
            }
          });
    }
 
    const handleOldPassChange = (e) =>{
        setOldPass(e.target.value)
    }

    const handlePasswordChange = (e) =>{
        setNewPass(e.target.value)
        setPasswordNotValidError('')
      
        let _rules = {
            length: false,
            capital: false,
            number: false,
            symbol: false,
          }
      
          //Check password rules
          if (e.target.value === '') {
            setPwStr(0)
          } else {
            if (e.target.value.length >= 8) {
              _rules.length = true
            }
            if (/[A-Z]/.test(e.target.value)) {
              // Check if password contains capital letter
              _rules.capital = true
            }
            if (/[a-z]/.test(e.target.value)) {
                // Check if password contains capital letter
                _rules.lower = true
              }
            if (/\d/.test(e.target.value)) {
              // Check if password contains number
              _rules.number = true
            }
            if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e.target.value)) {
              // Check if password contains special character
              _rules.symbol = true
            }
          }
          setPwRules(_rules)
    }

    const changePassword = (e) =>{
        e.preventDefault()
        if (!pwRules.length || !pwRules.capital || !pwRules.lower || !pwRules.number || !pwRules.symbol) {
            setPasswordNotValidError('Please enter a valid password')
        }
        else{
            setIsWaiting3(true)
            var data = JSON.stringify({
                "email": initialUserData.email,
                "oldPassword": oldPass,
                "newPassword": newPass
            });

            var config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/auth/changePassword`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                    'Content-Type': 'application/json'

                },
                data : data
            };
            axios(config)
            .then(function (response) {
                gaEvents.changePassSuccess()
                fireSuccessMessage(response)
                setOldPass('')
                setNewPass('')
                setPwRules({
                    length: false,
                    capital: false,
                    lower: false,
                    number: false,
                    symbol: false,
                })
                setPwStr(0)
                setIsWaiting3(false)
            })
            .catch(function (error) {
                gaEvents.changePassFail()
                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                    setOldPass('')
                    setNewPass('')
                    setPwRules({
                        length: false,
                        capital: false,
                        lower: false,
                        number: false,
                        symbol: false,
                    })
                    setPwStr(0)
                    setIsWaiting3(false)
                }else{
                    fireRequestError(error)
                    setOldPass('')
                    setNewPass('')
                    setPwRules({
                        length: false,
                        capital: false,
                        lower: false,
                        number: false,
                        symbol: false,
                    })
                    setPwStr(0)
                    setIsWaiting3(false)
                }
            });
        }
    }

    const deleteAccount = () =>{
        Swal.fire({
            icon: 'warning',
            title: 'Delete Account',
            position: 'center',
            width: '400',
            text: "You are about to DELETE your account permanently. You can not take this action back. Are you sure?",
            backdrop: true,
            showConfirmButton: true,
            showCancelButton: true,     
            confirmButtonColor: '#cf000fe6',
            cancelButtonColor: '#3A6FF8e6',
            cancelButtonText: 'Cancel',      
            confirmButtonText: 'Delete',
        }).then((result)=>{
            if (result.isConfirmed) {
                setDeleteAcc(true)
            }
        })
    }

    const closeDeletePopup = (flag) =>{
        setDeleteAcc(flag)
    }
 
  return (
    <div className="profile-content">
        <div className="profile-title-div titleArea">
            <GoBackButton/>
            <h1>Profile</h1>
        </div>
        <div className="profile-inner-container">
            {initialUserData ? initialUserData.accountSubsStatus.status!=='active' ?
                    <span className='sub-warning2' style={{display: 'flex', alignItems: 'center',width:'100%', margin:'0'}}><img style={{marginRight:'10px'}} src="/assets/Icons/exclamation-circle-fill.svg" alt="!!!" />{initialUserData.accountSubsStatus.message}</span>
                : 
                    ''
                : 
                    ''
            }
            <div className="profile-info-div">
                <h2>User Information</h2>
                <div className="app-data" style={{margin: '33px 0 0 0'}}>     
                    <div>
                        <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/username.svg" alt="versionIcon" />
                        <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                        <strong>
                        {initialUserData ? (
                            initialUserData.first_name+' '+initialUserData.last_name
                        ) : (
                            <SpinnerSmall />
                        )}
                        </strong>
                        </p>
                    </div>

                    <div>
                        <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/usermail.svg" alt="platformIcon" />
                        <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                        <strong>{initialUserData ? initialUserData.email : <SpinnerSmall />}</strong> 
                        </p>
                    </div>

                    <div>
                        <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/useraccount.svg" alt="primaryLocalIcon" />
                        <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                        <strong>{initialUserData ? <>{initialUserData.account_type}&nbsp;<span style={{ fontStyle:'italic'}}>{initialUserData.account_type!=="free"&&<>({initialUserData.accountSubsStatus.status!=='active' ?"Expired" : "Expires"} on {initialUserData.subscription_expires_at.slice(0,10)})</>}</span>&nbsp;<NavLink style={{textDecoration:'none', fontSize:'0.9rem', fontStyle:'italic', color:'#3A6FF8'}} to='/quota'>(Account Quotas)</NavLink> </> : <SpinnerSmall />}</strong>{' '}
                        </p>
                    </div>
                </div>
            </div>

            <div className="profile-form-div">
                <form className='profile-pass-page-form form1' onSubmit={changeEmail}>
                    <h2>Change Email</h2>


                    <div className='form-group'>
                        <div className="form-group-inner">
                            <label>New Email</label>
                            <input
                            type="text"
                            className="form-input password"
                            value={newEmail}
                            onChange={handleEmailChange}
                            placeholder="Enter new email address"
                            required={true}
                            />
                        </div>
                    </div>

                    <div style={{paddingTop:'10px'}} className='form-group'>
                        <div className="form-group-inner">
                            <label>Password</label>
                            <input
                            type="password"
                            className="form-input password"
                            value={pass}
                            onChange={handleCurrentPassChange}
                            placeholder="••••••••"
                            required={true}
                            />
                        </div>
                        
                    </div>

                    
                
                        
                    <div className="profilePpassPopupButtons">
                        <button type='submit' disabled={isWaiting2} className="profile-pass-page-popup-button">
                            {!isWaiting2 ? 'Confirm' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>
                    </div>
                </form>


                <form className='profile-pass-page-form form2' onSubmit={changePassword}>
                    <h2>Change Password</h2>


                    <div className='form-group'>
                        <div className="form-group-inner">
                            <label>Old Password</label>
                            <input
                            type="password"
                            className="form-input password"
                            value={oldPass}
                            onChange={handleOldPassChange}
                            placeholder="••••••••"
                            required={true}
                            />
                        </div>
                    </div>

                    <div style={{paddingTop:'10px'}} className='form-group'>
                        <div className="form-group-inner">
                            <label>New Password</label>
                            <input
                            type="password"
                            className="form-input password"
                            value={newPass}
                            onChange={handlePasswordChange}
                            placeholder="••••••••"
                            required={true}
                            />
                            <p className='error-message'>{passwordNotValidError}</p>
                        </div>
                        
                    </div>

                    <div className='password-strength-meter'>
                        <div className={`meter ${pwSTR >= 1 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div> 
                        <div className={`meter ${pwSTR >= 2 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                        <div className={`meter ${pwSTR >= 3 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                        <div className={`meter ${pwSTR >= 4 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                        <div className={`meter ${pwSTR >= 5 ? 'valid' : ''} ${pwSTR >= 5 ? 'all' : ''}`}></div>
                    </div>
                    <div className='password-message'>
                        Minimum 8 characters including uppacase, lowercase letters, numbers and symbols.
                    </div>
                    
                
                        
                    <div className="profilePpassPopupButtons">
                        <button type='submit' disabled={isWaiting3} className="profile-pass-page-popup-button">
                            {!isWaiting3 ? 'Confirm' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>
                    </div>
                </form>
            </div>

            <div className="profile-form-div">
                <form className='profile-pass-page-form' onSubmit={submitAppSumoCode}>
                    <h2>Redeem AppSumo Code</h2>
                    
                    <h3>How many codes will you be redeeming?</h3>

                    <select value={codeNumber} onChange={(e)=>setCodeNumber(e.target.value)}>
                        <option value="">Choose</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </select>

      
                    <div className='form-group'>
                        <div className="form-group-inner">
                            <input
                            type="text"
                            className="form-input password"
                            value={enterCode}
                            onChange={handleCodeChange}
                            placeholder="Enter AppSumo code"
                            disabled={codeNumber===''}
                            required={true}
                            style={{opacity:`${codeNumber==='' ? 0.5 : 1}`, cursor:`${codeNumber==='' ? "not-allowed" : "text"}`}}
                            />
                        </div>
                    </div>
                    <p></p>
                    <div className='form-group'>
                        <div className="form-group-inner">
                            <input
                            type="text"
                            className="form-input password"
                            value={enterCode2}
                            onChange={handleCodeChange2}
                            placeholder="Enter AppSumo code"
                            disabled={codeNumber!=='2'}
                            style={{opacity:`${codeNumber!=='2' ? 0.5 : 1}`, cursor:`${codeNumber!=='2' ? "not-allowed" : "text"}`}}
                            required={true}
                            />
                        </div>
                    </div>
                    <div className="profilePpassPopupButtons">
                        <button type='submit' disabled={isWaiting4 || !codeNumber} className="profile-pass-page-popup-button">
                            {!isWaiting4 ? 'Confirm' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>
                    </div>
            
                   
                     

                    {specialCodes ? specialCodes.length!==0 ? 
                        <div className="appSumoCodes">
                            <h3 style={{padding:'0', fontSize:"1.1rem"}}>Active Codes</h3>
                            {specialCodes.map((code, codeIndex)=>{ 
                                return(
                                    <div key={codeIndex} className="codeArea">
                                        <div className="app-data" style={{margin: '33px 0 0 0'}}>     
                                            <div>
                                                <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/gift.svg" alt="versionIcon" />
                                                <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                                                <strong>
                                                    {code.code}
                                                </strong>
                                                </p>
                                            </div>

                                            <div>
                                                <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/calendar_code.svg" alt="platformIcon" />
                                                <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                                                <strong>Used on {code.used_at.slice(0,10)}</strong> 
                                                </p>
                                            </div>

                                            <div>
                                                <img style={{height:'50px', width:'50px',marginRight:'15px'}} src="/assets/Icons/sand.svg" alt="primaryLocalIcon" />
                                                <p style={{fontWeight:'700', fontSize:'16.5px', lineHeight:'23px'}}>
                                                <strong>Valid for Lifetime</strong>{' '}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                            }
                            {specialCodes.length===1 ?
                                <p style={{color:'#a5a5a5', marginTop:'35px'}}>You have entered a single code, you now have access to <span style={{color:'#0f044c', fontWeight:"bold"}}>PREMIUM</span> feuatures.</p>
                            :
                            specialCodes.length===2 ?
                                <p style={{color:'#a5a5a5', marginTop:'35px'}}>You have entered a total of two codes, you now have access to <span style={{color:'#0f044c', fontWeight:"bold"}}>PPREMIUM PLUS</span> feuatures.</p>
                            :
                                ''
                            }
                        </div>
                    :
                        <p style={{color:'#a5a5a5', marginLeft:'30px', marginTop:'30px'}}>You have not redeemed a code yet.</p>

                    :
                        ''
                    }
                </form>

            </div>

            <button onClick={deleteAccount} className="delete-account-button">Delete Account</button>

        </div>

        {deleteAcc ? 
            <DeleteAccount func={closeDeletePopup}/>
        :
            ''
        }

    </div>
  )
}

export default ProfilePage