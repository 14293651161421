import {useState, useContext, useEffect} from 'react'
import VariableContext from '../userContext/VariableContext';
import SpinnerSmall from '../Misc/SpinnerSmall'
import axios from 'axios'
import './ImportZip.css'
import {fireCustomErrorV2, sortValue, fireFileMessage, fireSuccessMessage, fireCustomError, fireCustomSuccessMessage, fireRequestError } from '../Common Functions/commonFunctions'
import Select from "react-select";
import langJSON from '../flagsLanguages/lang.json'
import {NavLink, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import * as gaEvents from "../ga-utils"
import Qmark from '../Misc/Qmark';

function ImportZip({pageType, colId}) { 
    const {importArray, setImportArray} = useContext(VariableContext)
    const {importPercentage, setImportPercentage} = useContext(VariableContext)
    const {getColList, setGetColList} = useContext(VariableContext)
    const {appCol} = useContext(VariableContext)
    const {setSummary} = useContext(VariableContext)
    const {currentAppQuota} = useContext(VariableContext)
    const {initialUserData} = useContext(VariableContext)
    const {currentAppRole} = useContext(VariableContext)

    const [showDescription, setShowDescription] = useState(false)
    const [showWhatsnew, setShowWhatsnew] = useState(false)
    const [showKeyword, setShowKeyword] = useState(false)
    const [showScreenshots, setShowScreenshots] = useState(false)
    const [showPreviews, setShowPreviews] = useState(false)

    const [anwserToFile, setAnswerToFile] = useState(false)

    const [askScreenshotOrPreview ,setAskScreenshotOrPreview] = useState(false)
    const [answerToScreenshotOrPreview ,setAnswerToScreenshotOrPreview] = useState(false)

    const [answer, setAnswer] = useState(null)

    const [templateTurn, setTemplateTurn] = useState(false)
    const [uploadTurn, setUploadTurn] = useState(false)
    const [question, setQuestion] = useState(true)
    const [dragActive, setDragActive] = useState(false)
    const [zip, setZip] = useState(null)

    const [isDownloading, setIsDownlaoding] = useState(false)

    const [isWaiting, setIsWaiting] = useState(true)

    const [localized, setLocalized] = useState([])
    const [notLocalized, setNotLocalized] = useState([])

    const [userDontHaveDescription, setUserDontHaveDescription] = useState([])
    const [userHaveDescription, setUserHaveDescription] = useState([])
    const [userSelectForDescription, setUserSelectForDescription] = useState([])

    const [userDontHaveKeyword, setUserDontHaveKeyword] = useState([])
    const [userHaveKeyword, setUserHaveKeyword] = useState([])
    const [userSelectForKeyword, setUserSelectForKeyword] = useState([])

    const [userDontHaveWhatsnew, setUserDontHaveWhatsnew] = useState([])
    const [userHaveWhatsnew, setUserHaveWhatsnew] = useState([])
    const [userSelectForWhatsnew, setUserSelectForWhatsnew] = useState([])

    const [userDontHaveScreenshots, setUserDontHaveScreenshots] = useState([])
    const [dontHaveScreenshots, setDontHaveScreenshots] = useState([])
    const [userHaveScreenshots, setUserHaveScreenshots] = useState([])
    const [userSelectForScreenshots, setUserSelectForScreenshots] = useState([])
    const [openLangs, setOpenLangs] = useState([])

    const [userDontHavePreviews, setUserDontHavePreviews] = useState([])
    const [dontHavePreviews, setDontHavePreviews] = useState([])
    const [userHavePreviews, setUserHavePreviews] = useState([])
    const [userSelectForPreviews, setUserSelectForPreviews] = useState([])
    const [openLangsPreview, setOpenLangsPreview] = useState([])

    const [message, setMessage] = useState('')
    const [mode, setMode] = useState(null)

    const deviceList = [
        {value:'APP_IPHONE_65',label:"Iphone 6.5''"},
        {value:'APP_IPHONE_61',label:"Iphone 6.1''"},
        {value:'APP_IPHONE_58',label:"Iphone 5.8''"},
        {value:'APP_IPHONE_55',label:"Iphone 5.5''"},
        {value:'APP_IPHONE_47',label:"Iphone 4.7''"},
        {value:'APP_IPHONE_40',label:"Iphone 4''"},
        {value:'APP_IPHONE_35',label:"Iphone 3.5''"},
        {value:'APP_IPAD_PRO_3GEN_129',label:"Ipad 6th gen 12.9''"},
        {value:'APP_IPAD_PRO_3GEN_11',label:"Ipad 6th gen 11''"},
        {value:'APP_IPAD_PRO_129',label:"Ipad 12.9''"},
        {value:'APP_IPAD_105',label:"Ipad 10.5''"},
        {value:'APP_IPAD_97',label:"Ipad 9.7''"},
    ]

    const deviceListPreviews = [
        {value:'IPHONE_65',label:"Iphone 6.5''"},
        {value:'IPHONE_61',label:"Iphone 6.1''"},
        {value:'IPHONE_58',label:"Iphone 5.8''"},
        {value:'IPHONE_55',label:"Iphone 5.5''"},
        {value:'IPHONE_47',label:"Iphone 4.7''"},
        {value:'IPHONE_40',label:"Iphone 4''"},
        {value:'IPHONE_35',label:"Iphone 3.5''"},
        {value:'IPAD_PRO_3GEN_129',label:"Ipad 6th gen 12.9''"},
        {value:'IPAD_PRO_3GEN_11',label:"Ipad 6th gen 11''"},
        {value:'IPAD_PRO_129',label:"Ipad 12.9''"},
        {value:'IPAD_105',label:"Ipad 10.5''"},
        {value:'IPAD_97',label:"Ipad 9.7''"},
    ]

    let {appId} = useParams()
    let navigate = useNavigate()



    //popup close reset states
    const closePopup = () => {
        document.getElementById('importAppPopup'+colId).style.display = 'none'
        if(document.getElementById('uplodaTemplateDivInput')){
            document.getElementById('uplodaTemplateDivInput').value = ''
        }
        setQuestion(true)
        setAskScreenshotOrPreview(false)
        setAnswer(null)
        setAnswerToScreenshotOrPreview(false)
        setAnswerToFile(false)
        setTemplateTurn(false)
        setUploadTurn(false)
        setZip(null)
        setDragActive(false)

        setOpenLangs([])
        setOpenLangsPreview([])

        setLocalized([])
        setNotLocalized([])

        setUserDontHaveDescription([])
        setUserHaveDescription([])
        setUserSelectForDescription([])

        setUserDontHaveKeyword([])
        setUserHaveKeyword([])
        setUserSelectForKeyword([])

        setUserDontHaveWhatsnew([])
        setUserHaveWhatsnew([])
        setUserSelectForWhatsnew([])

        setUserDontHaveScreenshots([])
        setDontHaveScreenshots([])
        setUserHaveScreenshots([])
        setUserSelectForScreenshots([])

        if(currentAppQuota.preview_data_storage===1){
            setUserDontHavePreviews([])
            setDontHavePreviews([])
            setUserHavePreviews([])
            setUserSelectForPreviews([])
        }

        setIsWaiting(true)
        setIsDownlaoding(false)

        setMessage('')
        setMode(null)
    }
    // do you have a template file
    const answerQuestionYes = () =>{
        gaEvents.userHasTemplateFile(pageType)
        setQuestion(false)
        setAnswerToFile(true)
        if(pageType!=='screenshot'){
            setUploadTurn(true)
        }else{
            setAskScreenshotOrPreview(true)
        }
    }
    const answerQuestionNo = () =>{
        gaEvents.userHasNotTemplateFile(pageType)
        setQuestion(false)
        setAnswerToFile(false)
        if(pageType!=='screenshot'){
            setTemplateTurn(true)
            setIsWaiting(true)
        
        
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/app/collection/import/options?appId=${appId}&collectionId=${colId}`,
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                    'Content-Type': 'application/json'
                },
            };
            
            axios(config)
            .then(function (response) {
                gaEvents.getTeplateOptionsSuccess(pageType)
                setLocalized(sortValue(response.data.data.textualData.has))
                setNotLocalized(sortValue(response.data.data.textualData.notHas))

                setUserDontHaveDescription(sortValue(response.data.data.textualData.notHas))
                setUserSelectForDescription(sortValue(response.data.data.textualData.has))

                setUserDontHaveKeyword(sortValue(response.data.data.textualData.notHas))
                setUserSelectForKeyword(sortValue(response.data.data.textualData.has))

                setUserDontHaveWhatsnew(sortValue(response.data.data.textualData.notHas))
                setUserSelectForWhatsnew(sortValue(response.data.data.textualData.has))

                setUserDontHaveScreenshots(sortValue(response.data.data.photoData.notHas))
                setDontHaveScreenshots(sortValue(response.data.data.photoData.notHas))
                setUserSelectForScreenshots(sortValue(response.data.data.photoData.has))

                if(currentAppQuota.preview_data_storage===1){
                    setUserDontHavePreviews(sortValue(response.data.data.previewData.notHas))
                    setDontHavePreviews(sortValue(response.data.data.previewData.notHas))
                    setUserSelectForPreviews(sortValue(response.data.data.previewData.has))
                }

                setIsWaiting(false)

            })
            .catch(function (error) {
                gaEvents.getTeplateOptionsFail(pageType)
                closePopup()
                if(error.message === 'Network Error'){
                    fireCustomError('NETWORK ERROR. ')
                  }else{
                    fireRequestError(error)
                  }
            });
        }else{
            setAskScreenshotOrPreview(true)
        }
        
    }

    // do you have a template file (for screenshot or preview)
    const answerQuestionScreenshot = () =>{
        setAskScreenshotOrPreview(false)
        setAnswerToScreenshotOrPreview(0)
        if(anwserToFile){
            setUploadTurn(true)
        }else{
            setTemplateTurn(true)
        }
        setIsWaiting(true)
    
    
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/import/options?appId=${appId}&collectionId=${colId}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                'Content-Type': 'application/json'
            },
        };
        
        axios(config)
        .then(function (response) {
            gaEvents.getTeplateOptionsSuccess(pageType)
            setLocalized(sortValue(response.data.data.textualData.has))
            setNotLocalized(sortValue(response.data.data.textualData.notHas))

            setUserDontHaveDescription(sortValue(response.data.data.textualData.notHas))
            setUserSelectForDescription(sortValue(response.data.data.textualData.has))

            setUserDontHaveKeyword(sortValue(response.data.data.textualData.notHas))
            setUserSelectForKeyword(sortValue(response.data.data.textualData.has))

            setUserDontHaveWhatsnew(sortValue(response.data.data.textualData.notHas))
            setUserSelectForWhatsnew(sortValue(response.data.data.textualData.has))

            setUserDontHaveScreenshots(sortValue(response.data.data.photoData.notHas))
            setDontHaveScreenshots(sortValue(response.data.data.photoData.notHas))
            setUserSelectForScreenshots(sortValue(response.data.data.photoData.has))

            if(currentAppQuota.preview_data_storage===1){
                setUserDontHavePreviews(sortValue(response.data.data.previewData.notHas))
                setDontHavePreviews(sortValue(response.data.data.previewData.notHas))
                setUserSelectForPreviews(sortValue(response.data.data.previewData.has))      
            }

            setIsWaiting(false)

        })
        .catch(function (error) {
            gaEvents.getTeplateOptionsFail(pageType)
            closePopup()
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
        });
    }
    const answerQuestionPreview = () =>{
        setAskScreenshotOrPreview(false)
        setAnswerToScreenshotOrPreview(1)
        if(anwserToFile){
            setUploadTurn(true)
        }else{
            setTemplateTurn(true)
        }
        setIsWaiting(true)
    
    
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/import/options?appId=${appId}&collectionId=${colId}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                'Content-Type': 'application/json'
            },
        };
        
        axios(config)
        .then(function (response) {
            gaEvents.getTeplateOptionsSuccess("preview")

            setLocalized(sortValue(response.data.data.textualData.has))
            setNotLocalized(sortValue(response.data.data.textualData.notHas))

            setUserDontHaveDescription(sortValue(response.data.data.textualData.notHas))
            setUserSelectForDescription(sortValue(response.data.data.textualData.has))

            setUserDontHaveKeyword(sortValue(response.data.data.textualData.notHas))
            setUserSelectForKeyword(sortValue(response.data.data.textualData.has))

            setUserDontHaveWhatsnew(sortValue(response.data.data.textualData.notHas))
            setUserSelectForWhatsnew(sortValue(response.data.data.textualData.has))

            setUserDontHaveScreenshots(sortValue(response.data.data.photoData.notHas))
            setDontHaveScreenshots(sortValue(response.data.data.photoData.notHas))
            setUserSelectForScreenshots(sortValue(response.data.data.photoData.has))

            if(currentAppQuota.preview_data_storage===1){
                setUserDontHavePreviews(sortValue(response.data.data.previewData.notHas))
                setDontHavePreviews(sortValue(response.data.data.previewData.notHas))
                setUserSelectForPreviews(sortValue(response.data.data.previewData.has))      
            }
    
            setIsWaiting(false)

        })
        .catch(function (error) {
            gaEvents.getTeplateOptionsFail("preview")
            closePopup()
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
        });
    }
    const answerQuestionBoth = () =>{
        setAnswerToScreenshotOrPreview(2)
        setAskScreenshotOrPreview(false)
        if(anwserToFile){
            setUploadTurn(true)
        }else{
            setTemplateTurn(true)
        }
        setIsWaiting(true)
    
    
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/app/collection/import/options?appId=${appId}&collectionId=${colId}`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                'Content-Type': 'application/json'
            },
        };
        
        axios(config)
        .then(function (response) {
            gaEvents.getTeplateOptionsSuccess("previewScreenshot")

            setLocalized(sortValue(response.data.data.textualData.has))
            setNotLocalized(sortValue(response.data.data.textualData.notHas))

            setUserDontHaveDescription(sortValue(response.data.data.textualData.notHas))
            setUserSelectForDescription(sortValue(response.data.data.textualData.has))

            setUserDontHaveKeyword(sortValue(response.data.data.textualData.notHas))
            setUserSelectForKeyword(sortValue(response.data.data.textualData.has))

            setUserDontHaveWhatsnew(sortValue(response.data.data.textualData.notHas))
            setUserSelectForWhatsnew(sortValue(response.data.data.textualData.has))

            setUserDontHaveScreenshots(sortValue(response.data.data.photoData.notHas))
            setDontHaveScreenshots(sortValue(response.data.data.photoData.notHas))
            setUserSelectForScreenshots(sortValue(response.data.data.photoData.has))

            if(currentAppQuota.preview_data_storage===1){
                setUserDontHavePreviews(sortValue(response.data.data.previewData.notHas))
                setDontHavePreviews(sortValue(response.data.data.previewData.notHas))
                setUserSelectForPreviews(sortValue(response.data.data.previewData.has))      
            }
    
            setIsWaiting(false)

        })
        .catch(function (error) {
            gaEvents.getTeplateOptionsFail("previewScreenshot")

            closePopup()
            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
        });
    }

    //add-remove language and devices from folder
    const addDescription = (data) =>{
        if(notLocalized.find((i)=>i.value===data.value)){
            setUserSelectForDescription((current)=>sortValue([...current, data]))
            setUserDontHaveDescription(sortValue(userDontHaveDescription.filter((i)=>i.value!==data.value)))
        }
        else if(localized.find((i)=>i.value===data.value)){
            setUserSelectForDescription((current)=>sortValue([...current, data]))
            setUserHaveDescription(sortValue(userHaveDescription.filter((i)=>i.value!==data.value)))
        }
    }
    const deleteDescription = (index,e) =>{
        e.preventDefault()
        if(notLocalized.find((i)=>i.value===userSelectForDescription[index].value)){
            setUserDontHaveDescription(current =>sortValue([...current, {value: userSelectForDescription[index].value, label:langJSON[userSelectForDescription[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForDescription(sortValue(userSelectForDescription.filter((i)=>i!==userSelectForDescription[index])))
        }
        else if(localized.find((i)=>i.value===userSelectForDescription[index].value)){
            setUserHaveDescription(current =>sortValue([...current, {value: userSelectForDescription[index].value, label:langJSON[userSelectForDescription[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForDescription(sortValue(userSelectForDescription.filter((i)=>i!==userSelectForDescription[index])))
        }
    }

    const addKeyword = (data) =>{
        if(notLocalized.find((i)=>i.value===data.value)){
            setUserSelectForKeyword((current)=>sortValue([...current, data]))
            setUserDontHaveKeyword(sortValue(userDontHaveKeyword.filter((i)=>i.value!==data.value)))
        }
        else if(localized.find((i)=>i.value===data.value)){
            setUserSelectForKeyword((current)=>sortValue([...current, data]))
            setUserHaveKeyword(sortValue(userHaveKeyword.filter((i)=>i.value!==data.value)))
        }
    }
    const deleteKeyword = (index,e) =>{
        e.preventDefault()
        if(notLocalized.find((i)=>i.value===userSelectForKeyword[index].value)){
            setUserDontHaveKeyword(current =>sortValue([...current, {value: userSelectForKeyword[index].value, label:langJSON[userSelectForKeyword[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForKeyword(sortValue(userSelectForKeyword.filter((i)=>i!==userSelectForKeyword[index])))
        }
        else if(localized.find((i)=>i.value===userSelectForKeyword[index].value)){
            setUserHaveKeyword(current =>sortValue([...current, {value: userSelectForKeyword[index].value, label:langJSON[userSelectForKeyword[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForKeyword(sortValue(userSelectForKeyword.filter((i)=>i!==userSelectForKeyword[index])))
        }
    }

    const addWhatsnew = (data) =>{
        if(notLocalized.find((i)=>i.value===data.value)){
            setUserSelectForWhatsnew((current)=>sortValue([...current, data]))
            setUserDontHaveWhatsnew(sortValue(userDontHaveWhatsnew.filter((i)=>i.value!==data.value)))
        }
        else if(localized.find((i)=>i.value===data.value)){
            setUserSelectForWhatsnew((current)=>sortValue([...current, data]))
            setUserHaveWhatsnew(sortValue(userHaveWhatsnew.filter((i)=>i.value!==data.value)))
        }
    }
    const deleteWhatsnew = (index,e) =>{
        e.preventDefault()
        if(notLocalized.find((i)=>i.value===userSelectForWhatsnew[index].value)){
            setUserDontHaveWhatsnew(current =>sortValue([...current, {value: userSelectForWhatsnew[index].value, label:langJSON[userSelectForWhatsnew[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForWhatsnew(sortValue(userSelectForWhatsnew.filter((i)=>i!==userSelectForWhatsnew[index])))
        }
        else if(localized.find((i)=>i.value===userSelectForWhatsnew[index].value)){
            setUserHaveWhatsnew(current =>sortValue([...current, {value: userSelectForWhatsnew[index].value, label:langJSON[userSelectForWhatsnew[index].value.toLowerCase()].nameEnglish}]))
            setUserSelectForWhatsnew(sortValue(userSelectForWhatsnew.filter((i)=>i!==userSelectForWhatsnew[index])))
        }
    }

    const addScreenshots = (data) =>{
        if(notLocalized.find((i)=>i.value===data.value)){
            setUserSelectForScreenshots((current)=>sortValue([...current, data]))
            setUserDontHaveScreenshots(sortValue(userDontHaveScreenshots.filter((i)=>i.value!==data.value)))
            setOpenLangs(current=>[...current, data.value])
        }
        else if(localized.find((i)=>i.value===data.value)){
            setUserSelectForScreenshots((current)=>sortValue([...current, data]))
            if(dontHaveScreenshots.find((i)=>i.value===data.value)){
                setUserDontHaveScreenshots(sortValue(userDontHaveScreenshots.filter((i)=>i.value!==data.value)))
            }
            else{
                setUserHaveScreenshots(sortValue(userHaveScreenshots.filter((i)=>i.value!==data.value)))
            }
            setOpenLangs(current=>[...current, data.value])
        }
    }
    const deleteScreenshots = (index,e) =>{
        e.preventDefault()

        if(notLocalized.find((i)=>i.value===userSelectForScreenshots[index].value)){
            setUserDontHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
            setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
            setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
        }
        else if(localized.find((i)=>i.value===userSelectForScreenshots[index].value)){
            if(dontHaveScreenshots.find((i)=>i.value===userSelectForScreenshots[index].value)){
                setUserDontHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
                setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
                setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
            }
            else{
                setUserHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
                setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
                setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
            }
        }
    }

    const deleteScreenshots2 = (index) =>{
        if(notLocalized.find((i)=>i.value===userSelectForScreenshots[index].value)){
            setUserDontHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
            setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
            setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
        }
        else if(localized.find((i)=>i.value===userSelectForScreenshots[index].value)){
            if(dontHaveScreenshots.find((i)=>i.value===userSelectForScreenshots[index].value)){
                setUserDontHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
                setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
                setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
            }
            else{
                setUserHaveScreenshots(current =>sortValue([...current, {value: userSelectForScreenshots[index].value, label:langJSON[userSelectForScreenshots[index].value.toLowerCase()].nameEnglish, devices:userSelectForScreenshots[index].devices}]))
                setUserSelectForScreenshots(sortValue(userSelectForScreenshots.filter((i)=>i!==userSelectForScreenshots[index])))
                setOpenLangs(openLangs.filter((i)=>i!==userSelectForScreenshots[index].value))
            }
        }

    }
    const onDeviceSelect = (index, index2, e, valueLang) =>{
        if(e.target.checked){
            let temp = [...userSelectForScreenshots]
            temp[index].devices = [...temp[index].devices, {value: e.target.value, label: e.target.name}]
            setUserSelectForScreenshots([...temp])
        }
        else{
            let temp = [...userSelectForScreenshots]
            temp[index].devices = temp[index].devices.filter((i)=>i.value!==e.target.value) 
            setUserSelectForScreenshots([...temp])
        }
        let checked = 0
        let loopChoices = document.getElementsByClassName(valueLang+'devices')
        for(let a = 0; a<loopChoices.length; a++){
            if(loopChoices[a].checked===true){
                checked= checked+1
            }
            if(a===loopChoices.length-1){
                if(checked===0){
                    deleteScreenshots2(index)
                }
            }
        }

    }

    const addPreviews = (data) =>{
        if(notLocalized.find((i)=>i.value===data.value)){
            setUserSelectForPreviews((current)=>sortValue([...current, data]))
            setUserDontHavePreviews(sortValue(userDontHavePreviews.filter((i)=>i.value!==data.value)))
            setOpenLangs(current=>[...current, data.value])
        }
        else if(localized.find((i)=>i.value===data.value)){
            setUserSelectForPreviews((current)=>sortValue([...current, data]))
            if(dontHavePreviews.find((i)=>i.value===data.value)){
                setUserDontHavePreviews(sortValue(userDontHavePreviews.filter((i)=>i.value!==data.value)))
            }
            else{
                setUserHavePreviews(sortValue(userHavePreviews.filter((i)=>i.value!==data.value)))
            }
            setOpenLangs(current=>[...current, data.value])
        }
    }
    const deletePreviews = (index,e) =>{
        e.preventDefault()

        if(notLocalized.find((i)=>i.value===userSelectForPreviews[index].value)){
            setUserDontHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
            setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
            setOpenLangsPreview(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
        }
        else if(localized.find((i)=>i.value===userSelectForPreviews[index].value)){
            if(dontHavePreviews.find((i)=>i.value===userSelectForPreviews[index].value)){
                setUserDontHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
                setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
                setOpenLangs(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
            }
            else{
                setUserHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
                setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
                setOpenLangs(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
            }
        }
    }

    const deletePreviews2 = (index) =>{

        if(notLocalized.find((i)=>i.value===userSelectForPreviews[index].value)){
            setUserDontHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
            setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
            setOpenLangsPreview(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
        }
        else if(localized.find((i)=>i.value===userSelectForPreviews[index].value)){
            if(dontHavePreviews.find((i)=>i.value===userSelectForPreviews[index].value)){
                setUserDontHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
                setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
                setOpenLangs(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
            }
            else{
                setUserHavePreviews(current =>sortValue([...current, {value: userSelectForPreviews[index].value, label:langJSON[userSelectForPreviews[index].value.toLowerCase()].nameEnglish, devices:userSelectForPreviews[index].devices}]))
                setUserSelectForPreviews(sortValue(userSelectForPreviews.filter((i)=>i!==userSelectForPreviews[index])))
                setOpenLangs(openLangsPreview.filter((i)=>i!==userSelectForPreviews[index].value))
            }
        }

    }
    const onDeviceSelectPreview = (index, index2, e, valueLang) =>{
        if(e.target.checked){
            let temp = [...userSelectForPreviews]
            temp[index].devices = [...temp[index].devices, {value: e.target.value, label: e.target.name}]
            setUserSelectForPreviews([...temp])
        }
        else{
            let temp = [...userSelectForPreviews]
            temp[index].devices = temp[index].devices.filter((i)=>i.value!==e.target.value) 
            setUserSelectForPreviews([...temp])
        }
        let checked = 0
        let loopChoices = document.getElementsByClassName(valueLang+'devices')
        for(let a = 0; a<loopChoices.length; a++){
            if(loopChoices[a].checked===true){
                checked= checked+1
            }
            if(a===loopChoices.length-1){
                if(checked===0){
                    deletePreviews2(index)
                }
            }
        }

    }

    //show-hide
    const hideDescriptionFolder = () =>{
        setShowDescription(!showDescription)
    }
    const hideKeywordFolder = () =>{
        setShowKeyword(!showKeyword)
    }
    const hideWhatsnewFolder = () =>{
        setShowWhatsnew(!showWhatsnew)
    }
    const hideScreenshotsFolder = () =>{
        setShowScreenshots(!showScreenshots)
    }
    const hidePreviewsFolder = () =>{
        setShowPreviews(!showPreviews)
    }
    const hideLanguageFolder = (value) =>{
        setOpenLangs(current=>{
            
            if(current.includes(value)){
                return current.filter((i)=>i!==value)
            }else{
                return [...current,value]
            }
        })
    }
    const hideLanguageFolderPreview = (value) =>{
        setOpenLangsPreview(current=>{
            
            if(current.includes(value)){
                return current.filter((i)=>i!==value)
            }else{
                return [...current,value]
            }
        })
    }

    //popup download request and upload popup
    const getFile = async () =>{
        fireFileMessage()
        setIsDownlaoding(true)
        let fileStructure = new Promise ((resolve) =>{
        if(pageType==='description'){
            resolve(JSON.stringify({
                "details": {
                    "description" : userSelectForDescription
                }
            }))
        }
        else if(pageType==='keyword'){
            resolve(JSON.stringify({
                "details": {
                    "keyword" : userSelectForKeyword
                }
            }))
        }
        else if(pageType==='whatsnew'){
            resolve(JSON.stringify({
                "details": {
                    "whatsNew" : userSelectForWhatsnew
                }
            }))
        }
        else if(pageType==='screenshot' && answerToScreenshotOrPreview===2){
            resolve(JSON.stringify({
                "details": {
                    "photo" : userSelectForScreenshots,
                    "preview" : userSelectForPreviews
                }
            }))
        }
        else if(pageType==='screenshot' && answerToScreenshotOrPreview===0){
            resolve(JSON.stringify({
                "details": {
                    "photo" : userSelectForScreenshots,
                }
            }))
        }
        else if(pageType==='screenshot' && answerToScreenshotOrPreview===1){
            resolve(JSON.stringify({
                "details": {
                    "preview" : userSelectForPreviews,
                }
            }))
        }
        else if(pageType==='owner'){
            resolve(JSON.stringify({
                "details": {
                    "description" : userSelectForDescription,
                    "keyword" : userSelectForKeyword,
                    "whatsNew" : userSelectForWhatsnew,
                    "photo" : userSelectForScreenshots,
                    "preview" : userSelectForPreviews

                }
            }))
        }})


        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/import/template`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                'Content-Type': 'application/json'
            },
            data : await fileStructure
          };
          
          axios(config)
          .then(function (response) {
            gaEvents.downloadTemplateSuccess(pageType)
            let a = document.createElement('a');
            a.href = `${process.env.REACT_APP_API_URL}/${response.data.data.path}`;
            a.download = `${response.data.data.path}`.split("/")[2]
            a.click();
            Swal.close()
            setIsDownlaoding(false)
            setUploadTurn(true)
            setTemplateTurn(false)
          })
          .catch(function (error) {
            gaEvents.downloadTemplateFail(pageType)

            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            closePopup()
          }); 
    }
    const getFileE = async (e) =>{ 
        e.preventDefault()
        fireFileMessage()
        setIsDownlaoding(true)
        let fileStructure = new Promise ((resolve) =>{
        if(pageType==='description'){
            resolve(JSON.stringify({
                "details": {
                    "description" : userSelectForDescription
                }
            }))
        }
        else if(pageType==='keyword'){
            resolve(JSON.stringify({
                "details": {
                    "keyword" : userSelectForKeyword
                }
            }))
        }
        else if(pageType==='whatsnew'){
            resolve(JSON.stringify({
                "details": {
                    "whatsNew" : userSelectForWhatsnew
                }
            }))
        }
        else if(pageType==='screenshot' && answerToScreenshotOrPreview===2){
            resolve(JSON.stringify({
                "details": {
                    "photo" : userSelectForScreenshots,
                    "preview" : userSelectForPreviews
                }
            }))
        }
        else if(pageType==='screenshot' && answerToScreenshotOrPreview===0){
            resolve(JSON.stringify({
                "details": {
                    "photo" : userSelectForScreenshots,
                }
            }))
        }
        else if(pageType==='screenshot' && answerToScreenshotOrPreview===1){
            resolve(JSON.stringify({
                "details": {
                    "preview" : userSelectForPreviews,
                }
            }))
        }
        else if(pageType==='owner'){
            resolve(JSON.stringify({
                "details": {
                    "description" : userSelectForDescription,
                    "keyword" : userSelectForKeyword,
                    "whatsNew" : userSelectForWhatsnew,
                    "photo" : userSelectForScreenshots,
                    "preview" : userSelectForPreviews
                }
            }))
        }})


        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/app/collection/import/template`,
            headers: { 
                'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                'Content-Type': 'application/json'
            },
            data : await fileStructure
          };
          
          axios(config)
          .then(function (response) {
            gaEvents.downloadTemplateSuccess(pageType)

            let a = document.createElement('a');
            a.href = `${process.env.REACT_APP_API_URL}/${response.data.data.path}`;
            a.download = `${response.data.data.path}`.split("/")[2]
            a.click();
            Swal.close()
            setIsDownlaoding(false)
            setUploadTurn(true)
            setTemplateTurn(false)
          })
          .catch(function (error) {
            gaEvents.downloadTemplateFail(pageType)

            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            closePopup()
          }); 
    }

    //drag-drop upload
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActive(true);
              } else if (e.type === "dragleave") {
                setDragActive(false);
              }
        
    }
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                if(setZip(e.dataTransfer.files)){
                    e.dataTransfer.clearData()
                }
            }
        
    }
    const onZipUpload = (e) =>{ 
        setZip(e.target.files)
    }

    const modeSelect = (e) =>{
        setMode(e.target.value)
    }

    //import ready zip file
    const uploadZipE = (e) =>{
        e.preventDefault()
        var data = new FormData();
        if(pageType==='screenshot' || pageType==='owner'){
            data.append('mode', mode);
        }else{
            data.append('mode', '2');
        }
        for (const z of zip) {
            data.append('zip', z);
        }
        data.append('appId', appId);
        if(pageType==='whatsnew'){data.append('type', 'whatsNew');} 
        else if (pageType==='screenshot' && answerToScreenshotOrPreview===0) {data.append('type', 'photo')}
        else if (pageType==='screenshot' && answerToScreenshotOrPreview===1) {data.append('type', 'preview')}
        else if (pageType==='screenshot' && answerToScreenshotOrPreview===2) {data.append('type', 'media')}
        else if (pageType==='owner') {data.append('type', 'all')}
        else {data.append('type', pageType)}
        data.append('collectionId', colId)
        
        const onUploadProgress = (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            if (percent <= 100) {
                
                setImportPercentage(new Map(importPercentage.set(`${appId}-${colId}-${pageType}`, percent)))
            }
        };

        var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/app/collection/import`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
        },
        data : data,
        onUploadProgress
        };
        setImportArray(new Map(importArray.set(`${appId}-${colId}-${pageType}`, true)))
        axios(config)
        .then(function (response) {
            gaEvents.importProcessSuccess(pageType)
            setImportPercentage(new Map(importPercentage.set(`${appId}-${colId}-${pageType}`, false)))
            setImportArray(new Map(importPercentage.set(`${appId}-${colId}-${pageType}`, false)))
            setGetColList(getColList+1)
            setSummary(response.data.data)
            appCol.current[appId] = colId
            if(pageType==='owner'){
                navigate(`/${appId}/${pageType}/${colId}`)
            }
            fireCustomSuccessMessage(`Collection created from ${zip[0].name}`)

            closePopup()
        })
        .catch(function (error) {
            gaEvents.importProcessFail(pageType)

            if(error.message === 'Network Error'){
                fireCustomError('NETWORK ERROR. ')
              }else{
                fireRequestError(error)
              }
            setImportPercentage(new Map(importPercentage.set(`${appId}-${colId}-${pageType}`, false)))
            setImportArray(new Map(importPercentage.set(`${appId}-${colId}-${pageType}`, false)))
            setZip(null)
            if(document.getElementById('uplodaTemplateDivInput')){
                document.getElementById('uplodaTemplateDivInput').value = ''
            }
        });
    }

    useEffect(()=>{
        if(importPercentage.get(`${appId}-${colId}-${pageType}`)===100){
            setMessage('Importing to collection, please wait...')
            setTimeout(()=>{
                setMessage('This might take a while, do not close this popup or refresh your page...')
            },5000)
            setTimeout(()=>{
                setMessage('Almost there...')
            },35000)
        }
        else{
            setMessage('')
        }

    },[importPercentage])

    return ( 
        <div id={'importAppPopup'+colId} className='import-popup-container'>
            <div className='importPopupBack' onClick={isDownloading || importArray.get(`${appId}-${colId}-${pageType}`) ? ()=>{} : ()=>{closePopup()}}></div>
                {question ? 
                    <div className='import-popup-content-pre'>
                        <span className="import-popup-name">Do you have your template file? <Qmark link="https://doc.storesyncer.com/template-file/what-is-a-template-file" /></span>
                        <div className="importPopupButtons">
                                <button disabled={false} onClick={answerQuestionYes} className="import-popup-button-pre pre1">
                                    {!false ? 'Yes' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                                </button>

                                <button disabled={false} onClick={answerQuestionNo} className="import-popup-button-pre pre2">
                                    {!false ? 'No' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                                </button>

                                <span disabled={false} onClick={!false ? ()=>closePopup() : ()=>{}} className="import-popup-button2-pre popupCancel">
                                    Cancel 
                                </span>
                            </div>
                    </div>

                :''}
                {askScreenshotOrPreview ?
                    <div className='import-popup-content-pre-2'>
                        <span className="import-popup-name">What do you want to import? <Qmark link="https://doc.storesyncer.com/import/how-to-import-screenshots-and-previews-to-a-collection"/></span>

                        <div onChange={(e)=>{setAnswer(+e.target.value);}} className="importPopupButtons2">

                            {/* screenshots */}
                            <div
                                style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                borderRadius: "10px",
                                backgroundColor: "#f5f5f5",
                                width: "380px",
                                height: "50px",
                                fontWeight: "500",
                                fontSize: "20px",
                                padding: "20px 30px",
                                marginBottom: "10px",
                                }}
                            >
                                <label>
                                <input 
                                    disabled={currentAppQuota.screenshot_data_storage === 0}
                                    value={3} 
                                    required={true} 
                                    type="radio"
                                    name="item" 
                                />
                                &nbsp;&nbsp;
                                <span
                                    style={
                                    currentAppQuota.screenshot_data_storage === 0
                                        ? { color: "#aaa" }
                                        : { color: "unset" }
                                    }
                                >
                                    Screenshots
                                </span>
                                {/* quota reached */}
                                {currentAppQuota.screenshot_data_storage === 0 && currentAppRole==='owner'  ?
                                        <>
                                            &nbsp;
                                            <img
                                                style={{ width: "21px" }}
                                                src="/assets/Icons/lock-fill.svg"
                                                alt="locked"
                                            />
                                            &nbsp;
                                            <NavLink
                                                to={"/subscription"}
                                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                                            >
                                                (upgrade your subscription)
                                            </NavLink>
                                        </>
                                :
                                        ''
                                }
                                {currentAppQuota.screenshot_data_storage === 0 && currentAppRole!=='owner'  ?
                                        <>
                                            &nbsp;
                                            <img
                                                style={{ width: "21px" }}
                                                src="/assets/Icons/lock-fill.svg"
                                                alt="locked"
                                            />
                                            &nbsp;
                                            <NavLink
                                                to={"/subscription"}
                                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                                            >
                                                (not allowed)
                                            </NavLink>
                                        </>
                                :
                                        ''
                                }
                                </label>
                            </div>

                            {/* previews */}
                            <div
                                style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                borderRadius: "10px",
                                backgroundColor: "#f5f5f5",
                                width: "380px",
                                height: "50px",
                                fontWeight: "500",
                                fontSize: "20px",
                                padding: "20px 30px",
                                marginBottom: "10px",
                                }}
                            >
                                <label>
                                <input
                                    disabled={
                                    (initialUserData.account_type === "free" &&
                                        currentAppRole === "owner") ||
                                    currentAppQuota.preview_data_storage === 0
                                    }
                                    value={1}
                                    type="radio"
                                    name="item"
                                />
                                &nbsp;&nbsp;
                                <span
                                    style={
                                    (initialUserData.account_type === "free" &&
                                        currentAppRole === "owner") ||
                                    currentAppQuota.preview_data_storage === 0
                                        ? { color: "#aaa" }
                                        : { color: "unset" }
                                    }
                                >
                                    Previews
                                </span>

                                {/* free user is owner */}
                                {(initialUserData.account_type === "free" &&
                                    currentAppRole === "owner") ?
                                        <>
                                            &nbsp;
                                            <img
                                                style={{ width: "21px" }}
                                                src="/assets/Icons/lock-fill.svg"
                                                alt="locked"
                                            />
                                            &nbsp;
                                            <NavLink
                                                to={"/subscription"}
                                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                                            >
                                                (upgrade your subscription)
                                            </NavLink>
                                        </>
                                :
                                        ''
                                }

                                {/* quota reached */}
                                {currentAppQuota.preview_data_storage === 0 && currentAppRole !== "owner" ?
                                        <>
                                            &nbsp;
                                            <img
                                                style={{ width: "21px" }}
                                                src="/assets/Icons/lock-fill.svg"
                                                alt="locked"
                                            />
                                            &nbsp;
                                            <NavLink
                                                to={"/subscription"}
                                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                                            >
                                                (not allowed)
                                            </NavLink>
                                        </>
                                :
                                        ''
                                }
                                </label>
                            </div>

                            {/* both */}
                            <div
                                style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                borderRadius: "10px",
                                backgroundColor: "#f5f5f5",
                                width: "380px",
                                height: "50px",
                                fontWeight: "500",
                                fontSize: "20px",
                                padding: "20px 30px",
                                marginBottom: "15px",
                                }}
                            >
                                <label>
                                <input
                                    disabled={
                                    (initialUserData.account_type === "free" &&
                                        currentAppRole === "owner") ||
                                    currentAppQuota.screenshot_data_storage === 0 ||
                                    currentAppQuota.preview_data_storage === 0
                                    }
                                    value={2}
                                    type="radio"
                                    name="item"
                                />
                                &nbsp;&nbsp;
                                <span
                                    style={
                                    (initialUserData.account_type === "free" &&
                                        currentAppRole === "owner") ||
                                        currentAppQuota.screenshot_data_storage === 0 ||
                                        currentAppQuota.preview_data_storage === 0
                                        ? { color: "#aaa" }
                                        : { color: "unset" }
                                    }
                                >
                                    Both
                                </span>
                                {/* free user is owner */}
                                {(initialUserData.account_type === "free" &&
                                    currentAppRole === "owner") ?
                                        <>
                                            &nbsp;
                                            <img
                                                style={{ width: "21px" }}
                                                src="/assets/Icons/lock-fill.svg"
                                                alt="locked"
                                            />
                                            &nbsp;
                                            <NavLink
                                                to={"/subscription"}
                                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                                            >
                                                (upgrade your subscription)
                                            </NavLink>
                                        </>
                                :
                                        ''
                                }

                                {/* quota reached */}
                                {(currentAppQuota.preview_data_storage === 0 ||
                                currentAppQuota.screenshot_data_storage === 0) && currentAppRole !== "owner" ?

                                        <>
                                            &nbsp;
                                            <img
                                                style={{ width: "21px" }}
                                                src="/assets/Icons/lock-fill.svg"
                                                alt="locked"
                                            />
                                            &nbsp;
                                            <NavLink
                                                to={"/subscription"}
                                                style={{ fontSize: "0.8rem", fontStyle: "italic" }}
                                            >
                                                (not allowed)
                                            </NavLink>
                                        </>
                                :
                                        ''
                                }
                                </label>
                            </div>

                            <span style={{fontSize: '0.8rem', fontStyle: 'italic', margin:'15px 60px 25px 60px', display:'flex', alignItems:'center'}}>*Every screenshot and/or preview for every device and language in this collection will be exported.</span>

                            <span style={!answer ? {cursor:'not-allowed', marginRight:'0', backgroundColor:'#3A6FF8'} : { marginRight:'0', backgroundColor:'#3A6FF8'}} onClick={answer===3 ? ()=>answerQuestionScreenshot() : answer===1 ? ()=>answerQuestionPreview() : answer===2 ? ()=>answerQuestionBoth() : ()=>{}} className="import-popup-button2-pre popupCancel">
                                OK
                            </span>
                        </div>

                    </div>
                :
                    ''
                }
                {templateTurn ? 
                    <div className='import-popup-content'>
                        <span className="import-popup-name">Arrange Your Template File <Qmark link="https://doc.storesyncer.com/template-file/how-to-create-and-use-a-template-file"/></span>
                        <form className='create-import-form' onSubmit={isDownloading ? ()=>{} : getFileE} >
                            <div className='create-import-form-inner'>
                                {pageType==='description' || pageType==='owner' ?
                                    //description
                                    <div className='selectTeplateDiv'>

                                        <div className='selectTeplateTitle'>
                                            <img onClick={hideDescriptionFolder} style={{width:'20px', marginRight: '10px'}} src={showDescription ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                            <img style={{width:'30px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/> Description
                                        </div>

                                        {!isWaiting ?    
                                            showDescription ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Localized",
                                                                options: userHaveDescription
                                                            },
                                                            {
                                                                label: "Not Localized",
                                                                options: userDontHaveDescription
                                                            }]
                                                        }
                                                        placeholder="Add Languages"
                                                        value={''}
                                                        onChange={isDownloading ? ()=>{} : addDescription}
                                                        isSearchable={true}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        {userSelectForDescription.map((i,index)=>{
                                                            return(
                                                                <li className={`selectedLanguage ${i.value}`} key={index}><img style={{width:'30px'}} src='/assets/Icons/txt.svg'/>{i.label}({i.value}.txt)  <div className='selectedLanguageDelete' onClick={isDownloading ?()=>{} : (e)=>deleteDescription(index,e)}>-</div></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        :
                                            showDescription ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Loading",
                                                                options: []
                                                            }]
                                                        }
                                                        placeholder="Loading..."
                                                        value={''}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Loading...</li>
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        }

                                    </div>
                                : ''}
                                {pageType==='keyword' || pageType==='owner' ?
                                    //keyword
                                    <div className='selectTeplateDiv'>

                                        <div className='selectTeplateTitle'>
                                            <img onClick={hideKeywordFolder} style={{width:'20px', marginRight: '10px'}} src={showKeyword ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                            <img style={{width:'30px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/> Keyword
                                        </div>

                                        {!isWaiting ?    
                                            showKeyword ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Localized",
                                                                options: userHaveKeyword
                                                            },
                                                            {
                                                                label: "Not Localized",
                                                                options: userDontHaveKeyword
                                                            }]
                                                        }
                                                        placeholder="Add Languages"
                                                        value={''}
                                                        onChange={isDownloading ? ()=>{} : addKeyword}
                                                        isSearchable={true}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        {userSelectForKeyword.map((i,index)=>{
                                                            return(
                                                                <li className={`selectedLanguage ${i.value}`} key={index}><img style={{width:'30px'}} src='/assets/Icons/txt.svg'/>{i.label}({i.value}.txt)  <div className='selectedLanguageDelete' onClick={isDownloading ?()=>{} : (e)=>deleteKeyword(index,e)}>-</div></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        :
                                            showDescription ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Loading",
                                                                options: []
                                                            }]
                                                        }
                                                        placeholder="Loading..."
                                                        value={''}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Loading...</li>
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        }

                                    </div>
                                : ''}
                                {pageType==='whatsnew' || pageType==='owner' ?
                                    //whatsnew
                                    <div className='selectTeplateDiv'>

                                        <div className='selectTeplateTitle'>
                                            <img onClick={hideWhatsnewFolder} style={{width:'20px', marginRight: '10px'}} src={showWhatsnew ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                            <img style={{width:'30px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/> What's New
                                        </div>

                                        {!isWaiting ?    
                                            showWhatsnew ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Localized",
                                                                options: userHaveWhatsnew
                                                            },
                                                            {
                                                                label: "Not Localized",
                                                                options: userDontHaveWhatsnew
                                                            }]
                                                        }
                                                        placeholder="Add Languages"
                                                        value={''}
                                                        onChange={isDownloading ? ()=>{} : addWhatsnew}
                                                        isSearchable={true}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        {userSelectForWhatsnew.map((i,index)=>{
                                                            return(
                                                                <li className={`selectedLanguage ${i.value}`} key={index}><img style={{width:'30px'}} src='/assets/Icons/txt.svg'/>{i.label}({i.value}.txt)  <div className='selectedLanguageDelete' onClick={isDownloading ?()=>{} : (e)=>deleteWhatsnew(index,e)}>-</div></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        :
                                            showWhatsnew ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Loading",
                                                                options: []
                                                            }]
                                                        }
                                                        placeholder="Loading..."
                                                        value={''}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Loading...</li>
                                                    </ul>
                                                </>
                                            : 
                                                ''
                                        }

                                    </div>                                
                                : ''}
                                {((pageType==='screenshot' ) && (answerToScreenshotOrPreview===0 || answerToScreenshotOrPreview===2)) || pageType==='owner' ?
                                    //screenshot
                                    <div className='selectTeplateDiv'>

                                        <div className='selectTeplateTitle'>
                                            <img onClick={hideScreenshotsFolder} style={{width:'20px', marginRight: '10px'}} src={showScreenshots ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                            <img style={{width:'30px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>Screenshots
                                        </div>
                                        {!isWaiting ?
                                            showScreenshots ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Screenshots Found",
                                                                options: userHaveScreenshots
                                                            },
                                                            {
                                                                label: "Screenshots not found",
                                                                options: userDontHaveScreenshots
                                                            }]
                                                        }
                                                        placeholder="Add Languages"
                                                        value={''}
                                                        onChange={isDownloading ? ()=>{} : addScreenshots}
                                                        isSearchable={true}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        {userSelectForScreenshots.map((i,index)=>{
                                                            return(
                                                                <li className='selectedLanguage2' key={index}>
                                                                    <span className='selectedLangguage2inner'>
                                                                        <img id={i.value+'icon'} onClick={()=>hideLanguageFolder(i.value)} style={{width:'15px', marginRight: '10px'}} src={openLangs.includes(i.value) ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                                                        <img style={{width:'20px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>{i.label}({i.value}) 
                                                                        <div className='selectedLanguageDelete' onClick={isDownloading ? ()=>{} : (e)=>deleteScreenshots(index,e)}>-</div>
                                                                    </span>
                                                                    {openLangs.includes(i.value) ?  
                                                                        <span id={i.value}>
                                                                            {deviceList.map((j, index2)=>{
                                                                                return(
                                                                                    <label className='selectedDevice' key={index2}>
                                                                                        <input onChange={isDownloading ? ()=>{} : (e)=>onDeviceSelect(index, index2, e, i.value)} className={i.value+'devices'} type='checkbox'  checked={userSelectForScreenshots[index].devices.find((x)=>x.value===j.value) || false} value={j.value} name={j.label} disabled={isDownloading}/>
                                                                                        <img style={{width:'15px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>
                                                                                        <div>{j.label}</div>
                                                                                    </label>
                                                                                )
                                                                            })}
                                                                        </span>
                                                                    : ''}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            :   
                                                ''
                                        :
                                            showScreenshots ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Loading",
                                                                options: []
                                                            }]
                                                        }
                                                        placeholder="Loading..."
                                                        value={''}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                                <li>
                                                                    Loading...
                                                                </li>
                                                    </ul>
                                                </>
                                            :
                                                ''
                                        }

                                    </div>
                                : ''}

                                {((currentAppQuota.preview_data_storage===1) && (pageType==='screenshot' || pageType==='owner') && (answerToScreenshotOrPreview===1 || answerToScreenshotOrPreview===2))  ?
                                    //preview
                                    <div className='selectTeplateDiv'>

                                        <div className='selectTeplateTitle'>
                                            <img onClick={hidePreviewsFolder} style={{width:'20px', marginRight: '10px'}} src={showPreviews ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                            <img style={{width:'30px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>Preivews
                                        </div>
                                        {!isWaiting ?
                                            showPreviews ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Previews Found",
                                                                options: userHavePreviews
                                                            },
                                                            {
                                                                label: "Previews not Found",
                                                                options: userDontHavePreviews
                                                            }]
                                                        }
                                                        placeholder="Add Languages"
                                                        value={''}
                                                        onChange={isDownloading ? ()=>{} : addPreviews}
                                                        isSearchable={true}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                        {userSelectForPreviews.map((i,index)=>{
                                                            return(
                                                                <li className='selectedLanguage2' key={index}>
                                                                    <span className='selectedLangguage2inner'>
                                                                        <img id={i.value+'icon'} onClick={()=>hideLanguageFolderPreview(i.value)} style={{width:'15px', marginRight: '10px'}} src={openLangsPreview.includes(i.value) ? '/assets/Icons/arrow-toggle.svg' : '/assets/Icons/down-arrow-black.svg'}/>
                                                                        <img style={{width:'20px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>{i.label}({i.value}) 
                                                                        <div className='selectedLanguageDelete' onClick={isDownloading ? ()=>{} : (e)=>deletePreviews(index,e)}>-</div>
                                                                    </span>
                                                                    {openLangsPreview.includes(i.value) ?  
                                                                        <span id={i.value}>
                                                                            {deviceListPreviews.map((j, index2)=>{
                                                                                return(
                                                                                    <label className='selectedDevice' key={index2}>
                                                                                        <input onChange={isDownloading ? ()=>{} : (e)=>onDeviceSelectPreview(index, index2, e, i.value)} className={i.value+'devices'} type='checkbox'  checked={userSelectForPreviews[index].devices.find((x)=>x.value===j.value) || false} value={j.value} name={j.label} disabled={isDownloading}/>
                                                                                        <img style={{width:'15px', marginRight: '5px'}} src='/assets/Icons/folder.svg'/>
                                                                                        <div>{j.label}</div>
                                                                                    </label>
                                                                                )
                                                                            })}
                                                                        </span>
                                                                    : ''}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            :   
                                                ''
                                        :
                                            showScreenshots ? 
                                                <>
                                                    <Select
                                                        blurInputOnSelect = {true}
                                                        className='languageDropdown'
                                                        options={
                                                            [{
                                                                label: "Loading",
                                                                options: []
                                                            }]
                                                        }
                                                        placeholder="Loading..."
                                                        value={''}
                                                        />
                                                    <ul style={{listStyle:'none'}}>
                                                                <li>
                                                                    Loading...
                                                                </li>
                                                    </ul>
                                                </>
                                            :
                                                ''
                                        }

                                    </div>
                                : ''}
                            </div>

                            <input type='submit' hidden={true} disabled={isDownloading}/>
                            <div className="importPopupButtons">
                                <button disabled={isDownloading} onClick={getFile} className="import-popup-button">
                                    {!isDownloading ? 'Download' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                                </button>

                                <span disabled={isDownloading} onClick={!isDownloading ? ()=>closePopup() : ()=>{}} className="import-popup-button2 popupCancel">
                                    Cancel 
                                </span>
                            </div>
                        </form>
                    </div>
                : 
                    ''
                }

                {uploadTurn ? 
                    <div className='import-popup-content'>
                        <span className="import-popup-name">Upload Your Template File <Qmark link="https://doc.storesyncer.com/template-file/how-to-create-and-use-a-template-file" /></span>
                        <form className='create-import-form' onSubmit={!importArray.get(`${appId}-${colId}-${pageType}`) ? uploadZipE : ()=>{}}>
                            <div className='create-import-form-inner'>

                                <div className='uploadTemplateDiv' onDragEnter={handleDrag}>
                                    <span style={{color: '#8b7260'}}>
                                        <label style={{cursor: 'pointer', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}} htmlFor={'uplodaTemplateDivInput'}>
                                            <img src="/assets/Icons/uploading.svg" alt="folder" />
                                            <br />
                                            {!zip ? 
                                                'Choose or drag your .zip file' 
                                            : 
                                                importPercentage.get(`${appId}-${colId}-${pageType}`) ? '' : <span><span style={ {color:'#0f044c', fontWeight:'bold'}}>{zip[0].name}</span>, change or drag new file</span>}

                                            <br />

                                            {importPercentage.get(`${appId}-${colId}-${pageType}`) ? 
                                                importPercentage.get(`${appId}-${colId}-${pageType}`)<100 ?
                                                    <span style={{display: 'flex', alignItems: 'center'}}>Uploading...&nbsp;<span style={ {color:'#0f044c'}}>{importPercentage.get(`${appId}-${colId}-${pageType}`)}%</span></span> 
                                                :
                                                    <span style={{display: 'flex', alignItems: 'center'}}>{message}&nbsp;<SpinnerSmall/></span>
                                            : 
                                                ''
                                            }
                                        </label> 
                                    </span>
                                    
                                    <input type='file' accept='.zip' multiple={false} id={'uplodaTemplateDivInput'} hidden={true} onChange={onZipUpload} disabled={importArray.get(`${appId}-${colId}-${pageType}`) || zip} name='uplodaTemplateDivInput'/>

                                    {dragActive && <div className='dragDiv' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                                </div>

                            </div>

                            

                            {pageType==='screenshot' || pageType==='owner' ? 
                                <div onChange={modeSelect} style={{display:'flex', alingItems: 'center', flexWrap:'wrap', gap:'5px', marginTop:'5px'}}>
                                    <label style={{display:'flex', alingItems: 'center'}}><input required={true} type="radio" value="2" name="mode" style={{marginLeft:'5px'}}/> Overwrite screenshots/previews with imported ones</label>
                                    <label style={{display:'flex', alingItems: 'center'}}><input required={true} type="radio" value="3" name="mode" /> Keep screenshots/previews and add imported ones</label>
                                </div>
                            :
                                ''                        
                            }

                            <div className="importPopupButtons">
                                <button disabled={importArray.get(`${appId}-${colId}-${pageType}`)||!zip} type='submit' className="import-popup-button">
                                    {!importArray.get(`${appId}-${colId}-${pageType}`) ? 'Import' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                                </button>

                                <span disabled={importArray.get(`${appId}-${colId}-${pageType}`)} onClick={!importArray.get(`${appId}-${colId}-${pageType}`) ? ()=>closePopup() : ()=>{}} className="import-popup-button2 popupCancel">
                                    Cancel 
                                </span>
                            </div>
                        </form>
                    </div>
                : 
                    ''
                }
        </div>
    )
}




export default ImportZip