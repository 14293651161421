import {useContext} from 'react'
import VariableContext from '../userContext/VariableContext';
import './SummaryPopup.css'
import langJSON from '../flagsLanguages/lang.json'

//upload summary
function SummaryUploadPopup() { 
    const {summaryUpload, setSummaryUpload} = useContext(VariableContext)

    const {isUploadingDescription} = useContext(VariableContext)
    const {isUploadingKeyword} = useContext(VariableContext)
    const {isUploadingWhatsnew} = useContext(VariableContext)
    const {isUploadingPhoto} = useContext(VariableContext)
    const {isUploadingPreview} = useContext(VariableContext)
    const {isUploading} = useContext(VariableContext)
    
    //popup close reset states
    const closePopup = () => {
        setSummaryUpload(null)
    }

    return ( 
        <div id={'summaryPopup'} className='summary-popup-container'>
            <div className='summaryPopupBack' onClick={()=>closePopup()}></div>

                <div className='summary-popup-content'>

                    <span className="summary-popup-name">Upload Summary</span>
                    
                    <div className='summary-div'>

                        {summaryUpload.data.textualData.size===0 && summaryUpload.data.photo.size===0 && summaryUpload.data.preview.size===0 ?
                        <>
                            <p className="summaryHeading">Metadata Set is Empty</p>

                            <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>

                                <p className='summarySubHeading'>You have uploaded an empty set</p>
                                <p className='summaryP' style={{fontStyle:'italic'}}>*If your uploaded visual metadata is empty, your visiual metadata on App Store Connect is removed for your chosen app.</p>
                                <p></p>
                                <p className='summaryP' style={{fontStyle:'italic'}}>*If your uploaded textual metadata is empty, your textual metadata on App Store Connect is not changed for your chosen app.</p>

                            </div>
                        </>
                        :
                            ''
                        }

                        

                        {summaryUpload.data.textualData.size>0 ?
                        <p className="summaryHeading">Textual Errors
                            &nbsp;
                            {summaryUpload.data.textualData.errorList.length===0 ? 
                                <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                            :
                                <img src="/assets/Icons/x.svg" alt="negative_mark" />
                            }
                        </p> : ''}
                            {summaryUpload.data.textualData.size>0 ?

                            <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>

                                {summaryUpload.data.textualData.errorList.length>0 ? summaryUpload.data.textualData.errorList.map((i,index)=>{
                                            return(
                                                <span key={index+'uploadSummaryText'}>
                                                    <p className="summarySubHeading">{langJSON[i.language_code.toLowerCase()].nameEnglish} - {i.language_code}</p>
                                                        {isUploadingDescription.status ? 
                                                            <p className='summaryP'>❌&nbsp;<span style={{fontWeight: 'bold'}}>Description could not be uploaded.</span></p> : ''
                                                        }
                                                        {isUploadingKeyword.status ? 
                                                            <p className='summaryP'>❌&nbsp;<span style={{fontWeight: 'bold'}}>Keywords could not be uploaded.</span></p> : ''
                                                        }
                                                        {isUploadingWhatsnew.status ? 
                                                            <p className='summaryP'>❌&nbsp;<span style={{fontWeight: 'bold'}}>What's new could not be uploaded.</span></p> : ''
                                                        }
                                                        {isUploading.status ? 
                                                            <p className='summaryP'>❌&nbsp;<span style={{fontWeight: 'bold'}}>Text data could not be uploaded.</span></p> : ''
                                                        }       
                                                </span>
                                            )
                                        }
                                    )
                                : summaryUpload.data.textualData.size>0 ? <><p className="summarySubHeading">No errors for textual data</p></> : ''} 

                            </div>
                            : ''}


                            {summaryUpload.data.photo.size>0 ?
                            <p className="summaryHeading">Screenshot Errors
                                &nbsp;
                                {summaryUpload.data.photo.errorList.length===0 ? 
                                    <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                                :
                                    <img src="/assets/Icons/x.svg" alt="negative_mark" />
                                }
                            </p> : ''}

                            {summaryUpload.data.photo.size>0 ?

                                <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>
                                    {summaryUpload.data.photo.errorList.length>0 ? summaryUpload.data.photo.errorList.map((i,index)=>{
                                                return(
                                                    <span key={index+'uploadSummaryText'}>
                                                        <p className="summarySubHeading">{langJSON[i.language_code.toLowerCase()].nameEnglish} - {i.language_code}</p>
                                                        {i.error.first ? 
                                                            <p className='summaryP'>❌&nbsp;
                                                                {i.error.first.errors.length !==0 ?
                                                                <span style={{fontWeight: 'bold'}}>{i.error.first.errors[0].title}</span> : <span style={{fontWeight: 'bold'}}>Image proccesing tool failed.</span>}
                                                            </p>
                                                        :
                                                        i.error.second ?
                                                            <p className='summaryP'>❌&nbsp;
                                                                {i.error.second.errors.length !==0 ?
                                                                <span style={{fontWeight: 'bold'}}>{i.error.second.errors[0].title}</span> : <span style={{fontWeight: 'bold'}}>Image proccesing tool failed.</span>}
                                                            </p>
                                                        :
                                                        i.error.third ?
                                                            <p className='summaryP'>❌&nbsp;
                                                                {i.error.third.errors.length !==0 ?
                                                                <span style={{fontWeight: 'bold'}}>{i.error.third.errors[0].title}</span> : <span style={{fontWeight: 'bold'}}>Image proccesing tool failed.</span>}
                                                            </p>
                                                        :
                                                        i.error.fourth ?
                                                        <p className='summaryP'>❌&nbsp;
                                                            <span style={{fontWeight: 'bold'}}>Image proccesing tool failed.</span>
                                                        </p>
                                                        : ''
                                                        }
                                                    </span>
                                                )
                                            }
                                        )
                                    : summaryUpload.data.photo.size>0 ? <><p className="summarySubHeading">No errors for screenshots</p></> : ''}  

                                </div> 
                                
                            : ''}

                            {summaryUpload.data.preview.size>0 ?
                            <p className="summaryHeading">Preview Errors
                                &nbsp;
                                {summaryUpload.data.preview.errorList.length===0 ? 
                                    <img src="/assets/Icons/tik.svg" alt="positive_mark" />
                                :
                                    <img src="/assets/Icons/x.svg" alt="negative_mark" />
                                }
                            </p> : ''}

                            {summaryUpload.data.preview.size>0 ? 

                                <div style={{backgroundColor:'#fff', borderRadius:'10px', padding:'10px 10px 20px 10px'}}>
                                    {summaryUpload.data.preview.errorList.length>0 ? summaryUpload.data.preview.errorList.map((i,index)=>{
                                        return(
                                            <span key={index+'uploadSummaryText'}>
                                                <p className="summarySubHeading">{langJSON[i.language_code.toLowerCase()].nameEnglish} - {i.language_code} - {i.device_size} - {i.file_name}</p>
                                                {i.error.first ? 
                                                    <p className='summaryP'>❌&nbsp;
                                                        {i.error.first.errors.length !==0 ?
                                                        <span style={{fontWeight: 'bold'}}>{i.error.first.errors[0].title}</span> : <span style={{fontWeight: 'bold'}}>Image proccesing tool failed.</span>}
                                                    </p>
                                                :
                                                i.error.second ?
                                                    <p className='summaryP'>❌&nbsp;
                                                        {i.error.second.errors.length !==0 ?
                                                        <span style={{fontWeight: 'bold'}}>{i.error.second.errors[0].title}</span> : <span style={{fontWeight: 'bold'}}>Image proccesing tool failed.</span>}
                                                    </p>
                                                :
                                                i.error.third ?
                                                    <p className='summaryP'>❌&nbsp;
                                                        {i.error.third.errors.length !==0 ?
                                                        <span style={{fontWeight: 'bold'}}>{i.error.third.errors[0].title}</span> : <span style={{fontWeight: 'bold'}}>Image proccesing tool failed.</span>}
                                                    </p>
                                                :
                                                i.error.fourth ?
                                                    <p className='summaryP'>❌&nbsp;
                                                        <span style={{fontWeight: 'bold'}}>Image proccesing tool failed.</span>
                                                    </p>
                                                : ''
                                                }
                                            </span>
                                        )
                                    })

                                    : summaryUpload.data.preview.size>0 ? <><p className="summarySubHeading">No errors for previews</p></> : ''}  
                                </div>
                            : ''}                    






                    </div>

                    <div className="summaryPopupButtons">
                        <span onClick={()=>closePopup()} className="summary-popup-button2 popupCancel">
                            OK 
                        </span>
                    </div>
                </div>
        </div>
    )
}




export default SummaryUploadPopup