import { Route, Routes } from 'react-router-dom'
import {useParams} from 'react-router-dom'
import './KeywordPage.css'
import CollectionKeywordPage from './CollectionKeywordPage'
import CurrentKeywordPage from './CurrentKeywordPage'
import CollectionButtonsItem from '../Components/CollectionButtonsItem'
import { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import VariableContext from '../userContext/VariableContext';
import CreateCollectionPopup from '../Popup/CreateCollectionPopup'
import NewZip from '../Popup/NewZip'
import {fireCustomRequestError, fireCustomError, fireRequestError } from '../Common Functions/commonFunctions'
import GoBackButton from '../Components/GoBackButton'


function KeywordPage() {

  const[isWaitingCol, setIsWaitingCol] = useState(false)
  // const[isWaiting, setIsWaiting] = useState(false)

  const {setCurrentAppCols} = useContext(VariableContext)
  const {getColList} = useContext(VariableContext)

  const pageType = 'keyword'
  
  let {appId} = useParams()


  //get collections (themes)
  useEffect(()=>{
    
    setIsWaitingCol(true)
    var data = '';

    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/app/collection/${appId}`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      setCurrentAppCols(response.data.data)
      setIsWaitingCol(false)
      
    })
    .catch(function (error) {
      if(error.message === 'Network Error'){
        fireCustomError('NETWORK ERROR. ')
      }else{
        fireRequestError(error)
      }
    });
  
  },[getColList])
  
  return (
    
    <div className='keyword-content'>
      <div className="keyword-title-div titleArea">
        <GoBackButton/>
        <h1>Keywords</h1>
      </div>

      <CollectionButtonsItem isWaitingCol={isWaitingCol} pageType={pageType} ></CollectionButtonsItem>

      <div className="keyword-inner-container">
          <Routes>
            <Route exact  path='/' element={
              <CurrentKeywordPage/>
            }></Route>

            <Route exact  path={`/:collectionId`} element={
              <CollectionKeywordPage pageType={pageType}/>
            }></Route>
          </Routes> 
      </div>

      <CreateCollectionPopup pageType={pageType}/>
      <NewZip pageType={pageType}/>



    </div>
    
  )
}

export default KeywordPage