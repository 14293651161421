export const statusListMetadata = [
    'PREPARE_FOR_SUBMISSION',
    'READY_FOR_REVIEW',
    'INVALID_BINARY',
    'REJECTED',
    'METADATA_REJECTED',
    'DEVELOPER_REJECTED'
];

export const statusListVersion = [
    'READY_FOR_SALE',
    'PRE_ORDER_READY_FOR_SALE'
];

