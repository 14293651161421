import {useContext, useState} from 'react'
import SpinnerSmall from '../Misc/SpinnerSmall'
import VariableContext from '../userContext/VariableContext'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './ChangeCollectionNamePopup.css'
import { fireRequestError, fireSuccessMessage, fireCustomError } from '../Common Functions/commonFunctions'
import Qmark from '../Misc/Qmark'

function ChangeCollectionNamePopup({colId, func}) {

    let {appId, collectionId} = useParams()

    const[changingName, setChangingName] = useState(false)
    const[newName, setNewName] = useState('')

    const{getColList, setGetColList} = useContext(VariableContext)

    //popup create collection close
    const closePopup = () => {
        func(null)
    }
    //popup create collection name input
    const handleChange = (e) =>{
        setNewName(e.target.value)
    }

    //popup collection create request and close popup
    const changeName = () =>{ 
        setChangingName(true)
        var data = JSON.stringify({
        "appId": appId,
        "collectionId" : colId ? colId : collectionId,
        "collectionTitle": newName
        });
        
        var config = {
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/app/collection/title`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then(function (response) {
        closePopup()
        setGetColList(getColList+1)

        fireSuccessMessage(response)
        })
        .catch(function (error) {
        closePopup()
        
        if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            fireRequestError(error)
          }
        });  
    }

    const changeNameE = (e) =>{ 
        e.preventDefault()
        setChangingName(true)
        var data = JSON.stringify({
        "appId": appId,
        "collectionId" : colId ? colId : collectionId,
        "collectionTitle": newName
        });
        
        var config = {
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/app/collection/title`,
        headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`, 
            'Content-Type': 'application/json'
        },
        data : data
        };
        
        axios(config)
        .then(function (response) {
        closePopup()
        setGetColList(getColList+1)
        fireSuccessMessage(response)
        })
        .catch(function (error) {
        closePopup()
        
        if(error.message === 'Network Error'){
            fireCustomError('NETWORK ERROR. ')
          }else{
            fireRequestError(error)
          }
        });   
    }



    return (
        <div id='changeNamePopup' className='name-popup-container for_owner'>
            <div className='changeNamePopupBack' onClick={changingName ? ()=>{} : ()=>{closePopup()}}></div>
            <div className='name-popup-content'>
                <span className="name-popup-name">Rename Collection <Qmark link="https://doc.storesyncer.com/collections/what-are-collections" /></span>

                <form className='change-name-form' onSubmit={changeNameE}>
                    <input id='nameForm' autoFocus={true} onChange={handleChange} className="name-popup-form" type="text" value={newName} placeholder='New Collection Name' maxLength="15"/>
                    <input type='submit' hidden={true} disabled={changingName}/>
                    <div className="changeNamePopupButtons">
                        <button disabled={changingName} onClick={changeName} className="name-popup-button">
                            {!changingName ? 'Change' : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}><SpinnerSmall/></div> } 
                        </button>
                        <span disabled={changingName} onClick={!changingName ? ()=>closePopup() : ()=>{}} className="name-popup-button2 popupCancel">
                            Cancel 
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChangeCollectionNamePopup